import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { message } from 'antd';
import { AUTHROUTE, NONAUTHROUTE } from "../../../constants/RouterPath"
import { errorMessage } from '../../../utils/ServiceHandler';
import { authenticationService } from '../../general/login/login.service';
import { get_patient_obj, get_user_obj, get_provider_obj, get_provider_user_obj, get_consulation_obj, get_formated_date, get_price_with_curr } from './EnquiryUtils';
import { getParsedPatientSlots1 } from '../../provider/scheduler/SchedulerUtils';
import OtpInput from '../../../component/shared/otp/index';
import { Button, Spinner, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import './enquiry.style.css'
import moment from "moment-timezone";
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { FaUser, FaFile, FaFileAlt, FaFileWord } from 'react-icons/fa';
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import SlotsCarousel from './slotsCarousel';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Role from '../../../constants/role';
export class EnquiryPickSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            enquiry_response: {},
            selected_enquiry: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            consultation_obj: {},
            schedules: [],
            available_slots: [],
            enquiry_id: this.props && this.props.match && this.props.match.params && this.props.match.params.id,
            confirmationModal: false,
            OTPconfirmationModal: false,
            confirmLoading: false,
            prebookingModal: false,
            numInputs: 6,
            isDisabled: false,
            hasErrored: false,
            separator: '-',
            isInputNum: false,
            isInputSecure: false,
            placeholder: '',
            fileList: [],
            otp_val: '',
            schedule_date: '',
            schedule_id: '',
            schedule_slot_id: '',
            selected_slot: '',
            selected_date: '',
            salesOrderId: '',
            session_id: '',
            salesOrderCode: '',
            IsDate: false,
            loadedSlots: ""
        }
    }

    handleSubmit = async (e) => {
        if (this.state.selected_date) {
            //this.setState({ confirmationModal: true });
            this.setState({ prebookingModal: true, IsDate: false });
        }
        else {
            this.setState({ IsDate: true });
        }


        e.preventDefault();
    }
    getEnquiryDetails = async () => {
        try {
            this.setState({ loading: true });
            let response_id = await EnquiryListService.getEncryptedID(this.state.enquiry_id);
            if (response_id && response_id.data && response_id.data.status === "success") {
                let response = await EnquiryListService.getNonAuthEnquiryDetails(response_id.data.data);
                if (response && response.data && response.data.status === "success") {
                    const _response = response.data && response.data.data;
                    const _enquiry = _response.enquiryInfo
                    const _patient_obj = get_patient_obj(_response)
                    const _user_obj = get_user_obj(_response)
                    const _provider_obj = get_provider_obj(_response)
                    const _provider_user_obj = get_provider_user_obj(_provider_obj)
                    const _consultation_obj = get_consulation_obj(_response)
                    //const _schedules = _response.schedules
                    let provider_id = _provider_obj._id;
                    if (provider_id) {
                        let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
                        if (response.data.status === "success") {
                            if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
                                const responseObj = response.data
                                const _schedules = responseObj.schedules
                                const all_schedule = getParsedPatientSlots1(_schedules);
                                setTimeout(() => {
                                    this.setState({
                                        available_slots: all_schedule,
                                        scheduleloading: false,
                                        loadedSlots: true
                                    })
                                }, 100);
                            }
                        } else {
                            this.setState({ scheduleloading: false, available_slots: [], loadedSlots: false })
                        }
                    } else {
                        this.setState({ scheduleloading: false, available_slots: [], loadedSlots: false })
                        message.error(response.data.message);
                    }
                    this.setState({ loading: false })
                    this.setState({
                        enquiry_response: _response,
                        selected_enquiry: _enquiry,
                        patient_obj: _patient_obj,
                        user_obj: _user_obj,
                        provider_obj: _provider_obj,
                        provider_user_obj: _provider_user_obj,
                        consultation_obj: _consultation_obj,
                        fileList: _enquiry.documents

                    })
                } else {
                    this.setState({ loading: false })
                    message.error("Login to view your Enquiries")
                    this.props.history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
                }
            }
        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }

    }
    handleClose() {
        this.setState({ showImageViewer: false });
        this.setState({ showEditModal: false });
        this.setState({ prebookingModal: false })
    }
    documentViewer(document_url) {
        this.setState({ selected_view_document: document_url, showImageViewer: true });
    }

    componentDidMount() {
        this.getEnquiryDetails();
    }

    handleCancelModalRemove = () => {
        this.setState({ confirmationModal: false, OTPconfirmationModal: false, prebookingModal: false });
    }

    handleOkModalRemove = async () => {
        this.setState({ confirmLoading: true, otp_val: '' });
        var data = {
            "userid": this.state.user_obj && this.state.user_obj._id,
        };
        try {
            let response = await EnquiryListService.generateConsulationOTP(data);
            if (response.data.status === "success") {
                this.setState({ confirmationModal: false, confirmLoading: false, OTPconfirmationModal: true });
            } else {
                this.setState({ confirmationModal: false, confirmLoading: false, OTPconfirmationModal: true });
                //message.error(response.data.message);
            }
        } catch (error) {
            //errorMessage(error)
            this.setState({ confirmationModal: false, confirmLoading: false, OTPconfirmationModal: true });
        }
    };

    OTPPreValidation = () => {
        let errors = false
        if (!this.state.otp_val || (this.state.otp_val && (this.state.otp_val.length < this.state.numInputs))) {
            // message.error("Please enter OTP and proceed")
            errors = true
        }
        return errors
    }
    handleOtpChange = (otp_val) => {
        this.setState({ otp_val });
    };

    handleOTPOkModalRemove = async () => {
        if (!this.OTPPreValidation()) {
            this.setState({ confirmLoading: true });
            var data = {
                "userid": this.state && this.state.user_obj && this.state.user_obj._id,
                "verificationcode": this.state && this.state.otp_val,
                "patient_id": this.state && this.state.patient_obj && this.state.patient_obj._id,
                "provider_id": this.state && this.state.provider_obj && this.state.provider_obj._id,
                "selected_slot": this.state && this.state.selected_slot,
                "schedule_slot_id": this.state && this.state.schedule_slot_id,
                "price_id": this.state && this.state.provider_obj && this.state.provider_obj.price_id,
                "amount": this.state && this.state.provider_obj && this.state.provider_obj.price,
                "enquiry_id": this.state && this.state.selected_enquiry && this.state.selected_enquiry._id,
                "schedule_id": this.state && this.state.schedule_id,
                "schedule_date": this.state && this.state.schedule_date,
            };
            try {
                let response = await EnquiryListService.verifyConsulationOTP(data);
                if (response.data.status === "success") {
                    this.setState({ confirmLoading: false, OTPconfirmationModal: false, prebookingModal: true, salesOrderId: response && response.data.salesOrderId, session_id: response && response.data.session_id, salesOrderCode: response && response.data.salesOrderCode });
                    //this.navigateToCheckout(response.data)

                } else {
                    this.setState({ confirmLoading: false });
                    //message.error(response.data.message);
                }
            } catch (error) {
                errorMessage(error)
                this.setState({ confirmLoading: false })
            }
        }
    }

    handleOnSelectSlot = (event) => {
        // let eventobj = {}
        // eventobj["schedule_obj"] = this.state.available_slots[event.target.value];
        // eventobj["key"] = event.target.value;
        // if (eventobj && eventobj.key) {

        //     this.setState({ schedule_slot_id: eventobj.schedule_obj._id })
        // }   
        // if (eventobj && eventobj.schedule_obj) {
        //     if (eventobj.schedule_obj.providerScheduleId) {
        //         console.log("provider-id",eventobj.schedule_obj.providerScheduleId )

        //         this.setState({ schedule_id: eventobj.schedule_obj.providerScheduleId })
        //     } if (eventobj.schedule_obj.date) {
        //         console.log("date",eventobj.schedule_obj.date)
        //         this.setState({ schedule_date: eventobj.schedule_obj.date })

        //     } if (eventobj.schedule_obj.slot) {
        //         let _date = moment(eventobj.schedule_obj && eventobj.schedule_obj.slot).tz(authenticationService && authenticationService.user_timezone).format('MMM D, YYYY')
        //         let time_slot = moment(eventobj.schedule_obj && eventobj.schedule_obj.slot).tz(authenticationService && authenticationService.user_timezone).format('LT z')
        //         this.setState({ selected_date: _date })
        //         this.setState({ selected_slot: time_slot })
        //     }
        // }
        if (event) {
            const attTimeAndDate = moment(event.date + ' ' + event.slot, 'YYYY-MM-DD HH:mm A').format()
            let _date = moment(attTimeAndDate).tz(authenticationService && authenticationService.user_timezone).format('MMM D, YYYY')
            //let time_slot = moment(attTimeAndDate).tz(authenticationService && authenticationService.user_timezone).format('LT z')
            this.setState({
                schedule_slot_id: event._id, schedule_id: event.providerScheduleId,
                schedule_date: event.date, selected_date: _date, selected_slot: event.slot
            })
        }
    }

    handlePreBookingOkModalRemove = async () => {
        this.setState({ loading: true })
        var data = {
            "userid": this.state && this.state.user_obj && this.state.user_obj._id,
            "patient_id": this.state && this.state.patient_obj && this.state.patient_obj._id,
            "provider_id": this.state && this.state.provider_obj && this.state.provider_obj._id,
            "selected_slot": this.state && this.state.selected_slot,
            "schedule_slot_id": this.state && this.state.schedule_slot_id,
            "price_id": this.state && this.state.provider_obj && this.state.provider_obj.price_id,
            "amount": this.state && this.state.provider_obj && this.state.provider_obj.price,
            "enquiry_id": this.state && this.state.selected_enquiry && this.state.selected_enquiry._id,
            "schedule_id": this.state && this.state.schedule_id,
            "schedule_date": this.state && this.state.schedule_date,
        };
        try {
            let response = await EnquiryListService.generateOrdercode(data);
            if (response.data.status === "success") {
                this.setState({ confirmLoading: false, OTPconfirmationModal: false, prebookingModal: false, salesOrderId: response && response.data.salesOrderId, session_id: response && response.data.session_id, salesOrderCode: response && response.data.salesOrderCode });

                var data1 = {
                    "ordercode": this.state && this.state.salesOrderCode,
                };
                try {
                    let response = await EnquiryListService.confirmAppointmentBooking(data1);
                    if (response.data.status === "success") {
                        this.setState({ confirmLoading: false });

                        this.navigateToAppointmentConfirmation()
                        this.setState({ loading: false })
                    } else {
                        this.setState({ confirmLoading: false });
                        this.setState({ loading: false })
                        message.error(response.data.message);
                    }
                } catch (error) {
                    errorMessage(error)
                    this.setState({ confirmLoading: false });
                    this.setState({ loading: false })
                }
            } else {
                this.setState({
                    confirmLoading: false, loading: false, OTPconfirmationModal: false, prebookingModal: false
                });
                message.error(response.data.message);
            }
        } catch (error) {
            errorMessage(error)
            this.setState({ confirmLoading: false });
        }
    }

    navigateToAppointmentConfirmation() {
        this.props.history.push({
            pathname: AUTHROUTE.PATIENT.APPOINTMENT_CONFIRMATION_AUTH,
            state: {
                selected_enquiry: this.state.selected_enquiry,
                patient_obj: this.state.patient_obj,
                user_obj: this.state.user_obj,
                provider_obj: this.state.provider_obj,
                provider_user_obj: this.state.provider_user_obj,
                schedule_slot_id: this.state.schedule_slot_id,
                selected_slot: this.state.selected_slot,
                selected_date: this.state.selected_date,
                schedule_id: this.state.schedule_id,
                schedule_date: this.state.schedule_date,
                salesOrderId: this.state.salesOrderId,
                session_id: this.state.session_id,
                salesOrderCode: this.state.salesOrderCode,
            }
        });
    }

    renderConfirmationBlock = () => {
        return (
            <div>
                <span style={{ fontSize: '13px', color: 'grey' }}>
                    <strong>Order #{this.state && this.state.consultation_obj && this.state.consultation_obj.orderCode}</strong>. </span>
            </div>
        )
    }


    render() {
        const currentUser = authenticationService.currentUserValue;
        return (
            <AfterLoginLayout>

                {this.state.loading &&
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-3 p-4' variant="primary">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                }
                {(() => {
                    if (this.state && this.state.selected_enquiry) {
                        if (this.state.consultation_obj && this.state.consultation_obj._id) {
                            return <React.Fragment>
                                {!this.state.loading && <Container fluid className='pickslotlayout'>
                                    <Row style={{ "width": "100%" }}>
                                        <Col lg={12} md={12} sm={12} className='pb-3'> <h5 className='text-left'><Link to={{ pathname: currentUser.usertype === Role.patient.name ? AUTHROUTE.PATIENT.ENQUIRIES_PATH : AUTHROUTE.LOCALPROVIDER.PATIENTPATH }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link>Pick your slot</h5>
                                            <p>We have selected the right doctor for you</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={9} md={9} sm={12} className=' card ml-3 ' style={{ height: '150px' }}>
                                            <div className='card-body text-center mt-4' >
                                                <h6>Appointment has been already Booked!</h6>
                                                <p>{this.renderConfirmationBlock()}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                }
                            </React.Fragment>
                        } else {
                            return <React.Fragment>
                                {!this.state.loading && <Container fluid className='pickslotlayout'>
                                    <Row style={{ "width": "100%" }}>
                                        <Col lg={12} md={12} sm={12} className='pb-3'> <h5 className='text-left'><Link to={{ pathname: currentUser.usertype === Role.patient.name ? AUTHROUTE.PATIENT.ENQUIRIES_PATH : AUTHROUTE.LOCALPROVIDER.PATIENTPATH }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link>Pick your slot</h5>
                                            <p>We have selected the right doctor for you</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} md={4} sm={12} className='leftcontainer'>
                                            <Card>
                                                <Card.Header><div className='dricon'><FaUser className='fa-2x' /></div><div className='drname'>
                                                    <div>{this.state.provider_obj && "Dr. " + this.state.provider_obj.firstname}</div>
                                                    <div className='speciality'>{this.state.selected_enquiry && this.state.selected_enquiry.speciality && this.state.selected_enquiry.speciality.name}</div>
                                                </div></Card.Header>
                                                <Card.Body>
                                                    <Card.Title></Card.Title>
                                                    {/* <Card.Text>
                                                        <p>Some quick example text to build on the card title and make up the bulk
                                                            of the card's content. </p>
                                                    </Card.Text> */}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} className='rightcontainer'>
                                            <Row className='py-2' style={{ "border-bottom": "1px solid #ccc" }}>
                                                <Col lg={4} md={4} sm={12}>
                                                    <div className='breadcrumb-topics'>REFERENCE NO</div>
                                                    <div className='data mt-2'>{this.state.selected_enquiry && this.state.selected_enquiry.enquiryCode}</div>
                                                </Col>
                                                <Col lg={4} md={4} sm={12}>
                                                    <div className='breadcrumb-topics'>SUBMITTED DATE</div>
                                                    <div className='data mt-2'>{this.state.selected_enquiry && get_formated_date(this.state.selected_enquiry.createddate)}</div>
                                                </Col>
                                                <Col lg={4} md={4} sm={12}>
                                                </Col>
                                            </Row>
                                            <Row className='py-2' style={{ "border-bottom": "1px solid #ccc" }}>
                                                <Col lg={4} md={4} sm={12}>
                                                    <div className='breadcrumb-topics'>PATIENT</div>
                                                    <div className='data mt-2'>{this.state.patient_obj && this.state.patient_obj.firstname}</div>
                                                </Col>
                                                <Col lg={4} md={4} sm={12}>
                                                    <div className='breadcrumb-topics'>CONCERN</div>
                                                    <div className='data mt-2'>{this.state.selected_enquiry && this.state.selected_enquiry.enquiry_message}</div>
                                                </Col>
                                            </Row>
                                            <Row className='py-3 ' >
                                                <Col lg={10} md={10} sm={12} pt-6>
                                                    <div className='breadcrumb-topics'>DETAILS </div>
                                                    <Col className="gutter-row">
                                                        <Row className='mobile_row'>

                                                            {this.state.fileList.map((x, i) => {
                                                                return <Col lg={4} md={4} sm={6} xs={6} className="p-4" >
                                                                    {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                                    {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                                    {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                                    {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                        <div className='image'>
                                                                            <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            })}
                                                        </Row></Col>
                                                </Col>

                                            </Row>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12} md={12} sm={12} className="slotsection mb-3 ">
                                            {this.state.loadedSlots === false && this.state.available_slots.length === 0 && <React.Fragment>
                                                <p>No Available slots for now</p>
                                            </React.Fragment>}
                                            {
                                                this.state.available_slots && this.state.loadedSlots === true && this.state.available_slots.length !== 0 &&
                                                <>
                                                    <h6 className='text-left'>Please book a slot and confirm the appointment</h6>
                                                    <SlotsCarousel availableSlots={this.state.available_slots} onChangeEvent={this.handleOnSelectSlot}
                                                        onHandleSubmit={this.handlePreBookingOkModalRemove} IsDate={this.state.IsDate} />
                                                </>
                                            }
                                        </Col>

                                        <Modal show={this.state.showImageViewer}
                                            centered
                                            backdrop="static" onHide={this.handleClose.bind(this)}>
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>
                                            </Modal.Body>
                                        </Modal>

                                        <Modal show={this.state.confirmationModal} onHide={this.state.confirmationModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>OTP Generation</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>An OTP will be sent to the registered Email & Mobile Number for Verification.</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary m-1" onClick={this.handleCancelModalRemove}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary m-1" onClick={this.handleOkModalRemove}>
                                                    Ok
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={this.state.OTPconfirmationModal} onHide={this.state.OTPconfirmationModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Please Enter verification code</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <OtpInput
                                                    inputStyle="inputStyle"
                                                    numInputs={this.state.numInputs}
                                                    isDisabled={this.state.isDisabled}
                                                    hasErrored={this.state.hasErrored}
                                                    errorStyle="error"
                                                    onChange={this.handleOtpChange}
                                                    separator={<span>{this.state.separator}</span>}
                                                    isInputNum={false}
                                                    isInputSecure={this.state.isInputSecure}
                                                    value={this.state.otp_val}
                                                    placeholder={this.state.placeholder}
                                                />
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary m-1" onClick={this.handleCancelModalRemove}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary m-1" onClick={this.handleOTPOkModalRemove}>
                                                    Ok
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={this.state.prebookingModal} size='lg' fullscreen='xl-down' onHide={this.handleClose.bind(this)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> Make your payment</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {this.state.loading &&
                                                    <div className='d-flex justify-content-center flex-nowrap'>
                                                        <div className='position-absolute mx-3 p-4' variant="primary" style={{ marginTop: "15rem" }}>
                                                            <Spinner animation="border" variant="primary" />
                                                        </div>
                                                    </div>
                                                }

                                                {!this.state.loading && <Container fluid className='prebookingmodal'>


                                                    <Row>

                                                        <Col lg={6} md={6} sm={12}>
                                                            <Row>
                                                                <Col>
                                                                    <Row className='py-2'><Col><div className='title'>Total Cost<div>Session fee for 15 minutes</div></div></Col>
                                                                        <Col><div className='values'>{this.state.provider_obj && (get_price_with_curr(this.state.provider_obj.price) || "-")}</div></Col></Row>
                                                                    <Row className='py-2'><Col><div className='title'>Amount to be paid</div></Col>
                                                                        <Col><div className='values'>{this.state.provider_obj && (get_price_with_curr(this.state.provider_obj.price) || "-")}</div></Col></Row>
                                                                    <Row className='py-2'><Col>Payment details</Col></Row>

                                                                    <Row className='py-2'><Col className='m-0 p-2'><Button variant="primary m-1" onClick={this.handlePreBookingOkModalRemove}>

                                                                        Confirm payment
                                                                    </Button>

                                                                    </Col></Row>
                                                                </Col></Row>
                                                        </Col>

                                                        <Col lg={6} md={6} sm={12}><Row><Col><div className='prebookinginfo'></div></Col></Row></Col>
                                                    </Row>
                                                </Container>}
                                            </Modal.Body>
                                        </Modal>
                                    </Row>
                                </Container>}
                            </React.Fragment>
                        }
                    }
                })()}

            </AfterLoginLayout>

        )
    }

}


export default withRouter(EnquiryPickSlot);
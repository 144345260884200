import {userListAction} from './types'
import UserListService from '../../services/admin/UserList.Service';
import { alertActions } from "../alertAction";

/**
 * Get all Internal User list
 * @returns 
 */
export const getUserListDetails = () => async (dispatch) => {
  try {
    dispatch(userListDataRequest())
    const responseObj =await UserListService.getInternalUsers();
    if (responseObj.data) {
      const users = responseObj.data && responseObj.data.data
      dispatch(userListDataSuccess({ users}))
      return Promise.resolve({ users});
    } else {
      dispatch(userListDataFailure(responseObj.data.message));
      dispatch(alertActions.error(responseObj.data.message))
      return Promise.reject(responseObj.data.message);
    }
  } catch (err) {
    dispatch(userListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const userListDataRequest = () => {
  return {
    type: userListAction.DATA_REQUEST
  }
}

export const userListDataSuccess = data => {
  return {
    type: userListAction.DATA_SUCCESS,
    payload: data,
  }
}

export const userListDataFailure = error => {
  return {
    type: userListAction.DATA_FAILURE,
    payload: error
  }
}

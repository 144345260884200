import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { connect } from 'react-redux'
import { getProviderListDetails } from '../../../actions/admin/ProviderListAction'
import { message } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ProviderListService from '../../../services/admin/ProviderList.Service';
// import { formValidation } from '../../general/formvalidator/form.validation';
import "../../provider/profile/providerProfile.style.css"
import { errorMessage } from '../../../utils/ServiceHandler';
// import CreateProvider from './CreateProvider';
// import UpdateProviderScheduler from './UpdateProviderScheduler';
import SetAssignee from '../providers/SetAssignee';
import { handleOnSelectStage } from '../../patient/enquiry/EnquiryUtils';
import { Spinner, Badge, Button, Modal, ModalTitle, Form, Tabs, Tab } from 'react-bootstrap';


// import { Link } from 'react-router-dom';
import { HiOutlineUsers, HiOutlineCalendar } from 'react-icons/hi';
import { BsPersonPlus } from 'react-icons/bs';

import '../../patient/appointment/Appointment.css';
import UpdateProviderScheduler from '../../admin/providers/UpdateProviderScheduler';

import CreateProvider from './CreateProvider';
import { Formik } from 'formik';
// import { formikFormalidation } from '../../general/formvalidator/validationFroms';
import * as yup from 'yup'

import { formikFormalidation } from '../../general/formvalidator/validationFroms'
// const { TabPane } = Tabs;

export class ProviderListComponent extends React.Component {
  formRef = React.createRef();
  assigneeFormRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      npiresult: {},
      all_specialities: [],
      user_taxonomies: [],
      // selectedRowKeys: [],
      allSelectedRows: [],
      selectedRows: [],
      providers: [],
      internalusers: [],
      stages: [],
      selected_provider: {},
      provider_status: false,
      modalisVisible: false,
      assignee_modal: false,
      loading: false,
      showCreateProviderModal: false,
      isAssigneeRequired: false,
      hasSelected: false,
      tableFilterOption: 'new',
      newCount: 0,
      incompleteCount: 0,
      userTypeInfo: { firstname: "", lastname: "", NPI: "", experience: "", speciality: "", stage_id: "", taxonomy: "", assignee_id: "", timezone: "" },
      timezoneList: []
    };
    this.tableFilterOptionFn = this.tableFilterOptionFn.bind(this);
    this.countFinder = this.countFinder.bind(this);
    this.handleSpecialityChange = this.handleSpecialityChange.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
  }

  onFinish = async (values) => {
    let values1 = {
      ...values, speciality: Array.isArray(values.speciality) ? values.speciality : [values.speciality],
      taxonomy: Array.isArray(this.state.userTypeInfo.taxonomy) ? this.state.userTypeInfo.taxonomy : [this.state.userTypeInfo.taxonomy]
    }

    const allFormData = {
      formData: values1,
      currentProvider: this.state.selected_provider,
    }
    try {
      this.setState({ loading: true })
      let response = await ProviderListService.update(allFormData);
      if (response && response.data && response.data.status === "success") {
        message.success(response.data.message);
        this.setState({ loading: false, modalisVisible: false, selected_provider: [] })
        this.getProviderData()
      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }
    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }

  }

  setDefaultstage = () => {
    this.state.stages?.map((item) => {
      if (item.stage === "New") {
        this.setState({
          userTypeInfo: { ...this.state.userTypeInfo, stage_id: item._id }
        });       
      }
      return true;
    });
   
  }

  showCreateProviderModal = () => {
    this.setState({
      showCreateProviderModal: true,
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };

  setAssignee = (current_record, bulkassign) => {
    if (!bulkassign) {
      this.setState({ selectedRows: [current_record] });
    }
    this.setState({ assignee_modal: true })
  };

  getnpi = async (npi_id) => {
    try {
      this.setState({ loading: true })
      const response = await ProviderListService.getNPIDetails(npi_id)
      this.setState({ loading: false })
      if (response && response.data && response.data.status === "success") {
        this.setState({ npiresult: response.data })
      } else {
        this.setState({ npiresult: {} })
        message.error("Error while fetching NPI details : " + response.data.message);
      }
    } catch (error) {
      this.setState({ loading: false })
      errorMessage(error)
    }
  }

  getProviderData = () => {
    this.handleCancel();
    this.props.getProviderListDetails().then((response) => {
      let providers = response.users
      let stages = response.stages
      let internalusers = response.internalusers
      let all_specialities = response.specialities
      this.setState({ providers, stages, internalusers, all_specialities })
      this.setDefaultstage()
    }).then(x => {
      this.tableFilterOptionFn('new')
    }).then(x => {
      this.countFinder()
    }).catch(err => {
      errorMessage(err)
    })
  }

  componentDidMount() {
    this.getProviderData();
  }
  // onSelectChange = selectedRowKeys => {
  //   this.setState({ selectedRowKeys });
  // };
  handleCancel = () => {
    this.setState({ modalisVisible: false });
    this.setState({ assignee_modal: false, selectedRows: [] });
    this.setState({ showCreateProviderModal: false });
  };
  handleModalOk = () => {
    this.setState({ modalisVisible: false });
    this.setState({ assignee_modal: false, selectedRows: [] });
  };
  // Set Stages
  set_stage = (stage_id) => {
    let stage;
    const filteredResults = this.state.stages.filter(obj => obj._id === stage_id)
    if (filteredResults && filteredResults.length > 0) {
      stage = filteredResults[0] && filteredResults[0]._id
    } else { stage = null }
    return stage
  }
  // Set Provider Status (Active/Inactive)
  set_provider_status = (record) => {
    let status = false;
    if (record && record.providerInfo && record.providerInfo.length > 0) {
      let data = record.providerInfo[0];
      status = data.status && data.status === "Active" ? true : false;
    }
    return status
  }
  // Set Assignee
  set_assignee = (assignee_id) => {
    let assignee;
    const filteredResults = this.state.internalusers && this.state.internalusers.filter(obj => obj._id === assignee_id)
    if (filteredResults && filteredResults.length > 0) {
      assignee = filteredResults[0] && filteredResults[0]._id
    } else { assignee = null }
    return assignee
  }
  getFilteredTaxonomies(filteredResults) {
    var filtered_taxonomies = []
    if (filteredResults && filteredResults.length > 0) {
      filteredResults.forEach(e => {
        if ('taxonomies' in e) {
          var taxonomies = e['taxonomies']
          taxonomies.forEach(element => {
            filtered_taxonomies.push(element)
          });
        }
      })
    }
    return filtered_taxonomies
  }
  handleSpecialityChange(id) {
    const filteredResults = this.state.all_specialities.filter(result => id.includes(result._id))
    const user_taxonomies = this.getFilteredTaxonomies(filteredResults)
    this.setState({ user_taxonomies })
    this.formRef.current.setFieldsValue({ taxonomy: [] })
  }
  onChangeSwitch(checked) {
    this.setState({ provider_status: checked });
  }
  handleSelect(value) {
    this.state.all_specialities.map((data) => {
      if (data._id === value) {
        this.setState({ user_taxonomies: data.taxonomies })

      }
      return data;
    })
    this.setState({
      userTypeInfo: {
        ...this.state.userTypeInfo,
        taxonomy: "",
        speciality: value
      }
    });
  }

  handleSelectTaxonomy(value) {
    if (value) {
      this.setState({
        userTypeInfo: {
          ...this.state.userTypeInfo,
          taxonomy: value
        }
      });
    }
  }

  getTimezoneList = async () => {
    const responses = await ProviderListService.getTimezoneList();
    if (responses.data.status === "success") {
      this.setState({ timezoneList: responses.data.data });
    }
  }

  edit_record = (record) => {
    setTimeout(() => {
      if (record && record.providerInfo && record.providerInfo.length > 0) {

        this.setState({ selected_provider: record });
        this.getTimezoneList();

        let provider_status = this.set_provider_status(record)
        this.setState({ provider_status: provider_status });

        const { npi, experience, speciality, taxonomy  } = record.providerInfo[0] || {};
         //const stage = this.set_stage(stage_id);
        //assignee = this.set_assignee(assignee_id);

        const filteredResults = this.state.all_specialities.filter(result => speciality && speciality.includes(result._id))
        const user_taxonomies = this.getFilteredTaxonomies(filteredResults)
        this.setState({ user_taxonomies, taxonomy })

        this.getnpi(npi);
        const userInfo = {
          firstname: record.firstname || "",
          lastname: record.lastname || "",
          assignee_id: record.providerInfo[0].assignee_id,
          stage_id: record.providerInfo[0].stage_id || this.state.userTypeInfo.stage_id,
          NPI: npi,
          experience: experience,
          speciality: speciality ? record.providerInfo[0].speciality[0] : "",
          taxonomy: taxonomy ? record.providerInfo[0].taxonomy[0] : "",
          timezone: record.timezone || "",
          provider_status: provider_status || false
        }
        this.setState({ userTypeInfo: userInfo });
      } else {
        message.error("Not a valid Provider")
        this.setState({ modalisVisible: false });
      }
      this.setState({ modalisVisible: true });
    }, 1);


  }

  countFinder = async () => {
    const newCount = await (this.state.providers.filter(x => (x && x.providerInfo && x.providerInfo?.length > 0 && (x.providerInfo['0'].assignee_id === '' || !x.providerInfo['0'].assignee_id)))).length;
    const incompleteCount = await (this.state.providers.filter(x => x)).length;
    this.setState({
      newCount: newCount, incompleteCount: incompleteCount
    });
  }

  tableFilterOptionFn = async (option) => {
    await this.setState({ allSelectedRows: [] })
    await this.setState({ tableFilterOption: option });
    if (this.state.tableFilterOption === 'new') {
      let allProviders = this.state.providers;
      let allSelectedRows = await allProviders.filter(x => (x && x.providerInfo && x.providerInfo?.length > 0 && (x.providerInfo['0'].assignee_id === '' || !x.providerInfo['0'].assignee_id)));
      this.setState({ allSelectedRows: allSelectedRows });
    }
    if (this.state.tableFilterOption === 'incomplete') {
      let allSelectedRows = this.state.providers;
      this.setState({ allSelectedRows: allSelectedRows });
    }
  }

  get_stage = (data) => {
    let stage_name = "";
    if (data && data.stage_id) {
      const filteredResults = this.state.stages.filter(obj => obj._id === data.stage_id)
      if (filteredResults && filteredResults.length > 0) {
        stage_name = filteredResults[0] && filteredResults[0].stage
      } else { stage_name = "-" }
    } else { stage_name = "-" }
    return stage_name
  }
  get_assignee = (data) => {
    let displayname = "";
    if (data && data.assignee_id) {
      const filteredResults = this.state.internalusers && this.state.internalusers.filter(obj => obj._id === data.assignee_id)
      if (filteredResults && filteredResults.length > 0) {
        displayname = filteredResults[0] && filteredResults[0].displayname
      } else { displayname = "-" }
    } else { displayname = "-" }
    return displayname
  }

  handleOnSelectStage = (value, event) => {
    this.setState({ isAssigneeRequired: handleOnSelectStage(value, event) })
  }

  render() {
    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      lastname: formikFormalidation.lastname,
      NPI: formikFormalidation.npi,
      taxonomy: formikFormalidation.taxonomy,
      speciality: formikFormalidation.speciality,
      experience: formikFormalidation.experience,
      assignee_id: formikFormalidation.assignee_id,
      //stage_id: formikFormalidation.stage_id,
      timezone: formikFormalidation.timezone

    });
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );


    const options = {
      sizePerPage: 5,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };



    const selectRow = {
      mode: 'checkbox',
      // clickToSelect: true,
      onSelectAll: async (isSelect, rows, e) => {
        if (isSelect) {
          this.setState({ selectedRows: await [...rows, ...this.state.selectedRows] });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        } else {
          const { selectedRows } = this.state;
          rows.forEach(row => {
            selectedRows.splice(
              selectedRows.indexOf(row), 1
            );
          });
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        }
        this.state.hasSelected = this.state.selectedRows.length > 0;

      },
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          const { selectedRows } = this.state;
          selectedRows.push(row);
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        } else {
          const { selectedRows } = this.state;
          selectedRows.splice(
            selectedRows.indexOf(row), 1
          );
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        }
        this.setState({ hasSelected: this.state.selectedRows.length > 0 });

      }
    };

    const columns = [
      {
        text: 'First Name',
        dataField: 'firstname',
        sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      },
      {
        text: 'Last Name',
        dataField: 'lastname',
        key: 'lastname',
        sorter: (a, b) => a.lastname.localeCompare(b.lastname),

      },
      {
        text: 'NPI',
        dataField: 'providerInfo.npi',
        key: 'npi',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                return (
                  <span>{record.providerInfo[0].npi}</span>
                )
              }
            })()}
          </>
        ),
      },
      {
        text: 'Active',
        dataField: 'providerInfo.status',
        key: 'status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                let data = record.providerInfo[0]
                if (data.status === 'Active')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Active </Badge>
                  </React.Fragment>
                  )
                if (data.status === 'Inactive')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#FBC5C5', color: '#EB4747', fontSize: '14px' }}>Inactive</Badge>
                  </React.Fragment>
                  )
              }
            })()}
          </>
        ),
      },
      {
        text: '',
        dataField: 'enabled',
        formatter: (_, current_record) => (
          <>
            <Button variant='link' onClick={() => this.setAssignee(current_record, false)} disabled={(this.state?.selectedRows?.length > 1)} ><span style={{ padding: "3px" }}><HiOutlineUsers /></span>Assign to team member</Button>          </>
        ),
      },
      {
        text: '',
        dataField: '',
        formatter: (_, current_record) => (
          <div size="middle">
            <Button variant='link' onClick={() => this.edit_record(current_record)}>
              Edit
            </Button>
          </div>
        ),
      },
    ];
    const columns1 = [
      {
        text: 'First Name',
        dataField: 'firstname',
        sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      },
      {
        text: 'Last Name',
        dataField: 'lastname',
        key: 'lastname',
        sorter: (a, b) => a.lastname.localeCompare(b.lastname),

      },
      {
        text: 'NPI',
        dataField: 'providerInfo.npi',
        key: 'npi',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                return (
                  <span>{record.providerInfo[0].npi}</span>
                )
              } else {
                return (
                  <span>-</span>
                )
              }
            })()}
          </>
        ),
      },
      /*    {
            text: 'Stage',
            dataField: 'providerInfo.stage_id',
            key: 'stage_id',
            formatter: (text, record) => (
              <>
                {(() => {
                  if (record.providerInfo && record.providerInfo.length > 0) {
                    let data = record.providerInfo[0]
                    let stage_name = this.get_stage(data)
                    return (
                      <span>{stage_name}</span>
                    )
                  }
                })()}
              </>
            ),
          },
          */
      {
        text: 'Assignee',
        dataField: 'providerInfo.assignee_id',
        key: 'assignee_id',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                let data = record.providerInfo[0]
                let assignee_name = this.get_assignee(data)
                return (
                  <span>{assignee_name}</span>
                )
              } else {
                return (<span>-</span>)
              }
            })()}
          </>
        ),
      },
      {
        text: 'Active',
        dataField: 'providerInfo.status',
        key: 'status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                let data = record.providerInfo[0]
                if (data.status === 'Active')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Active </Badge>
                  </React.Fragment>
                  )
                if (data.status === 'Inactive')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#FBC5C5', color: '#EB4747', fontSize: '14px' }}>Inactive</Badge>
                  </React.Fragment>
                  )
              } else {
                return (
                  <span>- </span>
                )

              }
            })()}
          </>
        ),
      },
      {
        text: '',
        dataField: '',
        formatter: (_, current_record) => (
          <div size="middle">
            <Button variant='link' onClick={() => this.edit_record(current_record)}>
              Edit
            </Button>
          </div>
        ),
      },
    ];

    return (

      <React.Fragment >
        <AfterLoginLayout>
          <div className="container-fluid">

            <div className="banner-header">
              <h2>Global Providers</h2>
            </div>
            {!this.props.providersStore.loading ?
              <>
                <div>
                  <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'font-size': '14px' }} className='rounded-pill ml-0 my-3 font-weight-bold' onClick={() => this.tableFilterOptionFn('new')}>
                    <span style={{ padding: "3px" }}><BsPersonPlus /></span> New registration requests <Badge className='bg-light text-dark ml-2 ' style={{ fontSize: '12px' }}>{this.state.newCount}</Badge>
                  </Button>

                  <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'font-size': '14px' }} className='rounded-pill ml-0 my-3 font-weight-bold' onClick={() => this.tableFilterOptionFn('incomplete')}>
                    <span style={{ padding: "3px" }}><HiOutlineCalendar /> </span>Incomplete profiles <Badge className='bg-light text-dark ' style={{ fontSize: '12px' }}>{this.state.incompleteCount}</Badge>
                  </Button>
                </div>

                <Modal show={this.state.assignee_modal}
                  onHide={this.handleCancel} centered
                  backdrop="static">
                  <Modal.Header closeButton >
                    <ModalTitle>
                      Set Assignee
                    </ModalTitle>
                  </Modal.Header>
                  <Modal.Body className='p-5'>
                    <center >
                      {<SetAssignee
                        selectedRows={this.state.selectedRows}
                        internalUsers={this.state.internalusers}
                        onAssigned={this.getProviderData}
                        scope="PROVIDER"
                        {...this.props}
                      />}
                    </center>
                  </Modal.Body>
                </Modal >

                <div style={{ marginBottom: 10 }}>
                  {this.state.hasSelected}
                  <Button variant='link' className='p-0' onClick={() => this.setAssignee('', true)}>
                    <HiOutlineUsers />   Assign to team member
                  </Button>
                  <span style={{ marginLeft: 8 }}>
                    {this.state.hasSelected ? `Selected ${this.state.selectedRows.length} items` : ''}
                  </span>
                  <div style={{ marginBottom: 16, float: 'right' }}>
                    <Button type="primary" onClick={this.showCreateProviderModal}> Create New Global Provider</Button>
                  </div>
                </div>

                <Modal show={this.state.showCreateProviderModal}
                  onHide={this.handleCancel} centered
                  backdrop="static">
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Create Provider
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='p-5'>
                    {<CreateProvider
                      {...this.props}
                    />}
                  </Modal.Body>
                </Modal>
                <Modal
                  bodyStyle={{ height: 550 }}
                  onHide={this.handleCancel}
                  centered
                  show={this.state.modalisVisible}
                  backdrop="static"
                  onCancel={this.handleCancel}
                  onOk={this.handleModalOk}>

                  < Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Provider Verification
                    </Modal.Title>
                  </Modal.Header>
                  <div className="card-container" style={{ padding: "50px" }}>
                    <Tabs
                      defaultActiveKey="provider Information"
                      transition={false}
                      id="fill-tab-example"
                      className="mb-3"
                      fill
                    >
                      <Tab eventKey="provider Information" title="Provider Information" className='ml-2'>
                        <Formik
                          validationSchema={schema}
                          initialValues={this.state.userTypeInfo}
                          validate={values => {
                            const errors = {};
                            if (!this.state.userTypeInfo.taxonomy) {
                              errors.taxonomy = 'Taxonomy cannot be empty!';
                            }
                            return errors;
                          }}
                          onSubmit={async (values) => this.onFinish(values)}
                        >
                          {(({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    First Name <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Form.Control
                                    name="firstname"
                                    onChange={handleChange}
                                    value={values.firstname}
                                    isInvalid={errors.firstname && touched.firstname}

                                  />

                                  {
                                    errors.firstname && touched.firstname && (
                                      <div className="invalid-feedback">{errors.firstname}</div>
                                    )
                                  }

                                </div>
                              </div>

                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold"> Last Name  <span style={{ color: "red" }}>*</span></label>
                                  <Form.Control
                                    onChange={handleChange}
                                    name='lastname' value={values.lastname}
                                    isInvalid={errors.lastname && touched.lastname}

                                  />

                                  {
                                    errors.lastname && touched.lastname && (
                                      <div className="invalid-feedback">{errors.lastname}</div>
                                    )
                                  }


                                </div>
                              </div>


                              <div className='row'>
                                <div className='col form-group mr-2' >
                                  <label className="mb-1 font-weight-bold"> NPI  <span style={{ color: "red" }}>*</span></label>
                                  <Form.Control type="text"

                                    name="NPI"
                                    value={values.NPI}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.NPI && touched.NPI} />
                                  <span className={errors.NPI && touched.NPI ? 'invalid short-label' : 'short-label'}></span>

                                  {
                                    errors.NPI && touched.NPI && (
                                      <div className="invalid-feedback">{errors.NPI}</div>
                                    )
                                  }

                                </div>
                              </div>
                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    Experience <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Form.Control
                                    name="experience" onChange={handleChange} value={values.experience}
                                    isInvalid={errors.experience && touched.experience} />
                                  {
                                    errors.experience && touched.experience && (
                                      <div className="invalid-feedback">{errors.experience}</div>
                                    )
                                  }

                                </div>
                              </div>

                              <div className='row' style={{"display":"none"}} >
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    Stage <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Form.Control as={"select"}
                                    name="stage_id"
                                    onChange={handleChange}
                                    value={values.stage_id}
                                    className='lastname' isInvalid={errors.stage_id && touched.stage_id}>
                                    <option value="">Select</option>
                                    {
                                      this.state.stages?.map((item) => {
                                        const { stage, _id } = item;
                                        return <option value={_id} key={_id}>{stage}</option>
                                      })
                                    }
                                  </Form.Control>
                                  {
                                    errors.stage_id && touched.stage_id && (
                                      <div className="invalid-feedback">{errors.stage_id}</div>
                                    )
                                  }
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    Assignee  <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Form.Control as={"select"}
                                    name="assignee_id"
                                    onChange={handleChange}
                                    value={values.assignee_id}
                                    className='lastname' isInvalid={errors.assignee_id && touched.assignee_id}>
                                    <option value="">Select</option>
                                    {
                                      this.state.internalusers?.map((item) => {
                                        const { displayname, _id } = item;
                                        return <option value={_id} key={_id}>{displayname}</option>
                                      })
                                    }

                                  </Form.Control>
                                  {
                                    errors.assignee_id && touched.assignee_id && (
                                      <div className="invalid-feedback">{errors.assignee_id}</div>
                                    )
                                  }
                                </div></div>
                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    Speciality <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Form.Control as={"select"}
                                    name="speciality"
                                    className='selectors'
                                    isInvalid={errors.speciality && touched.speciality}
                                    onChange={event => { handleChange(event); this.handleSelect(event.target.value) }}
                                    onBlur={handleBlur}
                                    value={values.speciality ? values.speciality : ""}
                                    id={values.speciality ? values.speciality : ""}
                                  >
                                    <option value="">Select</option>

                                    {
                                      this.state.all_specialities?.map((item) => {
                                        const { name, _id } = item;
                                        return <option value={_id} key={_id}>{name}</option>

                                      })
                                    }

                                  </Form.Control>
                                  {!this.state.speciality &&
                                    <span className={errors.speciality && touched.speciality ? 'invalid short-label' : 'short-label'}></span>
                                  }
                                  {
                                    errors.speciality && touched.speciality && (
                                      <div className="invalid-feedback">{errors.speciality}</div>
                                    )
                                  }


                                </div> </div>
                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    Taxonomy <span style={{ color: "red" }}>*</span>
                                  </label>



                                  <Form.Control
                                    as={"select"}
                                    className='selectors'
                                    isInvalid={errors.taxonomy && touched.taxonomy}
                                    onChange={(value) => { handleChange(value); this.handleSelectTaxonomy(value.target.value) }}
                                    onBlur={handleBlur}
                                    name="taxonomy"
                                    value={values.taxonomy ? values.taxonomy : ""}
                                    id={values.taxonomy ? values.taxonomy : ""}
                                  >
                                    <option value=''>Select</option>
                                    {
                                      this.state.user_taxonomies?.map((item) => {
                                        return <option value={item._id} key={item._id}>{item.code}</option>
                                      })
                                    }

                                  </Form.Control>
                                  {
                                    errors.taxonomy && touched.speciality && (
                                      <div className="invalid-feedback">{errors.taxonomy}</div>
                                    )
                                  }
                                </div></div>

                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <label className="mb-1 font-weight-bold text-dark">
                                    TimeZone  <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Form.Control as={"select"}

                                    name="timezone"
                                    className='form-control shadow-none'
                                    isInvalid={errors.timezone && touched.timezone}
                                    onChange={handleChange}
                                    value={values.timezone ? values.timezone : ""}
                                    id={values.timezone ? values.timezone : ""}
                                  >
                                    <option value="">Select</option>
                                    {
                                      this.state.timezoneList?.map((item) => {
                                        const { name, _id } = item;
                                        return <option value={_id} key={_id}>{name}</option>
                                      })
                                    }
                                  </Form.Control>
                                  {
                                    errors.timezone && <div className="invalid-feedback">{errors.timezone}</div>
                                  }
                                </div>

                              </div>

                              <div className='row'>
                                <div className='col form-group mr-2'>
                                  <Form.Check
                                    checked={this.state.provider_status}
                                    type="switch"
                                    id="custom-switch"
                                    name="provider_status"
                                    onChange={event => { handleChange(event); this.onChangeSwitch() }}
                                  /></div></div>


                              <Button
                                variant="primary"
                                type="submit"
                                style={{ width: "100%", marginTop: "10px", fontFamily: "manrope" }}

                              >
                                Submit
                              </Button>
                            </Form>
                          ))}

                        </Formik>
                      </Tab>
                      <Tab eventKey="npi result" title="NPI Result">
                        <div>
                          <pre className="jsonView">
                            {JSON.stringify(this.state.npiresult, null, 2)}
                          </pre>
                        </div>
                      </Tab>
                      <Tab eventKey="schedule" title="Schedule">
                        <UpdateProviderScheduler
                          selected_provider={this.state.selected_provider}
                          {...this.props} />
                      </Tab>
                    </Tabs>
                  </div>
                </Modal>



                <BootstrapTable keyField='_id'
                  data={this.state.allSelectedRows}
                  hover={false}
                  columns={columns}
                  bordered={false}
                  selectRow={selectRow}
                  pagination={paginationFactory(options)}
                />
                <div className="banner-header">
                  <h4>All Providers</h4>
                </div>
                <BootstrapTable keyField='_id'
                  data={this.state.providers}
                  hover={false}
                  columns={columns1}
                  bordered={false}
                  pagination={paginationFactory(options)}
                />
              </> : <div className='d-flex justify-content-center flex-nowrap'>
                <div style={{ marginTop: "10rem", display: "flex", justifyContent: "center" }} className='position-absolute mx-3 p-4' variant="primary">
                  <Spinner animation="border" variant="primary" className='spin' />
                </div>
              </div>
            }
          </div>

        </AfterLoginLayout>
      </React.Fragment >)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProviderListDetails: () => dispatch(getProviderListDetails())
  }
}
const mapStateToProps = state => {
  return {
    providersStore: state.providerUserList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderListComponent)
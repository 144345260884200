import { GET, POST, PUT } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";
import Role from '../../constants/role'


/**
 * Get Local Provider List information 
 */
function getLocalProviders() {
  let url = APIENDPOINT.ADMIN.LISTLOCALPROVIDERS;
  return GET(url);
}

const createNewLocalProvider = async (formDetails) => {
  var data = {
    "firstname": formDetails.formData.firstname,
    "lastname": formDetails.formData.lastname,
    "licenseid": formDetails.formData.licenseid,
    "phone": formDetails.nationalNumber,
    "countrycode": formDetails.countryCallingCode,
    "email": formDetails.formData.email,
    "username": btoa(formDetails.formData.username),
    "usertype": Role.localprovider.name
  };

  return POST(APIENDPOINT.ADMIN.CREATE_NEW_LOCAL_PROVIDER, data)
}

const bulkUpdateAssignee = async (dataObj) => {
  //const formData = dataObj.formData
  const select_user_obj = dataObj && dataObj.select_user_obj
  const provider_ids = dataObj.provider_ids
  var data = {
    "provider_ids": provider_ids || [],
    "assignee_id": select_user_obj._id || "",
    "provider_type" : Role.localprovider.name || "localprovider"
  };
  return PUT(APIENDPOINT.ADMIN.BULK_LOCAL_PROVIDER_ASSIGNEE, data)
}

const localproviderListService = {
  getLocalProviders: getLocalProviders,
  createNewLocalProvider: createNewLocalProvider,
  bulkUpdateAssignee:bulkUpdateAssignee
}
export default localproviderListService;
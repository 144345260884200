import {providerListAction} from '../../actions/admin/types'
  
  const initialState = {
    loading: false,
    users: [],
    stages: [],
    internalusers: [],
    specialities: []
  }
  
  const ProviderListReducer = (state = initialState, action) => {
    switch (action.type) {
      case providerListAction.DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case providerListAction.DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          users: action.payload.users,
          stages: action.payload.stages,
          internalusers: action.payload.internalusers,
          specialities: action.payload.specialities,
        }
      case providerListAction.DATA_FAILURE:
        return {
          loading: false,
          users: [],
          stages: [],
          internalusers: [],
          specialities: []
        }
      default: return state
    }
  }
  
  export default ProviderListReducer
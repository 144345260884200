import { patientListAction } from './types'
import PatientListService from '../../services/localprovider/PatientList.Service';
import { alertActions } from "../alertAction";

/**
 * Get all Provider list
 * @returns 
 */
export const getPatientListDetails = () => async (dispatch) => {
  try {
    dispatch(patientListDataRequest())
    const userResponse = await PatientListService.getLocalPatients();

    let userObj = userResponse.data && userResponse.data;
    if (userObj && userObj.user && userObj.user.length > 0) {
      const patientList = userObj.user[0] && userObj.user[0].patientInfo;
      if (patientList && patientList.length > 0) {
        dispatch(patientListDataSuccess({ patients: patientList }))
        return Promise.resolve({ patients: patientList });
      } else {
        dispatch(patientListDataFailure(userResponse.data.message));
        dispatch(alertActions.error(userResponse.data.message))
        return Promise.reject(userResponse.data.message);
      }
    } else {
      dispatch(patientListDataFailure(userResponse.data.message));
      dispatch(alertActions.error(userResponse.data.message))
      return Promise.reject(userResponse.data.message);
    }
  } catch (err) {
    dispatch(patientListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}
 
export const getPatientListDetails1 = () => async (dispatch) => {
  try {
    dispatch(patientListDataRequest())
    const userResponse = await PatientListService.getPatients();

    let userObj = userResponse.data && userResponse.data.data;
    if (userObj &&  userObj.length > 0) {
      dispatch(patientListDataSuccess({ patients: userObj }))
      return Promise.resolve({ patients: userObj });
      // // const patientList = userObj.user[0] && userObj.user[0].patientInfo;
      // // if (patientList && patientList.length > 0) {
      //   dispatch(patientListDataSuccess({ patients: patientList }))
      //   return Promise.resolve({ patients: patientList });
      // } else {
      //   dispatch(patientListDataFailure(userResponse.data.message));
      //   dispatch(alertActions.error(userResponse.data.message))
      //   return Promise.reject(userResponse.data.message);
      // }
    } else {
      dispatch(patientListDataFailure(userResponse.data.message));
      dispatch(alertActions.error(userResponse.data.message))
      return Promise.reject(userResponse.data.message);
    }
  } catch (err) {
    dispatch(patientListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const patientListDataRequest = () => {
  return {
    type: patientListAction.DATA_REQUEST
  }
}

export const patientListDataSuccess = data => {
  return {
    type: patientListAction.DATA_SUCCESS,
    payload: data,
  }
}

export const patientListDataFailure = error => {
  return {
    type: patientListAction.DATA_FAILURE,
    payload: error
  }
}

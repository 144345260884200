const NONAUTHROUTE = {
	GENERAL: {
		LANDINGPATH: "/",
		ABOUTUSPATH: "/about-us",
		DOCTORPATH: "/doctor",
		CONTACTUSPATH: "/contact-us",
		LOGINPATH: "/login",
		REGSITRATIONOTPPATH: "/otp",
		FORGOTPASSWORDPATH: "/forgotpassword",
		RESETPASSWORDPATH: "/resetpassword",
		GETOPINION: "/getopinion",
		OPINIONCONFIRMATION: "/opinionconfirmation",
		PATIENTPATH:"/patients",
	},
	PATIENT: {
		REGISTRATIONPATH: "/patient-registration",
		TERMSPATH: "/patient-terms-and-conditions",
		PRIVACYPOLICYPATH: "/patient-privacy-policy",
		ENQUIRY_DETAILS: "/patient/customer-enquiry/:id",
		ENQUIRY_SESSION: "/patient/appointment-pre-booking",
		APPOINTMENT_CONFIRMATION: "/patient/appointment-confirmation",
	},
	PROVIDER: {
		REGISTRATIONPATH: "/provider-registration",
		TERMSPATH: "/provider-terms-and-conditions",
		PRIVACYPOLICYPATH: "/provider-privacy-policy",
	},
	INTERNALUSER: {
		INTERNALRESETPASSWORDPATH: "/activatelogin/:id"
	}
}

const AUTHROUTE = {
	GENERAL: {
		HOMEPATH: "/home",
		NOMATCH: "*",
		TWILIO_VIDEO_PREJOIN_SCREEN: "/online/video-consult",
	},
	PROVIDER: {
		DASHBOARDPATH: "/provider-dashboard",
		PROFILEPATH: "/provider-profile",
		SCHEDULERPATH: "/my-schedule",
		APPOINTMENT_PATH: "/provider/appointments",
		APPOINTMENT_PROVIDER: "/provider/appointments/view",
		CALENDER_VIEW: "/provider/calender",
		RESCHEDULE_VIEW: "/provider/appointments/reschedule",
		RESCHEDULE_SUCCESS: "/provider/appointments/reschedulesuccess"
	},
	PATIENT: {
		DASHBOARDPATH: "/patient-dashboard",
		OPINION_SUCCESS: "/opinion-success",
		PROFILEPATH: "/patient-profile",
		ENQUIRIES_PATH: "/my/enquiries",
		VIEW_ENQUIRY: "/patient/enquiry/:id",
		ENQUIRYPICKSLOT: "/patient/pickslot/:id",
		AFTER_LOGIN_VIEW_ENQUIRY: "/patient/enquiry/",
		APPOINTMENT_PATH: "/patient/appointments",
		VIEW_APPOINTMENT: "/patient/appointment/:id",
		ENQUIRY_SESSION: "/patient/appointment-pre-booking",
		APPOINTMENT_CONFIRMATION_AUTH: "/patient/appointmentconfirmation",
		APPOINTMENT_View: "/patient/appointments/view",
		APPOINTMENT_rechedule_PATH: "/patient/appointments/reschedule",
		APPOINTMENT_rechedule_sucess_PATH: "/patient/appointments/reschedulesuccess",
		APPOINTMENT_cancel_PATH: "/patient/appointments/cancel",
		ENQUIRIES_VIEW: "/my/enquiries/view/:id",
		ENQUIRIES_EDITS: "/my/enquiries/edits/:id",
	},
	INTERNALUSER: {
		DASHBOARDPATH: "/global-dashboard",
		LIST_MY_PROVIDERS: "/my-providers",
		ENQUIRIES_PATH: "/patient-enquiries",
		ADD_USER: "/add_user",
	},
	ADMIN: {
		LISTINTERNALUSERS: "/users",
		ADDNEWUSERUSERS: "/addnewuser",
		LISTPROVIDERS: "/providers",
		LISTLOCALPROVIDERS: "/localproviders",
		ENQUIRIES_PATH: "/patient/enquiries",
		ADD_USER: '/add_user',
		PATIENT:'/patient'
	},
	LOCALPROVIDER: {
		DASHBOARDPATH: "/local-dashboard",
		PATIENTPATH: "/my/patients",
		VIEW_PATIENT: "/patient/view/:id",
		APPOINTMENT_PATH: "/local-provider/appointments",
	}
}

export { NONAUTHROUTE, AUTHROUTE }

import React from 'react'
import { message } from 'antd';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { AfterLoginDashboardLayout } from '../../shared/AfterLoginDashboardLayout';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap'
import '../../patient/appointment/Appointment.css';
import { get_day_from_datetime, calculate_age, get_formated_time_only_provider, get_formated_date_only_provider } from '../../patient/enquiry/EnquiryUtils';
import { AppointmentModal } from '../../patient/appointment/AppointmentModal';
import { parseAppointments } from '../../patient/appointment/Utils';
import { FaClock, FaRegCalendarAlt, FaFile, FaFileAlt, FaFileWord } from 'react-icons/fa';
import { get_room_details_obj } from '../../patient/appointment/Utils';
import { get_provider_obj, get_patient_obj, get_patient_user_obj, get_speciality_obj } from '../../patient/enquiry/EnquiryUtils';
import { authenticationService } from '../../general/login/login.service';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { BsPersonCircle } from 'react-icons/bs';
import moment from 'moment';

// import FileViewer from 'react-file-viewer';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import PDFViewer from 'pdf-viewer-reactjs'


class AppointmentProviderViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            consultationInfo: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            all_specialities: [],
            previewVisible: false,
            currentUser: {},
            currentItem: {},
            speciality_obj: {},
            showModal: false,
            modalData: '',
            fileList: [],
            dayChecker: '',
            showImageViewer: false,
            selected_view_document: ""
        }
    }
    async findStatus() {
        if (this.props?.location?.state?.selected_appointment) {
            const _consultation = await parseAppointments([this.props?.location?.state?.selected_appointment]);
            this.setState({
                dayChecker: (_consultation && _consultation.upcoming.length > 0) ? 'upcoming' : 'closed'
            });
        }
        if (this.props.location) {
            // if (typeof this.props.location.query == "undefined") {
            //     this.props.history.push(AUTHROUTE.PATIENT.APPOINTMENT_PATH);
            // }
        }

    }


    onShowModel = (value) => {
        this.setState({ modalData: value })
        this.setState({ showModal: true })
    }
    onHideModal = () => {
        this.setState({ showModal: false })
    }
    onYesModal = () => {
        const { history } = this.props;
        const tempData = this.props?.location?.state?.selected_appointment;
        tempData.provider_level_view = true;
        // if (this.state.modalData === 'cancel') {
        //     history.push({ pathname: "/patient/appointments/cancel", state: tempData })
        // }
        if (this.state.modalData === 'reschedule') {
            history.push({ pathname: "/provider/appointments/reschedule", state: tempData })
        }
        this.setState({ showModal: false })
    }

    getVals() {
        const currentItem = this.props?.location?.state?.selected_appointment
        const consultationInfo = this.props?.location?.state?.selected_appointment?.consultationInfo
        const currentUser = authenticationService.currentUserValue;
        const fileList = this.props?.location?.state?.selected_appointment?.consultationInfo?.documents
        //const all_specialities = this.props && this.props.all_specialities
        const patient_obj = get_patient_obj(currentItem)
        const user_obj = get_patient_user_obj(currentItem)
        const provider_obj = get_provider_obj(currentItem)
        // const provider_user_obj = get_provider_user_obj(currentItem)
        const speciality_obj = get_speciality_obj(consultationInfo)

        this.setState({
            currentItem: currentItem,
            consultationInfo: consultationInfo,
            user_obj: user_obj,
            patient_obj: patient_obj,
            provider_obj: provider_obj,
            currentUser: currentUser,
            fileList: fileList || [],
            speciality_obj: speciality_obj
        })
    }

    componentDidMount() {
        this.getVals();
        this.findStatus();
    }
    documentViewer(document_url) {
        this.setState({ selected_view_document: document_url, showImageViewer: true });
    }
    handleClose() {
        this.setState({ showImageViewer: false });
    }


    navigatetoVideoCall = () => {
        const room_details_obj = get_room_details_obj(this.state && this.props?.location?.state?.selected_appointment?.consultationInfo)
        if (room_details_obj) {
            this.props && this.props.history && this.props.history.push({
                pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
                state: {
                    currentItem: this.state.currentItem,
                    consultationInfo: this.state.consultationInfo,
                    user_obj: this.state.user_obj,
                    patient_obj: this.state.patient_obj,
                    provider_obj: this.state.provider_obj,
                    provider_user_obj: this.state.provider_user_obj,
                    currentUser: this.state.currentUser,
                }
            });
        } else {
            message.error("Issue while initiating the Video call. Room details not available")
        }
    };

    render() {
        return (
            <AfterLoginDashboardLayout>
                <Row className='main-layout'>
                    <Col lg={9} md={9} sm={12} pt-3>
                        <h5 className='text-left ml-4'><Link to={{ pathname: AUTHROUTE.PROVIDER.APPOINTMENT_PATH }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link> View appointments</h5>
                        <div className='ml-4 pt-3' >
                            <Card className='lefttopsection'>
                                <Row className="p-4"  >
                                    <Col lg={3} md={3} sm={12} style={{ marginLeft: "30px" }} >
                                        <div className='profileicon'>
                                            <i><BsPersonCircle /></i>
                                        </div>
                                        <div className='drdetails px-1'>
                                            <div>{this.props?.location?.state?.selected_appointment?.patientUserInfo?.firstname}</div>
                                            <div style={{ 'fontSize': '13px' }}>{this.props?.location?.state?.selected_appointment?.patientInfo?.gender},
                                                {calculate_age(this.props?.location?.state?.selected_appointment?.patientInfo?.dob)}Years</div>

                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className='profileicon' >
                                            <i><FaClock className='facircle' /></i>
                                        </div>
                                        {/* <div className='drdetails px-2'>
                                            <div>{get_formated_time_only(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo?.slot).split(" ")['0']}{get_formated_time_only(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo?.slot).split(" ")['1']}</div>
                                        </div> */}
                                        <div className='drdetails px-2'>
                                            <div>{get_formated_time_only_provider(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo &&
                                                moment(this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date + ' ' + this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot, 'YYYY-MM-DD HH:mm A').format())}{this.state.consultationInfo.timename}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className='profileicon'>
                                            <i><FaRegCalendarAlt /> </i>
                                        </div>
                                        <div className='drdetails px-2'>
                                            <div>{get_day_from_datetime(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo &&
                                                moment(this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date + ' ' + this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot, 'YYYY-MM-DD HH:mm A').format())}
                                            </div>
                                            <div>{get_formated_date_only_provider(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo &&
                                                moment(this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date + ' ' + this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot, 'YYYY-MM-DD HH:mm A').format())}
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={2} md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="joinBtn"  >
                                            {this.state.dayChecker === 'upcoming' &&
                                                <Button variant='link' style={{
                                                    "border": "1px solid #ffffff", "color": "#ffffff"
                                                }} className='px-4' onClick={this.navigatetoVideoCall}>Join  </Button>
                                            }
                                        </div>

                                    </Col>
                                </Row>
                            </Card>
                            <div className='ml-4 pt-2 pb-3'>
                                <div className='pl-3 reschedule'>
                                    {this.state.dayChecker === 'upcoming' &&
                                        <Button variant='link' onClick={() => this.onShowModel('reschedule')}>Reschedule appointment</Button>
                                    }

                                </div>
                                {/* <div>
                                <Button variant='link' onClick={() => this.onShowModel('cancel')}>Cancel appointment</Button>
                            </div> */}
                            </div>
                        </div>

                        <div className='ml-5'>
                            <Card className='leftbottomsection p-4'>
                                <Row className=''>
                                    <Col lg={6} md={6} sm={12} pt-3>
                                        <div className='breadcrumb-topics'>CONCERN</div>
                                        <div className='data mt-2'>  {this.props?.location?.state?.selected_appointment?.enquiryInfo?.enquiry_message}</div>
                                    </Col>
                                </Row>
                                <Row><Col><hr /></Col></Row>
                                <Row className=''>
                                    <Col lg={12} md={12} sm={12} pt-4>
                                        <div className='breadcrumb-topics'>DETAILS</div>
                                        <Col className="gutter-row">
                                            <Row className='mobile_row'>

                                                {this.state.fileList.map((x, i) => {
                                                    return <Col lg={4} md={4} sm={6} xs={6} className="p-4" key={i} >
                                                        {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                        {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                        {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                        {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                            <div className='image'>
                                                                <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                            </div>
                                                        }
                                                    </Col>
                                                })}
                                            </Row></Col>
                                    </Col>

                                </Row>

                            </Card>

                        </div>

                    </Col>

                    <Col lg={3} md={3} sm={12} className=' px-3'>

                    </Col>
                </Row>
                <AppointmentModal
                    modalData={this.state.modalData}
                    showModal={this.state.showModal}
                    onHide={this.onHideModal}
                    onYesClick={this.onYesModal}
                    onNoClick={this.onHideModal}
                />

                <Modal show={this.state.showImageViewer}
                    centered
                    backdrop="static" onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>

                        <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>


                    </Modal.Body>

                </Modal>
            </AfterLoginDashboardLayout >
        )
    }
}

export default AppointmentProviderViewer

/* Authentication actions */
import { message } from 'antd';
import axios from 'axios';
import { APIENDPOINT } from "../../../constants/APIEndpoint";

export const email = async (props, formDetails, setSubmitMsg) => {
    var data = {
        "email": formDetails.email,
    };
    var config = {
        method: 'POST',
        url: process.env.REACT_APP_API_URL + APIENDPOINT.EMAIL_SUBSCRIBE,
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        data: data
    };
    

    await axios(config)
        .then(function (response) {
            if (response.data.status === 400) {
                message.error('Subscription Failed')
                return
            } if (response.data.status === "success") {
                setSubmitMsg(true)
                formDetails.email="";  
            } else {
                message.error('subsription Failed :' + response.data.message)
            }
        })
        .catch(function (err) {
            if (err.response) {
                console.log("client received an error response (5xx, 4xx)", err);
            } else if (err.request) {
                console.log("client never received a response, or request never left", err);
            } else {
                // anything else
                console.log("Error : ", err);
            }
            message.error(err.message)
        });
}
import { message } from 'antd';
import { logout } from '../component/general/login/login.service';

export const errorMessage = (err) =>{
    if (err.response) {
        if (err.response.status === 401) {
            message.error("Token expired, you will logged out. Kindly login again.")
            setTimeout(() => {
                logout()
                window.location.reload(); 
            }, 1000)
        }else{
            console.log("client received an error response (5xx, 4xx)", err.response);
        }
    } else if (err.request) {
        console.log("client never received a response, or request never left", err);
       
    } else {
        // anything else
        console.log("Error : ", err);
    }
    //message.error(err.message)
}
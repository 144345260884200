import React, { Component } from "react";
import { Layout } from "../../component/shared";
import { Card, Row, Col, Container, Form, Button, Spinner, Modal } from "react-bootstrap";
import { NONAUTHROUTE } from "../../constants/RouterPath";
import "./ContactPage.style.css";
import { FiPhoneCall } from "react-icons/fi";
import { IoIosMailOpen } from "react-icons/io";
import { Formik } from "formik";
import * as yup from "yup";
import { formikFormalidation } from "../../component/general/formvalidator/validationFroms";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { contactForm } from "./contactForm.service";
import GetOpinion from "../landing/landingPage/GetOpinion/GetOpinion";
class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formData: "",
      value: null,
      fullname: "",
      email: "",
      query: "",
      validated: false,
      showModal: false,
    };
  }
  onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {

    }
  }
  render() {
    const schema = yup.object().shape({
      fullname: formikFormalidation.fullname,
      mobilenumber: formikFormalidation.mobilenumber,
      email: formikFormalidation.emaildr,
      query: formikFormalidation.query
    });
    const handleClose = (e) => {
      this.setState({ showModal: false });
    }
    return (<>

      <Formik
        validationSchema={schema}
        validate={(values) => {
          const errors = {};
          if (!values.mobilenumber) {
            errors.mobilenumber = "Mobile number is required!";
          } else {
            const countryCallingCode = parsePhoneNumber(
              values.mobilenumber
            )?.countryCallingCode;
            if (
              !countryCallingCode ||
              !isValidPhoneNumber(values.mobilenumber)
            ) {
              errors.mobilenumber = "Invalid Mobile number format!";
            }
          }
          return errors;
        }}

        onSubmit={async (values, { resetForm }) => {
          const countryCallingCode =
            parsePhoneNumber(values.mobilenumber) &&
            parsePhoneNumber(values.mobilenumber).countryCallingCode;
          const nationalNumber =
            parsePhoneNumber(values.mobilenumber) &&
            parsePhoneNumber(values.mobilenumber).nationalNumber;
          this.setState({ formData: values });
          const allFormData = {
            formData: this.state.formData,
            countryCallingCode: "+" + countryCallingCode,
            nationalNumber: nationalNumber,
          };
          this.setState({ loading: true });
          await contactForm(this.props, allFormData); resetForm();
          this.setState({ loading: false });
        }}
        initialValues={{
          fullname: "",
          mobilenumber: "",
          email: "",
          query: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Layout className="registration" isContactHeader={true} isFooter={true}>
            <div className=" px-9 contactus">
              <div className="contact-section">
                <Container>
                  <Row className="contact-row">
                    <Col lg='6' className="contact-col">
                      <h3>Contact us</h3>
                      <p>
                        Have questions about our products, support
                        <br /> services, or anything else? Let us know and we’ll
                        get
                        <br /> back to you.
                      </p>
                      <FiPhoneCall
                        size={40}
                        style={{ color: "#81A0DE", marginTop: "20px" }}
                      />
                      <small>Call us at </small>
                      <br />
                      <h6>9995678777</h6>
                      <br />
                      <IoIosMailOpen
                        size={40}
                        style={{ color: "#81A0DE", marginTop: "10px" }}
                      />
                      <small>Write to us </small> <br />{" "}
                      <h6>hello@secondopinion.com</h6>
                    </Col>

                    <Col lg='6'>
                      <Card className="card-1">
                        {this.state.loading &&
                          <div className='d-flex justify-content-center flex-nowrap'>
                            <div className='position-absolute mx-3 p-4' variant="primary" >
                              <Spinner animation="border" variant="primary" />
                            </div>
                          </div>
                        }
                        <Card.Title className="mt-5 text">
                          Please fill the below form to get in <br />
                          touch with us
                        </Card.Title>
                        <fieldset disabled={this.state.loading}>

                          <Form
                            className={this.state.loading ? "blur" : ""}
                            noValidate
                            validated={this.state.validated}
                            onSubmit={handleSubmit}
                          ><Form.Group className="contact-form mb-3">
                              <Form.Control

                                type="text"
                                placeholder="Fullname"
                                name="fullname"
                                required
                                value={values.fullname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.fullname && touched.fullname}
                              /><Form.Control.Feedback type="invalid">
                                {errors.fullname}
                              </Form.Control.Feedback></Form.Group>
                            <Form.Group className="contact-form mb-3"> <Form.Control

                              as={PhoneInput}
                              placeholder="Mobile number"
                              name="mobilenumber"
                              required
                              international
                              defaultCountry="US"
                              countries={["US", "IN"]}
                              countryCallingCodeEditable={false}
                              limitMaxLength={true}
                              value={values.mobilenumber}
                              onChange={e => { values.mobilenumber = e; }}
                              onBlur={handleBlur}
                              isInvalid={
                                errors.mobilenumber && touched.mobilenumber
                              }
                            /><Form.Control.Feedback type="invalid">
                                {errors.mobilenumber}
                              </Form.Control.Feedback></Form.Group>
                            <Form.Group className="contact-form mb-3"> <Form.Control

                              type="email"
                              placeholder="Email"
                              name="email"
                              required
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={errors.email && touched.email}
                            /><Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback></Form.Group>
                            <Form.Group className="contact-form mb-3">  <Form.Control

                              as="textarea"
                              rows={5}
                              placeholder="Query"
                              name="query"
                              required
                              value={values.query}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={errors.query && touched.query}
                            /><Form.Control.Feedback type="invalid">
                                {errors.query}
                              </Form.Control.Feedback></Form.Group>
                            <Button
                              variant="primary"
                              className="ml-4 mt-2 contact-button"
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Form>
                        </fieldset>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container className="mt-5">
                <Card className="card-2">
                  <Row className="no-glutters">
                    <Col>
                      <Card.Body>
                        <h3 className="mt-4">
                          {" "}
                          Your appointment fee <br /> contributes to the Global
                          Fund’s <br /> support programmes.
                        </h3>
                        <div className="my-5 pl-5">

                          <Button className='btn btn-primary  button-1'
                            style={{ fontSize: '16px' }}
                            onClick={() => this.setState({ showModal: true, newUserVal: true })}>
                            Get an opinion</Button>

                          <a
                            href={NONAUTHROUTE.PROVIDER.REGISTRATIONPATH}
                            className="btn button-2 "
                          >
                            Register as a doctor
                          </a>
                        </div>
                      </Card.Body>
                    </Col>
                    <Col md={5} lg={5}>
                      <Card.Img
                        src="/contactusdoctor.png"
                        alt="doctor image"
                        className="cardImg"
                      />
                    </Col>
                  </Row>
                </Card>
              </Container>
            </div>
          </Layout>
        )}
      </Formik>
      <Modal show={this.state.showModal} onHide={handleClose}
        backdrop="static"
        keyboard={true}
        centered={false}
        size="xl"
        className='modalData'
      >
        <Modal.Header style={{ border: "0" }} closeButton>
        </Modal.Header>
        <Modal.Body><GetOpinion {...this.props} handleClose={handleClose} /></Modal.Body>
      </Modal>
    </>
    );
  }
}
export default ContactPage;

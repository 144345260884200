import React, { Component } from 'react';
import { Layout } from '../../component/shared';
import Section1 from './landingPage/Section1/Section1';
import Section2 from './landingPage/Section2/Section2';
import Section3 from './landingPage/Section3/Section3';
import Section4 from './landingPage/Section4/Section4';
import Section5 from './landingPage/Section5/Section5';
import Section6 from './landingPage/Section6/Section6';
import Section7 from './landingPage/Section7/Section7';
import Section8 from './landingPage/Section8/Section8';

class LandingPage extends Component {
    render() {
        return (
            <Layout isHeader={true} isFooter={true}>
                <div className='section'>
                    <Section1 {...this.props} />
                    <Section2 />
                    <Section3 />
                    <Section4 {...this.props} />
                    <Section5 />
                    <Section6 {...this.props} />
                    <Section7 />
                    <Section8 />
                </div>
            </Layout>
        );
    }
}
export default LandingPage;
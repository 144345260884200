
import React, { useEffect, useState } from 'react'
import { Row, Card, Carousel,  } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import './slotsCarosel.css'

const PaginationDates = ({ allDisplaySlos, selDefActIndex, onHandleSubmit, defaultLoadSlots, onChangeEvent,

  todaysDate, tomorrowDate, availableSlots, IsDate,
  displayDates, loadSlots, handleSelectedDate }) => {
  const [slotBgColor, setSlotBgColor] = useState('')
  const [selectedIndex, setSlectedIndex] = useState(selDefActIndex ? selDefActIndex : '')
  const [buttonDisable,setButtonDisable] = useState(true)

  displayDates && displayDates.sort((a,b) => moment(a) - moment(b))
  const slot1 = displayDates.slice(0, 4)
  const slot2 = displayDates.slice(4, 8)
  const slot3 = displayDates.slice(8, 12)

  const allDefaultSlots = defaultLoadSlots && availableSlots.filter(item => item.date === defaultLoadSlots[0].date).map((a) => {
    return {...a,slot:a.slot.split('').filter((itm,indx) => indx <= 7 && itm).join(""),date:moment(a.date).format("YYYY-MM-DD")}
}).sort((a,b) => new Date(moment(a.date+ ' '+a.slot,"YYYY-MM-DD HH:mm A").format()) - new Date(moment(b.date+ ' '+b.slot,"YYYY-MM-DD HH:mm A").format())).map(item => {
    return {...item,slot:item.slot+" IST"}
})

const sortedTimes = loadSlots && loadSlots.map((a) => {
  return {...a,slot:a.slot.split('').filter((itm,indx) => indx <= 7 && itm).join(""),date:moment(a.date).format("YYYY-MM-DD")}
}).sort((a,b) => new Date(moment(a.date+ ' '+a.slot,"YYYY-MM-DD HH:mm A").format()) - new Date(moment(b.date+ ' '+b.slot,"YYYY-MM-DD HH:mm A").format())).map(item => {
  return {...item,slot:item.slot+" IST"}
})

  const getLength = (dat) => {
    let len = availableSlots && availableSlots.filter(item => item.date === (dat === "Today" ? todaysDate : '' || dat === "Tomorrow" ? tomorrowDate : '' || dat))
    return len.length === 0 ? <span style={{ fontSize: "12px" }}>No slots available</span> :
      <span style={{ fontSize: "12px" }} className="slots-length-color">{`${len.length} slots available`}</span>
  }

 

  return (

      <Card className='border-0'>
        <div >
          <Row >
            <Carousel indicators={false}
              interval={null} style={{ width: "100%", height: "4rem", }}
              className="small-view  d-flex justify-content-between border-bottom align-items-center"
            >
              <Carousel.Item className='container '>
                <div className=' d-flex justify-content-around pt-3 pb-2 '>
                  {slot1 && slot1.map((item, index) => {
                    return (
                      <div style={item === selectedIndex ? { borderBottom: "2px solid #143C8C", borderWidth: "solid #143C8C" } : {}} className={`d-flex flex-column justify-content-center align-items-center`}
                        key={index}>
                        <button onClick={() => {

                          setSlectedIndex(item)
                          handleSelectedDate(item)
                        }} className="btn btn-link m-0 p-0 font-weight-bold text-dark" style={{ textDecoration: "none", listStyle: "none" }}>
                          {item === "Today" || item === "Tomorrow" ?
                            item : `${moment(item).format('ddd')}, ${moment(item).format('DD MMM')}`}
                        </button>
                        <p style={{ paddingBottom: "0.4rem" }} className='pt-0  mt-0'>{getLength(item)}</p>
                      </div>
                    )
                  })}
                </div>
              </Carousel.Item>

              <Carousel.Item className='container   '>
                <div className=' d-flex justify-content-around pt-3 pb-2  '>
                  {slot2 && slot2.map((item, index) => {
                    return (
                      <div style={item === selectedIndex ? { borderBottom: "2px solid #143C8C", borderWidth: "solid #143C8C" } : {}} className={`d-flex flex-column justify-content-center align-items-center`}
                        key={index}>
                        <button onClick={() => {

                          setSlectedIndex(item)
                          handleSelectedDate(item)
                        }} className="btn btn-link m-0 p-0 font-weight-bold text-dark" style={{ textDecoration: "none", listStyle: "none" }}>
                          {item === "Today" || item === "Tomorrow" ?
                            item : `${moment(item).format('ddd')}, ${moment(item).format('DD MMM')}`}
                        </button>
                        <p style={{ paddingBottom: "0.4rem" }} className='pt-0 mt-0'>{getLength(item)}</p>
                      </div>
                    )
                  })}
                </div>
              </Carousel.Item>

              <Carousel.Item className='container'>
                <div className=' d-flex justify-content-around pt-3 pb-2  '>
                  {slot3 && slot3.map((item, index) => {
                    return (
                      <div style={item === selectedIndex ? { borderBottom: "2px solid #143C8C", borderWidth: "solid #143C8C" } : {}} className={`d-flex flex-column justify-content-center align-items-center`}
                        key={index}>
                        <button onClick={() => {

                          setSlectedIndex(item)
                          handleSelectedDate(item)
                        }} className="btn btn-link m-0 p-0 font-weight-bold text-dark" style={{ textDecoration: "none", listStyle: "none" }}>
                          {item === "Today" || item === "Tomorrow" ?
                            item : `${moment(item).format('ddd')}, ${moment(item).format('DD MMM')}`}
                        </button>
                        <p style={{ paddingBottom: "0.4rem" }} className='pt-0 mt-0'>{getLength(item)}</p>
                      </div>
                    )
                  })}
                </div>
              </Carousel.Item>


            </Carousel>

            <Carousel defaultActiveIndex={1} indicators={false} // deprected-dom-node-errors resolve by-passing -- slide={false} fade={true}  
              interval={null} //style={{ width: "100%", height: "15vh", }}
              className="d-sm-none  "
            >
              {
                allDisplaySlos && allDisplaySlos.map((item, index) => {
                  return (

                    <Carousel.Item key={index}>
                      <div style={item === selectedIndex ? { borderBottom: "2px solid #143C8C", borderWidth: " solid #143C8C" } : {}} className={`d-flex flex-column justify-content-center align-items-center`}
                        key={index}>
                        <button onClick={() => {

                          setSlectedIndex(item)
                          handleSelectedDate(item)
                        }} className="btn btn-link m-0 p-0 font-weight-bold text-dark" style={{ textDecoration: "none", listStyle: "none" }}>
                          {item === "Today" || item === "Tomorrow" ?
                            item : `${moment(item).format('ddd')}, ${moment(item).format('DD MMM')}`}
                        </button>
                        <p className='pt-0 mt-0'>{getLength(item)}</p>
                      </div>
                    </Carousel.Item>
                  )
                })
              }

            </Carousel>
          </Row>
        </div>

        <div className='mt-5'>
          <Row >
            {
              allDefaultSlots ?
                <div className=' d-flex flex-wrap'  >
                  <p className=' font-weight-bold mb-2 ml-3 ' style={{ marginTop: "10px" }}>Book a slot</p>
                  <div>
                    <div className='d-flex ml-2 flex-wrap' >
                      {allDefaultSlots.map(item => {
                        return (

                          <p key={item._id} className={`button-style py-1 px-2 m-1 ${slotBgColor && item._id === slotBgColor && `bg-slot-color text-white `}`}
                            onClick={() => {
                              setSlotBgColor(item._id)
                              onChangeEvent(item)
                              setButtonDisable(false)
                            }}
                            style={{
                              border: "1px solid #143C8C", borderWidth: "1px solid #143C8C",
                              borderRadius: "4px", color: "#143C8C", fontWeight: "bold"
                            }}
                          >
                            {/* get_formated_time_only(moment(item.date + ' ' + item.slot,'YYYY-MM-DD HH:mm A').format()) */}
                             {item.slot}
                          </p>

                        )
                      })}
                    </div>
                 
                    <button disabled={buttonDisable} style={{ fontSize: "0.9rem", marginLeft: "75px" }} className='ml-2 btn btn-primary mt-3' onClick={onHandleSubmit}>CONFIRM</button>
                  
                  </div>
                </div>
                :
                <div className='d-flex flex-wrap align-items-center ' >
                  {loadSlots.length !== 0  && <p className='font-weight-bold mr-2' >Book a slot</p>}
                  {loadSlots.length !== 0 && sortedTimes.length !== 0 ? sortedTimes.map(item => {
                    return <p key={item._id} className={`button-style py-1 px-2 m-1  ${slotBgColor && item._id === slotBgColor && `bg-slot-color text-white `}`}
                      onClick={() => {
                        setSlotBgColor(item._id)
                        onChangeEvent(item)
                        setButtonDisable(false)
                      }}
                      style={{
                        border: "1px solid #143C8C", borderWidth: "1px solid #143C8C",
                        borderRadius: "4px", color: "#143C8C", fontWeight: "bold"
                      }}
                    >
                      {item.slot}</p>
                  }) : <p className='d-flex justify-content-center font-weight-bold align-items-center'>No slots available</p>}

                </div>
            }
          </Row>
          <div className='mt-3'>
           
            {loadSlots.length !== 0 && <button disabled={buttonDisable} style={{ fontSize: "0.9rem", marginLeft: "75px" }} className='btn btn-primary' onClick={onHandleSubmit}>CONFIRM</button>
          
          }
          </div>
         {IsDate&& <p style={{ marginLeft: "90px",color:"red"}}  >
           please book a slot
           
          </p>}

        </div>
      </Card>
  )
}


function SlotsCarousel({ availableSlots, onChangeEvent, onHandleSubmit ,IsDate },props) {
  const [displayDates, setDisplayDates] = useState([])
  const [patientSelDate, setPatientSelDate] = useState('')
  const [currentPage, setCurrentPage] = useState()

const new_fil_slots = []
availableSlots && availableSlots.forEach((day,index) => {
  if(index === 0) new_fil_slots.push(day)
  if( new_fil_slots.find(item => item.date+item.slot ===day.date+day.slot )){

  }else {
    new_fil_slots.push(day)
  }
})


  //days-today-important
  const todaysDate = moment().format('YYYY-MM-DD')
  //tomorrow-important
  const tomorrowDate = moment().add(1, 'days').format('YYYY-MM-DD')

  useEffect(() => {
    const getFirstFourSlots = () => {
      const uniqueDates = []
      const todaySlots = availableSlots.length !== 0 && availableSlots.filter(item => item.date === todaysDate)
      availableSlots.length !== 0 && availableSlots.forEach((item) => {
        if (!uniqueDates.includes(item.date)) {
          if (item.date === todaysDate && !uniqueDates.includes("Today")) {
            uniqueDates.push("Today")
          } else if (!uniqueDates.includes("Tomorrow") && item.date === tomorrowDate) {
            uniqueDates.push("Tomorrow")
          }
          else if (!uniqueDates.includes(item.date) && item.date !== todaysDate && item.date !== tomorrowDate) {
            uniqueDates.push(item.date)
          }
        }
      })
      todaySlots.length === 0 && !uniqueDates.includes("Today") && uniqueDates.unshift("Today")
      setDisplayDates(uniqueDates)
    }
    getFirstFourSlots()
  }, [availableSlots, todaysDate, tomorrowDate])

  const handleSelectedDate = (selectedDate) => {
    if (selectedDate === "Today") {
      setPatientSelDate(todaysDate)
    } else if (selectedDate === "Tomorrow") {
      setPatientSelDate(tomorrowDate)
    } else setPatientSelDate(selectedDate)

  }

  const loadSlots = patientSelDate && new_fil_slots && new_fil_slots.filter(item => item.date === patientSelDate)

  const defaultLoadSlots = !patientSelDate && new_fil_slots && new_fil_slots.sort((a,b) => moment(a.date) - moment(b.date))
  let selDefActIndex;
  if (defaultLoadSlots[0]) {
    if (defaultLoadSlots[0].date === todaysDate) {
      selDefActIndex = "Today"
    } else if (defaultLoadSlots[0].date === tomorrowDate) {
      selDefActIndex = "Tomorrow"
    } else selDefActIndex = defaultLoadSlots[0].date
  }

  const handleNextDatesPage = (val) => {
    setCurrentPage(currentPage + val)
  }

  const handlePrevDatesPage = (val) => {
    setCurrentPage(currentPage - val)
  }


  return (
    <div >
      <PaginationDates allDisplaySlos={displayDates} selDefActIndex={selDefActIndex} onHandleSubmit={onHandleSubmit} today={todaysDate} tomorrow={tomorrowDate} defaultLoadSlots={defaultLoadSlots} onChangeEvent={onChangeEvent} todaysDate={todaysDate} tomorrowDate={tomorrowDate}
        availableSlots={new_fil_slots} displayDates={displayDates} loadSlots={loadSlots}
        handleSelectedDate={handleSelectedDate} handleNextDatesPage={handleNextDatesPage}
        handlePrevDatesPage={handlePrevDatesPage} IsDate={IsDate}/>
    </div>
  );
}
export default SlotsCarousel

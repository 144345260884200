
import React, { Component } from 'react'
import { AfterLoginLayout } from '../../shared/AfterLoginLayout'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Dropdown } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { errorMessage } from '../../../utils/ServiceHandler';
import { connect } from 'react-redux';
import { getPatientListDetails } from '../../../actions/localprovider/PatientListAction';
import { FaTimes } from 'react-icons/fa';
import '../../patient/appointment/Appointment.css'
import { parseAppointments } from '../../patient/appointment/Utils';
import { message } from 'antd';
// import AppointmentService from '../../../services/provider/Appointment.Service';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button className='threedotbutton' variant='link'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </Button>
));

export class AdminPatientView extends Component {
    formRef = React.createRef();
    layout = {
      labelCol: { span: 24 }
    };
    constructor(props) {
      super(props);
      this.state = {
        patient_id: this.props && this.props.patient_id,
        selected: null,
        selectedRowKeys: [],
        selectedRows: [],
        patients: [],
        modalVisible: false,
        loading: false,
        createUser: true,
        hasSelected: false,
        filterItems: ['waiting'],
       
  
      }
    }
    handleTableFilter = async (data, filterType) => {
      await this.setState({ showFilterList: [] });
      await this.setState({
        filterItems: await Array.from(new Set([...this.state.filterItems, data]))
      })
      if (this.state.filterItems.includes('assigned')) {
        await this.setState({ showFilterList: await this.state.assigned });
      }
      if (this.state.filterItems.includes('waiting')) {
        await this.setState({ showFilterList: await [...this.state.showFilterList, ...this.state.waiting] })
      }
      this.renderFilteredList(data, filterType);
    }
    handleRemoveFilter = async (index) => {
      await this.state.filterItems.splice(index, 1);
      await this.setState({ showFilterList: [] });
      if (this.state.filterItems.includes('assigned')) {
        await this.setState({ showFilterList: await this.state.assigned });
      }
      if (this.state.filterItems.includes('waiting')) {
        await this.setState({ showFilterList: await [...this.state.waiting] })
      }
      this.renderFilteredList();
    }
    
    handleResponse = (response) => {
      if (response.data.status === "success") {
          if (response && response.data && response.data.data) {
              if (response.data.data.length > 0) {
                  const _consultation = parseAppointments(response.data.data)
                  console.log(response.data.data)
                  this.setState({
                      upcoming: _consultation && _consultation.upcoming,
                      past: _consultation && _consultation.past,
                      loading: false
                  })
              }
          }
      } else {
          this.setState({ loading: false })
          message.error(response.data.message);
      }
  }

  // getAppointments = async () => {
  //     try {
  //       console.log("mn")
  //         this.setState({ loading: true });
  //         const currentUser = authenticationService.currentUserValue;
  //         this.setState({ loading: true });
  //         console.log(currentUser,"v")
  //         let response = await AppointmentService.getLocalPatientAppointments(currentUser._id);
         
  //        console.log( response,"mn")
  //         this.handleResponse(response)
  //     } catch (error) {
  //         errorMessage(error)
  //         this.setState({ loading: false })
  //     }
  // }
  //   handleResponse = (response) => {
  //     if (response.data.status === "success") {
  //         if (response && response.data && response.data.data) {
  //             if (response.data.data.length > 0) {
  //                 const _consultation = parseAppointments(response.data.data)


  //                 this.setState({
  //                     upcoming: _consultation && _consultation.upcoming,
  //                     past: _consultation && _consultation.past,
  //                     loading: false
  //                 })
  //             }
  //         }
  //     } else {
  //         this.setState({ loading: false })
  //         message.error(response.data.message);
  //     }
  // }

  //   getAppointments = async () => {
  //     try {
  //         this.setState({ loading: true });
  //         //const currentUser = authenticationService.currentUserValue;
  //         this.setState({ loading: true })

  //         let response = await AppointmentService.getPatientAppointments(this.state && this.state.patient_id);
  //         console.log(response)
  //         this.handleResponse(response)
  //     } catch (error) {
  //         errorMessage(error)
  //         this.setState({ loading: false })
  //     }
  // }
  
    handleCancel = () => {
      this.setState({ modalisVisible: false });
      this.setState({ showCreateModal: false });
    };
    getPatientlist = () => {
        this.props.getPatientListDetails().then((res) => {
    console.log(res,"hi")
          let patients =res.patients
          this.setState({
            patients
          })
        }).catch(err => {
          errorMessage(err)
        })
      }
    
    
      componentDidMount() {
        // this.getAppointments()
        this.getPatientlist()
        this.setState({
          showMultiSectionOption: this.props && this.props.showMultiSectionOption,
          showCreate: (this.props && this.props.showCreate) || false,
    
        });
      }
  render() {
    
    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
      }) => (
        <div className="loadmore" role="group">
          {
            options.map(option => (
              <Button
                key={option.text}
                type="button"
                onClick={() => onSizePerPageChange(option.page)}
                className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
              >
                {'load more'}
              </Button>
            ))
          }
          <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
        </div>
      );
  
      const options = {
        sizePerPage: 5,
        disablePageTitle: false,
        sizePerPageRenderer,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        sizePerPageList: [{
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }]
      };
  
    const allAdminType = [

        {
  
          dataField: 'firstname',
          text: 'PATIENT'
  
        },
        {
  
          dataField: "gender",
          text: 'GENDER'
  
        },
        {
  
          dataField: "",
          text: 'APPOINTMENTS'
  
        },
        {
  
          dataField: "",
          text: 'RESCHEDULED',
        
  
        },
        
  
        {
          text: ' ',
          dataField: '',
          formatter: (text, record) => (
            <>
            <React.Fragment>
                  <div className='actionHolder' onMouseOver={() => this.view_record(record)}>
                    <div className="joinBtn">
            
                    </div>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              {/* <Link to={{ pathname: AUTHROUTE.PATIENT.APPOINTMENT_View, state: { selected_appointment: record } }}>View
                              </Link> */}
                            </Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
            </>
          )
        }
  
  
      ]
  
    return (<React.Fragment>
        
        
        <AfterLoginLayout>
          <div className="container-fluid">
            <div className="banner-header">
              <h3>Patients</h3>
            </div>
  
        
            <React.Fragment>
                <Dropdown className="float-right">
                  <Dropdown.Toggle variant="primary" id="filter-dropdown" style={{ marginright: '40%' }}>
                    Filter
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
            
                    <Dropdown.Item onClick={() => this.handleTableFilter('waiting', 'addFilter')}  >Waiting</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </React.Fragment>
          
            {this.state.filterItems ? this.state.filterItems.map((e, i) => (
                <div className='float-left'>
                  <Button className='filtertag' key={i} variant="outline-info" onClick={() => this.handleRemoveFilter(i)}>{e} <span><FaTimes /></span></Button>
                </div>
              )) : null
              }
               
  
  
{/*   
            <span style={{ marginLeft: 8 }}>
              {this.state.hasSelected ? `Selected ${this.state.selectedRows.length} items` : ''}
            </span> */}
            {this.state.patients && this.state.patients.length >0 ?
              <BootstrapTable keyField='_id'
                
                data={this.state.patients}
                columns={allAdminType}
                bordered={false}
                hover={false}
                pagination={paginationFactory(options)}
                rowKey={record => record._id} /> : <div>
                {this.state.loading ? "" :
                  <div>
                    <div class=" text-center">
                   
                      <h6>No Data Found</h6>
                      
                    </div>
                    
    
                  </div>}
              </div>
            }
  
          </div>
          {/* <div className='d-flex justify-content-center flex-nowrap'>
                    <div style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }} className='position-absolute mx-3 p-4' variant="primary">
                      <Spinner  animation="border" variant="primary" className='spin' />
                    </div>
                  </div> */}
        
        </AfterLoginLayout>
      </React.Fragment>)
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      getPatientListDetails: () => dispatch(getPatientListDetails())
    }
  }
  const mapStateToProps = state => {
    return {
      localPatientStore: state.localPatientsList,
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdminPatientView)
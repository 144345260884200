import { message } from 'antd';
import axios from 'axios';
import { NONAUTHROUTE } from "../../../../constants/RouterPath";
import { APIENDPOINT } from "../../../../constants/APIEndpoint";


export const opinionForm = async (props, formDetails, newUserVal) => {
    // console.log("opinion-service",newUserVal)

    var data = {
        "firstname": formDetails.formData.firstname,
        "lastname": formDetails.formData.lastname,
        "phone": formDetails.nationalNumber,
        "email": formDetails.formData.email,
        "countrycode": formDetails.countryCallingCode,
        "enquiry_message": formDetails.formData.message
    };
    var config = {
        method: 'POST',
        url: process.env.REACT_APP_API_URL + APIENDPOINT.GET_OPINION,
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        data: data
    };

    await axios(config)
        .then(function (response) {
            if (response.data.status === 400) {
                message.error('Something wrong. ')
                return
            } if (response.data.status === "success") {
                props.history.push({
                    pathname: NONAUTHROUTE.GENERAL.OPINIONCONFIRMATION,
                    state: {
                        detail: formDetails,
                        response: response.data,
                        newUserVal:"newUser",
                        user_entered_concern:data
                    }
                });

            } else {
                message.error('Failed :' + response.data.message)
            }
        })
        .catch(function (err) {
            if (err.response) {
                console.log("client received an error response (5xx, 4xx)", err);
            } else if (err.request) {
                console.log("client never received a response, or request never left", err);
            } else {
                // anything unknown
                console.log("Error : ", err);
            }
            message.error(err.message)
        });
}
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService,logout } from '../component/general/login/login.service';
import {NONAUTHROUTE} from "../constants/RouterPath";
import { message } from 'antd';

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            logout()
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: NONAUTHROUTE.GENERAL.LOGINPATH, state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.usertype) === -1) {
            // role not authorised so redirect to home page
            message.error("Sorry, you don't have that Access to that page.")
            return <Redirect to={{ pathname: NONAUTHROUTE.GENERAL.LANDINGPATH}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)
export default PrivateRoute;
import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaUserAlt, FaQuestion, FaCalendarAlt } from 'react-icons/fa';
import '../../../assets/scss/sidebar.scss';
import { AUTHROUTE } from "../../../constants/RouterPath";
import logo from '../../../assets/images/logo.png';
import NeedHelp from './NeedHelp';
const PatientSideBar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  //const intl = useIntl();
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="sidebar-header">
          <img src={logo} className="img-fluid" alt="Second Opinion" />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaTachometerAlt />} suffix={<span className="badge red">NEW</span>}>
            <Link to={AUTHROUTE.PATIENT.DASHBOARDPATH}>Dashboard</Link>
          </MenuItem>
          <MenuItem icon={<FaUserAlt />}>
            <Link to={AUTHROUTE.PATIENT.PROFILEPATH}>Profile
            </Link>
          </MenuItem>
          <MenuItem icon={<FaQuestion />}>
            <Link to={AUTHROUTE.PATIENT.ENQUIRIES_PATH}>Enquiries
            </Link>
          </MenuItem>
          <MenuItem icon={<FaCalendarAlt />}>
            <Link to={AUTHROUTE.PATIENT.APPOINTMENT_PATH}>Appointments
            </Link>
          </MenuItem>
        </Menu>
      </SidebarContent>


      <NeedHelp />

    </ProSidebar>
  );
};

export default PatientSideBar;

import React from 'react'
import { Form, Select, message, Button, Spin, AutoComplete } from 'antd';
import LocalproviderListService from '../../../services/admin/LocalProviderList.Service';
import ProviderListService from '../../../services/admin/ProviderList.Service';
import UserListService from '../../../services/admin/UserList.Service';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { formValidation } from '../../general/formvalidator/form.validation';
import _ from 'lodash';

class SetAssigneeComponent extends React.Component {

  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      assignee_modal: false,
      loading: false,
      //internalusers: [],
      searchloading: false,
      localprovider: false,
      scope: "",
      data: [],
      searchParams: "",
      select_user_obj: {}
    }
    this.dispatchSearch = _.debounce(this.dispatchSearch, 300);
  }

  componentDidMount() {
    this.setState({
      //internalusers: this.props && this.props.internalUsers,
      selectedRows: this.props && this.props.selectedRows,
      localprovider: this.props && this.props.localprovider,
      scope: this.props && this.props.scope
    });
  }

  onAssigned = async () => {
    this.props.onAssigned()
  }

  get_provider_ids = (selectedRows) => {
    var provider_ids = []
    if (selectedRows && selectedRows.length > 0) {
      selectedRows.forEach(e => {
        if ('providerInfo' in e) {
          var providerInfo = e['providerInfo']
          providerInfo.forEach(element => {
            provider_ids.push(element['_id'])
          });
        } else {
          provider_ids.push(e._id)
        }
      })
      return provider_ids;
    }
  }

  get_enquiry_ids = (selectedRows) => {
    var _ids = []
    if (selectedRows && selectedRows.length > 0) {
      selectedRows.forEach(e => {
        if ('enquiryInfo' in e) {
          var  enquiryInfo = e['enquiryInfo'];
          _ids.push(enquiryInfo['_id']);
        }
      })
      return _ids
    }
  }


  triggerProviderBulkUpdate = async (values) => {
    if (this.state.select_user_obj && this.state.select_user_obj._id) {
      const provider_ids = this.get_provider_ids(this.state.selectedRows)
      const allFormData = {
        select_user_obj: this.state.select_user_obj,
        provider_ids: provider_ids
      }
      try {
        this.setState({ loading: true })
        let response;
        if (this.state.localprovider) {
          response = await LocalproviderListService.bulkUpdateAssignee(allFormData);
        } else {
          response = await ProviderListService.bulkUpdateAssignee(allFormData);
        }
        if (response.data.status === "success") {
          this.onAssigned()
          message.success(response.data.message);
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })
          message.error(response.data.message);
        }
      } catch (error) {
        errorMessage(error)
        this.setState({ loading: false })
      }
    } else {
      message.error(values && values.assignee_id + " user does not exist");
      return
    }
  }

  triggerEnquiryBulkUpdate = async (values) => {
    if (this.state.select_user_obj && this.state.select_user_obj._id) {
      const _ids = this.get_enquiry_ids(this.state.selectedRows)    
      const allFormData = {
        select_user_obj: this.state.select_user_obj,
        enquiry_ids: _ids
      }
      try {
        this.setState({ loading: true })
        let response = await EnquiryListService.bulkUpdateAssignee(allFormData);
        if (response.data.status === "success") {
          this.onAssigned()
          message.success(response.data.message);
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })
          message.error(response.data.message);
        }
      } catch (error) {
        errorMessage(error)
        this.setState({ loading: false })
      }
    } else {
      message.error(values && values.assignee_id + " user does not exist");
      return
    }
  }

  onSetAssigneeFinish = (values) => {
    if(this.state){
      if(this.state.scope ==="LOCALPROVIDER" || this.state.scope==="PROVIDER"){
       this.triggerProviderBulkUpdate(values)
     }else if(this.state.scope ==="ENQUIRY"){
      this.triggerEnquiryBulkUpdate(values)
     }

    }
  }

  handleSearch = value => {
    if (value) {
      let searchParams = { "searchkey_value": value }
      this.setState({ searchParams: searchParams, searchloading: true })
      this.dispatchSearch()
    } else {
      this.setState({ data: [] });
    }
  };

  dispatchSearch = async () => {
    try {
      let response = await UserListService.searchInternalUser(this.state.searchParams);
      if (response && response.data && response.data.status === "success") {
        this.setState(({ searchloading: false, data: response.data.data }));
      } else {
        this.setState({ data: [], searchloading: false, select_user_obj: null })
      }
    } catch (error) {
      errorMessage(error)
    }
  }

  handleOnSelectSlot = (value, event) => {
    if (event && event.select_obj) {
      this.setState({ select_user_obj: event.select_obj })
    } else {
      this.setState({ select_user_obj: null })
    }
  }

  render() {
    return (<React.Fragment>
      <div className="card-container">
        <Spin spinning={this.state.loading}>
          <Form
            className="profile"
            {...this.layout}
            name="profile"
            ref={this.assigneeFormRef}
            onFinish={this.onSetAssigneeFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              name="assignee_id"
              label="Assignee"
              rules={formValidation.assignee}>
              {/* <Select className='selectors' allowClear={true}>
                {

                  this.state.internalusers?.map((item) => {
                    const { displayname, _id } = item;
                    return <Select.Option value={_id} key={_id}>{displayname}</Select.Option>
                  })
                }
              </Select> */}

              <AutoComplete onSearch={this.handleSearch}
                placeholder="Search by First/Last name"
                loading={this.state.searchloading}
                onSelect={(value, event) => this.handleOnSelectSlot(value, event)}>
                {this.state.data && this.state.data.map((item) => (
                  <Select.Option select_obj={item} value={item.displayname} key={item._id}>{item.displayname}</Select.Option>
                ))}
              </AutoComplete>

            </Form.Item>

            <Form.Item >
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Update
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
    )
  }


}
export default SetAssigneeComponent;
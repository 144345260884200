import React from 'react';
import "../../provider/profile/providerProfile.style.css"
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import AppointmentTableView from '../../patient/appointment/AppointmentTableView';

export class AppointmentLocalProviderView extends React.Component {
  render() {
    return (
      <React.Fragment>
        <AfterLoginLayout>
          <div className="container-fluid">
            <div className="banner-header">
              <h2>Appointments</h2>
            </div>

            <AppointmentTableView
              islocalProviderView={true}
              {...this.props} />

          </div>
        </AfterLoginLayout>
      </React.Fragment>)
  }
}

export default AppointmentLocalProviderView
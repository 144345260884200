import { GET, POST } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";
import { NONAUTHROUTE } from "../../constants/RouterPath"
import { message } from 'antd';
import btoa from 'btoa';

/**
 * Get Internal User list information 
 */
function getInternalUsers() {
  let url = APIENDPOINT.ADMIN.LISTUSERS;
  return GET(url);
}

const createInternalUser = async (formDetails) => {
  //const userObj = authenticationService.currentUserValue
  let data = {
    "firstname": formDetails.firstname || "",
    "middlename": formDetails.middlename || "",
    "lastname": formDetails.lastname || "",
    "email": formDetails.email || "",
    "phone": formDetails.phone || "",
    "countrycode": formDetails.countryCallingCode || "",
    "usertype": formDetails.usertype || "",
    "username": btoa(formDetails.username) || ""
  }
  return POST(APIENDPOINT.SIGNUP, data)

  // await POST(APIENDPOINT.SIGNUP,data).then((response)=>{
  //   if (response.data.status === "success"){
  //       message.success(response.data.message);
  //   }else{
  //     message.error(response.data.message);
  //   }
  // }).catch((err)=>{
  //   message..message);
  // });
}

// Verification of Internaluser Activation link
const verifyActivationlink = async (history, id) => {
  var data = {
    "token": id
  };

  await POST(APIENDPOINT.INTERNALUSER.VERIFYACTIVATIONLINK, data).then((response) => {
    if (response.data.status === "success") {
      if (response.data && response.data.passwordupdated && response.data.passwordupdated === true) {
        message.error("User is activated already, so Activation link is invalid. Please login..");
        history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
      }
    } else {
      history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
      message.error("Link Expired:" + response.data.message);
    }
  }).catch((err) => {
    history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
    message.error(err.message);
  });
}

//InternalUser Resetpassword
const internalResetPassword = async (details, history) => {
  var data = {
    "password": btoa(details.formData.password) || "",
    "confirmpassword": btoa(details.formData.confirmpassword) || "",
    "token": details.id,
  }

  await POST(APIENDPOINT.INTERNALUSER.INTERNALRESETPASSWORD, data).then((response) => {
    if (response.data.status === "success") {
      message.success(response.data.message);
      history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
    } else {
      message.error(response.data.message);
    }
  }).catch((err) => {
    message.error(err.message);
  });
}

/**
 * Search Internal User
 */
function searchInternalUser(searchParams) {
  const _searchQuery_params = "&searchKey=" + (searchParams && searchParams.searchkey_value)
  let url = APIENDPOINT.ADMIN.LISTUSERS + _searchQuery_params
  return GET(url);
}

const userListService = {
  getInternalUsers: getInternalUsers,
  createInternalUser: createInternalUser,
  internalResetPassword: internalResetPassword,
  verifyActivationlink: verifyActivationlink,
  searchInternalUser: searchInternalUser
}
export default userListService;
import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import '../../../landing/landingPage/Section1/Section1.css'
import { NONAUTHROUTE } from '../../../../constants/RouterPath';
import drmale from "../../../../assets/images/drmale.png";
import './Section1.css'
export class Section1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            newUserVal: false
        }
    }

    render() {

        return (
            <div className='section1'>
                <Container>
                    <Row>
                        <Col className='section1Col'>
                            <h1 className='mt-5'>Take your <br /> practice to <br /><span style={{ color: '#327FC9' }}> new </span>heights </h1>
                            <p className='mt-4'>Serve more patients efficiently,manage everything from Patient Records and Prescription,to Scheduling Appoinment seamlessly</p>
                            <Button className=' section btn' style={{ fontSize: '16px', backgroundColor: '#224893', hover: 'none', border: 'none', textAlign: 'center' }} href={NONAUTHROUTE.PROVIDER.REGISTRATIONPATH}>Get started for FREE</Button>
                        </Col>
                        <Col lg="6" className='section1Col2'>
                            {/* <div>
                                <img className='appointment' src='/Appointment.png' alt='appointment' />
                            </div>
                            <div className='mt-3' style={{ marginTop: '-100px' }}>
                                <img className='consult' src='/consult.png' alt='consult' />
                            </div>
                            <div style={{ marginLeft: '-100px', marginTop: '-100px' }}>
                                <img className='onCall' src='/onCall.png' alt='onCall' />
                            </div> */}
                            <img className='consultDoctor' src={drmale} alt='maleDoctor' />

                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Section1
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { authenticationService } from '../../general/login/login.service';
import { get_provider_user_obj, get_room_details_obj } from '../../patient/appointment/Utils';
import VideoComponent from '../../twilio-video/VideoComponent';
import { get_provider_obj, get_patient_obj, get_patient_user_obj } from '../../patient/enquiry/EnquiryUtils';

export class PreJoinScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentUser: [],
            currentItem: {},
            user_obj: {},
            patient_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            room_details_obj: {},
            consultationInfo: {},
        }
        this.redirectCheck(props)
    }
    redirectCheck(props) {
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
            }
        }
    }

    getvalsfromAPI = async () => {
        this.setState({ loading: true })
        const currentItem = this.props && this.props.location && this.props.location.state && this.props.location.state.currentItem
        const consultationInfo = this.props && this.props.location && this.props.location.state && this.props.location.state.consultationInfo
        const currentUser = authenticationService.currentUserValue;
        if (currentItem) {
            const patient_obj = get_patient_obj(currentItem)
            const user_obj = get_patient_user_obj(currentItem)
            const provider_obj = get_provider_obj(currentItem)
            const provider_user_obj = get_provider_user_obj(currentItem)
            const room_details_obj = get_room_details_obj(consultationInfo)
            this.setState({
                currentItem: currentItem,
                user_obj: user_obj,
                patient_obj: patient_obj,
                provider_obj: provider_obj,
                provider_user_obj: provider_user_obj,
                room_details_obj: room_details_obj,
                currentUser: currentUser,
            })
        } else {
            this.redirectCheck(this.props)
        }
    }

    componentDidMount() {
        this.getvalsfromAPI()
    }

    render() {
        return (
            <React.Fragment>
                <AfterLoginLayout>
                    <div className="container-fluid">
                        {/* <Breadcrumb>
                            <Breadcrumb.Item>
                                Enquiries
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{(this.state.selected_enquiry && this.state.selected_enquiry.enquiryexternalid) || this.state.selected_enquiry._id}</Breadcrumb.Item>
                        </Breadcrumb> */}
                        <br />

                        <VideoComponent
                            {...this.props} />



                    </div>
                </AfterLoginLayout>
            </React.Fragment>
        )
    }
}

export default withRouter(PreJoinScreen)
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LocalpatientListService from '../../../services/localprovider/PatientList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { EnquiryTableView } from '../../patient/enquiry/EnquiryTableView';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { getEnquiryListDetails } from '../../../actions/patient/EnquiriesAction'
import AppointmentTableView from '../../patient/appointment/AppointmentTableView';
import OpinionRequest from "../../patient/dashboard/OpinionRequest";

export class ViewPatientDetails extends Component {
    constructor(props) {
        super(props);
        const id = this.props && this.props.match && this.props.match.params && this.props.match.params.id
        this.state = {
            selected_id: id,
            loading: true,
            selected_record: (this.props && this.props.location && this.props.location.state && this.props.location.state.selected_record) || {},
            prevPath: (this.props && this.props.location && this.props.location.state && this.props.location.state.prevPath) || "",
            selectedTabs: ''
        }
        this.redirectCheck(props)
    }
    redirectCheck(props) {
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
            }
        }
    }


    getPatientData = async () => {
        try {
            this.setState({ loading: true });
            let response = await LocalpatientListService.getUserProfile(this.state && this.state.selected_id);
            if (response.data.status === "success") {
                this.setState({ loading: false })
                // if (response && response.data && response.data.user) {
                //     if (response.data.user.length > 0) {
                //         const _user = response.data.user[0]
                //         const _patient_obj = get_patient_obj(_user)
                //         this.setState({ selected_record: _patient_obj, loading: false })
                //     }
                // }
            } else {
                this.setState({ loading: false })
                //message.error(response.data.message);
            }
        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }


    componentDidMount() {
        this.getPatientData();
        this.setState({ selectedTabs: 'enquiries' })
    }

    render() {
        const renderPatientName = () => {
            let displayName = ""
            if (this.state && this.state.selected_record) {
                displayName = this.state.selected_record.firstname + " " + this.state.selected_record.middlename + " " + this.state.selected_record.lastname
            }
            return displayName
        }

        const handleShow = () => {

            this.setState({ openModal: true })
        };
        const handleClose = (set = '') => {
            this.setState({ openModal: false })
            if (set !== '') {
                this.getEnquiries()
            }
        };

        return (
            <React.Fragment>
                <AfterLoginLayout>
                    <div className="container-fluid">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={this.state.prevPath}>Patient</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{renderPatientName()}</Breadcrumb.Item>
                        </Breadcrumb>
                        <br />

                        {(() => {
                            if (!this.state.loading) {

                                return <React.Fragment>
                                    {this.state.selectedTabs === 'enquiries' && <div className='createenquiry col-lg-3 mr-3 float-right' style={{ zIndex: "100", verticalAlign: "top", marginTop: "-39px" }}><button onClick={handleShow} className="btn btn-primary "><i className="fa fa-solid fa fa-plus pr-1"></i>New opinion request</button>
                                        {this.state.openModal && <OpinionRequest displayname={renderPatientName()} patient_id={this.state && this.state.selected_id} openModal={this.state.openModal} setOpenModal={handleClose} localProvider={true} />}
                                    </div>}
                                    <Tabs defaultActiveKey='enquiries' onSelect={(k) => this.setState({ selectedTabs: k })} >

                                        <Tab title="Enquiries" eventKey="enquiries">

                                            <EnquiryTableView
                                                showMultiSectionOption={false}
                                                showCreate={true}
                                                showCreateEnquiry={this.state.openModal}
                                                //patient_id={this.state && this.state.selected_record && this.state.selected_record.user_id}
                                                patient_id={this.state && this.state.selected_id}
                                                {...this.props}
                                            />
                                        </Tab>
                                        <Tab title="Appointments" eventKey="appointments">
                                            <AppointmentTableView
                                                patient_id={this.state && this.state.selected_id}
                                                {...this.props} />
                                        </Tab>


                                    </Tabs>
                                </React.Fragment>
                            }
                        })()}
                    </div>
                </AfterLoginLayout>
            </React.Fragment >
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getEnquiryListDetails: () => dispatch(getEnquiryListDetails(ownProps))
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        enquiryStore: state.enquiryList,
    }
}

//export default withRouter(ViewPatientDetails)
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ViewPatientDetails);

import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from 'react-bootstrap';
import { message } from 'antd';
import { getParsedSlots, new_parse_timeslot, update_myslots_parse_timeslot } from './SchedulerUtils';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { authenticationService } from '../../general/login/login.service';
import providerSchedulerService from '../../../services/provider/Scheduler.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import "./providerScheduler.css"

export default function SchedulerView() {
    const [unselectedSlot, setUnselectedzSlot] = useState([])
    const [loading, setLoading] = useState(false)
    const [allScedules, setAllSchedules] = useState({ schedules: [], blocked_times: [] })
    const history = useHistory()
    const [newRes, setNewRes] = useState([])

    const allSlots = (allScedules.schedules && allScedules.schedules) || (allScedules.blocked_times && allScedules.blocked_times) ? [...allScedules.schedules, ...allScedules.blocked_times] : []

    const slotz = new_parse_timeslot(allSlots)

    const bookedSlotz = allScedules.blocked_times && allScedules.blocked_times.map(item => moment(item).format())
    const pre_schedule = allScedules.schedules && allScedules.schedules.map(item => moment(item).format())

    const handleAvailabiltiy = (date, time) => {
        const dateTime = moment(date + " " + time, "YYYY-MM-DD HH:mm A").format()
        if (!unselectedSlot.includes(dateTime)) {
            setUnselectedzSlot([...unselectedSlot, dateTime])
        } else if (unselectedSlot.includes(dateTime)) {
            setUnselectedzSlot([...unselectedSlot.filter(item => item !== dateTime)])
        }
    }

    async function getProviderSchedules() {
        try {
            const currentUser = authenticationService.currentUserValue;
            const provider_id = currentUser && currentUser.usertype_id
            setLoading(false)
            let response = await providerSchedulerService.getSchedulebyProvider(provider_id);
            if (response.data.status === "success") {
                if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
                    const _schedules = response.data.schedules
                    const all_schedule = getParsedSlots(_schedules);
                    setAllSchedules({ ...allScedules, schedules: all_schedule && all_schedule.available_slots, blocked_times: all_schedule && all_schedule.booked_slots })
                    setNewRes(response.data.schedules)
                    setLoading(true)
                }
            } else if (response.data.status === "failure") {
                setAllSchedules({ ...allScedules, schedules: [] })
                setLoading(true)
            }
        } catch (err) {
            errorMessage(err)
        }
    }

    const handleUpdateSlots = async () => {
        const currentUser = authenticationService.currentUserValue;
        const updatedAvail = []
        pre_schedule && pre_schedule.forEach(item => {
            if (!unselectedSlot.includes(item)) updatedAvail.push(new Date(item))
        })
        const momeUpdate = updatedAvail.map(item => moment(item).format())
        try {
            setLoading(false)
            const _parsedslots = update_myslots_parse_timeslot(newRes, momeUpdate, updatedAvail)
            const data = { 
                provider_id: currentUser && currentUser.usertype_id,
                schedules: _parsedslots
            }
            const response = await providerSchedulerService.saveProviderSchedule(data);
            if (response.data.status === "success") {
                setUnselectedzSlot([])
                setLoading(true)
                message.success(response.data.message);
                getProviderSchedules()
            } else {
                setLoading(false)
                message.error(response.data.message);
            }

        } catch (err) {
            errorMessage(err);
        }
    }

    useEffect(() => {
        getProviderSchedules()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <AfterLoginLayout>
            <div>
                <div>
                    {loading ?
                        <div>
                            <div className='d-flex align-items-center mb-2'>
                                <FontAwesomeIcon onClick={() => history.push("/my-schedule")} style={{ cursor: "pointer" }} icon={faArrowLeft} />
                                <span className='ml-2'>Schedule</span>
                            </div>
                            <div className='container-fluid px-4 py-2 bg-white'>
                                {slotz.length !== 0 || allScedules.schedules.length !== 0 ?
                                    <div>
                                        <h4>Your slots</h4>
                                        <div>
                                            {
                                                
                                              slotz && slotz.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className='font-weight-bold'>{moment(item.day).format("DD/MM/YYYY")}</div>

                                                            <div className='d-flex flex-wrap'>
                                                                {item.slots.sort((a,b)=>  moment(item.date+' '+a,"YYYY-MM-DD HH:mm A")- moment(item.date+' '+b,"YYYY-MM-DD HH:mm A") ).map((slotTime, idx) => <p onClick={() => {
                                                                    if (!bookedSlotz.includes(moment(item.date + " " + slotTime, "YYYY-MM-DD HH:mm A").format())) {
                                                                        handleAvailabiltiy(item.date, slotTime)
                                                                    }
                                                                }} style={{ border: "1px solid #143C8C", fontSize: "12px", width: "85px" }} className={`mr-2 py-1 font-weight-bold rounded 
                                                         ${allScedules.blocked_times && allScedules.blocked_times.find(booked => moment(booked, "YYYY-MM-DD HH:mm A").format() === moment(item.date + " " + slotTime, "YYYY-MM-DD HH:mm A").format()) ? "slot-booked" :
                                                                        unselectedSlot.length !== 0 && unselectedSlot.find(unselected => unselected === moment(item.date + " " + slotTime, "YYYY-MM-DD HH:mm A").format()) ? "slot-not-sel" : "slot-sel"}`} key={idx + item.date}>{slotTime}</p>)}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <button className='btn btn-primary' onClick={() => {
                                                if (unselectedSlot && unselectedSlot.length !== 0) {
                                                    handleUpdateSlots()
                                                }
                                            }} >Update my slots</button>
                                        </div>
                                    </div>
                                    :
                                    <div className='d-flex justify-content-center align-items-center' >
                                        <p className='text-center'>No Schedules Found</p>
                                    </div>

                                }
                            </div>
                        </div> :

                        <div className='d-flex justify-content-center'>
                            <div>
                                <Spinner animation="border " variant="primary" />
                            </div>
                        </div>
                    }
                </div>

            </div>

        </AfterLoginLayout>
    )
}


import React, { useState } from 'react'
import { authenticationService } from '../../../component/general/login/login.service';
import { AUTHROUTE } from "../../../constants/RouterPath";
import Appointments from './Appointments';
import { AfterLoginDashboardLayout } from '../../shared/AfterLoginDashboardLayout';
import OpinionRequest from './OpinionRequest';

const PatientDashboard = ({ ...props }) => {

  const [openModal, setOpenModal] = useState(true);
  const handleClose = () => {
    setOpenModal(false)
    localStorage.setItem("newUserVal", false)
  };

  if (props && props.location && props.location.state) {
    const redirect_url = props.location.state && props.location.state.redirect_url && props.location.state.redirect_url.url;
    const patient_url = props.location.state && props.location.state.token && props.location.state.token !== undefined && props.location.state.pathname;
    if (redirect_url) {
      props.history.push({
        pathname: AUTHROUTE.PATIENT.ENQUIRIES_PATH,
      });
    }
    if (patient_url) {
      props.history.push({
        pathname: props.location.state.pathname,
      });
    }
  }

  const currentUser = authenticationService.currentUserValue;
  return (
    <AfterLoginDashboardLayout from={'dashboard'}>
      {
        localStorage.getItem("newUserVal") === "newUser" && <OpinionRequest displayname={currentUser && currentUser.displayname} patient_id={currentUser && currentUser.usertype_id} openModal={openModal} setOpenModal={handleClose} />
      }
      <Appointments
        patient_id={currentUser && currentUser.usertype_id}
        {...props}
      />
    </AfterLoginDashboardLayout>
  )
}

export default PatientDashboard
import React from "react";
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { Container, Row, Col, Card } from "react-bootstrap";
import secondlogo from "../../../../src/assets/images/secondlogo.png";
import opiniontext from "../../../../src/assets/images/opiniontext.png";
import './OpinionSuccess.css'
import { AiOutlineFile } from "react-icons/ai";
import moment from "moment";

export default function OpinionSuccess(props) {
  let data = props.location.state[0].selectedFiles;
  return (
    <React.Fragment>
      <div className="opinionSuccess">
        <Container>
          <Row>
            <Col className="col1">
              <img src={secondlogo} alt="2nd logo" />
              <span className="ml-1"> <img src={opiniontext} alt="opinion text" /></span>
              <h4 style={{ color: "white" }}>
                We are looking forward to assist you <br />with your health concern
              </h4>
              <a className='btn btn-primary my-4 px-4' style={{ backgroundColor: '#Ea634A' }} href='/patient-dashboard'>Go to Dashboard</a>
            </Col>
            <Col className="col2">
              <Card className="card">
                <Col>
                  <Row lg={12} sm={12} md={12}>
                    <div>
                      <h4>Hey {props.location.state[0].selfUserName},</h4>
                      <p>
                        Your concern has been received and we will assign a
                        doctor and contact you soon
                      </p>
                      <hr style={{ width: '118.5%', marginLeft: '30px' }} />
                    </div>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <div className="label ml-3">Reference no</div>
                      <div className="ml-3 mt-2 font-weight-bold reference">{props.location.state[0].enquiryCode}</div>
                    </Col>
                    <Col xs={4}>
                      <div className="label ml-3">Submitted date</div>
                      <div className="ml-3 mt-2 font-weight-bold submitDate">{moment(props.location.state[0].submittedDate).format('MMMM Do YYYY')}</div>
                    </Col>
                    <hr style={{ width: '93%', marginLeft: '30px' }} />
                    {/* <Col xs={8}>
                      <div className="label">Submitted Date</div>
                      <div className="value">
                        {get_formated_date_only(
                          props.location.state[0].selectedDate
                        )}
                      </div>
                    </Col> */}

                  </Row>
                  <Card className="p-2 ml-3"
                    style={{
                      backgroundColor: "#EFF3F4",
                      height: "auto",
                      marginTop: "8px",
                      width: '700px'
                    }}
                  >
                    <Row className="py-2 px-2 card2">
                      <Col>
                        <h6 className="text-secondary" >PATIENT</h6>
                        <div className='mt-3 font-weight-bold data'>
                          {props.location.state[0].selfUserName}
                        </div>
                      </Col>
                      <Col>
                        <h6 className="text-secondary">CONCERN</h6>
                        <div className='mt-3 data'>
                          {props.location.state[0].healthConcern}
                        </div>
                      </Col>
                      <Col>
                        <h6 className="text-secondary">DETAILS</h6>
                        {Object.keys(data).map((key) => (
                          <div value={key} className='mt-3 data'>
                            <AiOutlineFile className='p-1 mr-2 text-light rounded-circle text-dark' style={{ backgroundColor: '#D1D1D1' }} size={24} />
                            {data[key].name}
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Card>

                  <Row lg={12} md={12} sm={12}>
                    <div>
                      <p style={{ marginLeft: '35px', marginTop: '-10px' }}>
                        We will choose an expert doctor for your health concern
                        and  send the available slots to <br />you via mail/phone!
                      </p>
                      <h6 style={{ marginTop: "10px", marginLeft: '34px',marginBottom:'30px' }}>Thank you!</h6>
                    </div>
                  </Row>
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
        <Row className="mt-5">
          <Col>
            <footer className="page-footer font-small blue pt-4 mt-4 ">
              <div className="text-white footer-copyright text-center pb-3"> Questions ?{" "}
                <a className="page-footer"
                  style={{ color: "white" }}
                  href={NONAUTHROUTE.GENERAL.CONTACTUSPATH}
                >
                  Contact our Customer Care Support
                </a>
              </div>
            </footer>
          </Col>

        </Row>

      </div>
    </React.Fragment>
  );
}

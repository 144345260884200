import axios from 'axios';
import store from "../store";
// Set config defaults when creating the instance
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export function GET(url) {
  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
    "Authorization": store.getState().storage.auth
  }
  return API.get(url, { headers });
}
export function GET_NONAUTH(url) {
  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
  }
  return API.get(url, { headers });
}
export function POST(url, data) {
  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
    "Authorization": store.getState().storage.auth
  }
  return API.post(url, data, { headers });
}
export function PUT(url, data) {
  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
    "Authorization": store.getState().storage.auth
  }
  return API.put(url, data, { headers });
}
export function DELETE(url, data) {
  return axios.delete(
    process.env.REACT_APP_API_URL + url,
    {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": store.getState().storage.auth
      },
      data: JSON.stringify(data)
    })
}
export function POST_NONAUTH(url, data) {
  let headers = {
    'Content-Type': 'application/json;charset=utf-8',
  }
  return API.post(url, data, { headers });
}

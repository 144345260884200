import React, { Component } from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'

import './Section4.css'
import Onlinebooking from "../../../../assets/images/patients/Onlinebooking.png";
import QualifiedDoctors from "../../../../assets/images/patients/QualifiedDoctors.png";
import Freefollowup from "../../../../assets/images/patients/Freefollowup.png";
import Privacy from "../../../../assets/images/patients/Privacy.png";



export class Section4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false,
            visible: true
        }
    }
    render() {
        return (
            <div className='mt-5  pt-5 sec4'>
                <Container >
                    <h5 className='mt-5'>BENEFITS</h5>
                    <h3 className='mt-3 head_sec'>Exclusive to Second Opinion</h3>

                    <Row className='no-gutters mt-5 cards1'>
                        <Col lg='6'>
                            <div className="card-holder">
                                <Card.Img
                                    src={Onlinebooking}
                                    alt='doctor'

                                    // style={{ height: "320px", width: "520px" }}
                                    className='online_booking'

                                />
                                <Card.Text>
                                    <h6 className='OB'>Online booking</h6>
                                    <p className='OB1'>Do everything online from appointment <br />booking to consultation and payment.</p>
                                </Card.Text>
                            </div>

                        </Col>

                        <Col lg='6'>
                            <div className="card-holder">

                                <Card.Img
                                    src={QualifiedDoctors}
                                    alt='doctor'
                                    className='qualified_doctor'
                                    height={"280px"}


                                />
                                <Card.Text>
                                    <h6 className='QD'>Qualified Doctors</h6>
                                    <p  className='QD1'>Consult the specialists in the industry and <br />get access to the best diagnosis.</p>
                                </Card.Text>
                            </div>
                        </Col>
                    </Row>
                    <Row className='no-gutters mt-5 cards1'>
                        <Col lg='6' className='card1col1'>
                            <div className="card-holder">

                                <Card.Img
                                    src={Freefollowup}
                                    alt='doctor'
                                    className='free_followup'
                                    height={"280px"}


                                />
                                <Card.Text>
                                    <h6 className='FP'>Free followup</h6>
                                    <p className='FP1'>Get free follow ups to ensure that you stay on <br />track of your health conditions.</p>
                                </Card.Text>
                            </div>
                        </Col>

                        <Col lg='6' className='card1col1'>
                            <div className="card-holder">

                                <Card.Img
                                    src={Privacy}
                                    alt='doctor'
                                    className='privacy'
                                // style={{ height: "370px", width: "470px" }}


                                />
                                <Card.Text>
                                    <h6 className='card2h6'>Privacy</h6>
                                    <p className='card2p'>Complete protection of your data with <br />strong encryption and privacy policy.</p>
                                </Card.Text>
                            </div>
                        </Col>
                    </Row>



                </Container>

            </div>
        )
    }
}

export default Section4
import React, { useEffect } from "react";

export default function OtpTimer({handleCount,count}){

    const secondsToDisplay = count % 60
    const minutesRemaining = (count - secondsToDisplay) / 60
    const minutesToDisplay = minutesRemaining % 60
   // const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

    useEffect(() => {
        const timer =
        count > 0 && setInterval(() => handleCount(), 1000);
      return () => clearInterval(timer);
    },[count,handleCount])

    return (
        <span className="font-weight-bold" >Resend OTP in <span className="font-weight-bold ml-2" style={{color:"#0BC1C2"}}>{twoDigits(minutesToDisplay)}:
        {twoDigits(secondsToDisplay)}</span></span>
    )
}

const twoDigits = (num) => String(num).padStart(2, '0')
import { GET, POST } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";

/**
 * Get Schedule by Provider 
 */
function getSchedulebyProvider(provider_id) {
  let url = APIENDPOINT.PROVIDER.GET_PROVIDER_SCHEDULE+provider_id;
  return GET(url);
}

/**
 * Save Provider Schedule
 */
const saveProviderSchedule = async (dataObj) => {
  return POST(APIENDPOINT.PROVIDER.SAVE_PROVIDER_SCHEDULE, dataObj)
}

// Get all timezone slots
const getAllTimeZones = () => {
  return GET(APIENDPOINT.PROVIDER.GET_TIMEZONES)
}

//Get slots for particular time zone id
const getTimezoneSlots = (timezone_id) => {
  return GET(APIENDPOINT.PROVIDER.GET_TIMEZONE_AVAIL_SLOTS + timezone_id)
}

const providerSchedulerService = {
  getSchedulebyProvider: getSchedulebyProvider,
  saveProviderSchedule:saveProviderSchedule,
  getAllTimeZones:getAllTimeZones,
  getTimezoneSlots:getTimezoneSlots,
}
export default providerSchedulerService;


import React from 'react';
import { message } from 'antd';
import { Spinner, Row, Col, Dropdown } from 'react-bootstrap';
import { RiCalendar2Line } from "react-icons/ri";
import { errorMessage } from '../../../utils/ServiceHandler';
import AppointmentService from '../../../services/patient/Appointment.Service';
import { get_room_details_obj } from '../../patient/appointment/Utils';
import { FaVideo } from 'react-icons/fa';
import { BiRefresh } from "react-icons/bi";
import { parseAppointments } from '../appointment/Utils';
import { formateName, get_formated_patient_ist_date, get_formated_patient_ist_time, new_get_formated_patient_ist_date } from '../enquiry/EnquiryUtils';
import { authenticationService } from '../../general/login/login.service';
import { Button, Badge } from 'react-bootstrap';
import { AUTHROUTE } from '../../../constants/RouterPath';
import { get_provider_user_obj } from '../../patient/appointment/Utils';

import { get_provider_obj, get_patient_obj, get_patient_user_obj, get_speciality_obj } from '../../patient/enquiry/EnquiryUtils';
import "./dashboard.css";
// import'../appointment/appointment.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { get_schedule_details_obj } from '../../patient/appointment/Utils';
import MyRequests from './MyRequests';
import { Link } from 'react-router-dom';
import PatientRightBar from '../sidebar/PatientRightBar';
import OpinionRequest from './OpinionRequest';
import moment from 'moment';

// import moment from 'moment';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button className='threedotbutton' variant='link'
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </Button>
));
export class Appointments extends React.Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        this.state = {
            patient_id: this.props && this.props.patient_id,
            islocalProviderView: (this.props && this.props.islocalProviderView) || false,
            loading: false,
            consultations: [],
            past: [],
            upcoming: [],
            previewVisible: false,
            previewImage: "",
            fileList: [],
            currentUser: {},
            viewer_visible: false,
            reschedule_visible: false,
            edit_visible: false,
            selected_appointment: {},
            showList: [],
            consultationInfo: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            all_specialities: [],
            currentItem: {},
            speciality_obj: {}
        }
    }

    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };

    handleCancel = () => {
    };

    view_record = (record) => {
        setTimeout(() => {
            if (record) {
                this.setState({ selected_appointment: record, viewer_visible: true });
            }
        }, 1);
    }

    handleResponse = (response) => {
        if (response.data.status === "success") {
            if (response && response.data && response.data.data) {
                if (response.data.data.length > 0) {
                    const _consultation = parseAppointments(response.data.data)

                    const new_objs = []
                    const array_data = []
                    const filter_dates = _consultation && _consultation.upcoming.filter((item,index) => {
                        let newItem
                      if(!new_objs.includes(moment(item.consultationInfo.scheduleDetailsInfo.date+ ' '+item.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format())){
                        new_objs.push(moment(item.consultationInfo.scheduleDetailsInfo.date+ ' '+item.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format())
                        let obj={}
                        obj.norm_date=moment(item.consultationInfo.scheduleDetailsInfo.date+ ' '+item.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format()
                        obj.conv_date=new_get_formated_patient_ist_date(item.consultationInfo.scheduleDetailsInfo.date,item.consultationInfo.scheduleDetailsInfo.slot,item.consultationInfo.timename)
                        array_data.push(obj)
                        newItem = item
                      }
                      return newItem
                    })
                    new_objs.sort((a,b) => moment(a) - moment(b))
                    array_data.sort((a,b) => moment(a.conv_date)-moment(b.conv_date))
                    const new_sltz = []
                    array_data.forEach((item) => {
                      filter_dates.forEach(objitm => {
                       if(item.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date+ ' '+objitm.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format()){
                        const indx = array_data.findIndex(searchItm => searchItm.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date+ ' '+objitm.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format())
                        new_sltz.splice(indx,0,{...objitm,shown_status:'upcoming'})
                       }
                      })
                    })
                    this.setState({
                        upcoming: _consultation && new_sltz,
                        past: _consultation && _consultation.past,
                        loading: false
                    })
                }
            }
        } else {
            this.setState({ loading: false })
            // message.error(response.data.message);
        }
    }


    getAppointments = async () => {
        try {
            this.setState({ loading: true });
            //const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })

            let response = await AppointmentService.getPatientAppointments(this.state && this.state.patient_id);
            this.handleResponse(response)
        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }

    getLocalPatientAppointments = async () => {
        try {
            this.setState({ loading: true });
            const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })

            let response = await AppointmentService.getLocalPatientAppointments(currentUser._id);
            this.handleResponse(response)

        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }



    showModal = () => {
        this.setState({
            modalVisible: true,
            fileList: []
        });
    };


    componentDidMount() {
        this.getVals()
        if (this.props && this.props.patient_id) {
            this.getAppointments()
        } else if (this.props && this.props.islocalProviderView) {
            this.getLocalPatientAppointments()
        }
    }

    getVals() {
        const currentItem = this.state.selected_appointment
        const currentUser = authenticationService.currentUserValue;
        //const all_specialities = this.props && this.props.all_specialities
        const patient_obj = get_patient_obj(currentItem)
        const user_obj = get_patient_user_obj(currentItem)
        const provider_obj = get_provider_obj(currentItem)
        const provider_user_obj = get_provider_user_obj(currentItem)
        const speciality_obj = get_speciality_obj(this.state.selected_appointment.consultationInfo)

        this.setState({
            currentItem: currentItem,
            consultationInfo: this.state.selected_appointment.consultationInfo,
            user_obj: user_obj,
            patient_obj: patient_obj,
            provider_obj: provider_obj,
            provider_user_obj: provider_user_obj,
            currentUser: currentUser,
            speciality_obj: speciality_obj
            //all_specialities: all_specialities
        })
    }

    handleClick = () => {

    }

    navigatetoVideoCall = () => {
        const room_details_obj = get_room_details_obj(this.state && this.state.selected_appointment.consultationInfo)
        if (room_details_obj) {
            this.props && this.props.history && this.props.history.push({
                pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
                state: {
                    currentItem: this.state.currentItem,
                    consultationInfo: this.state.selected_appointment.consultationInfo,
                    user_obj: this.state.user_obj,
                    patient_obj: this.state.patient_obj,
                    provider_obj: this.state.provider_obj,
                    provider_user_obj: this.state.provider_user_obj,
                    currentUser: this.state.currentUser,
                }
            });
        } else {
            message.error("Issue while initiating the Video call. Room details not available")
        }
    };
    handleShow = () => { this.setState({ openModal: true }) };
    handleClose = () => { this.setState({ openModal: false }) };

    render() {

        const columns = [
            {
                text: 'PATIENT',
                dataField: 'patientUserInfo.firstname',
                // key: ['patientInfo', '_id'],
                formatter: (text, record) => (
                    <>
                        {(() => {
                            return formateName(record.patientUserInfo ? record.patientUserInfo.firstname : '')
                        })
                            ()}
                    </>
                )
            },
            {
                text: 'TIME',
                dataField: 'scheduleDates',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)

                            let time_format = get_formated_patient_ist_time(schedule_obj.date, schedule_obj.slot, record.consultationInfo.timename)
                            return (<React.Fragment>
                                <span>{time_format}</span>
                            </React.Fragment>
                            )
                        })()}
                    </>
                ),
            },
            {
                text: 'DATE',
                dataField: 'scheduleDate',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)

                            let time_format = get_formated_patient_ist_date(schedule_obj.date, schedule_obj.slot, record.consultationInfo.timename)
                            return (<React.Fragment>
                                <span>{time_format}</span>
                            </React.Fragment>
                            )
                        })()}
                    </>
                ),
            },
            {
                text: 'DOCTOR',
                dataField: 'providerUserInfo.firstname',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            return formateName(record.providerUserInfo ? record.providerUserInfo.firstname : '')
                        })
                            ()}
                    </>
                )
            },

            {
                text: '',
                dataField: '',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            return (<React.Fragment>
                                <div className='actionHolder' onMouseOver={() => this.view_record(record)}>
                                    <div className="joinBtn">
                                        < FaVideo className='text-info' />
                                        <Button variant='link' style={{ "padding": "5px" }} onClick={this.navigatetoVideoCall.bind(this, record)}> Join </Button>
                                    </div>
                                    <div className="joinHolder">
                                        <div className="moreBtn">
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} />
                                                <Dropdown.Menu style={{ minWidth: "0px" }} title="">
                                                    <Dropdown.Item>
                                                        <Link to={{ pathname: AUTHROUTE.PATIENT.APPOINTMENT_View, state: { selected_appointment: record } }}>View
                                                        </Link>
                                                    </Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            )
                        })()}
                    </>
                ),
                // key: ['patientInfo', '_id'],
            },
        ]

        const currentUser = authenticationService.currentUserValue;

        const new_sort = this.state.upcoming && this.state.upcoming.slice(0, 10).map((a, b) => {
            return moment(a.consultationInfo.scheduleDetailsInfo.date + ' ' + a.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()
          }).sort((a, b) => new Date(a) - new Date(b))
      
          const newArray = []
          this.state.upcoming && this.state.upcoming && [...new Set(new_sort)].map((item, index) => this.state.upcoming.slice(0, 10).map(itm => {
            let newItm
            if (moment(itm.consultationInfo.scheduleDetailsInfo.date + ' ' + itm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format() === item) {
              newArray.splice(index, 0, itm)
              newItm = itm
            }
            return newItm
          })
          )
      
        return (

            <React.Fragment>
                {this.state.loading ? <div className='d-flex justify-content-center flex-nowrap'>
                    <div className='position-absolute p-4' variant="primary" style={{ marginRight: '190px' }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                </div> :
                    <Row className='main-layout'>
                        <Col lg={9} md={9} sm={12} className='px-4'>
                            {this.state.upcoming.length !== 0 ?
                                <div>
                                    <h4 className='text-left font-weight-bold'>Appointments</h4>
                                    <p className='text-left'>Select item to view details and pick a slot</p>
                                    <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'fontSize': '14px' }} className='rounded-pill ml-0 my-3'>
                                        <BiRefresh /> Upcoming <Badge className='bg-light text-dark' style={{ fontSize: '12px' }}>{this.state.upcoming.length}</Badge>
                                    </Button>
                                    <BootstrapTable keyField='consultationInfo.id'
                                        wrapperClasses="mytable-responsive"
                                        data={(this.state.upcoming.slice(0,5))}
                                        hover={false}
                                        columns={columns}
                                        bordered={false}
                                    />
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <u><a className='align-items-center font-weight-bold ostextcolor' href='/patient/appointments'>View more</a></u>
                                    </div>
                                </div> :
                                <div>
                                    {!this.state.loading &&
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <RiCalendar2Line size={60} className='text-secondary' />
                                                <h6>No appointments</h6>
                                                <small><p className='text-secondary'>We are finding the right doctor to provide you proper care. You will be notified within 24 hours.
                                                    Meanwhile you can <Button variant='link' onClick={this.handleShow}>create opinion request.{this.state.openModal}</Button></p></small>
                                            </div>
                                            {this.state.openModal && <OpinionRequest displayname={this.state.currentUser && this.state.currentUser.displayname} patient_id={this.state.currentUser && this.state.currentUser.usertype_id} openModal={this.state.openModal} setOpenModal={this.handleClose} />}

                                        </div>
                                    }
                                </div>
                            }

                            <div className=''>
                                <MyRequests
                                    showMultiSectionOption={false}
                                    showCreate={true}
                                    patient_id={currentUser && currentUser.usertype_id}
                                    {...this.props}
                                />
                            </div>


                        </Col>

                        <Col lg={3} md={3} sm={12} className='right-notifications px-3'>
                            <PatientRightBar
                                notification={this.state.upcoming.length !== 0 ? true : false}
                                {...this.props}
                            />
                        </Col>
                    </Row>}

            </React.Fragment >)
    }
}
export default Appointments

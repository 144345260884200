import './App.scss';
import { BsMic } from "react-icons/bs";
import { BsMicMute } from "react-icons/bs";
import { BsCameraVideo } from "react-icons/bs";
import { BsCameraVideoOff } from "react-icons/bs";
import { Button } from 'react-bootstrap';

function AVControl(props) {
  let icon;

  if (props.trackOff) {
    icon = props.type === 'audio' ? <BsMicMute style={{ fontSize: "23px" }} /> : <BsCameraVideoOff style={{ fontSize: "23px" }} />;
  } else {
    icon = props.type === 'audio' ? <BsMic style={{ fontSize: "23px" }} /> : <BsCameraVideo style={{ fontSize: "23px" }} />;
  }


  return (
    <div className="avControl">
      <Button variant="light" style={{ paddingleft: "10px" }} className='fullroundcircle' onClick={() => props.toggleTrack()} >{icon}</Button>
    </div>
  );


}

export default AVControl;
import { POST } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";

/**
 * Get Twilio Token 
 */
function fetch_token(data) {
  let url = APIENDPOINT.FETCH_TWILIO_TOKEN;
  return POST(url, data);
}

const twilioService = {
  fetch_token: fetch_token,
}
export default twilioService;
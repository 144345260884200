import { providerListAction } from './types'
import LocalProviderListService from '../../services/admin/LocalProviderList.Service';
import ProviderListService from '../../services/admin/ProviderList.Service';
import UserListService from '../../services/admin/UserList.Service';
import { alertActions } from "../alertAction";

/**
 * Get all Provider list
 * @returns 
 */
export const getLocalProviderListDetails = () => async (dispatch) => {
  try {
    dispatch(providerListDataRequest())
    const providerPromise = await LocalProviderListService.getLocalProviders();
    const stagesPromise = await ProviderListService.getStages();
    const internalUsersPromise = await UserListService.getInternalUsers();

    const reponses = await Promise.all([providerPromise, stagesPromise, internalUsersPromise]);
    const [providerResponse, stagesResponse, internalUsersResponse] = reponses;
    if (providerResponse.data && stagesResponse.data && internalUsersResponse.data) {
      const users = providerResponse.data && providerResponse.data.data
      const stages = stagesResponse.data && stagesResponse.data.data;
      const internalusers = internalUsersResponse.data && internalUsersResponse.data.data

      dispatch(providerListDataSuccess({ users, stages: stages, internalusers: internalusers }))
      return Promise.resolve({ users, stages: stages, internalusers: internalusers});
    } else {
      dispatch(providerListDataFailure(reponses.data.message));
      dispatch(alertActions.error(reponses.data.message))
      return Promise.reject(reponses.data.message);
    }
  } catch (err) {
    dispatch(providerListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const providerListDataRequest = () => {
  return {
    type: providerListAction.DATA_REQUEST
  }
}

export const providerListDataSuccess = data => {
  return {
    type: providerListAction.DATA_SUCCESS,
    payload: data,
  }
}

export const providerListDataFailure = error => {
  return {
    type: providerListAction.DATA_FAILURE,
    payload: error
  }
}

import { message } from 'antd';
import { GET,POST} from "../../api"
import {APIENDPOINT} from "../../constants/APIEndpoint";
import store from '../../store';
import { storageActions } from "../../actions/storageAction";
import moment from 'moment';
import { authenticationService } from '../../component/general/login/login.service';
import {encryptData} from '../../utils/SecureStorage';
import { errorMessage } from '../../utils/ServiceHandler';

/**
 * Get Profile details informations
 * @param {} users and details 
 */
function getProfile(){
  let currentUser = authenticationService.currentUserValue
  let url=APIENDPOINT.PROFILE_DETAILS+"/"+currentUser._id;
    return GET(url);
}

function getPatientOpinion(){
  let url=APIENDPOINT.GET_PATIENT_OPINION;
  return GET(url);
}


const updateProfile = async(formDetails) =>{
  const userObj = authenticationService.currentUserValue
  var dateOfBirth = moment(formDetails.dateOfBirth).format("MM-DD-YYYY");
  var data = {
    "phone": formDetails.phone,
    "firstname": formDetails.firstname,
    "middlename": formDetails.middlename || "",
    "lastname": formDetails.lastname || "",
    "email": formDetails.email,
    "countrycode": formDetails.countrycode,
    "usertype": userObj?.usertype,
    "dob": dateOfBirth,
    "bloodgroup": formDetails.bloodgroup || "",
    "gender": formDetails.gender || "",
    "maritalstatus": formDetails.maritalstatus || "",
    "height": formDetails.height || "",
    "weight": formDetails.weight || "",
    "emergencycontact": formDetails.emergencycontact || "",
    "emergencycontactcountrycode": formDetails.emergencycontactcountrycode || "",
    "location": formDetails.location || "",
    "user_id": userObj?._id,
    "usertype_id": userObj?.usertype_id
  }
    
  await POST(APIENDPOINT.PROFILE_DETAILS,data).then((response)=>{
      if (response.data.status === "success"){
          message.success(response.data.message);
          store.dispatch(storageActions.update("currentUser",encryptData(response.data.data.user)))
      }else{
        message.error(response.data.message);
      }
    }).catch((err)=>{
      errorMessage(err+'err')
      //message.error(err.message);
    });
  }

  const profileService =  {
    update:updateProfile,
    getProfile:getProfile,
    //getSpecialites:getSpecialites,
    getPatientOpinion: getPatientOpinion
  }
  export default profileService;
import React, { Component } from 'react'
import { RiCalendar2Line } from "react-icons/ri";
import { Row, Col } from 'react-bootstrap';
import ProviderCard from './ProviderCard';
import { authenticationService } from '../../general/login/login.service';
import RightSideBar from '../sidebar/RightSideBar';

export class NoAppointments extends Component {
    render() {
        const currentUser = authenticationService.currentUserValue;
        return (
            <Row className='main-layout'>

                <Col lg={8} md={8} sm={12} pt-3>
                    <div className='ml-2'>
                        <h3>Welcome {currentUser && currentUser.displayname}</h3>
                        <p className='text-secondary font-weight-normal' style={{ fontSize: '18px' }}>Check the latest updates on your account!</p>
                    </div>
                    <ProviderCard />

                    <h4 className='mb-3 ml-2'>Your Appointments</h4>

                    <div class="card">
                        
                        <div class="card-body text-center">
                            <RiCalendar2Line size={60} className='text-secondary' />
                            <h6>No appointments</h6>
                        </div>
                    </div>
                </Col>

                <Col>
                    <RightSideBar />
                </Col>
            </Row>
        )
    }
}

export default NoAppointments
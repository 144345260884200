import { message } from 'antd';
import axios from 'axios';
import btoa from 'btoa';
import {NONAUTHROUTE} from "../../../constants/RouterPath";
import {APIENDPOINT} from "../../../constants/APIEndpoint";
import Role from '../../../constants/role'

// PATIENT-REGISTRATION
export const patientRegister = async(props,formDetails)=>{
  //console.log(formDetails.nationalNumber,formDetails.countryCallingCode);
    var data = {
      "firstname": formDetails.formData.firstname,
      "middlename":formDetails.formData.middlename,
      "lastname": formDetails.formData.lastname,
      "phone": formDetails.nationalNumber,
      "email": formDetails.formData.email,
      "username": btoa(formDetails.formData.username),
      "password": btoa(formDetails.formData.password),
      "usertype": Role.patient.name,
      "countrycode": formDetails.countryCallingCode,
    };
    var config = {
      method: 'POST',
      url: process.env.REACT_APP_API_URL+APIENDPOINT.SIGNUP,
      headers: { 
        'Content-Type': 'application/json;charset=utf-8'
      },
      data : data
    };

    await axios(config)
    .then(function (response) {
      if (response.data.status === 400){
        message.error('Something wrong. ')
        return
      }if (response.data.status === "success"){
        //message.success(response.data.message)
        //redirect to OTP page
        props.history.push({
          pathname: NONAUTHROUTE.GENERAL.REGSITRATIONOTPPATH,
          state: {
             detail: formDetails,
             response:response.data}
        });
        message.success('OTP sent');
     }else {
       message.error('Failed :'+response.data.message)
      }
    })
    .catch(function (err) {
      if (err.response) {
        console.log("client received an error response (5xx, 4xx)",err);
      } else if (err.request) {
        console.log("client never received a response, or request never left",err);
      } else {
        // anything unknown
        console.log("Error : ",err);
      }
      message.error(err.message)
    });   
  }
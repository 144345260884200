import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { errorMessage } from '../../../utils/ServiceHandler';
import { authenticationService } from '../../general/login/login.service';
import { get_patient_obj, get_provider_obj, get_provider_user_obj, get_formated_timeslot_provider } from '../../patient/enquiry/EnquiryUtils';
// import { DescriptionItem, get_formated_timeslot,  get_provider_obj, get_patient_obj, get_patient_user_obj } from '../enquiry/EnquiryUtils';
import { get_patient_user_obj } from '../../patient/enquiry/EnquiryUtils';
import { getParsedPatientSlots } from '../../provider/scheduler/SchedulerUtils';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import moment from "moment-timezone";
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { FaUser, FaFile, FaFileWord, FaFileAlt } from 'react-icons/fa';
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import AppointmentService from '../../../services/patient/Appointment.Service';
import RescheduleSlot from '../../patient/appointment/RescheduleSlot';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import '../../patient/enquiry/enquiry.style.css';
import { AUTHROUTE } from "../../../constants/RouterPath"
import { message } from 'antd';


export class AppointmentProviderReschedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            enquiry_response: {},
            selected_enquiry: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            consultation_obj: {},
            schedules: [],
            available_slots: [],
            enquiry_id: this.props && this.props.match && this.props.match.params && this.props.match.params.id,
            confirmationModal: false,
            OTPconfirmationModal: false,
            confirmLoading: false,
            numInputs: 6,
            isDisabled: false,
            hasErrored: false,
            separator: '-',
            isInputNum: false,
            isInputSecure: false,
            placeholder: '',
            otp_val: '',
            schedule_date: '',
            schedule_id: '',
            schedule_slot_id: '',
            selected_slot: '',
            selected_date: '',
            salesOrderId: '',
            session_id: '',
            salesOrderCode: '',
            fileList: [],
            datesCollection: [],
            selected_view_document: "",
            showImageViewer: false,
            newSelected_date: "",
            newSelected_Time: "",
            loadedSlots: ""
        }
    }
    documentViewer(document_url) {
        this.setState({ selected_view_document: document_url, showImageViewer: true });

    }
    handleSubmit = async () => {
        this.setState({ confirmationModal: true });
    }
    handleClose() {
        this.setState({ showImageViewer: false });
        this.setState({ showEditModal: false });
    }
    componentDidMount() {
        this.getVals()
    }

    getVals = async () => {
        const selected_appointment = this.props && this.props?.location?.state;
        const consultationInfo = this.props && this.props?.location?.state && this.props?.location?.state?.consultationInfo
        const reschedule_visible = this.props && this.props.reschedule_visible
        const fileList = this.props?.location?.state?.consultationInfo?.documents
        const currentUser = authenticationService.currentUserValue;
        const patient_obj = get_patient_obj(selected_appointment)
        const user_obj = get_patient_user_obj(selected_appointment)
        const provider_obj = get_provider_obj(selected_appointment)
        const provider_user_obj = get_provider_user_obj(selected_appointment);
        try {
            this.setState({ loading: true, reschedule_visible: reschedule_visible, selected_appointment: selected_appointment, consultationInfo: consultationInfo });
            const provider_id = provider_obj && provider_obj._id
            if (provider_id) {
                let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
                if (response.data.status === "success") {
                    if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
                        const responseObj = JSON.parse(JSON.stringify(response.data));
                        const _schedules = responseObj.schedules;
                        const all_schedule = getParsedPatientSlots(_schedules);
                        setTimeout(() => {
                            this.setState({
                                user_obj: user_obj,
                                patient_obj: patient_obj,
                                provider_obj: provider_obj,
                                provider_user_obj: provider_user_obj,
                                currentUser: currentUser,
                                available_slots: all_schedule,
                                fileList: fileList || [],
                                loading: false,
                                loadedSlots: true
                            })
                        }, 100);
                    }
                } else {
                    this.setState({ loading: false, available_slots: [], loadedSlots: false })
                }
            } else {
                this.setState({ loading: false, available_slots: [], loadedSlots: false })
                //message.error(response.data.message);
            }
        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false, available_slots: [], loadedSlots: false })
        }

    }
    handleOnSelectSlot = (event) => {
        if (event) {
            // const attTimeAndDate = moment(event.date + ' ' + event.slot, 'YYYY-MM-DD HH:mm A').format()
            // let _date = moment(attTimeAndDate).tz(authenticationService && authenticationService.user_timezone).format('MMM D, YYYY')
            // let time_slot = moment(attTimeAndDate).tz(authenticationService && authenticationService.user_timezone).format('LT z')
            this.setState({
                schedule_slot_id: event._id, schedule_id: event.providerScheduleId,
                schedule_date: event.date, selected_date: event.date, selected_slot: event.slot,
                newSelected_date: event.date,
                newSelected_Time: event.slot, timezone: event.timezone
            })
        }

    }
    successModal = async () => {
        const allFormData = {
            "consultation_id": this.state && this.state.consultationInfo && this.state.consultationInfo.id,
            "schedule_details_id": this.state && this.state.schedule_slot_id,
            "schedule_id": this.state && this.state.schedule_id
        }
        try {
            this.setState({ loading: true });
            let response = await AppointmentService.rescheduleAppointments(allFormData);
            if (response.data.status === "success") {
                message.success(response.data.message);
                this.setState({ loading: false, reschedule_visible: false })
                const { history } = this.props;
                const tempData = this.props?.location?.state;
                tempData.selected_date = moment(this.state.newSelected_date).format("YYYY-MM-DD");
                tempData.selected_slot = this.state.newSelected_Time;
                tempData.previous_date = this.props && this.props.location.state.consultationInfo.scheduleDetailsInfo.date;
                tempData.previous_slot = this.props && this.props.location.state.consultationInfo.scheduleDetailsInfo.slot;
                tempData.selected_date = this.state.selected_date;
                tempData.selected_slot = this.state.selected_slot;
                // const data = this.props?.location?.state?.consultationInfo?.scheduleDetailsInfo;
                history.push({ pathname: "/provider/appointments/reschedulesuccess", state: tempData })
                //this.successModal();
            } else {
                this.setState({ loading: false })
                message.error(response.data.message);
            }
        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }

        // this.setState({ showModal: false })
    }

    render() {
        const currentUser = authenticationService.currentUserValue;
        return (
            <React.Fragment>
                <AfterLoginLayout>
                    <Container fluid className='pickslotlayout'>
                        <Row style={{ "width": "100%" }}>
                            <Col lg={12} md={12} sm={12}> <h5 className='text-left'><Link to={{ pathname: (this.props?.location?.state?.provider_level_view ? AUTHROUTE.PROVIDER.APPOINTMENT_PROVIDER : AUTHROUTE.PATIENT.APPOINTMENT_View), state: { selected_appointment: this.props?.location?.state } }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link>Pick your slot</h5>
                                {currentUser.usertype === 'patient' && <p style={{ paddingBottom: '50px' }}>We have selected the right doctor for you</p>}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={12} className='leftcontainer'>
                                <Card>
                                    <Card.Header><div className='dricon'><FaUser className='fa-2x' /></div><div className='drname'>
                                        <div>{this.state.provider_obj && "Dr. " + this.props?.location?.state?.providerInfo?.userInfo?.firstname}</div>
                                        <div className='speciality'>{this.props?.location?.state?.consultationInfo?.speciality?.name}</div>
                                    </div>
                                        <u></u>
                                    </Card.Header>

                                </Card>
                            </Col>
                            <Col lg={8} md={8} sm={12} className='rightcontainer'>
                                <Row className='py-2' style={{ borderBottom: "1px solid #ccc" }}>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='breadcrumb-topics'>REFERENCE NO</div>
                                        <div className='data mt-2'>{this.props?.location?.state?.consultationInfo?.orderCode}</div>
                                    </Col>
                                    <Col lg={5} md={5} sm={12}>
                                        <div className='breadcrumb-topics'>APPOINTMENT DATE AND TIME</div>
                                        <div className='data mt-2'>
                                            <div >{get_formated_timeslot_provider(moment(this.props?.location?.state?.consultationInfo?.scheduleDetailsInfo
                                                && this.props.location.state.consultationInfo.scheduleDetailsInfo.date + ' ' + this.props.location.state.consultationInfo.scheduleDetailsInfo.slot, 'YYYY-MM-DD HH:mm A').format())}{this.props?.location?.state?.consultationInfo?.timename}</div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <div className='breadcrumb-topics'>Fee</div>
                                        <div className='data mt-2'>
                                            {/* <div>{get_formated_date(this.props?.location?.state?.consultationInfo?.scheduleDetailsInfo?.slot)}</div> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='breadcrumb-topics'>PATIENT</div>

                                        <div className='data mt-2'>{this.props?.location?.state?.patientUserInfo?.firstname}</div>
                                    </Col>
                                    <Col lg={5} md={5} sm={12}>
                                        <div className='breadcrumb-topics'>CONCERN</div>
                                        <div className='data mt-2'>{this.props?.location?.state?.enquiryInfo?.enquiry_message}</div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className='py-2'>
                                    <Col lg={12} md={12} sm={12} pt-4>
                                        <div className='breadcrumb-topics'>DETAILS</div>
                                        <Col className="gutter-row">
                                            <Row className='mobile_row'>

                                                {this.state.fileList.map((x, i) => {
                                                    return <Col lg={4} md={4} sm={6} xs={6} className="p-4" key={i} >
                                                        {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                        {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                        {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                        {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                            <div className='image'>
                                                                <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                            </div>
                                                        }
                                                    </Col>
                                                })}
                                            </Row></Col>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="slotsection mb-3 ">
                                {this.state.loadedSlots === false && this.state.available_slots.length === 0 && <React.Fragment>
                                    <Col>
                                        <p>No Available slots for now</p>
                                    </Col>
                                </React.Fragment>}
                                {this.state.loadedSlots === true && this.state.available_slots.length !== 0 &&
                                    <>
                                        <h6 className='text-left'>Please book a slot and confirm the appointment</h6>
                                        <RescheduleSlot buttonName={"Confirm"} availableSlots={this.state.available_slots} onChangeEvent={this.handleOnSelectSlot}
                                            onHandleSubmit={this.successModal} />

                                    </>}
                                {/*                               
                                {(() => {

                                    if (this.state && this.state.available_slots) {

                                        if (this.state.available_slots && this.state.available_slots.length === 0) {
                                            return <React.Fragment>
                                                <Col>
                                                    <p>No Available slots for now</p>
                                                </Col>
                                            </React.Fragment>
                                        }
                                        else {

                                            return (

                                                <>
                                                  <h6 className='text-left'>Please book a slot and confirm the appointment</h6>
                                                    <RescheduleSlot buttonName={"Confirm"} availableSlots={this.state.available_slots} onChangeEvent={this.handleOnSelectSlot}
                                                        onHandleSubmit={this.successModal} />

                                                </>

                                            )

                                        }
                                    }
                                })()} */}
                            </Col>
                        </Row>
                        {(() => {
                            if (this.state && this.state.available_slots) {
                                if (this.state.available_slots && this.state.available_slots.length === 0) {
                                    return <React.Fragment>

                                    </React.Fragment>
                                }

                            }
                        })()}

                    </Container>

                    <Modal show={this.state.showImageViewer}
                        centered
                        backdrop="static" onHide={this.handleClose.bind(this)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>
                        </Modal.Body>
                    </Modal>
                </AfterLoginLayout>
            </React.Fragment>

        )
    }

}


export default withRouter(AppointmentProviderReschedule);
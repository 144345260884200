import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaUserAlt, FaProductHunt, FaQuestion, FaCity } from 'react-icons/fa';
import '../../../assets/scss/sidebar.scss';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { authenticationService } from '../../../component/general/login/login.service';
import Role from '../../../constants/role'
import logo from '../../../assets/images/logo.png';

const InternalUserSideBar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {
  const currentUser = authenticationService.currentUserValue;

  //const intl = useIntl();
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="sidebar-header">
          <img src={logo} className="img-fluid" alt="Second Opinion" />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaTachometerAlt />} suffix={<span className="badge red">NEW</span>}>
            <Link to={AUTHROUTE.INTERNALUSER.DASHBOARDPATH}>Dashboard</Link>
          </MenuItem>

          {(() => {
            if (currentUser && currentUser.usertype === Role.admin.name) {
              return (<React.Fragment>
                <MenuItem icon={<FaQuestion />}>
                  <Link to={AUTHROUTE.ADMIN.ENQUIRIES_PATH}>Opinion Request
                  </Link>
                </MenuItem>
                <MenuItem icon={<FaProductHunt />}>
                  <Link to={AUTHROUTE.ADMIN.LISTPROVIDERS}> Global Providers </Link>
                </MenuItem>
                <MenuItem icon={<FaCity />}>
                  <Link to={AUTHROUTE.ADMIN.LISTLOCALPROVIDERS}> Local Providers </Link>
                </MenuItem>
                <MenuItem icon={<FaUserAlt />}>
                  <Link to={AUTHROUTE.ADMIN.LISTINTERNALUSERS}>Internal Users </Link>
                </MenuItem>
                <MenuItem icon={<FaUserAlt />}>
                <Link to={AUTHROUTE.ADMIN.PATIENT}>  Patients</Link>
                </MenuItem>

              </React.Fragment>)
            }
          })()}

          {(() => {
            if (currentUser && currentUser.usertype === Role.internaluser.name) {
              return (<React.Fragment>
                <MenuItem icon={<FaQuestion />}>
                  <Link to={AUTHROUTE.INTERNALUSER.ENQUIRIES_PATH}>Opinion Request
                  </Link>
                </MenuItem>
                <MenuItem icon={<FaProductHunt />}>
                  <Link to={AUTHROUTE.INTERNALUSER.LIST_MY_PROVIDERS}>Global Providers </Link>
                </MenuItem>
                <MenuItem icon={<FaCity />}>
                  <Link to={AUTHROUTE.ADMIN.LISTLOCALPROVIDERS}> Local Providers </Link>
                </MenuItem>

              </React.Fragment>)
            }
          })()}
        </Menu>
      </SidebarContent> 
      </ProSidebar>
  );
};

export default InternalUserSideBar;

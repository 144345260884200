 /* Authentication actions */
import { message } from 'antd';
import axios from 'axios';
import btoa from 'btoa';
import {NONAUTHROUTE} from "../../../constants/RouterPath";
import {APIENDPOINT} from "../../../constants/APIEndpoint";

// Trigger Reset Password Service call
export const resetpassword = async(props,details) => {
    var data = {
         "userid": details && details.user_id,    
         "password": btoa(details && details.password),
         "confirmpassword": btoa(details && details.confirmpassword),
         "verificationcode":details && details.verificationcode,
      };
      var config = {
        method: 'POST',
        url: process.env.REACT_APP_API_URL+APIENDPOINT.RESET_PASSWORD,
        headers: { 
          'Content-Type': 'application/json;charset=utf-8'
        },
        data : data
      };
      await axios(config)
        .then(function (response) {
          if (response.data.status === 400){
            message.error('Reset password Failed')
            return
          }if (response.data.status === "success"){
            props.history.push({
                pathname: NONAUTHROUTE.GENERAL.LOGINPATH,
              });
            message.success(response.data.message)
         }else {
           message.error('Failed :'+response.data.message)
          }
        })
        .catch(function (err) {
          if (err.response) {
            console.log("client received an error response (5xx, 4xx)",err);
          } else if (err.request) {
            console.log("client never received a response, or request never left",err);
          } else {
            // anything else
            console.log("Error : ",err);
          }
          message.error(err.message)
        });        
}

export const resetResendOtp = async (usrId) => {
  const data ={
    "user_id":usrId
  }
  var config = {
    method: 'POST',
    url: process.env.REACT_APP_API_URL + APIENDPOINT.RESET_PASSWORD_RESENDOTP,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    data,
  };

  let stausInfo = ""

  await axios(config).then(res => {
    if(res && res.data.status === "success"){
      message.success(res.data.message)
      stausInfo= "success"
    } else stausInfo= "failure"
  }).catch(err => console.log("err",err))

return stausInfo
}
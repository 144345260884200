import React from 'react'
import { message } from 'antd';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { AfterLoginDashboardLayout } from '../../shared/AfterLoginDashboardLayout';
import { Row, Col, Card, Badge, Button, Modal, Spinner } from 'react-bootstrap'
import './Appointment.css';
import Role from '../../../constants/role';
import { get_formated_patient_ist_time, get_provider_obj, get_formated_patient_ist_datetime, get_formated_patient_ist_date, get_speciality_obj, get_patient_obj, get_patient_user_obj, get_day_from_datetime } from '../enquiry/EnquiryUtils';
import { AppointmentModal } from './AppointmentModal';
import { parseAppointments } from './Utils';
import { FaClock, FaRegCalendarAlt, FaFile, FaFileWord, FaFileAlt } from 'react-icons/fa';
import { get_room_details_obj } from '../../patient/appointment/Utils';
import { authenticationService } from '../../general/login/login.service';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { PatientHelpRightBar } from '../sidebar/PatientHelpRightBar';
import { AppointmentEditor } from './AppointmentEditor';
import '../enquiry/enquiry.style.css'
import { BsPersonCircle } from 'react-icons/bs';
import moment from 'moment';
import { new_get_formated_patient_ist_date } from '../enquiry/EnquiryUtils';
import { errorMessage } from '../../../utils/ServiceHandler';
import AppointmentService from '../../../services/patient/Appointment.Service';

// import moment from 'moment';
class AppointmentView extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            consultationInfo: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            // provider_user_obj: {},
            all_specialities: [],
            previewVisible: false,
            currentUser: {},
            currentItem: {},
            speciality_obj: {},
            showModal: false,
            modalData: '',
            fileList: [],
            selected_view_document: "",
            showEditModal: false,
            dayChecker: '',
            upcoming: [],
            past: [],
            patient_id: this.props.location.state.selected_appointment.patientInfo._id,
            upcoming_updated_appointment: {},
            past_updated_appointment: {}
        }
    }
    async findStatus() {
        if (this.props?.location?.state?.selected_appointment) {
            const _consultation = await parseAppointments([this.props?.location?.state?.selected_appointment]);
            this.setState({
                dayChecker: (_consultation && _consultation.upcoming.length > 0) ? 'upcoming' : 'closed'
            });
        }
        if (this.props.location) {
            if (typeof this.props.location.query === "undefined") {
                // this.props.history.push(AUTHROUTE.PATIENT.APPOINTMENT_PATH);
            }
        }

    }
    showEditModal = () => {
        this.setState({
            showEditModal: true,
        });
        setTimeout(() => {
            this.formRef && this.formRef.current && this.formRef.current.resetFields();
        }, 100);
    };
    setEdit = () => {
        this.setState({ showEditModal: true })
    };

    onShowModel = (value) => {
        this.setState({ modalData: value })
        this.setState({ showModal: true })
        this.setState({ showEditModal: false });
    }
    onHideModal = () => {
        this.setState({ showModal: false })
        this.setState({ showEditModal: false });
    }
    onYesModal = () => {
        const { history } = this.props;
        const tempData = this.props?.location?.state?.selected_appointment;
        if (this.state.modalData === 'cancel') {
            history.push({ pathname: "/patient/appointments/cancel", state: tempData })
        }
        if (this.state.modalData === 'reschedule') {
            history.push({ pathname: "/patient/appointments/reschedule", state: tempData })
        }
        this.setState({ showModal: false })
    }

    getVals() {
        const currentItem = this.props?.location?.state?.selected_appointment
        const consultationInfo = this.props?.location?.state?.selected_appointment?.consultationInfo
        const fileList = this.props?.location?.state?.selected_appointment?.consultationInfo?.documents
        const currentUser = authenticationService.currentUserValue;
        //const all_specialities = this.props && this.props.all_specialities
        const patient_obj = get_patient_obj(currentItem)
        const user_obj = get_patient_user_obj(currentItem)
        const provider_obj = get_provider_obj(currentItem)
        // const provider_user_obj = get_provider_user_obj(currentItem)
        const speciality_obj = get_speciality_obj(consultationInfo)

        this.setState({
            currentItem: currentItem,
            consultationInfo: consultationInfo,
            user_obj: user_obj,
            patient_obj: patient_obj,
            provider_obj: provider_obj,
            // provider_user_obj: provider_user_obj,
            currentUser: currentUser,
            fileList: fileList || [],
            speciality_obj: speciality_obj
            //all_specialities: all_specialities
        })
    }

    componentDidMount() {
        this.getVals();
        this.findStatus();
        this.getAppointments();
    }
    documentViewer(document_url) {
        this.setState({ selected_view_document: document_url, showImageViewer: true });
    }
    handleClose() {
        this.setState({ showImageViewer: false });
        this.setState({ showEditModal: false });

    }

    navigatetoVideoCall = () => {
        const room_details_obj = get_room_details_obj(this.state && this.props?.location?.state?.selected_appointment?.consultationInfo)
        if (room_details_obj) {
            this.props && this.props.history && this.props.history.push({
                pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
                state: {
                    currentItem: this.state.currentItem,
                    consultationInfo: this.state.consultationInfo,
                    user_obj: this.state.user_obj,
                    patient_obj: this.state.patient_obj,
                    provider_obj: this.state.provider_obj,
                    // provider_user_obj: this.state.provider_user_obj,
                    currentUser: this.state.currentUser,
                }
            });
        } else {
            message.error("Issue while initiating the Video call. Room details not available")
        }
    };

    getAppointments = async () => {
        try {
            this.setState({ loading: true });
            //const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })

            let response = await AppointmentService.getPatientAppointments(this.state && this.state.patient_id);
            this.handleResponse(response)

        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }

    handleResponse = async (response) => {
        if (response.data.status === "success") {
            if (response && response.data && response.data.data) {
                if (response.data.data.length > 0) {
                    const _consultation = parseAppointments(response.data.data)

                    const orderDates = () => {
                        const new_objs = []
                        const array_data = []
                        const filter_dates = _consultation && _consultation.upcoming.filter((item, index) => {
                            let newItem
                            if (!new_objs.includes(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())) {
                                new_objs.push(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())
                                let obj = {}
                                obj.norm_date = moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()
                                obj.conv_date = new_get_formated_patient_ist_date(item.consultationInfo.scheduleDetailsInfo.date, item.consultationInfo.scheduleDetailsInfo.slot, item.consultationInfo.timename)
                                array_data.push(obj)
                                newItem = item
                            }
                            return newItem
                        })
                        new_objs.sort((a, b) => moment(a) - moment(b))
                        array_data.sort((a, b) => moment(a.conv_date) - moment(b.conv_date))
                        const new_sltz = []
                        array_data.forEach((item) => {
                            filter_dates.forEach(objitm => {
                                if (item.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()) {
                                    const indx = array_data.findIndex(searchItm => searchItm.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())

                                    new_sltz.splice(indx, 0, { ...objitm, shown_status: 'upcoming' })

                                    const result = new_sltz.filter(data => data.consultationInfo.id === this.props.location.state.selected_appointment.consultationInfo.id);
                                    this.setState({ upcoming_updated_appointment: result && result[0], enquiriesEdit: this.props.location.state.enquiriesEdit, loading: false });
                                }
                            })
                        })
                        return new_sltz
                    }

                    const order_past_records = () => {
                        const new_objs = []
                        const array_data = []
                        const filter_dates = _consultation && _consultation.past.filter((item, index) => {
                            let newItem
                            if (!new_objs.includes(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())) {
                                new_objs.push(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())
                                let obj = {}
                                obj.norm_date = moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()
                                obj.conv_date = new_get_formated_patient_ist_date(item.consultationInfo.scheduleDetailsInfo.date, item.consultationInfo.scheduleDetailsInfo.slot, item.consultationInfo.timename)
                                array_data.push(obj)
                                newItem = item
                            }
                            return newItem
                        })
                        new_objs.sort((a, b) => moment(b) - moment(a))
                        array_data.sort((a, b) => moment(b.conv_date) - moment(a.conv_date))
                        const new_sltz = []
                        array_data.forEach((item) => {
                            filter_dates.forEach(objitm => {
                                if (item.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()) {
                                    const indx = array_data.findIndex(searchItm => searchItm.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())
                                    new_sltz.splice(indx, 0, { ...objitm, shown_status: 'closed' })

                                    const result = new_sltz.filter(data => data.consultationInfo.id === this.props.location.state.selected_appointment.consultationInfo.id);
                                    this.setState({ past_updated_appointment: result && result[0], enquiriesEdit: this.props.location.state.enquiriesEdit, loading: false });
                                }
                            })
                        })
                        return new_sltz
                    }

                    this.setState({

                        upcoming: _consultation && orderDates(),
                        past: _consultation && order_past_records(),
                        loading: false
                    });
                }
            }
        } else {
            this.setState({ loading: false })
            const currentUser = authenticationService.currentUserValue;
            if (currentUser.usertype !== Role.localprovider.name)
                message.error(response.data.message);
        }
    }


    render() {
        const currentUser = authenticationService.currentUserValue;
        const document = this.state.past_updated_appointment?.consultationInfo?.documents || this.state.upcoming_updated_appointment?.consultationInfo?.documents;
        return (
            <AfterLoginDashboardLayout>
                {!document ?
                    <div style={{ marginTop: "12rem", display: "flex", justifyContent: "center" }}>
                        <Spinner animation="border" variant="primary" />
                    </div> :
                    <div>
                        <Row>
                            <Col className='mx-3'>
                                <div>
                                    <h4><Link to={{ pathname: currentUser.usertype === Role.patient.name ? AUTHROUTE.PATIENT.APPOINTMENT_PATH : AUTHROUTE.LOCALPROVIDER.APPOINTMENT_PATH }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link>View appointments</h4>
                                </div>
                            </Col>
                        </Row>

                        <Row className='main-layout md-no-reverse pt-3'>
                            <Col lg={9} md={9} sm={12} pt-3 >
                                {/* <h5 className='text-left ml-4'><Link to<FaArrowLeft /> View appointments</h5> */}
                                <div className='ml-3 mr-3'>
                                    <Card className='lefttopsection'>
                                        <Row className="p-3">
                                            <Col lg={10} md={10} sm={6}>
                                                <h6 className='py-2 pl-3'>You have an upcoming appointment</h6>
                                            </Col>
                                            <Col lg={2} md={2} sm={6}>
                                                <div className="joinBtn">
                                                    {this.state.dayChecker === 'upcoming' &&
                                                        <Button variant='link' style={{
                                                            "border": "1px solid #ffffff", "color": "#ffffff"
                                                        }} className='px-4' onClick={this.navigatetoVideoCall}>Join  </Button>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="p-3 pb-4">
                                            <Col lg={4} md={4} sm={12} >
                                                <div className='profileicon'>
                                                    <i><BsPersonCircle /></i>
                                                </div>
                                                <div className='drdetails px-2'>
                                                    <div>{this.props?.location?.state?.selected_appointment?.providerUserInfo?.firstname}</div>
                                                    <div>{this.props?.location?.state?.selected_appointment?.consultationInfo?.speciality?.name}</div>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <div className='profileicon'>
                                                    <i><FaClock className='facircle' /></i>
                                                </div>
                                                <div className='drdetails px-2'>
                                                    {/* <div>{get_formated_time_only(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo?.slot).split(" ")['0']}{get_formated_time_only(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo?.slot).split(" ")['1']}</div> */}
                                                    <div >{get_formated_patient_ist_time(this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date, this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot, this.props.location.state.selected_appointment.consultationInfo.timename)}</div>
                                                </div>
                                                {/* <div className='drdetails px-2'>
                                            <div>{get_formated_date_only(moment(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo
                                                && this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date+ ' ' +this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot))}</div>
                                        </div> */}
                                                {/* <div className='drdetails px-2'>
                                            <div>{get_formated_time_only(this.props?.location?.state?.selected_appointment?.consultationInfo?.scheduleDetailsInfo?.slot)}</div>
                                        </div> */}
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <div className='profileicon'>
                                                    <i><FaRegCalendarAlt /> </i>
                                                </div>
                                                <div className='drdetails px-2'>
                                                    <div className='drdetails px-2'>
                                                        <div>{get_day_from_datetime(get_formated_patient_ist_datetime(this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date, this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot, this.props.location.state.selected_appointment.consultationInfo.timename))}
                                                        </div>
                                                        <div>{get_formated_patient_ist_date(this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.date, this.props.location.state.selected_appointment.consultationInfo.scheduleDetailsInfo.slot, this.props.location.state.selected_appointment.consultationInfo.timename)}
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                                <div className='ml-3 mr-3'>
                                    <Card className='leftbottomsection'>
                                        <Row className='p-4'>
                                            <Col lg={4} md={4} sm={12}>
                                                <div className='breadcrumb-topics'>BOOKING NUMBER</div>
                                                <div className='data mt-2'>{this.props?.location?.state?.selected_appointment?.consultationInfo?.orderCode}</div>
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <div className='breadcrumb-topics'>PAYMENT STATUS</div>
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <div className='breadcrumb-topics'>APPOINTMENT STATUS</div>
                                                {this.state.dayChecker === 'upcoming' ? <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Upcoming </Badge> : <Badge style={{ backgroundColor: '#C7C7C7', color: '#858585', fontSize: '14px' }}>Closed </Badge>}
                                            </Col>

                                        </Row>
                                        <Row><Col><hr /></Col></Row>
                                        <Row className='p-4' style={{ height: 'auto' }}>
                                            <Col lg={4} md={4} sm={12} pt-3>
                                                <div className='breadcrumb-topics'>PATIENT</div>

                                                <div className='data mt-2'>{this.props?.location?.state?.selected_appointment?.patientUserInfo?.firstname}</div>
                                            </Col>
                                            <Col lg={4} md={4} sm={12} pt-3>
                                                <div className='breadcrumb-topics'>CONCERN</div>
                                                <div className='data mt-2'>{this.props?.location?.state?.selected_appointment?.enquiryInfo?.enquiry_message}</div>
                                            </Col>
                                        </Row>
                                        <Row><Col><hr /></Col></Row>
                                        <Row className='p-4'>
                                            <Col lg={10} md={10} sm={12} pt-4>
                                                <div className='breadcrumb-topics'>DETAILS </div>
                                                <Col className="gutter-row">
                                                    <Row className='mobile_row'>


                                                        {this.props.location.state.selected_appointment.shown_status === "upcoming" ? this.state.upcoming_updated_appointment?.consultationInfo?.documents.map((x, i) => {
                                                            return <Col lg={4} md={4} sm={6} xs={6} className="p-4" key={i} >
                                                                {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                                {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                                {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                                {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                    <div className='image'>
                                                                        <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        }) :
                                                            this.state.past_updated_appointment?.consultationInfo?.documents.map((x, i) => {
                                                                return <Col lg={4} md={4} sm={6} xs={6} className="p-4" key={i} >
                                                                    {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                                    {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                                    {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                                    {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                        <div className='image'>
                                                                            <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            })}
                                                    </Row></Col>
                                            </Col>
                                            <Col lg={2} md={2} sm={12} pt-4>
                                                <h6><Button variant='link' onClick={e => this.setState({ showEditModal: true })}>  Edit</Button></h6>
                                                {/* Link to={{ pathname: '/patient/appointment/' + this.props?.location?.state?.selected_appointment?.consultationInfo?.id }} */}
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                                <div className='ml-3 mr-3'>
                                    {this.state.dayChecker === 'upcoming' &&
                                        <Button variant='link' onClick={() => this.onShowModel('reschedule')}>Reschedule appointment</Button>
                                    }
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={12} className='px-0 appointment-help'>
                                <Row className=' px-2'>
                                    <PatientHelpRightBar showText={true} /></Row>
                                <Card className='instruction px-2'>
                                    <p className='instruction-a ml-2 mt-3'>Instruction to follow</p>
                                    <ul className='mybullet1'>
                                        <li>You will be notified 5 mins before the appointment.</li>
                                        <li>Sit in a room with stable internet connectivity and no distraction.</li>
                                        <li>Wait until the doctor starts your consultation.</li>
                                    </ul>
                                </Card>
                                <Card className='faq px-2'>
                                    <p className='instruction-a ml-2 mt-3'>FAQ</p>
                                    <ul className='mybullet'>
                                        <li><h6>Will I get a refund if I cancel appointment?</h6>
                                            <span>No. Refund will be given in 24 hours
                                            </span>
                                        </li>
                                        <li>
                                            <h6>Can I reschedule my appointment?</h6>
                                            <span>No. Refund will be given in 24 hours
                                            </span>
                                        </li>
                                    </ul>
                                </Card>
                            </Col>
                        </Row>

                        <AppointmentModal
                            modalData={this.state.modalData}
                            showModal={this.state.showModal}
                            onHide={this.onHideModal}
                            onYesClick={this.onYesModal}
                            onNoClick={this.onHideModal}
                        />

                        <Modal show={this.state.showImageViewer}
                            centered
                            backdrop="static" onHide={this.handleClose.bind(this)}>
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>

                                <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>


                            </Modal.Body>

                        </Modal>

                        <Modal
                            size="lg"
                            show={this.state.showEditModal}
                            onHide={this.handleClose.bind(this)}
                            // onHide={() => setShow(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    Edit
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.props?.location?.state?.selected_appointment?.consultationInfo?.id && <AppointmentEditor id={this.props?.location?.state?.selected_appointment?.consultationInfo?.id} />}
                            </Modal.Body>
                        </Modal>
                    </div>}
            </AfterLoginDashboardLayout >
        )
    }
}

export default AppointmentView

import React from 'react';
import { BsBellFill } from "react-icons/bs";
import { Card } from 'react-bootstrap';
//import "../../provider/profile/providerProfile.style.css"
import NextAppointments from '../notifications/NextAppointments';
import ConfirmAppointments from '../notifications/ConfirmAppointments';
import { authenticationService } from '../../../component/general/login/login.service';

export class PatientRightBar extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };

  render() {
    const currentUser = authenticationService.currentUserValue;
    return (
      <React.Fragment>
        {this.props.notification ?
          <div>
            <div style={{ "minHeight": "100px" }}>
            </div>
            <div className="next-appo">
              <h5 className='m-2'>Notifications</h5>
              <NextAppointments
                patient_id={currentUser && currentUser.usertype_id}
                {...this.props} />
            </div>
            <div className="confirm-appo">
              <ConfirmAppointments
                showMultiSectionOption={false}
                showCreate={true}
                patient_id={currentUser && currentUser.usertype_id}
                {...this.props}
              />
            </div>
          </div> :

          <div>
            <div style={{ "minHeight": "100px" }}>
            </div>
            <div className="next-appo">
              <Card>
                <BsBellFill size={50} className='text-info ' style={{ marginLeft: '70px' }} />
                <div className='mt-3' style={{ marginLeft: '56px' }}>No notification</div>
              </Card>
            </div>
          </div>}
      </React.Fragment >)
  }
}

export default PatientRightBar
import React, { Component } from 'react';
import logo from "../../../../assets/images/logo.png";
import { Form, Button, Spinner, Row, Col, Card, Container } from 'react-bootstrap';
import { formikFormalidation } from '../../../../component/general/formvalidator/validationFroms';
import { login } from './PatientLogin.service';
import { Link } from 'react-router-dom';
import { NONAUTHROUTE } from "../../../../constants/RouterPath";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import './GetOpinion.css'

class PatientLogin extends Component {
    constructor(props) {
        super(props)
        this.state = { loading: false, value: 1, username: "", password: "", validated: false, showPassword: false, }
    }

    handleShowPassword = (passwordProps) => {
        if (passwordProps === 'password') {
            this.setState({ showPassword: !this.state.showPassword })
        }
    }

 

    render() {
         localStorage.setItem("newUserVal",this.props.location.state.newUserVal)
        const schema = yup.object().shape({
            username: formikFormalidation.username,
            password: formikFormalidation.password
        });
      
        return (
            <Formik
                validationSchema={schema}
                onSubmit={async (values) => {
                    this.setState({ loading: true })
                    await login(this.props, values,this.props.location.state.newUserVal );
                    this.setState({ loading: false });
                }
                }
                initialValues={{
                    username: '',
                    password: ''
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (

                    <Row className='existingUser'>
                        <Col md={8} style={{ paddingTop: '120px', paddingBottom: '210px', backgroundColor: '#E5E5E5' }}>
                            <Container>
                                <img src={logo} className="img-fluid pb-5" alt="Logo" style={{ mixBlendMode: 'darken', marginLeft: '30px' }} />
                                <h6 style={{ fontSize: '13px', color: '#143C8C', marginLeft: '40px' }}>WELCOME BACK!</h6>
                                <h5 className='mt-3' style={{ fontSize: '18px', marginLeft: '40px' }}>Your concern has been received.</h5>
                                <p className='mt-3' style={{ marginLeft: '40px' }}> Seems like you have already registered. Please sign in and provide more details.</p>
                                <Card className="p-2 mt-5 userCard"
                                style={{ height: "auto" }}>
                                    <Row className="py-2 px-2 card2">
                                        <Col>
                                            <h6 className="text-secondary" style={{ fontSize: '13px' }} >CONCERN</h6>
                                            <p className='font-weight-bold'>{this.props.location.state.response.userData.enquiry_message}</p>
                                        </Col>
                                        <Col>
                                            <h6 className="text-secondary" style={{ fontSize: '13px' }}>SUBMITTED DATE</h6>
                                            <p className='font-weight-bold'>{moment(this.props.location.state.response.updatedDate).format('MMMM Do YYYY')}</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Container>
                        </Col>

                        <Col className='px-5' style={{ paddingTop: '80px', backgroundColor: 'white' }}>
                            <h2 style={{ marginTop: '90px' }}>Sign In</h2>
                            <p style={{ fontSize: '14px' }}>Welcome back to Second Opinion !</p>
                            {this.state.loading &&
                                <div className='d-flex justify-content-center flex-nowrap'>
                                    <div className='position-absolute mx-3 p-4' variant="primary">
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                </div>
                            }
                            <Form noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                <fieldset disabled={this.state.loading}>
                                    <Form.Group className="mb-3" controlId="formBasicUsername">
                                        <Form.Control className='field' type="text" placeholder="Username" name="username" required value={values.username} onChange={handleChange} isInvalid={!!errors.username} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.username}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-2" controlId="formBasicPassword">
                                        <Form.Control className='field' type={this.state.showPassword ? "text" : "password"} placeholder="Password" name="password" required value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                                        {!this.state.showPassword && <FaEyeSlash onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                        {this.state.showPassword && <FaEye onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox" label="Check me out" />
											</Form.Group> */}
                                    <div className="mb-2 text-right">
                                        <Link to={NONAUTHROUTE.GENERAL.FORGOTPASSWORDPATH} className="login-link">Forgot password? </Link>
                                        <br />
                                    </div>
                                    <Button className="ml-0 btn-block" variant="primary" type="submit" >
                                        Sign in
                                    </Button>
                                </fieldset>
                            </Form>

                        </Col>
                    </Row>

                )
                }
            </Formik>
        );
    }
}

export default PatientLogin;
import React from 'react';
import { connect } from 'react-redux'
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { getLocalProviderListDetails } from '../../../actions/admin/LocalProviderListAction';
import { message } from 'antd';
// import { formValidation } from '../../general/formvalidator/form.validation';
import { errorMessage } from '../../../utils/ServiceHandler';
import CreateLocalProvider from './CreateLocalProvider';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner, Badge, Modal, Button, ModalTitle } from 'react-bootstrap';
import '../../patient/appointment/Appointment.css'
import Role from '../../../constants/role';
import { authenticationService } from '../../general/login/login.service';
//import { get_user_obj } from '../../patient/enquiry/EnquiryUtils';
import SetAssignee from '../providers/SetAssignee';
import { HiOutlineUsers, HiOutlineCalendar } from 'react-icons/hi';
import { BsPersonPlus } from 'react-icons/bs';

import '../../patient/appointment/Appointment.css';
import { formateName } from '../../patient/enquiry/EnquiryUtils';
import { RiCalendar2Line } from "react-icons/ri";


export class ProviderListComponent extends React.Component {
  formRef = React.createRef();
  assigneeFormRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      allSelectedRows: [],
      providers: [],
      internalusers: [],
      stages: [],
      selected_provider: {},
      provider_status: false,
      modalisVisible: false,
      loading: false,
      showMultiSectionOption: false,
      currentUser: {},
      showCreate: false,
      showCreateProviderModal: false,
      hasSelected: false,
      tableFilterOption: 'new',
      newCount: 0,
      incompleteCount: 0
    };
    this.tableFilterOptionFn = this.tableFilterOptionFn.bind(this);
    this.countFinder = this.countFinder.bind(this);


    this.onChangeSwitch = this.onChangeSwitch.bind(this);
  }

  showCreateProviderModal = () => {
    this.setState({
      showCreateProviderModal: true,
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };

  setAssignee = (current_record,bulkassign) => {
    if(!bulkassign){
      this.setState({ selectedRows: [current_record]}); 
     }
    this.setState({ assignee_modal: true })
  };

  getProviderData = () => {
    this.handleCancel()
    this.props.getLocalProviderListDetails().then((response) => {
      let providers = response.users || [];
      let stages = response.stages || [];
      let internalusers = response.internalusers || [];
      this.setState({ providers, stages, internalusers })
    }).then(x => {
      this.tableFilterOptionFn('new')
    }).then(x => {
      this.countFinder()
    }).catch(err => {
      errorMessage(err)
    })
  }


  componentDidMount() {
    this.getProviderData()
    const currentUser = authenticationService.currentUserValue;
    this.setState({
      showMultiSectionOption: this.props && this.props.showMultiSectionOption,
      showCreate: (this.props && this.props.showCreate) || false,
      currentUser: currentUser,
    });
  }
  handleCancel = () => {
    this.setState({ modalisVisible: false });
    this.setState({ assignee_modal: false,  selectedRows: [] });
    this.setState({ showCreateProviderModal: false });
  };
  handleModalOk = () => {
    this.setState({ modalisVisible: false });
    this.setState({ assignee_modal: false, selectedRows: [] });
  };


  countFinder = async () => {
    const newCount = await (this.state.providers.filter((x) => x && (x.assignee_id === '' || !x.assignee_id))).length;
    const incompleteCount = await (this.state.providers.filter(x => x)).length;
    this.setState({
      newCount: newCount, incompleteCount: incompleteCount
    });
  }

  tableFilterOptionFn = async (option) => {
    await this.setState({ allSelectedRows: [] })
    await this.setState({ tableFilterOption: option });
    if (this.state.tableFilterOption === 'new') {
      let allProviders = this.state.providers;
      let allSelectedRows = await allProviders.filter(x => (x && (x.assignee_id === '' || !x.assignee_id)));
      this.setState({ allSelectedRows: allSelectedRows });
    }
    if (this.state.tableFilterOption === 'incomplete') {
      let allSelectedRows = this.state.providers;
      this.setState({ allSelectedRows: allSelectedRows });
    }
  }


  // Set Stages
  set_stage = (stage_id) => {
    let stage;
    const filteredResults = this.state.stages.filter(obj => obj._id === stage_id)
    if (filteredResults && filteredResults.length > 0) {
      stage = filteredResults[0] && filteredResults[0]._id
    } else { stage = null }
    return stage
  }
  // Set Provider Status (Active/Inactive)
  set_provider_status = (record) => {
    let status = false;
    if (record) {
      status = record.status && record.status === "Active" ? true : false;
    }
    return status
  }


  onChangeSwitch(checked) {
    this.setState({ provider_status: checked });
  }

  edit_record = (record) => {
    setTimeout(() => {
      if (record && record.userInfo && record.userInfo.length > 0) {
        let stage, assignee;
        this.setState({ selected_provider: record });

        let provider_status = this.set_provider_status(record)
        this.setState({ provider_status: provider_status });

        const { experience, assignee_id, stage_id } = record || {};
        stage = this.set_stage(stage_id);
        assignee = this.set_assignee(assignee_id);


        //this.getnpi(npi);

        this.formRef.current.setFieldsValue({
          firstname: record.userInfo[0].firstname || "",
          lastname: record.userInfo[0].lastname || "",
          assignee_id: assignee,
          licenseid: record.licenseid,
          stage_id: stage,
          experience: experience,
          provider_status: provider_status || false
        });

      } else {
        message.error("Not a valid Provider")
        this.setState({ modalisVisible: false });
      }
    }, 1);
    this.setState({ modalisVisible: true });
  }
  get_stage = (data) => {
    let stage_name = "";
    if (data && data.stage_id) {
      const filteredResults = this.state.stages.filter(obj => obj._id === data.stage_id)
      if (filteredResults && filteredResults.length > 0) {
        stage_name = filteredResults[0] && filteredResults[0].stage
      } else { stage_name = "-" }
    } else { stage_name = "-" }
    return stage_name
  }
  get_assignee = (data) => {
    let displayname = "";
    if (data && data.assignee_id) {
      const filteredResults = this.state.internalusers && this.state.internalusers.filter(obj => obj._id === data.assignee_id)
      if (filteredResults && filteredResults.length > 0) {
        displayname = filteredResults[0] && filteredResults[0].displayname
      } else { displayname = "-" }
    } else { displayname = "-" }
    return displayname
  }

  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );


    const options = {
      sizePerPage: 5,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };
    const selectRow = {
      mode: 'checkbox',
      //clickToSelect: true,
      onSelectAll: async (isSelect, rows, e) => {
        if (isSelect) {
          this.setState({ selectedRows: await [...rows, ...this.state.selectedRows] });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        } else {
          const { selectedRows } = this.state;
          rows.forEach(row => {
            selectedRows.splice(
              selectedRows.indexOf(row), 1
            );
          });
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        }

        this.state.hasSelected = this.state.selectedRows.length > 0;

      },
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          const { selectedRows } = this.state;
          selectedRows.push(row);
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        } else {
          const { selectedRows } = this.state;
          selectedRows.splice(
            selectedRows.indexOf(row), 1
          );
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });
        }
        this.setState({ hasSelected: this.state.selectedRows.length > 0 });
      }
    };
    // function onChange(pagination, filters, sorter, extra) {
    // }

    const columns = [
      {
        text: 'First Name',
        dataField: 'firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              let name = "-"
              if (record && record.userInfo && record.userInfo.length > 0) {
                let userData = record.userInfo[0]
                if (userData) { name = formateName(userData.firstname) }
              }
              return (
                <span>{name}</span>
              )
            })()}
          </>
        ),
      },
      {
        text: 'Last Name',
        dataField: 'lastname',
        key: 'lastname',
        formatter: (text, record) => (
          <>
            {(() => {
              let name = "-"
              if (record && record.userInfo && record.userInfo.length > 0) {
                let userData = record.userInfo[0]
                if (userData) { name = formateName(userData.lastname) }
              }
              return (
                <span>{name}</span>
              )
            })()}
          </>
        ),
      },
      {
        text: 'License No',
        dataField: 'userInfo.licenseid',
        key: 'licenseid',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record) {
                return (
                  <span>{record.licenseid || "-"}</span>
                )
              }
            })()}
          </>
        ),
      },
      // {
      //   text: 'Stage',
      //   dataField: 'userInfo.stage_id',
      //   key: 'stage_id',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record) {
      //           let data = record;
      //           let stage_name = this.get_stage(data)
      //           return (
      //             <span>{stage_name}</span>
      //           )
      //         }
      //       })()}
      //     </>
      //   ),
      // },
      // {
      //   text: 'Assignee',
      //   dataField: 'userInfo.assignee_id',
      //   key: 'assignee_id',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record) {
      //           let data = record;
      //           const assignee_name = this.get_assignee(data)
      //           return (
      //             <span>{assignee_name}</span>
      //           )
      //         }
      //       })()}
      //     </>
      //   ),
      // },
      // {
      //   text: '',
      //   dataField: 'enable',
      //   formatter: enabled => (
      //     <>
      //       verify
      //     </>
      //   ),
      // },

      {
        text: 'Active',
        dataField: 'userInfo.status',
        key: 'status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record) {
                let data = record;
                if (data.status === 'Active')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Active </Badge>
                  </React.Fragment>
                  )
                if (data.status === 'Inactive')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#FBC5C5', color: '#EB4747', fontSize: '14px' }}>Inactive</Badge>
                  </React.Fragment>
                  )
              }
            })()}

          </>
        )
      },

      {
        text: '',
        dataField: 'enabled',
        formatter: (_, current_record) => (
          <>
            <Button variant='link' onClick={() => this.setAssignee(current_record, false)} disabled={(this.state?.selectedRows?.length > 1)} ><span style={{ padding: "3px" }}><HiOutlineUsers /></span>Assign to team member</Button>          </>
        ),
      },

    ];

    const columns1 = [
      {
        text: 'First Name',
        dataField: 'firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              let name = "-"
              if (record && record.userInfo && record.userInfo.length > 0) {
                let userData = record.userInfo[0]
                if (userData) { name = formateName(userData.firstname) }
              }
              return (
                <span>{name}</span>
              )
            })()}
          </>
        ),
      },
      {
        text: 'Last Name',
        dataField: 'lastname',
        key: 'lastname',
        formatter: (text, record) => (
          <>
            {(() => {
              let name = "-"
              if (record && record.userInfo && record.userInfo.length > 0) {
                let userData = record.userInfo[0]
                if (userData) { name = formateName(userData.lastname) }
              }
              return (
                <span>{name}</span>
              )
            })()}
          </>
        ),

      },
      {
        text: 'License No',
        dataField: 'userInfo.licenseid',
        key: 'licenseid',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record) {
                return (
                  <span>{record.licenseid || "-"}</span>
                )
              }
            })()}
          </>
        ),
      },
      // {
      //   text: 'Stage',
      //   dataField: 'userInfo.stage_id',
      //   key: 'stage_id',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record) {
      //           let data = record;
      //           let stage_name = this.get_stage(data)
      //           return (
      //             <span>{stage_name}</span>
      //           )
      //         }
      //       })()}
      //     </>
      //   ),
      // },
      // {
      //   text: 'Assignee',
      //   dataField: 'userInfo.assignee_id',
      //   key: 'assignee_id',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record) {
      //           let data = record;
      //           let assignee_name = this.get_assignee(data)
      //           return (
      //             <span>{assignee_name}</span>
      //           )
      //         }
      //       })()}
      //     </>
      //   ),
      // },
      {
        text: 'Active',
        dataField: 'userInfo.status',
        key: 'status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record) {
                let data = record;
                if (data.status === 'Active')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Active </Badge>
                  </React.Fragment>
                  )
                if (data.status === 'Inactive')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#FBC5C5', color: '#EB4747', fontSize: '14px' }}>Inactive</Badge>
                  </React.Fragment>
                  )
              }
            })()}
          </>
        )
      }
    ];
    // const expandRow = {
    //   renderer: (row, rowIndex) => (
    //     <div>
    //       <p>{`This Expand row is belong to rowKey ${row.id}`}</p>
    //       <p>You can render anything here, also you can add additional data on every row object</p>
    //       <p>expandRow.renderer callback will pass the origin row object to you</p>
    //     </div>
    //   )
    // };
    return (<React.Fragment>
      <AfterLoginLayout>
        <div className="container-fluid">

          {!this.props.providersStore.loading ?
            <>

              {this.state.currentUser.usertype === Role.admin.name &&
                <React.Fragment>
                  <div className="banner-header">
                    <h2>Local Providers</h2>
                  </div>
                  <div>
                    <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'font-size': '14px' }} className='rounded-pill ml-0 my-3 font-weight-bold' onClick={() => this.tableFilterOptionFn('new')}>
                      <span style={{ padding: "3px" }}><BsPersonPlus /></span> New registration requests <Badge className='bg-light text-dark ml-2' style={{ fontSize: '12px' }}>{this.state.newCount}</Badge>
                    </Button>
                    <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'font-size': '14px' }} className='rounded-pill ml-0 my-3 font-weight-bold' onClick={() => this.tableFilterOptionFn('incomplete')}>
                      <span style={{ padding: "3px" }}><HiOutlineCalendar /> </span>Incomplete profiles <Badge className='bg-light text-dark ml-2' style={{ fontSize: '12px' }}>{this.state.incompleteCount}</Badge>
                    </Button>
                  </div>
                  <Modal show={this.state.assignee_modal}
                    onHide={this.handleCancel} centered
                    backdrop="static">
                    <Modal.Header closeButton>
                      <ModalTitle>
                        Set Assignee
                      </ModalTitle>
                    </Modal.Header>
                    <Modal.Body className='p-5'>
                      <center>
                        {<SetAssignee
                          selectedRows={this.state.selectedRows}
                          internalUsers={this.state.internalusers}
                          onAssigned={this.getProviderData}
                          localprovider={true}
                          scope="LOCALPROVIDER"
                        // {...this.props}
                        />}
                      </center>
                    </Modal.Body>
                  </Modal>
                </React.Fragment>
              }



              {/* <Modal
            bodyStyle={{ height: 400 }}
            keyboard={true}
            maskClosable={false}
            destroyOnClose={true}
            centered={true}
            visible={this.state.modalisVisible}
            title="Local Provider Verification"
            okText="Create"
            cancelText="Cancel" footer={false}
            onCancel={this.handleCancel}
            onOk={this.handleModalOk}
          >
            <div className="card-container">
              <Spin spinning={this.state.loading}>
                <Form
                  className="profile"
                  {...this.layout}
                  name="profile"
                  ref={this.formRef}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                >
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    className='firstname'
                    rules={formValidation.firstname}>
                    <Input placeholder="" className='inputHeight' autoComplete='off' />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="lastname"
                    type="text"
                    className='lastname'
                    rules={formValidation.lastname}
                  >
                    <Input placeholder="" className='inputHeight' autoComplete='off' />
                  </Form.Item>
                  {<Form.Item
                    label="License No"
                    name="licenseid"
                    className='lastname'
                    rules={formValidation.licenseid}
                  >
                    <Input placeholder="" className='inputHeight' />
                  </Form.Item>}

                  <Form.Item
                    label="Experience(No. of years)"
                    name="experience"
                    className='lastname'
                    tooltip="Overall Experience in Years. Ex: 18"
                    rules={formValidation.experience}
                  >
                    <Input placeholder="" className='inputHeight' />
                  </Form.Item>

                  <Form.Item
                    name="stage_id"
                    label="Stage"
                    className='lastname'>
                    <Select className='selectors'>
                      {
                        this.state.stages?.map((item) => {
                          const { stage, _id } = item;
                          return <Select.Option value={_id} key={_id}>{stage}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="assignee_id"
                    label="Assignee"
                    className='lastname'>
                    <Select className='selectors' allowClear={true}>
                      {
                        this.state.internalusers?.map((item) => {
                          const { displayname, _id } = item;
                          return <Select.Option value={_id} key={_id}>{displayname}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>


                  <Form.Item
                    name="provider_status"
                  >
                    <Switch checked={this.state.provider_status}
                      checkedChildren="Active" unCheckedChildren="Inactive"
                      onChange={this.onChangeSwitch} />

                  </Form.Item>


                  <Form.Item >
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                      Update User
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>
            </div>
          </Modal> */}

              {(() => {
                if (this.state.showCreate) {
                  return <React.Fragment>
                    <div style={{ marginBottom: 16, float: 'right' }}>
                      <Button type="primary" onClick={this.showCreateProviderModal}> Create New Local Provider</Button>
                    </div>
                  </React.Fragment>
                }
              })()}

              {(() => {

                if (this.state.showMultiSectionOption && this.state.currentUser.usertype === Role.admin.name) {
                  return <React.Fragment>
                    <div style={{ marginBottom: 10 }}>
                      {this.state.hasSelected}
                      <Button variant='link' className='p-0' onClick={() => this.setAssignee('', true)}>
                        <HiOutlineUsers />   Assign to team member
                      </Button>
                    </div>
                    <span style={{ marginLeft: 8 }}>
                      {this.state.hasSelected ? `Selected ${this.state.selectedRows.length} items` : ''}
                    </span>

                    {/* <Table rowSelection={rowSelection} loading={this.props.providersStore.loading}
                  dataSource={this.state.providers}
                  onChange={onChange}

                  pagination={{ defaultPageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], showSizeChanger: true }}
                  rowKey={record => record._id} columns={columns} /> */}
                    {/* <div>
              {this.state.filterItems?.map((data, index) => (
                <Button key={index} style={{ backgroundColor: 'white', color: '#252930', borderRadius: '30px' }} variant="outline-info" onClick={() => this.handleRemoveFilter(index)}>{data === 'past' ? 'closed' : data} x</Button>
              ))}
            </div>
             */}

                    <BootstrapTable keyField='_id'
                      data={this.state.allSelectedRows}
                      hover={false}
                      columns={columns}
                      bordered={false}
                      selectRow={selectRow}
                      pagination={paginationFactory(options)}
                    />
                    <div className="banner-header">
                      <h4>All Providers</h4>
                    </div>
                    {!this.props.providersStore.loading &&
                      <BootstrapTable keyField='_id'
                        data={this.state.providers}
                        hover={false}
                        columns={columns1}
                        bordered={false}
                        // expandRow={expandRow}
                        pagination={paginationFactory(options)}
                      />
                    }
                  </React.Fragment>
                } else {
                  return <React.Fragment>
                    {/* <BootstrapTable keyField='_id'
                  loading={this.props.providersStore.loading}
                  data={this.state.providers}
                  onChange={onChange}
                  // pagination={{ defaultPageSize: 5, pageSizeOptions: [5, 10, 20, 30, 50, 100], showSizeChanger: true }}
                  // rowKey={record => record._id}
                  columns={columns} />
                <div>
                  {this.state.filterItems?.map((data, index) => (
                    <Button key={index} style={{ backgroundColor: 'white', color: '#252930', borderRadius: '30px' }} variant="outline-info" onClick={() => this.handleRemoveFilter(index)}>{data === 'past' ? 'closed' : data} x</Button>
                  ))}
                </div>
              */}

                    {/* <BootstrapTable keyField='_id'
                      data={this.state.allSelectedRows}
                      hover={false}
                      columns={columns}
                      bordered={false}
                      selectRow={selectRow}
                      pagination={paginationFactory(options)}
                    /> */}
                    <div className="banner-header">
                      <h4>All Providers</h4>
                    </div>
                    {this.state.providers.length > 0 ? <BootstrapTable keyField='_id'
                      data={this.state.providers}
                      hover={false}
                      columns={columns1}
                      bordered={false}
                      // expandRow={expandRow}
                      pagination={paginationFactory(options)}
                    /> :
                      <div class="card" style={{ marginTop: "15px" }}>
                        <div class="card-body text-center">
                          <RiCalendar2Line size={60} className='text-secondary' />
                          <h6>No Data Found</h6>
                          <small><p className='text-secondary'>Create an provider request, share your health concerns, and connect with expert doctors.
                          </p></small>
                        </div>
                      </div>
                    }

                  </React.Fragment>
                }
              })()}

              <Modal show={this.state.showCreateProviderModal}
                onHide={this.handleCancel} centered
                backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Create Provider
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-5'>
                  {/* <Modal
            keyboard={true}
            maskClosable={false}
            destroyOnClose={true}
            centered={true}
            visible={this.state.showCreateProviderModal}
            title="Create Local Provider"
            okText="Create"
            cancelText="Cancel" footer={false}
            onCancel={this.handleCancel}
            onOk={this.handleModalOk}> */}

                  {<CreateLocalProvider
                    onSuccess={this.getProviderData}
                    {...this.props}
                  />}
                </Modal.Body>
              </Modal>
            </> : <div className='d-flex justify-content-center flex-nowrap'>
              <div style={{ marginTop: "10rem", display: "flex", justifyContent: "center" }} className='position-absolute mx-3 p-4' variant="primary">
                <Spinner animation="border" variant="primary" className='spin' />
              </div>
            </div>}
        </div>

      </AfterLoginLayout>
    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLocalProviderListDetails: () => dispatch(getLocalProviderListDetails())
  }
}
const mapStateToProps = state => {
  return {
    providersStore: state.providerUserList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderListComponent)

import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { connect } from 'react-redux'
import { getProfileDetails } from '../../../actions/patient/ProfileAction';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import ProfileService from '../../../services/patient/Profile.Service';
import moment from 'moment';
import "./PatientProfile.style.css";
import { errorMessage } from '../../../utils/ServiceHandler';
import { BloodGroupComponent, GenderComponent, MaritalStatusComponent } from './Utils';

import { formikFormalidation } from '../../general/formvalidator/validationFroms'
import { Form } from 'react-bootstrap'
import { Formik } from 'formik';
import * as yup from 'yup'

//const { Option } = Select;

export class PatientProfileComponent extends React.Component {
  //formRef = React.createRef();
  // layout = {
  //   labelCol: { span: 24 }
  // };

  constructor(props) {
    super(props)
    this.state = {
      user: {
        firstname: "", middlename: '', lastname: '', email: '', dob: '', location: '',
        height: '', weight: '', load: false, phone: '', emergencycontact: '', countrycode: '', emergencycontactcountrycode: ''
      },
      gender: '',
      bloodgroup: '',
      maritalstatus: '',
    }
  }

  emergencyContactChange = (e) => {
    this.setState({
      emergencyPhoneInput: e
    });
  }


  getInitalValues(user) {
    const { firstname, middlename, lastname, email, phone, countrycode, patientInfo } = user || {};

    const { bloodgroup, gender, dob, maritalstatus, height, weight, emergencycontact, emergencycontactcountrycode, location, } = patientInfo?.[patientInfo.length - 1] || {}
    const ddmmyy = moment(dob, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.setState({
      user: {
        ...this.state.user, firstname: firstname, lastname: lastname,
        email: email, middlename: middlename, load: true,
        phone: `${countrycode}${phone}`, countrycode: countrycode, dob: ddmmyy, height: height ? height : "",
        weight: weight ? weight : "", location: location ? location : "",
        emergencycontact: `${emergencycontactcountrycode ? emergencycontactcountrycode : '+91'}${emergencycontact ? emergencycontact : ""}`,
        emergencycontactcountrycode: emergencycontactcountrycode ? emergencycontactcountrycode : "+91"
      }, bloodgroup: bloodgroup ? bloodgroup : "", gender: gender ? gender : "", maritalstatus: maritalstatus ? maritalstatus : ""
    })
  }

  handleOnSelectGender = (value) => {
    this.setState({ gender: value });

  }

  handleOnSelectBloodGroup = (bloodCode) => {
    this.setState({ bloodgroup: bloodCode });
  }

  handleOnSelectMaritalStatus = (value) => {
    this.setState({ maritalstatus: value });
  }

  componentDidMount() {
    this.props.getProfileDetails().then((profileData) => {
      this.getInitalValues(profileData?.users)
    }).catch(err => {
      errorMessage(err)
      console.log(err);
    })
  }

  render() {
    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      middlename: formikFormalidation.middlename,
      lastname: formikFormalidation.lastname,
      email: formikFormalidation.email,
      location: formikFormalidation.location,
      weight: formikFormalidation.weight,
      height: formikFormalidation.height,
      dob: formikFormalidation.dob,
      phone: formikFormalidation.phone1,
      emergencycontact: formikFormalidation.emergencycontact
    })
    return (<React.Fragment>
      <AfterLoginLayout>
        {this.state.user.load ?
          <Formik
            validationSchema={schema}
            initialValues={this.state.user}
            validate={values => {
              const errors = {};
              if (!values.phone) {
                errors.phone = "Mobile number is required!";
              } else {
                const countryCallingCode = parsePhoneNumber(values.phone)?.countryCallingCode;
                if (!countryCallingCode || !isValidPhoneNumber(values.phone)) {
                  errors.phone = 'Invalid Mobile number format!';
                }
              }
              if (values.emergencycontact) {
                const emergencyCountryCode = parsePhoneNumber(values.emergencycontact) && parsePhoneNumber(values.emergencycontact).countryCallingCode;
                // const emerNumber = isValidPhoneNumber(values.emergencycontact)
                if (emergencyCountryCode !== undefined && emergencyCountryCode === "91" && values.emergencycontact.length !== 13) {
                  errors.emergencycontact = 'Invalid Emergency Mobile number format!';
                }
                if (emergencyCountryCode !== undefined && emergencyCountryCode === "1" && values.emergencycontact.length !== 12) {
                  errors.emergencycontact = 'Invalid Emergency Mobile number format!';
                }
              }
              if (values.dob === "Invalid date") {
                errors.dob = "Please select valid date"
              }
              return errors;
            }}

            onSubmit={async (values) => {
              const phoneCountryCallingCode = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).countryCallingCode
              const phoneNationalNumber = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).nationalNumber
              let emergencyCountryCallingCode;
              let emergencyNationalNumber;
              if (values.emergencycontact) {
                emergencyCountryCallingCode = parsePhoneNumber(values.emergencycontact) && parsePhoneNumber(values.emergencycontact).countryCallingCode
                emergencyNationalNumber = parsePhoneNumber(values.emergencycontact) && parsePhoneNumber(values.emergencycontact).nationalNumber
              }
              const { dob, height, lastname, firstname, middlename, weight, email, location } = values
              //  const mmddyy =  moment(dob,'DD-MM-YYYY').format('MM-DD-YYYY');
              const { bloodgroup, maritalstatus, gender } = this.state
              const allFormData = {
                countrycode: "+" + phoneCountryCallingCode,
                phone: phoneNationalNumber,
                emergencycontactcountrycode: emergencyCountryCallingCode === undefined ? "" : "+" + emergencyCountryCallingCode,
                emergencycontact: emergencyNationalNumber === undefined ? "" : emergencyNationalNumber,
                bloodgroup: bloodgroup,
                maritalstatus: maritalstatus,
                dateOfBirth: dob,
                gender: gender,
                lastname: lastname,
                firstname: firstname,
                middlename: middlename,
                weight: weight,
                email: email,
                height: height,
                location: location
              }
              await ProfileService.update(allFormData)
            }}
          >
            {(({ values, errors, handleChange, touched, handleSubmit }) => (
              <div className="container-fluid">
                <div>
                  <div style={{ 'width': "100%" }} class="row">
                    <div class="pb-3 col-lg-12 col-md-12 col-sm-12">
                      <h5 class="text-left">Profile</h5>
                    </div>
                  </div>
                  <div className='p-5 profilescreen'>
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold text-dark">
                            First Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <Form.Control isInvalid={errors.firstname || !values.firstname} autoComplete='off' class className='shadow-none'
                            name="firstname" onChange={handleChange} value={values.firstname}
                          />
                          {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                        </div>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold">Middle Name</label>
                          <Form.Control isInvalid={errors.middlename} autoComplete='off' className='shadow-none'
                            onChange={handleChange}
                            name='middlename' value={values.middlename} />
                          {errors.middlename && <div className="text-danger">{errors.middlename}</div>}
                        </div>
                        <div className='col form-group'>
                          <label className="mb-1 font-weight-bold">Last Name</label>
                          <Form.Control autoComplete='off' isInvalid={errors.lastname}
                            className='shadow-none'
                            onChange={handleChange} name="lastname"
                            value={values.lastname} />
                          {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                        </div>
                      </div>
                      {/* phone inp1 */}
                      <div className='row'>
                        <div className='col'>
                          <label className='font-weight-bold mb-1'>Mobile<span style={{ color: "red" }}>*</span></label>
                          <Form.Control as={PhoneInput} className="w-100"
                            label="Mobile"
                            placeholder="Enter Mobile number"
                            style={values.phone && isValidPhoneNumber(values.phone) ? { border: '1px solid #d9d9d9' } : { border: '1px solid red' }}
                            international
                            defaultCountry="IN"
                            countries={['US', 'IN']}
                            countryCallingCodeEditable={false}
                            limitMaxLength={true}
                            value={values.phone}
                            onChange={e => values.phone = e}
                            isInvalid={errors.phone && touched.phone}
                          />
                          {errors.phone && <div className="text-danger">{errors.phone}</div>}
                        </div>

                        <div className='col'>
                          <label className='font-weight-bold mb-1'>Emergency Contact</label>
                          <Form.Control as={PhoneInput} className="w-100"
                            label="Mobile"
                            placeholder="Enter Mobile number"
                            style={values.emergencycontact && isValidPhoneNumber(values.emergencycontact) ? { border: '1px solid #d9d9d9' } : { border: '' }}
                            international
                            defaultCountry="IN"
                            countries={['US', 'IN']}
                            countryCallingCodeEditable={false}
                            limitMaxLength={true}
                            value={values.emergencycontact}
                            onChange={e => values.emergencycontact = e}
                            isInvalid={errors.emergencycontact && touched.emergencycontact}

                          />
                          {errors.emergencycontact && <div className="text-danger">{errors.emergencycontact}</div>}
                        </div>
                      </div>
                      {/* gender and height weight */}
                      <div className='row mt-4'>
                        <div className='col'>
                          <GenderComponent genderValue={this.state.gender}
                            handleOnSelectGender={this.handleOnSelectGender} />
                          {/* {this.state.gender.length < 1 && <div>Select gender</div>} */}
                        </div>
                        <div className='col'>
                          <div className='d-flex'>
                            <div className='form-group mr-4'>
                              <label className="mb-1 font-weight-bold">Height(cm)</label>
                              <Form.Control isInvalid={errors.height} autoComplete='off' className={`form-control shadow-none ${errors.height && 'border-danger'}`}
                                name='height' value={values.height} onChange={handleChange} />
                              {errors.height && <div className="text-danger">{errors.height}</div>}
                            </div>
                            <div className='form-group'>
                              <label className="mb-1 font-weight-bold">Weight(kg)</label>
                              <Form.Control isInvalid={errors.weight} autoComplete='off' name='weight' className={`form-control shadow-none ${errors.weight && 'border-danger'}`}
                                value={values.weight} onChange={handleChange} />
                              {errors.weight && <div className="text-danger">{errors.weight}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* blood group & marratial status */}
                      <div className='row'>
                        <div className='col mr-2'>
                          <BloodGroupComponent bloodGroupValue={this.state.bloodgroup} onSelectBloodGroup={this.handleOnSelectBloodGroup} />
                        </div>
                        <div className='col'>
                          <MaritalStatusComponent maritalstatusValue={this.state.maritalstatus} handleOnSelectMaritalStatus={this.handleOnSelectMaritalStatus} />
                          {/* {!maritalstatus && <div className='text-danger'>Please selecet Maritial status</div>} */}
                        </div>
                      </div>
                      {/* email && dob */}
                      <div className='row mt-4'>
                        <div className='col'>
                          <div className='form-group w-100 mr-2'>
                            <label className="mb-1 font-weight-bold">Email</label>
                            <Form.Control isInvalid={errors.email} autoComplete='off' className='shadow-none'
                              name='email' value={values.email} onChange={handleChange} />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group w-100 mr-2'>
                            <label className="mb-1 font-weight-bold">Date Of Birth<span style={{ color: 'red' }}>*</span></label>
                            <Form.Control isInvalid={errors.dob || !values.dob} autoComplete='off' type="date" name='dob' value={values.dob}
                              onChange={handleChange} className='shadow-none' />
                            {errors.dob && <div className="text-danger">{errors.dob}</div>}
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* location */}
                        <div className='form-group'>
                          <label className="mb-1 font-weight-bold">Location</label>
                          <Form.Control autoComplete='off' className='shadow-none'
                            type='text' name='location' value={values.location} onChange={handleChange} />
                          {errors.location && <div className="text-danger">{errors.location}</div>}
                        </div>
                        <button className='btn btn-primary my-2' type="submit">
                          Save
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            ))}
          </Formik> : <div className='container d-flex justify-content-center align-items-center' style={{}}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
      </AfterLoginLayout>
    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProfileDetails: () => dispatch(getProfileDetails())
  }
}
const mapStateToProps = state => {
  return {
    profileStore: state.patientProfileData
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientProfileComponent)


import { message } from 'antd';
import axios from 'axios';
import { APIENDPOINT } from "../../constants/APIEndpoint";


export const contactForm = async (props, formDetails) => {

    var data = {
        "fullname": formDetails.formData.fullname,
        "phone": formDetails.nationalNumber,
        "email": formDetails.formData.email,
        "countrycode": formDetails.countryCallingCode,
        "query": formDetails.formData.query
    };
    var config = {
        method: 'POST',
        url: process.env.REACT_APP_API_URL + APIENDPOINT.CONTACT_USER,
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        data: data
    };

    await axios(config)
        .then(function (response) {
            if (response.data.status === 400) {
                message.error('Something wrong. ')
                return
            } if (response.data.status === "success") {
                message.success(' Your Query has been received!')
                return

            } else {
                message.error('Failed :' + response.data.message)
            }
        })
        .catch(function (err) {
            if (err.response) {
                console.log("client received an error response (5xx, 4xx)", err);
            } else if (err.request) {
                console.log("client never received a response, or request never left", err);
            } else {
                // anything unknown
                console.log("Error : ", err);
            }
            message.error(err.message)
        });
}
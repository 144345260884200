import React, { useState, useCallback, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import opinionbg from "../../../../src/assets/images/opinionrequest-bg.png";
import personicon from "../../../../src/assets/images/personicon.png";
// import uploadfile from "../../../../src/assets/images/uploadfile.png";
import { getSpecialities } from "../../../utils/PersistentData";
import * as yup from "yup";
import { formikFormalidation } from "../../general/formvalidator/validationFroms";
import { Modal, Button, Form, Card, Row, Col, Spinner, Carousel } from "react-bootstrap";
import { AUTHROUTE } from "../../../constants/RouterPath";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import EnquiryListService from "../../../services/patient/EnquiryList.Service";
import { message } from "antd";
import { errorMessage } from "../../../utils/ServiceHandler";
import { useDropzone } from "react-dropzone";
import { FaTrash } from "react-icons/fa";
import "./OpinionRequest.style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ProfileService from "../../../services/patient/Profile.Service";
import { FaFileAlt } from 'react-icons/fa';
import { FaFile } from 'react-icons/fa';


const OpinionRequest = (
  { openModal, setOpenModal, displayname, patient_id, localProvider },
  props
) => {
  // const [selectedDate, setSelectedDate] = useState(null);
  const [fileName, setFileName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opinionData, setOpinionData] = useState({ healthconcern: "", speciality: "", id: "" });
  const [opinionInfo, setOpinionInfo] = useState("");
  const [fileError, setFileError] = useState("");

  // useEffect(() => {
  //   const getPatientHealthConcern = async () => {
  //     try {
  //       let all_opinion_data = await ProfileService.getPatientOpinion();
  //       console.log("all-opinion-data",all_opinion_data)
  //       setOpinionData({ ...opinionData,healthConcern:all_opinion_data.data.data.enquirylookup.enquiry_message })
  //       setOpinionLoaded(true)
  //     } catch (err){
  //       console.log(err)
  //     }
  //   }

  //   getPatientHealthConcern()
  // }, []);

  const handleShowModl = () => {
    localStorage.setItem("newUserVal", false)
    setOpenModal()
  }
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileName(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
      // console.log("accepted files>>", acceptedFiles);
      setFileName(fileName.concat(...acceptedFiles));
    },
    [fileName]
  );


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*,.pdf,.doc,.docx",
    maxFiles: "5",
  });


  const previewName = fileName?.map((file, i) => (
    <Card className="ml-3 mt-4"
      key={file.name}
      style={{
        position: "relative",
        backgroundColor: "#F8F8F9",
        height: "50px auto",
        width: "200px",
      }}
    >

      {(file?.type === 'image/jpeg' || file?.type === 'image/png') &&
        <img src={file.preview} alt="opinionImg" width="200px" height="160px" />}
      {file?.type === 'text/plain' && <div className="pb-5"> <FaFileAlt className='fa fa-6x text-secondary' /></div>}

      {(file?.type === 'application/pdf' || file?.type === 'application/msword' || file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
        <div className='image pb-5'>
          <FaFile className='fa fa-6x text-info' />
        </div>
      }

      <Row className="py-2 px-2 dropped-files" style={{ justifyContent: "stretch", alignItems: "center" }}>
        <Col sm={8}>
          <h6 style={{ fontFamily: "manrope", fontWeight: "500" }}>
            {file.name}
          </h6>
          {file.size}&nbsp;
        </Col>
        <Col sm={4}>
          <FaTrash
            style={{ cursor: "pointer" }}
            onClick={() => removeFile(i)}
          />
        </Col>
      </Row>
    </Card>
  ));

  const removeFile = (file) => {
    const newFiles = [...fileName];
    newFiles.splice(file, 1);
    setFileName(newFiles);
    // console.log("new files>.", newFiles);
  };

  const handleCloseModalCheck = () => {
    // console.log("clocked")
    localStorage.setItem("newUserVal", false)

  }



  useEffect(() => {
    fileName.forEach((file) => URL.revokeObjectURL(file.name));
    const getPatientHealthConcern = async () => {
      try {
        let all_opinion_data = await ProfileService.getPatientOpinion();
        setOpinionData({ ...opinionData, healthconcern: all_opinion_data.data.status === "failure" ? "" : all_opinion_data.data.data.enquirylookup.enquiry_message, id: all_opinion_data.data.status === "failure" ? "" : all_opinion_data.data.data.enquirylookup._id })
        if (all_opinion_data.data.status) {
          setOpinionInfo(true)
        }

      } catch (err) {
        console.log(err)
      }
    }
    if (!opinionInfo) {
      getPatientHealthConcern()
    }
  },
    // [fileName,opinionData]
  );
  const history = useHistory();

  const getSpeciality = getSpecialities() || [];
  // console.log("getspeciality", getSpeciality)

  const opinionSchema = yup.object().shape({
    // email: formikFormalidation.email,
    // mobilenumber: formikFormalidation.mobilenumber,
    speciality: formikFormalidation.speciality,
    healthconcern: formikFormalidation.healthconcern,
  });

  return (
    <React.Fragment>
      <Modal
        {...props}
        width={500}
        show={openModal}
        onHide={setOpenModal}
        backdrop="static"
        keyboard={true}
        centered={false}
        size="xl"
      >
        <Modal.Header style={{ border: "0" }} closeButton={() => handleCloseModalCheck()}>
          <Modal.Title
            style={{
              fontFamily: "manrope",
              fontWeight: "800", fontSize: "23px"
            }}
          >
            Get an opinion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <div className="d-flex justify-content-center flex-nowrap">
              <div className="position-absolute mx-3" style={{ marginTop: '450px' }} variant="primary">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          )}
          {Object.keys(opinionData).length !== 0 ?
            <Formik
              enableReinitialize={true}
              initialValues={opinionData && opinionData}
              validationSchema={opinionSchema}

              validate={values => {
                const errors = {}
                // const img = [...fileName, ...allData.fileList]

                if (fileName.length === 0 && fileName.length === 0) {
                  errors.fileName = "Please select file here!"
                  setFileError(errors.fileName);
                }
                else if (fileName.length > 5) {
                  errors.fileName = "upload maximum 5 files"
                  setFileError(errors.fileName);
                }

                return errors
              }}

              onSubmit={async (values) => {
                setLoading(true);
                if (fileName.length === 0) {
                  message.error("Please upload Prescription");
                  return;
                }

                let formData = new FormData();

                formData.append("speciality_id", values.speciality || "");
                formData.append("enquiry_message", values.healthconcern || "");
                formData.append("patient_id", patient_id);
                formData.append("general_opionion_id", opinionData?.id || "");

                for (let i = 0; i < fileName.length; i++) {
                  const file = fileName[i];
                  formData.append("file", file);
                }

                try {
                  let response = await EnquiryListService.createEnquiry(formData);

                  if (response.data.status === "success") {
                    localStorage.setItem("newUserVal", false)
                    setLoading(false);
                    message.success(response.data.message);
                    if (localProvider) {
                      setOpenModal('close');
                      return;
                    }
                    history.push(AUTHROUTE.PATIENT.OPINION_SUCCESS, [
                      {
                        selectedFiles: fileName,
                        selfUserName: displayname,
                        healthConcern: values.healthconcern,
                        speciality: values.speciality,
                        enquiryCode: response.data.data.enquiryCode,
                        submittedDate: response.data.data.createddate
                      },
                    ]);
                    // getEnquiries();
                  } else {
                    message.error(response.data.message);
                  }
                } catch (error) {
                  setLoading(false);
                  errorMessage(error);
                }
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <fieldset disabled={loading}>
                  <Form
                    style={{ width: "100%", display: "flex" }}
                    className="request-form opinionrequest"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div>
                      <div>
                        <Form.Group controlId="addPatient">
                          <h5 style={{ fontFamily: "manrope", fontWeight: "700", fontSize: "15px", lineHeight: "20px", marginTop: "-15px" }}>
                            Is this for you or someone else?
                          </h5>
                          <div style={{ flexDirection: "row", display: "flex", width: "600px" }}>
                            <div style={{ marginLeft: "4%", textAlign: "center" }}>
                              <img src={personicon} alt="person" />
                              <h6 style={{ fontWeight: "600", fontSize: "13px", marginTop: "10px" }}>{displayname}</h6>
                              <h6 style={{ fontWeight: "400", fontSize: "13px" }}>(You)</h6>
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group controlId="formControlsTextara">
                          <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                            What is your health concern?
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          <h6 style={{ fontWeight: "400", fontSize: "13px" }}>
                            your data will be secured with us
                          </h6>

                          <Form.Control
                            as="textarea"
                            rows={2} style={{ border: "1px solid #CFCFCF", borderRadius: "8px", boxSizing: "border-box", position: "relative", width: "616px" }}
                            name="healthconcern"
                            type="text"
                            value={values.healthconcern}
                            isInvalid={
                              errors.healthconcern && touched.healthconcern
                            }
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.healthconcern}
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group>
                  <h4>Date of recent consultation</h4>
                  <Form.Control
                    type="date"
                    name="selectedDate"
                    // selected={selectedDate}
                    onChange={handleChange}
                    dateformat="dd/MM/yyyy"
                    maxdate={new Date()}
                    placeholder="DD/MM/YYYY"
                    // isClearable
                    // showYearDropdown
                    // scrollableMonthYearDropdown
                    value={values.selectedDate}
                    isInvalid={errors.selectedDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedDate}
                  </Form.Control.Feedback>
                </Form.Group> */}

                        <div style={{ position: "relative", marginTop: "30px" }}>
                          <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                            Add prescriptions/past records
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          <div

                            {...getRootProps({ className: "drop-zone", role: "button" })}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p
                                style={{
                                  border: "2px dashed #CFCFCF",
                                  boxSizing: "border-box",
                                  borderRadius: "8px",
                                  width: "616px",
                                  height: "80px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  color: "#0BC1C2",
                                }}
                              >
                                Drop the files here ...
                              </p>
                            ) : (
                              <>
                                {/* <img
                            style={{
                              position: "absolute",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              left: "26%",
                              right: "14.38%",
                              top: "50%",
                              bottom: "25%",
                            }}
                            src={uploadfile}
                            alt="upload here"
                          /> */}
                                <p
                                  style={{
                                    border: "2px dashed #CFCFCF",
                                    borderRadius: "8px",
                                    width: "616px",
                                    height: "110px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#0BC1C2",
                                  }}
                                >
                                  Drag and drop or browse files
                                </p>
                              </>
                            )}
                          </div>
                          <p className="d-flex flex-wrap">{previewName}</p>
                          {fileError && <p style={{ color: "#dc3545", fontSize: '13px' }}>{fileError}</p>}
                        </div>


                        <div style={{ marginTop: "40px", fontFamily: "manrope" }}>
                          {" "}
                          <h5 style={{ fontSize: "15px", fontWeight: "700", fontFamily: 'manrope' }}>
                            Which type of doctor did you see for this problem?
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          {values.speciality ? null : (errors.speciality && <p style={{ color: "#dc3545", fontSize: '13px' }}>Please select speciality!</p>)}
                          <Form.Group controlId="formControlSelectArea">
                            <Carousel interval={null} indicators={false} >
                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ height: "180px", width: "100px" }} >
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(0, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(6, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(11, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(16, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(21, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(26, 2)}</div></Carousel.Item>


                              {/* </div> */}</Carousel>

                            {/* <Form.Control.Feedback type="invalid">
                        {errors.speciality}
                      </Form.Control.Feedback> */}
                          </Form.Group>
                        </div>

                        {/* <Form.Label style={{marginTop:"50px"}}>
                  How should we send you details to you safely?
                </Form.Label>
                <Container style={{ height: "100px", minHeight: "10px" }}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Email-ID"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={errors.email}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.healthconcern}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Mobilenumber"
                          name="mobilenumber"
                          value={values.mobilenumber}
                          onChange={handleChange}
                          isInvalid={errors.mobilenumber}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.mobilenumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container> */}
                      </div>
                      <div style={{ marginTop: "130px", flexDirection: "row" }}>
                        <Form.Group
                          style={{
                            display: "flex",
                            justifyContent: "initial", flexDirection: "row", position: "relative",
                          }}
                        >
                          <Button
                            variant="primary" className="submit-button"
                            type="submit"
                            style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}
                          >
                            Submit
                          </Button>

                          <Button
                            variant="white" className="cancel-button"
                            style={{ marginLeft: "30px", fontSize: "15px", fontWeight: "700" }}
                            onClick={() => handleShowModl()}
                          >
                            Cancel
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                    <div>
                      <img
                        alt="example"
                        style={{
                          position: "absolute",
                          width: "350px",
                          height: "500px",
                          left: "770px",
                          top: "-25px",
                          opacity: "0.9",
                          borderRadius: "20px",
                        }}
                        src={opinionbg}
                      />
                    </div>
                  </Form>
                </fieldset>
              )}
            </Formik> :
            <Formik
              initialValues={opinionData && opinionData}
              validationSchema={opinionSchema}

              validate={values => {
                const errors = {}
                // const img = [...fileName, ...allData.fileList]

                if (fileName.length === 0 && fileName.length === 0) {
                  errors.fileName = "Please select file here!"
                  setFileError(errors.fileName);
                }
                else if (fileName.length > 5) {
                  errors.fileName = "upload maximum 5 files"
                  setFileError(errors.fileName);
                }

                return errors
              }}

              onSubmit={async (values) => {
                setLoading(true);
                if (fileName.length === 0) {
                  message.error("Please upload Prescription");
                  return;
                }

                let formData = new FormData();

                formData.append("speciality_id", values.speciality || "");
                formData.append("enquiry_message", values.healthconcern || "");
                formData.append("patient_id", patient_id);
                formData.append("general_opionion_id", opinionData?.id || "");

                for (let i = 0; i < fileName.length; i++) {
                  const file = fileName[i];
                  formData.append("file", file);
                }

                try {
                  let response = await EnquiryListService.createEnquiry(formData);

                  if (response.data.status === "success") {
                    localStorage.setItem("newUserVal", false)
                    setLoading(false);
                    message.success(response.data.message);
                    history.push(AUTHROUTE.PATIENT.OPINION_SUCCESS, [
                      {
                        selectedFiles: fileName,
                        selfUserName: displayname,
                        healthConcern: values.healthconcern,
                        speciality: values.speciality,
                        enquiryCode: response.data.data.enquiryCode,
                        submittedDate: response.data.data.createddate
                      },
                    ]);
                    // getEnquiries();
                  } else {
                    message.error(response.data.message);
                  }
                } catch (error) {
                  setLoading(false);
                  errorMessage(error);
                }
              }}
            >
              {({ values, errors, touched, handleSubmit, handleChange }) => (
                <fieldset disabled={loading}>
                  <Form
                    style={{ width: "100%", display: "flex" }}
                    className="request-form opinionrequest"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div>
                      <div>
                        <Form.Group controlId="addPatient">
                          <h5 style={{ fontFamily: "manrope", fontWeight: "700", fontSize: "15px", lineHeight: "20px", marginTop: "-15px" }}>
                            Is this for you or someone else?
                          </h5>
                          <div style={{ flexDirection: "row", display: "flex", width: "600px" }}>
                            <div style={{ marginLeft: "4%", textAlign: "center" }}>
                              <img src={personicon} alt="person" />
                              <h6 style={{ fontWeight: "600", fontSize: "13px", marginTop: "10px" }}>{displayname}</h6>
                              <h6 style={{ fontWeight: "400", fontSize: "13px" }}>(You)</h6>
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group controlId="formControlsTextara">
                          <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                            What is your health concern?
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          <h6 style={{ fontWeight: "400", fontSize: "13px" }}>
                            your data will be secured with us
                          </h6>

                          <Form.Control
                            as="textarea"
                            rows={2} style={{ border: "1px solid #CFCFCF", borderRadius: "8px", boxSizing: "border-box", position: "relative", width: "616px" }}
                            name="healthconcern"
                            type="text"
                            value={values.healthconcern}
                            isInvalid={
                              errors.healthconcern && touched.healthconcern
                            }
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.healthconcern}
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* <Form.Group>
                  <h4>Date of recent consultation</h4>
                  <Form.Control
                    type="date"
                    name="selectedDate"
                    // selected={selectedDate}
                    onChange={handleChange}
                    dateformat="dd/MM/yyyy"
                    maxdate={new Date()}
                    placeholder="DD/MM/YYYY"
                    // isClearable
                    // showYearDropdown
                    // scrollableMonthYearDropdown
                    value={values.selectedDate}
                    isInvalid={errors.selectedDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedDate}
                  </Form.Control.Feedback>
                </Form.Group> */}

                        <div style={{ position: "relative", marginTop: "30px" }}>
                          <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                            Add prescriptions/past records
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          <div

                            {...getRootProps({ className: "drop-zone", role: "button" })}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p
                                style={{
                                  border: "2px dashed #CFCFCF",
                                  boxSizing: "border-box",
                                  borderRadius: "8px",
                                  width: "616px",
                                  height: "80px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  color: "#0BC1C2",
                                }}
                              >
                                Drop the files here ...
                              </p>
                            ) : (
                              <>
                                {/* <img
                            style={{
                              position: "absolute",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              left: "26%",
                              right: "14.38%",
                              top: "50%",
                              bottom: "25%",
                            }}
                            src={uploadfile}
                            alt="upload here"
                          /> */}
                                <p
                                  style={{
                                    border: "2px dashed #CFCFCF",
                                    borderRadius: "8px",
                                    width: "616px",
                                    height: "110px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#0BC1C2",
                                  }}
                                >
                                  Drag and drop or browse files
                                </p>
                              </>
                            )}
                          </div>
                          <p className="d-flex flex-wrap">{previewName}</p>
                          {fileError && <p style={{ color: "#dc3545", fontSize: '13px' }}>{fileError}</p>}
                        </div>

                        {fileName.length > 5 && errors.speciality && <p style={{ color: "#dc3545", fontSize: '13px' }}>upload max 5 files</p>}

                        <div style={{ marginTop: "40px", fontFamily: "manrope" }}>
                          {" "}
                          <h5 style={{ fontSize: "15px", fontWeight: "700", fontFamily: 'manrope' }}>
                            Which type of doctor did you see for this problem?
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          {values.speciality ? null : (errors.speciality && <p style={{ color: "#dc3545", fontSize: '13px' }}>Please select speciality!</p>)}
                          <Form.Group controlId="formControlSelectArea">
                            <Carousel interval={null} indicators={false}>
                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ height: "180px", width: "100px" }} >
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(0, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(6, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(11, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(16, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(21, 5)}</div></Carousel.Item>

                              <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{getSpeciality?.map((item) => {
                                return (
                                  <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                      <Card.Body>
                                        <Card.Subtitle className="text-start">
                                          <div><img src={item.documents[0].url} alt="opinion" /></div>
                                          <div><input onChange={e => values.speciality = e.target.value} name="speciality" value={item._id} key={item.id} id={item.id} type="radio"
                                            style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                        </Card.Subtitle>
                                      </Card.Body>
                                      <div>
                                        <label className="mt-4" htmlFor={item.id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }).splice(26, 2)}</div></Carousel.Item>


                              {/* </div> */}</Carousel>

                            {/* <Form.Control.Feedback type="invalid">
                        {errors.speciality}
                      </Form.Control.Feedback> */}
                          </Form.Group>
                        </div>

                        {/* <Form.Label style={{marginTop:"50px"}}>
                  How should we send you details to you safely?
                </Form.Label>
                <Container style={{ height: "100px", minHeight: "10px" }}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Email-ID"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={errors.email}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.healthconcern}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Mobilenumber"
                          name="mobilenumber"
                          value={values.mobilenumber}
                          onChange={handleChange}
                          isInvalid={errors.mobilenumber}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.mobilenumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container> */}
                      </div>
                      <div style={{ marginTop: "130px", flexDirection: "row" }}>
                        <Form.Group
                          style={{
                            display: "flex",
                            justifyContent: "initial", flexDirection: "row", position: "relative",
                          }}
                        >
                          <Button
                            variant="primary" className="submit-button"
                            type="submit"
                            style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}
                          >
                            Submit
                          </Button>

                          <Button
                            variant="white" className="cancel-button"
                            style={{ marginLeft: "30px", fontSize: "15px", fontWeight: "700" }}
                            onClick={() => handleShowModl()}
                          >
                            Cancel
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                    <div>
                      <img
                        alt="example"
                        style={{
                          position: "absolute",
                          width: "350px",
                          height: "500px",
                          left: "770px",
                          top: "-25px",
                          opacity: "0.9",
                          borderRadius: "20px",
                        }}
                        src={opinionbg}
                      />
                    </div>
                  </Form>
                </fieldset>
              )}
            </Formik>
          }
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default OpinionRequest;

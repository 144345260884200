import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import personicon from "../../../../src/assets/images/personicon.png";
import addperson from "../../../../src/assets/images/addperson.png";
import "./enquiry.style.css";
import opinionbg from "../../../../src/assets/images/opinionrequest-bg.png";
import { FaFile } from 'react-icons/fa';
import { getSingleEnquiryDetails } from '../../../actions/patient/EnquiriesAction'
import { Form, Button, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { message } from 'antd';
import { authenticationService } from '../../../component/general/login/login.service';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import Role from '../../../constants/role'
import { compose } from 'redux';
import { connect } from 'react-redux'
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import { getParsedPatientSlots } from '../../provider/scheduler/SchedulerUtils';
import { get_patient_obj, get_provider_obj, get_user_obj, get_provider_user_obj, getBase64, refreshPage, handleOnSelectStage } from './EnquiryUtils';
import _ from 'lodash';



export class EnquiryDetailsEditor extends Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {

        super(props);
        const id = this.props && this.props.match && this.props.match.params && this.props.match.params.id

        this.state = {
            enquiry_id: id,
            loading: true,
            selected_enquiry: {},
            prevPath: "",
            all_specialities: [],
            suggested_providers: [],
            fileList: [],
            currentUser: [],
            chosen_provider: {},
            visible: false,
            previewVisible: false,
            confirmationModal: false,
            selectedFile: {},
            patient_obj: {},
            patient_user_obj: {},
            confirmLoading: false,
            isAssigneeRequired: false,
            scheduleloading: false,
            selected_view_document: "",
            showImageViewer: false,
            available_slots: [],
            searchParams: {},
            enquiry_message: this.props && this.props.location && this.props.location.state && this.props.location.state.selected_enquiry.enquiryInfo.enquiry_message,
            speciality: this.props && this.props.location && this.props.location.state && this.props.location.state.selected_enquiry?.enquiryInfo.speciality.id
        }
        this.dispatchProviderSearch = _.debounce(this.dispatchProviderSearch, 1000);
        //this.redirectCheck(props)
    }
    redirectCheck(props) {
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
            }
        }
    }

    async pushImage(event) {

        const oldfiles = this.state.fileList;

        if (event.target.files[0].type === 'application/pdf') {
            await oldfiles.push({ filetype: 'application/pdf', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) });
        }
        else if (event.target.files[0].type === 'application/msword') {
            await oldfiles.push({ filetype: 'application/msword', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) })
        }
        else if (event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            await oldfiles.push({ filetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) })
        }
        else
            await oldfiles.push({ filetype: 'image/jpeg', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) });

        this.setState({ fileList: oldfiles });
    }
    documentViewer(document_url) {

        this.setState({ selected_view_document: document_url, showImageViewer: true });


    }
    handleClose() {
        this.setState({ showImageViewer: false });
    }

    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };
    hideCancelModal = () => {
        this.setState({ visible: false, confirmLoading: false });
    };
    setProviderModal = async () => {
        if (this.state.available_slots.length > 0) {
            this.setState({ confirmLoading: true })
            var data = {
                "enquiry_id": this.state.selected_enquiry && this.state.selected_enquiry._id,
                "provider_id": this.state.chosen_provider && this.state.chosen_provider._id,
                "countrycode": this.state.patient_user_obj && this.state.patient_user_obj.countrycode,
                "phone": this.state.patient_user_obj && this.state.patient_user_obj.phone,
                "email": (this.state.patient_user_obj && this.state.patient_user_obj.email) || "",
                "patientname": this.state.patient_user_obj && this.state.patient_user_obj.displayname,
            };
            try {
                let response = await EnquiryListService.setEnquiryProvider(data);
                if (response.data.status === "success") {
                    this.setState({ visible: false, confirmLoading: false });
                    refreshPage()
                } else {
                    this.setState({ visible: false, confirmLoading: false });
                    message.error(response.data.message);
                }

            } catch (error) {
                errorMessage(error)
                this.setState({ visible: false, confirmLoading: false });
            }
        } else {
            this.setState({ visible: false, confirmLoading: false });
        }
    }

    // hideModal = () => {
    // };


    handlePreview = async file => {
        if (file && file.filetype && !file.filetype.match('image')) {
            var win = window.open(file.url, '_blank');
            win.focus();
        } else {
            if (file.originFileObj) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.setState({
                previewImage: file.preview || file.url,
                previewVisible: true
            });
        }

    };
    handleUpload = ({ fileList }) => {
        this.setState({ fileList });
    };
    onRemove = (file) => {

        this.setState({ selectedFile: file })
        return new Promise((resolve, reject) => {
            this.setState({ confirmationModal: true });
            resolve(false)
        })
    };

    handleOkModalRemove = async () => {
        if (this.state.fileList && this.state.fileList.length === 1) {
            message.error("You cannot delete this as atleast one file is mandatory.");
            return
        }
        else if (this.state.selectedFile && !this.state.selectedFile.originFileObj) {
            var data = {
                "enquiry": this.state.selected_enquiry,
                "file_obj": this.state.selectedFile
            };
            try {
                this.setState({ confirmLoading: true, confirmationModal: false })
                let response = await EnquiryListService.removeFile(data);

                if (response.data.status === "success") {
                    refreshPage()
                } else {
                    this.setState({ confirmationModal: false, confirmLoading: false })
                    message.error(response.data.message);
                }
            } catch (error) {
                this.setState({ confirmationModal: false, confirmLoading: false })
                errorMessage(error)
            }
        } else {
            refreshPage()
        }
    };

    handleCancelModalRemove = () => {
        this.setState({ confirmationModal: false });
    }
    getInitalValues(_enquiry) {
        return {
            speciality: (_enquiry && _enquiry.speciality && _enquiry.speciality.id) || [],
            enquiry_message: _enquiry.enquiry_message || "",
            assignee_id: (_enquiry && _enquiry.assignee && _enquiry.assignee.id) || "",
            stage_id: (_enquiry && _enquiry.stage && _enquiry.stage.id) || null,
        }
    }
    getSuggestedProviders = async (_enquiry) => {
        try {
            this.setState({ provider_loading: true });
            let response = await EnquiryListService.getProviderBySpeciality(_enquiry);
            if (response.data.status === "success") {
                this.setState(state => ({
                    provider_loading: false,
                    suggested_providers: response.data.data
                }));
                //this.setState({ suggested_providers: response.data.data, loading: false })
            } else {
                this.setState({ suggested_providers: [], provider_loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            this.setState({ suggested_providers: [], provider_loading: false })
        }
    }

    getSchedulebyProviders = async (item) => {
        if (item) {
            try {
                this.setState({ scheduleloading: true });
                const provider_id = item && item._id
                if (provider_id) {
                    let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
                    if (response.data.status === "success") {
                        if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
                            const responseObj = response.data
                            const _schedules = responseObj.schedules
                            const all_schedule = getParsedPatientSlots(_schedules);
                            setTimeout(() => {
                                this.setState({
                                    available_slots: all_schedule,
                                    scheduleloading: false,
                                })
                            }, 100);
                        }
                    } else {
                        this.setState({ scheduleloading: false, available_slots: [] })
                    }
                } else {
                    this.setState({ scheduleloading: false, available_slots: [] })
                    //message.error(response.data.message);
                }
            } catch (error) {
                errorMessage(error)
                this.setState({ scheduleloading: false, available_slots: [] })
            }
        }
    }


    getvalsfromAPI = async () => {
        try {
            this.setState({ loading: true });
            const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })
            //const _all_specialities = this.props && this.props.location && this.props.location.state && this.props.location.state.all_specialities
            const _prevPath = this.props && this.props.location && this.props.location.state && this.props.location.state.prevPath
            //const _internalusers = this.props && this.props.location && this.props.location.state && this.props.location.state.internalusers
            //const _stages = this.props && this.props.location && this.props.location.state && this.props.location.state.stages
            const _enquiryProps = this.props && this.props.location && this.props.location.state && this.props.location.state.selected_enquiry
            let patient_id;
            if (currentUser.usertype === Role.localprovider.name) {
                const _patientProps = get_patient_obj(_enquiryProps)
                patient_id = _patientProps && _patientProps._id
            } else if (currentUser.usertype === Role.admin.name || currentUser.usertype === Role.internaluser.name) {
                patient_id = currentUser._id
            } else if (currentUser.usertype === Role.patient.name) {
                patient_id = currentUser.usertype_id
            }
            const data = {
                enquiry_id: this.state && this.state.enquiry_id,
                patient_id: patient_id
            }

            let response = await EnquiryListService.getEnquiryRelatedDetails(data)
            if (response && response.enquiryData) {
                let _stages, _internalusers, _all_specialities;
                if (response && response.stages) {
                    _stages = response.stages
                }
                if (response && response.specialities) {
                    _all_specialities = response.specialities
                }
                if (response && response.internalusers) {
                    _internalusers = response.internalusers
                }

                const _enquiry = response.enquiryData && response.enquiryData.enquiryInfo
                const _selected_enquiry = response.enquiryData && response.enquiryData
                const _provider_obj = get_provider_obj(_selected_enquiry)
                const _provider_user_obj = get_provider_user_obj(_provider_obj)
                this.setState({
                    loading: false,
                    selected_enquiry: _enquiry,
                    stages: _stages,
                    all_specialities: _all_specialities,
                    internalusers: _internalusers,
                    prevPath: _prevPath,
                    patient_obj: get_patient_obj(_selected_enquiry),
                    patient_user_obj: get_user_obj(_selected_enquiry),
                    provider_obj: _provider_obj,
                    provider_user_obj: _provider_user_obj,
                    currentUser: currentUser,
                    fileList: _enquiry.documents || []
                })
                if (currentUser.usertype === Role.admin.name || currentUser.usertype === Role.internaluser.name) {
                    this.getSuggestedProviders(_enquiry)
                }
                if (this.formRef && this.formRef.current) {
                    this.formRef.current.setFieldsValue(this.getInitalValues(_enquiry))
                }
            } else {
                this.setState({ loading: false })
                message.error(response.data.message);
            }



        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }

    }

    componentDidMount() {
        //this.getValsfromProps()
        this.getvalsfromAPI()

    }
    onFinish = async (values) => {
        values.preventDefault()

        if (this.state.fileList.length === 0) {
            message.error('Please upload Past Reports')
            return
        }
        let formData = new FormData();
        formData.append("speciality_id", this.state.speciality || "");
        formData.append("enquiry_message", this.state.enquiry_message || "");
        formData.append("patient_id", this.state.patient_obj && this.state.patient_obj._id);
        formData.append("enquiry_id", this.state.selected_enquiry && this.state.selected_enquiry._id);
        // formData.append("file_count", this.state.fileList && this.state.fileList.length);
        for (let i = 0; i < this.state.fileList.length; i++) {
            const file = this.state.fileList[i];
            if (file['originFileObj']) {
                formData.append('file', file['originFileObj']);
            }
        }

        if (this.state.currentUser) {
            if (this.state.currentUser.usertype === Role.admin.name || this.state.currentUser.usertype === Role.internaluser.name) {
                if (values && values.stage_id) {
                    formData.append("stage_id", values.stage_id || "");
                }
            }
            if (this.state.currentUser.usertype === Role.admin.name) {
                if (values && values.assignee_id) {
                    formData.append("assignee_id", values.assignee_id || "");
                }
            }
        }

        try {
            this.setState({ loading: true });
            let response = await EnquiryListService.updateEnquiry(formData);

            if (response.data.status === "success") {
                message.success(response.data.message);
                this.setState({ loading: false })
                refreshPage()
            } else {
                this.setState({ loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }
    onFinishFailed = values => {
        values.preventDefault()
        refreshPage()
    }

    handleOnSelectStage = (value, event) => {
        this.setState({ isAssigneeRequired: handleOnSelectStage(value, event) })
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    onSearch = (event) => {
        if (event && event.target && event.target.value) {
            let searchParams = {
                "speciality_id": this.state.selected_enquiry && this.state.selected_enquiry.speciality && this.state.selected_enquiry.speciality.id,
                "searchkey_value": event.target.value
            }
            this.setState({ searchParams: searchParams })
            this.dispatchProviderSearch()
        } else {
            this.getSuggestedProviders(this.state.selected_enquiry)
        }
    }

    dispatchProviderSearch = async () => {
        try {
            let response = await EnquiryListService.searchProviders(this.state.searchParams);
            if (response && response.data && response.data.status === "success") {
                this.setState(({
                    provider_loading: false,
                    suggested_providers: response.data.data
                }));
            } else {
                this.setState({ suggested_providers: [], provider_loading: false })
            }
        } catch (error) {
            errorMessage(error)
        }
    }

    render() {
        // const onClick = (item) => {
        //     this.getSchedulebyProviders(item)
        //     this.setState({ visible: true, chosen_provider: item });
        // }
        // const { previewVisible, previewImage, confirmationModal } = this.state;
        // const showUploadList = ({
        //     showPreviewIcon: true,
        // });
        // const uploadButton = (
        //     <div>
        //         <div className="ant-upload-text">Add Files</div>
        //     </div>
        // );
        return (

            <React.Fragment>
                {/* <AfterLoginLayout> */}
                <div className="container-fluid">
                    {/* <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={this.state.prevPath}>Enquiries</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{(this.state.selected_enquiry && this.state.selected_enquiry.enquiryCode)}</Breadcrumb.Item>
                    </Breadcrumb> */}
                    <br />

                    <Form
                        style={{ paddingLeft: "0%", maxWidth: "100%" }}
                        ref={this.formRef}
                        {...this.layout}
                        onSubmit={this.onFinish}
                    >

                        <Form.Group controlId="addPatient">
                            <h5 style={{ fontFamily: "manrope", fontWeight: "700" }}>
                                Is this for you or someone else?
                            </h5>
                            <div style={{ flexDirection: "row", display: "flex" }}>
                                <div style={{ flex: "1", textAlign: "center" }}>
                                    <img src={personicon} alt="person" />

                                    <h6 style={{ fontWeight: "600" }}>{this.state.patient_user_obj.displayname}</h6>
                                    <h6 style={{ fontWeight: "400" }}>(You)</h6>
                                </div>
                                <div style={{ flex: "4" }}>
                                    <img src={addperson} alt="add person" />
                                    <h6>Add Patient</h6>
                                </div>
                            </div>
                        </Form.Group>

                        {this.state.all_specialities.length > 0 ? null : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <Spinner animation="border" variant="primary" /></div>}

                        <Col lg={7} md={7} sm={12}>
                            <Row>
                                <div style={{ marginTop: "40px", fontFamily: "manrope" }}>
                                    {" "}
                                    <h5>
                                        Which type of doctor did you see for this problem?
                                        <span style={{ color: "red" }}>*</span>
                                    </h5>


                                    <Form.Group className="mb-3" controlId="formBasicSpeciality">

                                        <Form.Control as={"select"}
                                            disabled={(this.state && this.state.selected_enquiry && !this.state.selected_enquiry.provider_id) ? false : true}
                                            name="speciality"
                                            onChange={this.handleChange}


                                        >



                                            {
                                                this.state.all_specialities?.map((item) => {
                                                    const { name, _id } = item;
                                                    return <option value={_id} key={_id}>{name}</option>

                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>


                                </div>
                            </Row>
                            <Form.Group>
                                <h5 style={{ fontFamily: "manrope", marginLeft: "-10px" }}>
                                    What is your health concern?
                                    <span style={{ color: "red" }}>*</span>
                                </h5>
                                <h6 style={{ fontWeight: "400" }}>
                                    your data will be secured with us
                                </h6>
                                <Row>


                                    {/* <Form.Label>Describe Your Problem</Form.Label> */}
                                    <Form.Control type="text"


                                        name="enquiry_message"
                                        as="textarea"
                                        rows={2}

                                        onChange={this.handleChange}
                                        defaultValue={this.state.enquiry_message}
                                    />



                                </Row>
                            </Form.Group>
                        </Col>





                        <div style={{ position: "relative", marginTop: "30px" }}>
                            <h5 style={{ fontFamily: "manrope" }}>
                                Add prescriptions/past records
                                <span style={{ color: "red" }}>*</span>
                                <h6 style={{ fontWeight: "400" }}>(max.5 Files)</h6>
                            </h5>
                            <Row style={{ width: "50%" }}>

                                {this.state.fileList.map((x) => {
                                    return <Col lg={4} md={4} sm={12} xs={12} className="p-3">
                                        {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => (this.documentViewer(x?.url))} ></img>}
                                        {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                            <div className='image'>
                                                <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                            </div>
                                        }
                                    </Col>
                                })}
                                <Col lg={4} md={4} sm={12} xs={12} className="p-3">
                                    <span class="btn btn-default btn-file" style={{ padding: '38px 26px', border: '1px solid black', backgroundColor: 'white' }}>
                                        AddFiles
                                        <Form.Control type="file" accept=".pdf, .jpg,.png,.jpeg,.doc,.docx"
                                            required label={'Past Reports'} name="prescription"
                                            placeholder="Enter email" onChange={e => this.pushImage(e)} style={{ padding: '30px' }} size="60" />
                                    </span>
                                </Col>


                            </Row>
                        </div>
                        {/* </Row> */}

                        <div style={{ marginTop: "50px" }}>
                            <Form.Group
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    variant="primary"
                                    type="submit"
                                    style={{ width: "45%", fontFamily: "manrope" }}
                                    onClick={this.onFinish}
                                >
                                    Submit
                                </Button>

                                <Button
                                    variant="secondary"
                                    style={{ width: "45%" }}
                                    onClick={this.onFinishFailed}

                                >
                                    Cancel
                                </Button>
                            </Form.Group>
                        </div>

                        <div>
                            <img
                                alt="example"
                                style={{
                                    position: "absolute",
                                    width: "350px",
                                    height: "500px",
                                    left: "570px",
                                    top: "20px",
                                    opacity: "1",
                                    borderRadius: "20px",
                                }}
                                src={opinionbg}
                            />
                        </div>
                    </Form>
                </div>
                <Modal show={this.state.showImageViewer}
                    size="lg"

                    centered
                    backdrop="static" onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>
                        <Button style={{ marginTop: "2rem" }} onClick={this.handleOkModalRemove}>delete</Button>
                    </Modal.Body>
                </Modal>
                {/* </AfterLoginLayout> */}
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleEnquiryDetails: () => dispatch(getSingleEnquiryDetails())
    }
}
const mapStateToProps = state => {
    return {
        enquiryStore: state.enquiryList,
    }
}


//export default withRouter(EnquiryDetailsEditor)
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EnquiryDetailsEditor);
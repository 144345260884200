import React from 'react';
// import "../../provider/profile/providerProfile.style.css"
import { message } from 'antd';
import { errorMessage } from '../../../utils/ServiceHandler';
import AppointmentService from '../../../services/patient/Appointment.Service';
import BootstrapTable from 'react-bootstrap-table-next';
import { parseAppointments, get_schedule_details_obj, get_room_details_obj } from './Utils';
import { Dropdown, Button, Badge, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import { get_provider_obj, formateName, get_formated_patient_ist_time, get_formated_patient_ist_date, new_get_formated_patient_ist_date } from '../enquiry/EnquiryUtils';
import { FaVideo } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { authenticationService } from '../../general/login/login.service';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { get_provider_user_obj } from '../../patient/appointment/Utils';
import { get_patient_obj, get_patient_user_obj, get_speciality_obj } from '../enquiry/EnquiryUtils'
import '../appointment/Appointment.css'
import { FaTimes } from "react-icons/fa";
import OpinionRequest from '../dashboard/OpinionRequest';
import { RiCalendar2Line } from "react-icons/ri";
import Role from '../../../constants/role';
import moment from 'moment';

// import moment from 'moment';



const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button className='threedotbutton' variant='link'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </Button>
));
export class AppointmentTableView extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props && this.props.patient_id,
      islocalProviderView: (this.props && this.props.islocalProviderView) || false,
      loading: false,
      consultations: [],
      past: [],
      upcoming: [],
      previewVisible: false,
      previewImage: "",
      fileList: [],
      currentUser: {},
      viewer_visible: false,
      reschedule_visible: false,
      edit_visible: false,
      filterItems: ['upcoming'],
      selected_appointment: {},
      showFilterList: [],
      currentItem: {},
      speciality_obj: {}

    }
  }
  getVals() {
    const currentItem = this.state.selected_appointment
    const currentUser = authenticationService.currentUserValue;
    //const all_specialities = this.props && this.props.all_specialities
    const patient_obj = get_patient_obj(currentItem)
    const user_obj = get_patient_user_obj(currentItem)
    const provider_obj = get_provider_obj(currentItem)
    const provider_user_obj = get_provider_user_obj(currentItem)
    const speciality_obj = get_speciality_obj(this.state.selected_appointment.consultationInfo)

    this.setState({
      currentItem: currentItem,
      consultationInfo: this.state.selected_appointment.consultationInfo,
      user_obj: user_obj,
      patient_obj: patient_obj,
      provider_obj: provider_obj,
      provider_user_obj: provider_user_obj,
      currentUser: currentUser,
      speciality_obj: speciality_obj
      //all_specialities: all_specialities
    })
  }
  navigatetoVideoCall = (record) => {
    const room_details_obj = get_room_details_obj(this.state && this.state.selected_appointment.consultationInfo)
    if (room_details_obj) {
      this.props && this.props.history && this.props.history.push({
        pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
        state: {
          currentItem: this.state.currentItem,
          consultationInfo: this.state.selected_appointment.consultationInfo,
          user_obj: this.state.user_obj,
          patient_obj: this.state.patient_obj,
          provider_obj: this.state.provider_obj,
          provider_user_obj: this.state.provider_user_obj,
          currentUser: this.state.currentUser,
        }
      });
    } else {
      message.error("Issue while initiating the Video call. Room details not available")
    }
  };
  handleTableFilter = async (data, filterType) => {
    await this.setState({ showFilterList: [] });
    await this.setState({
      filterItems: await Array.from(new Set([...this.state.filterItems, data]))
    })
    if (this.state.filterItems.includes('upcoming')) {
      await this.setState({ showFilterList: await this.state.upcoming });
    }
    if (this.state.filterItems.includes('past')) {
      await this.setState({ showFilterList: await [...this.state.showFilterList, ...this.state.past] })
    }
    // this.renderFilteredList(data, filterType);
  }



  handleRemoveFilter = async (index) => {
    await this.state.filterItems.splice(index, 1);
    await this.setState({ showFilterList: [] });
    if (this.state.filterItems.includes('upcoming')) {
      await this.setState({ showFilterList: await this.state.upcoming });
    }
    if (this.state.filterItems.includes('past')) {
      await this.setState({ showFilterList: await [...this.state.past] })
    }
    if (this.state.filterItems.length === 0) {
      await this.setState({ showFilterList: await [...this.state.upcoming, ...this.state.past] })
    }
    // this.renderFilteredList();
  }

  // renderFilteredList(data) {
  //   this.setState({
  //     showList: data
  //   })
  // }






  view_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record, viewer_visible: true });
      }
    }, 1);
  }

  edit_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record });
        const id = record && record.consultationInfo && record.consultationInfo.id
        if (!id) {
          message.error("Some error occured. Not able to edit at the moment")
          return
        }
        this.props.history.push({
          pathname: "/patient/appointment/" + id,

          state: {
            selected_appointment: record,
            currentUser: this.state.currentUser,
            prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }
  reschedule_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record, reschedule_visible: true });

      }
    }, 1);
  }
  onClose = () => {
    this.setState({
      viewer_visible: false,
      reschedule_visible: false
    });
  };

  handleResponse = async (response) => {
    if (response.data.status === "success") {
      if (response && response.data && response.data.data) {
        if (response.data.data.length > 0) {
          const _consultation = parseAppointments(response.data.data)

          const orderDates = () => {
            const new_objs = []
            const array_data = []
            const filter_dates = _consultation && _consultation.upcoming.filter((item, index) => {
              let newItem
              if (!new_objs.includes(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())) {
                new_objs.push(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())
                let obj = {}
                obj.norm_date = moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()
                obj.conv_date = new_get_formated_patient_ist_date(item.consultationInfo.scheduleDetailsInfo.date, item.consultationInfo.scheduleDetailsInfo.slot, item.consultationInfo.timename)
                array_data.push(obj)
                newItem = item
              }
              return newItem
            })
            new_objs.sort((a, b) => moment(a) - moment(b))
            array_data.sort((a, b) => moment(a.conv_date) - moment(b.conv_date))
            const new_sltz = []
            array_data.forEach((item) => {
              filter_dates.forEach(objitm => {
                if (item.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()) {
                  const indx = array_data.findIndex(searchItm => searchItm.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())

                  new_sltz.splice(indx, 0, { ...objitm, shown_status: 'upcoming' })
                }
              })
            })
            return new_sltz
          }

          const order_past_records = () => {
            const new_objs = []
            const array_data = []
            const filter_dates = _consultation && _consultation.past.filter((item, index) => {
              let newItem
              if (!new_objs.includes(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())) {
                new_objs.push(moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())
                let obj = {}
                obj.norm_date = moment(item.consultationInfo.scheduleDetailsInfo.date + ' ' + item.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()
                obj.conv_date = new_get_formated_patient_ist_date(item.consultationInfo.scheduleDetailsInfo.date, item.consultationInfo.scheduleDetailsInfo.slot, item.consultationInfo.timename)
                array_data.push(obj)
                newItem = item
              }
              return newItem
            })
            new_objs.sort((a, b) => moment(b) - moment(a))
            array_data.sort((a, b) => moment(b.conv_date) - moment(a.conv_date))
            const new_sltz = []
            array_data.forEach((item) => {
              filter_dates.forEach(objitm => {
                if (item.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()) {
                  const indx = array_data.findIndex(searchItm => searchItm.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date + ' ' + objitm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format())
                  new_sltz.splice(indx, 0, { ...objitm, shown_status: 'closed' })
                }
              })
            })
            return new_sltz
          }

          this.setState({

            upcoming: _consultation && orderDates(),
            past: _consultation && order_past_records(),
            loading: false
          });
          this.setState({
            showFilterList: this.state.upcoming
          })
        }
      }
    } else {
      this.setState({ loading: false })
      const currentUser = authenticationService.currentUserValue;
      if (currentUser.usertype !== Role.localprovider.name)
        message.error(response.data.message);
    }
  }


  getAppointments = async () => {
    try {
      this.setState({ loading: true });
      //const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true })

      let response = await AppointmentService.getPatientAppointments(this.state && this.state.patient_id);
      this.handleResponse(response)

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  getLocalPatientAppointments = async () => {
    try {
      this.setState({ loading: true });
      const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true })

      let response = await AppointmentService.getLocalPatientAppointments(currentUser._id);
      this.handleResponse(response)
    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }



  showModal = () => {
    this.setState({
      modalVisible: true,
      fileList: []
    });
  };

  componentDidMount() {
    this.getVals()
    if (this.props && this.props.patient_id) {
      this.getAppointments()
    } else if (this.props && this.props.islocalProviderView) {
      this.getLocalPatientAppointments()
    }
  }
  handleShow = () => { this.setState({ openModal: true }) };
  handleClose = () => { this.setState({ openModal: false }) };


  render() {

    const currentUser = authenticationService.currentUserValue;
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );

    const options = {
      sizePerPage: 10,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };
    const columns = [
      {
        text: 'PATIENT',
        dataField: 'patientUserInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              if (currentUser.usertype === Role.patient.name)
                return formateName(record.patientUserInfo ? record.patientUserInfo.firstname : '')
              if (currentUser.usertype === Role.localprovider.name)
                return formateName(record.patientInfo ? record.patientInfo.firstname : '')

            })
              ()}
          </>
        )
      },
      {
        text: 'DATE',
        dataField: 'scheduleDate',
        formatter: (text, record) => (
          <>
            {(() => {
              let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)
              // const verifyDateFormat = moment(schedule_obj.slot)
              // let attDateAndTime = ""
              // if (verifyDateFormat.isValid()) {
              //   attDateAndTime = schedule_obj.slot
              // } else {
              //   attDateAndTime = moment(schedule_obj.date + ' ' + schedule_obj.slot, 'YYYY-MM-DD HH:mm A').format()
              // }

              let time_format = get_formated_patient_ist_date(schedule_obj.date, schedule_obj.slot, record.consultationInfo.timename)
              return (<React.Fragment>
                {['topleft'].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        Tooltip on <strong>{placement}</strong>.
                      </Tooltip>
                    }
                  >
                    <span>{time_format}</span>
                  </OverlayTrigger>
                ))}
              </React.Fragment>
              )
            })()}
          </>
        ),
      },
      {
        text: 'TIME',
        dataField: 'scheduleDate',
        formatter: (text, record) => (
          <>

            {(() => {
              let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)
              // const verifyDateFormat = moment(schedule_obj.slot)

              // let attDateAndTime = ""
              // if (verifyDateFormat.isValid()) {
              //   attDateAndTime = schedule_obj.slot
              // } else {
              //   attDateAndTime = moment(schedule_obj.date + ' ' + schedule_obj.slot, 'YYYY-MM-DD HH:mm A').format()
              // }
              let time_format = get_formated_patient_ist_time(schedule_obj.date, schedule_obj.slot, record.consultationInfo.timename)
              // let relative_hrs = get_relative_time(schedule_obj.slot)
              return (<React.Fragment>
                {['topleft'].map((placement, index) => (
                  <OverlayTrigger
                    key={index}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        Tooltip on <strong>{placement}</strong>.
                      </Tooltip>
                    }
                  >
                    <span>{time_format}</span>
                  </OverlayTrigger>
                ))}
              </React.Fragment>
              )
            })()}
          </>
        ),
      },


      {
        text: 'DOCTOR',
        dataField: 'providerUserInfo',
        formatter: (text, record) => (
          <>
            {(() => {
              return (
                //<div className='profileicon'><img
                //   className="rounded-circle"
                //   src={IMG01}
                //   width="31"
                //   alt=''
                // />

                (get_provider_obj(record) && formateName(get_provider_obj(record)?.userInfo?.firstname || "-"))

              )
            })()}
          </>
        ),
      },

      {
        text: 'STATUS',
        dataField: 'shown_status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.shown_status === 'upcoming')
                return (<React.Fragment>
                  <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Upcoming </Badge>
                </React.Fragment>
                )
              if (record.shown_status === 'closed')
                return (<React.Fragment>
                  <Badge style={{ backgroundColor: '#C7C7C7', color: '#858585', fontSize: '14px' }}>Closed </Badge>
                </React.Fragment>
                )
            })()}
          </>
        ),

      },
      {
        text: ' ',
        dataField: '',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.shown_status === 'upcoming') {
                return (<React.Fragment>
                  <div className='actionHolder' onMouseOver={() => this.view_record(record)}>
                    <div className="joinBtn">
                      < FaVideo className='text-info mx-2' />
                      <Button style={{ alignItems: 'center' }} variant='link' onClick={this.navigatetoVideoCall.bind(this, record)}> Join </Button>
                    </div>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              <Link to={{ pathname: AUTHROUTE.PATIENT.APPOINTMENT_View, state: { selected_appointment: record } }}>View
                              </Link>
                            </Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }
              else {
                return (<React.Fragment>

                  <div className='actionHolder'><p style={{ width: "70px" }}>      _     </p>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              <Link to={{ pathname: AUTHROUTE.PATIENT.APPOINTMENT_View, state: { selected_appointment: record } }}>View
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }
            })()}
          </>
        ),
        // key: ['patientInfo', '_id'],
      },
      // {
      //   text: ' ',
      //   dataField: '',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record.shown_status === 'upcoming')
      //           return (<React.Fragment>
      //             <div className="joinHolder">
      //               <div className="moreBtn">
      //                 <Dropdown>
      //                   <Dropdown.Toggle as={CustomToggle} />
      //                   <Dropdown.Menu title="">
      //                     <Dropdown.Item>
      //                       <Link to={{ pathname: AUTHROUTE.PATIENT.APPOINTMENT_View, query: { selected_appointment: record } }}>View
      //                       </Link>
      //                     </Dropdown.Item>

      //                   </Dropdown.Menu>
      //                 </Dropdown>
      //               </div>
      //             </div>
      //           </React.Fragment>
      //           )
      //       })()}
      //     </>
      //   ),
      //   // key: ['patientInfo', '_id'],
      // },

    ]



    return (

      <React.Fragment>
        {this.state.showFilterList.length > 0 ?
          <div>
            <Dropdown className="float-right">
              <Dropdown.Toggle variant="primary" id="filter-dropdown" style={{ marginright: '40%', marginTop: '10px' }}>
                Filter
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleTableFilter('upcoming', 'addFilter')}>Upcoming</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleTableFilter('past', 'addFilter')}>Closed</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div>
              {this.state.filterItems?.map((data, index) => (

                <Button style={{ marginTop: "15px" }} key={index} className='filtertag' variant="outline-info" onClick={() => this.handleRemoveFilter(index)}>{data === 'past' ? 'closed' : data} <span><FaTimes /></span> </Button>

              ))}
            </div>
            <div>
              <BootstrapTable
                keyField='_id'
                data={this.state.showFilterList && this.state.showFilterList}
                hover={false}
                columns={columns}
                bordered={false}
                pagination={paginationFactory(options)}
              /></div>
          </div>
          :
          <div>
            {!this.state.loading &&
              <div style={{ marginTop: "15px" }} className="card">
                <div className="card-body text-center">
                  <RiCalendar2Line size={60} className='text-secondary' />
                  <h6>No appointments</h6>
                  <small><p className='text-secondary'>We are finding the right doctor to provide you proper care. You will be notified within 24 hours.
                  </p></small>
                </div>
                {this.state.openModal && <OpinionRequest displayname={this.state.currentUser && this.state.currentUser.displayname} patient_id={this.state.currentUser && this.state.currentUser.usertype_id} openModal={this.state.openModal} setOpenModal={this.handleClose} />}

              </div>
            }
          </div>
        }
        {this.state.loading &&
          <div style={{ marginTop: "12rem", display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        }

      </React.Fragment>)
  }
}

export default AppointmentTableView
import React, { Component } from 'react'
import { get_formated_date_only, get_formated_time_only } from '../enquiry/EnquiryUtils';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../enquiry/enquiry.style.css'
import { Link } from 'react-router-dom'


export class AppointmentCancel extends Component {
    constructor(props) {
        super(props);
        console.log(props)
    }

    render() {
        return (
            <React.Fragment>
                <Container fluid className='pickslotlayout' style={{ paddingTop: '9%', paddingBottom: '5%', paddingLeft: '8%', paddingRight: '8%' }}>
                    <Row style={{ "width": "100%" }}>
                        <Col lg={12} md={12} sm={12}>
                            <div>

                            </div>

                            <h5 className='text-left' style={{ color: 'blue', paddingRight: '25px', paddingTop: '25px', paddingBottom: '25px' }}><b>SAD TO SEE YOU</b></h5>
                            <h4 className='text-left' style={{ paddingRight: '25px', paddingBottom: '25px' }}><b>Your cancellation request has been received</b></h4>
                            <p style={{ fontSize: '20px !important', paddingBottom: '70px' }}>we have initiated the refund process and it may take <b>2-5 business days</b></p>
                        </Col>
                    </Row>
                    <Row lg={12} md={12} sm={12}>
                        <Col lg={8} md={8} sm={12}>
                            <Card style={{ backgroundColor: '#EFF3F4', height: 'auto' }}>
                                <Row style={{ height: 'auto' }}>
                                    <Col lg={3} md={3} sm={12}>
                                        <h6>PATIENT</h6>
                                        {this.props?.location?.query?.patientInfo?.displayname}
                                    </Col>
                                    <Col lg={3} md={3} sm={12}>
                                        <h6>BOOKING NUMBER</h6>
                                        {this.props?.location?.query?.consultationInfo?.orderCode}
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <h6>APPOINTMENT DATE & TIME</h6>
                                        {get_formated_date_only(this.props?.location?.query?.consultationInfo?.scheduleDetailsInfo?.slot)}<span style={{ paddingRight: '10px' }}></span>
                                        {get_formated_time_only(this.props?.location?.query?.consultationInfo?.scheduleDetailsInfo?.slot)}
                                    </Col>
                                    {/* <Col lg={3} md={3} sm={12} pt-3>

                                        </Col> */}
                                </Row>

                            </Card>
                        </Col>
                        <Col lg={8} md={8} sm={12}>
                            <Link to="/patient-dashboard" className='col-lg-10 text-left'><Button className="btn btn-primary ps-1 m-0">Go to dashboard</Button></Link>
                        </Col>
                    </Row>
                </Container >
            </React.Fragment>
        )
    }

}


export default AppointmentCancel;
import React from 'react';
//import "../../provider/profile/providerProfile.style.css"
import { message } from 'antd';
import { errorMessage } from '../../../utils/ServiceHandler';
import AppointmentService from '../../../services/patient/Appointment.Service';
import { parseAppointments } from '../appointment/Utils';
import { get_formated_patient_ist_date,get_formated_patient_ist_time, new_get_formated_patient_ist_date} from '../enquiry/EnquiryUtils';
import { authenticationService } from '../../general/login/login.service';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaUserAlt } from "react-icons/fa";
import { BsFillAlarmFill } from "react-icons/bs";
import { get_provider_user_obj } from '../../patient/appointment/Utils';
import { get_provider_obj, get_patient_obj, get_patient_user_obj, get_speciality_obj, formateName } from '../../patient/enquiry/EnquiryUtils';
import { AUTHROUTE } from '../../../constants/RouterPath';
import { get_room_details_obj, get_schedule_details_obj } from '../../patient/appointment/Utils';
import moment from 'moment';

export class NextAppointments extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props && this.props.patient_id,
      islocalProviderView: (this.props && this.props.islocalProviderView) || false,
      loading: false,
      upcoming: [],
      previewVisible: false,
      currentUser: {},
      selected_appointment: {},
      showList: [],
      consultationInfo: {},
      patient_obj: {},
      user_obj: {},
      provider_obj: {},
      provider_user_obj: {},
      all_specialities: [],
      currentItem: {},
      speciality_obj: {}
    }
  }

  handleImagePreviewCancel = () => {
    this.setState({ previewVisible: false });
  };


  handleResponse = (response) => {
    if (response.data.status === "success") {
      if (response && response.data && response.data.data) {
        if (response.data.data.length > 0) {
          const _consultation = parseAppointments(response.data.data)

          const new_objs = []
          const array_data = []
          const filter_dates = _consultation && _consultation.upcoming.filter((item,index) => {
            let newItem
            if(!new_objs.includes(moment(item.consultationInfo.scheduleDetailsInfo.date+ ' '+item.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format())){
              new_objs.push(moment(item.consultationInfo.scheduleDetailsInfo.date+ ' '+item.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format())
              let obj={}
              obj.norm_date=moment(item.consultationInfo.scheduleDetailsInfo.date+ ' '+item.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format()
              obj.conv_date=new_get_formated_patient_ist_date(item.consultationInfo.scheduleDetailsInfo.date,item.consultationInfo.scheduleDetailsInfo.slot,item.consultationInfo.timename)
              array_data.push(obj)
              newItem = item
            }
            return newItem
          })
          new_objs.sort((a,b) => moment(a) - moment(b))
          array_data.sort((a,b) => moment(a.conv_date)-moment(b.conv_date))
          const new_sltz = []
          array_data.forEach((item) => {
            filter_dates.forEach(objitm => {
             if(item.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date+ ' '+objitm.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format()){
              const indx = array_data.findIndex(searchItm => searchItm.norm_date === moment(objitm.consultationInfo.scheduleDetailsInfo.date+ ' '+objitm.consultationInfo.scheduleDetailsInfo.slot,"YYYY-MM-DD HH:mm A").format())
              new_sltz.splice(indx,0,{...objitm,shown_status:'upcoming'})
             }
            })
          })

          this.setState({
            upcoming: _consultation && new_sltz && new_sltz,
            past: _consultation && _consultation.past,
            loading: false
          })
        }
      }
    } else {
      this.setState({ loading: false })
      message.error(response.data.message);
    }
  }

  view_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record, viewer_visible: true });
      }
    }, 1);
  }

  edit_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record });
        const id = record && record.consultationInfo && record.consultationInfo.id
        if (!id) {
          message.error("Some error occured. Not able to edit at the moment")
          return
        }
        this.props.history.push({
          pathname: "/patient/appointment/" + id,

          state: {
            selected_appointment: record,
            currentUser: this.state.currentUser,
            prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }
  reschedule_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record, reschedule_visible: true });
      }
    }, 1);
  }

  getAppointments = async () => {
    try {
      this.setState({ loading: true });
      this.setState({ loading: true })

      let response = await AppointmentService.getPatientAppointments(this.state && this.state.patient_id);
      this.handleResponse(response)

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  getLocalPatientAppointments = async () => {
    try {
      this.setState({ loading: true });
      const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true })

      let response = await AppointmentService.getLocalPatientAppointments(currentUser._id);
      this.handleResponse(response)
    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }


  componentDidMount() {
    this.getVals()
    if (this.props && this.props.patient_id) {
      this.getAppointments()
    } else if (this.props && this.props.islocalProviderView) {
      this.getLocalPatientAppointments()
    }
  }

  getVals() {
    const currentItem = this.state.selected_appointment
    const currentUser = authenticationService.currentUserValue;
    //const all_specialities = this.props && this.props.all_specialities
    const patient_obj = get_patient_obj(currentItem)
    const user_obj = get_patient_user_obj(currentItem)
    const provider_obj = get_provider_obj(currentItem)
    const provider_user_obj = get_provider_user_obj(currentItem)
    const speciality_obj = get_speciality_obj(this.state.selected_appointment.consultationInfo)

    this.setState({
      currentItem: currentItem,
      consultationInfo: this.state.selected_appointment.consultationInfo,
      user_obj: user_obj,
      patient_obj: patient_obj,
      provider_obj: provider_obj,
      provider_user_obj: provider_user_obj,
      currentUser: currentUser,
      speciality_obj: speciality_obj
      //all_specialities: all_specialities
    })
  }

  navigatetoVideoCall = () => {
    const room_details_obj = get_room_details_obj(this.state && this.state.selected_appointment.consultationInfo)
    if (room_details_obj) {
      this.props && this.props.history && this.props.history.push({
        pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
        state: {
          currentItem: this.state.currentItem,
          consultationInfo: this.state.selected_appointment.consultationInfo,
          user_obj: this.state.user_obj,
          patient_obj: this.state.patient_obj,
          provider_obj: this.state.provider_obj,
          provider_user_obj: this.state.provider_user_obj,
          currentUser: this.state.currentUser,
        }
      });
    } else {
      message.error("Issue while initiating the Video call. Room details not available")
    }
  };

  render() {

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    const new_sort = this.state.upcoming && this.state.upcoming.slice(0, 10).map((a, b) => {
      return moment(a.consultationInfo.scheduleDetailsInfo.date + ' ' + a.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format()
    }).sort((a, b) => new Date(a) - new Date(b))

    const newArray = []
    this.state.upcoming && this.state.upcoming && [...new Set(new_sort)].map((item, index) => this.state.upcoming.slice(0, 10).map(itm => {
      let newItm
      if (moment(itm.consultationInfo.scheduleDetailsInfo.date + ' ' + itm.consultationInfo.scheduleDetailsInfo.slot, "YYYY-MM-DD HH:mm A").format() === item) {
        newArray.splice(index, 0, itm)
        newItm = itm
      }
      return newItm
    })
    )

    return (
      <React.Fragment>
        <div>
          <Card>
            <h6 className='font-weight-bold'>Next Appointment</h6>
            {newArray.map((data, id) =>
              <div key={id} onMouseOver={() => this.view_record(data)}>
                <div className="media mt-3">
                  <span className="media-left">
                    <FaUserAlt className='mr-3  text-light rounded-circle' size={28} />
                  </span>
                  <div className="media-body">
                    <h6 className='font-weight-normal'>{formateName(data?.providerUserInfo?.firstname)}
                      <small><p className='font-weight-light'>{data?.consultationInfo?.speciality?.name}</p></small></h6>
                  </div>
                </div>

                <div className="media mt-2">
                  <span className="media-left">
                    <FaUserAlt className='mt-1 mr-3  text-light rounded-circle' size={28} />
                  </span>
                  <div className="media-body">
                    <div className='font-weight-light'><small>Patient</small>
                      <small><h6 className='ml-0 font-weight-normal'>{formateName(data?.patientUserInfo?.firstname)}</h6></small>
                    </div>
                  </div>
                </div>

                <div className="media mt-2">
                  <span className="media-left">
                    <BsFillAlarmFill className='mt-1  mr-3 text-light rounded-circle bg-info' size={28} />
                  </span>
                  <div className="media-body">
                    <Row>
                      <Col>
                        <div className='font-weight-normal'>
                          {days[moment(get_formated_patient_ist_date(get_schedule_details_obj(data && data.consultationInfo).date, get_schedule_details_obj(data && data.consultationInfo).slot, data && data.consultationInfo.timename)).day()]}
                          <h6 className=' font-weight-light small'>{get_formated_patient_ist_date(get_schedule_details_obj(data && data.consultationInfo).date, get_schedule_details_obj(data && data.consultationInfo).slot, data && data.consultationInfo.timename)}
                          </h6></div>
                      </Col>
                      <Col>

                        <div className='font-weight-normal small  mt-2'> {get_formated_patient_ist_time(get_schedule_details_obj(data && data.consultationInfo).date, get_schedule_details_obj(data && data.consultationInfo).slot, data && data.consultationInfo.timename)}</div></Col>
                    </Row>
                  </div>
                </div>
                <Button onClick={this.navigatetoVideoCall.bind(this, data)} variant="outline-info" style={{ 'border': '1px solid #fff' }} className='text-white mt-3 px-4 mx-2 py-1'>Join now</Button>
              </div>
            ).slice(0,1)}
          </Card>
        </div>
      </React.Fragment >)
  }
}

export default NextAppointments
import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import './Section5.css'

export class Section5 extends Component {
    render() {
        return (
            <div className='bg-white py-5 mt-6 section_5'>
                <Card className="bg-dark text-white">
                    <Card.Img src="/ward.png" alt="Card image" className='card_Img1' />
                    <div class="card-img-overlay figcaption container" style={{ background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3))` }}>
                        <h6>OUR INTIATIVES</h6>
                        <p>With your help, consultation <br />fees have provided life- <br />saving treatment to over 13.8 <br /> million people And our work <br />isn’t done yet.</p>
                    </div>
                </Card>
            </div>
        )
    }
}

export default Section5
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css";
import './providerScheduler.css'
import providerSchedulerService from "../../../services/provider/Scheduler.Service";
import moment from "moment";
import DatePicker from 'react-datepicker'
import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from '@fortawesome/free-solid-svg-icons'


const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
const numOfWeeks = ["1week", "2week", "3week"]

export default function NewSched({ timeZones, updateScheduler, proTimzo, allSlotsData ,usertype}) {
    const tomarrowDate = moment().add(1, 'days')
    const today = moment().format('YYYY/MM/DD')

    const timezoneProfId = timeZones.length !==0 && timeZones.filter(item => item._id === proTimzo)
    const [selectedDays, setSelectedDays] = useState([])
    const [selectedWeek, setSelectedWeek] = useState("")
    const [selectedTimezone] = useState(timeZones && proTimzo && timezoneProfId ? timezoneProfId[0].name : "")
    const [availSlots, setAvailSlots] = useState([])
    const [weekDay, setWeekDay] = useState(["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"])
   // const [showTwoWeekTimes, setShowTwoWeekTimes] = useState([])
    const [selectDate, setSelectDate] = useState(new Date(tomarrowDate))

    // matching selected time colors
    const [provSelTimes, setProvSelTimes] = useState([])
    const [dispSelTime, setDispSelTime] = useState([])
    const history = useHistory()

    const [newProvSlots, setNewProvSlots] = useState([])
    const [display,setDisplay] = useState(false)
    //dates-formats

    const prov_prev_slots = [...allSlotsData.booked_slots, ...allSlotsData.schedule].map(item => moment(item).format())
  

    const handleDays = (day) => {
        if (selectedDays.includes(day)) {
            setSelectedDays([...selectedDays.filter((item) => item !== day)])
        } else {
            setSelectedDays([...selectedDays, day])
        }
    }
    const handleWeeks = (week) => {
        setSelectedWeek(week)
    }

    const handleAddSlots = (selwe) => {
        const providerSelDate = moment(selectDate).isAfter(tomarrowDate) ? moment(selectDate) : moment();
      
        const totalDays = selwe === "1week" ? 7 : selwe === "2week" ? 14 : 21
        const sevenDays = [];
        for (let i = 1; i <= totalDays; i++) {
            sevenDays.push(moment(providerSelDate).add(i, 'days').format("YYYY/MM/DD"));
        }
        const showTwoSlots = []
        const checkDays = []
        sevenDays.map((item) =>
            weekDay.map(eachday => {
                if (moment(item).format('dddd') === eachday && moment(item).isAfter(today) && selectedDays.includes(eachday)) {
                    showTwoSlots.push(item)
                    checkDays.push(eachday)
                }
                return eachday
            })
        )
        //setShowTwoWeekTimes(showTwoSlots)
        
        const generateNewSlots = () => {
            const genSlats = []
            showTwoSlots && showTwoSlots.forEach(sel_day => {
                availSlots && availSlots.forEach(sys_day => {
                    if(sys_day.dayofweek === moment(sel_day).format('dddd')){
                        sys_day.slottime.forEach(sys_gen_time => {
                            if(!prov_prev_slots.includes(moment(sel_day + ' ' + sys_gen_time, "YYYY-MM-DD HH:mm A").format())){
                                genSlats.push(moment(sel_day + ' ' + sys_gen_time, "YYYY-MM-DD HH:mm A").format())
                            }
                        })
                    }
                })
            })
            let da = [...new Set(genSlats)]
            const ok = []
            da.forEach(item => {
                if (ok.length === 0 || ok.filter(itm => moment(itm).format("dddd") === moment(item).format("dddd")).length <= 3) {
                    if (ok.find(el => moment(el).format("dddd") + moment(el).format("hh:mm A") === moment(item).format("dddd") + moment(item).format("hh:mm A"))) {

                    } else ok.push(item)
                }
            })
            const newsSlotzzz = []
             ok.length !==0 && selectedDays.forEach(item => {
                const newObj = {}
                newObj.slots = []
                ok.forEach(itm => {
                    if (item === moment(itm).format('dddd')) {
                        newObj.date = moment(itm).format("YYYY-MM-DD")
                        newObj.slots.push(moment(itm).format("hh:mm A"))
                    }
                })
                newsSlotzzz.push(newObj)
            })
            setNewProvSlots(newsSlotzzz)
            setDisplay(true)
        }
        generateNewSlots()
    }


    useEffect(() => {
        const getAvailSlots = async () => {
            const currentDate = moment();
            const weekStart = currentDate.clone().startOf('isoWeek')
            const sevenDays = [];
            for (let i = 0; i <= 13; i++) {
                sevenDays.push(moment(weekStart).add(i, 'days').format("YYYY/MM/DD"));
            }
            if (selectedTimezone) {
                const selTimeZo = timeZones.filter(item => item.name === selectedTimezone)
                const res = await providerSchedulerService.getTimezoneSlots(selTimeZo[0]._id)

                setAvailSlots(res.data.data)
                const availableDays = []
                res.data.data.map(item => sevenDays.map((itm) => (item.dayofweek === moment(itm).format('dddd') && availableDays.includes(item.dayofweek)) ||
                    moment(today).isSame(itm, 'day')
                    ? availableDays.push(item.dayofweek) : ""))
                const uniqueDays = [...new Set(availableDays)]
                setWeekDay(uniqueDays)
            }
        }
        getAvailSlots()
    }, [selectedTimezone, timeZones, today])

    const handleSelectTimezoneProp = () => {

        if (proTimzo === null || "undefined") {
            message.info("Please select the appropriate timezone ")
            history.push("/provider-profile")
        }
    }

    const handleProvSelSlots = (selTime, selDate) => {
        const convMoment = moment(selDate + ' ' + selTime).format("YYYY-MM-DD[T]HH:mm:ss")
        if (dispSelTime.includes(selDate + selTime)) {
            setDispSelTime([...dispSelTime.filter((item) => item !== selDate + selTime)])
        } else {
            setDispSelTime([...dispSelTime, selDate + selTime])
        }
        if (provSelTimes.includes(convMoment)) {
            setProvSelTimes([...provSelTimes.filter((item) => item !== convMoment)])
        } else {
            setProvSelTimes([...provSelTimes, convMoment])
        }
    }

    const handleProvUpdSlots = () => {
        const tomorrow = moment(selectDate).isAfter(tomarrowDate) ? moment(selectDate) : moment().add(1, 'days');
        if (selectedWeek && provSelTimes.length !== 0) {
            const count = selectedWeek === "1week" ? 6 : ((selectedWeek === "2week" && 13) || (selectedWeek === "3week" && 20))
            const addedDays = []
            for (let i = 0; i <= count; i++) {
                if (i === 0) {
                    addedDays.push(moment(tomorrow).format("YYYY/MM/DD"))
                } else {
                    addedDays.push(moment(tomorrow).add(i, 'days').format("YYYY/MM/DD"))
                }
            }
            const data = []
            provSelTimes.map((item) =>
                addedDays.filter(selDay => {
                    if (moment(selDay).format('dddd') === moment(item).format('dddd')) {
                        const selTime = moment(item).format("hh:mm A")
                        const selectedDate = selDay
                        const convMoment = moment(selectedDate + ' ' + selTime, "YYYY-MM-DD HH:mm A").format()
                        const reguDate = new Date(convMoment)
                        data.push(reguDate)
                    }
                    return (selDay)
                })

            )
            updateScheduler(data)
        }
    }

    const findUserType = () => {
        if(usertype && usertype === "provider")  {
            return proTimzo ? <p className="ml-2" style={{ fontSize: "12px" }}>Your timezone is {selectedTimezone}</p> : <p className="ml-2" style={{ fontSize: "12px", cursor: "pointer" }} onClick={() => handleSelectTimezoneProp()}>Select the timezone
            <span className="ml-2"><FontAwesomeIcon icon={faPen} /></span></p>
        }
        if(usertype && usertype === "admin") {
            return proTimzo ? <p className="ml-2" style={{ fontSize: "12px" }}>Provider timezone is {selectedTimezone}</p> : <p className="ml-2" style={{ fontSize: "12px", cursor: "pointer" }} >Provider doesn't selected timezone
            <span className="ml-2"><FontAwesomeIcon icon={faPen} /></span></p>
        } 
        if(usertype && usertype === "internal") {
            return proTimzo ? <p className="ml-2" style={{ fontSize: "12px" }}>Provider timezone is {selectedTimezone}</p> : <p className="ml-2" style={{ fontSize: "12px", cursor: "pointer" }} >Provider doesn't selected timezone
            <span className="ml-2"><FontAwesomeIcon icon={faPen} /></span></p>
        } 
    }


    return (
        <div className="mb-2">
            <div className="d-flex justify-content-between  align-items-center ">
                {/* <div className="mt-2">
                    <h6 className="font-weight-bold ml-2 pb-0 mb-0">My calender</h6>
                    {currentUser.usertype === "admin"? <p className="ml-2" style={{ fontSize: "12px" }}>Your timezone is {selectedTimezone}</p>: proTimzo ? <p className="ml-2" style={{ fontSize: "12px" }}>Your timezone is {selectedTimezone}</p> : <p className="ml-2" style={{ fontSize: "12px", cursor: "pointer" }} onClick={() => handleSelectTimezoneProp()}>Select the timezone
                        <span className="ml-2"><FontAwesomeIcon icon={faPen} /></span></p>}
                </div> */}
                <div className="mt-2">
                <h6 className="font-weight-bold ml-2 pb-0 mb-0">My calender</h6>
                   {findUserType()}
                </div>

                <div>
                    {usertype && usertype === "provider" && <button className='btn btn-primary btn-sm text-white' onClick={() => history.push("/provider/calender")}>view my slots
                    </button>}
                </div>
            </div>

            <div className="py-3 px-2 bg-white rounded w-100 mt-3 mb-5">
                {/* working-days */}
                <div className="mb-3">
                    <label style={{ fontSize: "15px" }} className="font-weight-bold mb-1">Working days</label>
                    <div className="d-flex flex-wrap mb-2">
                        {
                            weekDay && weekDay ? weekDay.map((item, index) => <p style={{ border: "1px solid #143C8C", fontSize: "13px" }} onClick={() => handleDays(item)}
                                className={`m-2 py-1 font-weight-bold rounded  ${selectedDays.includes(item) ? 'slot-sel' : 'slot-not-sel'}`} key={index}>{item}</p>) :
                                days.map((item, index) => <p style={{ border: "1px solid #143C8C", fontSize: "13px" }} onClick={() => handleDays(item)}
                                className={`m-2 py-1 font-weight-bold rounded  ${selectedDays.includes(item) ? 'slot-sel' : 'slot-not-sel'}`} key={index}>{item}</p>)
                        }
                    </div>
                </div>
                {/* weeks recurring */}
                <label style={{ fontSize: "15px" }} className="font-weight-bold mb-1">Set as recurring schedule for</label>
                <div className="mb-3">
                    <div className="d-flex flex-wrap mb-2">
                        {numOfWeeks.map((item, index) => <p style={{ border: "1px solid #143C8C", fontSize: "13px" }} onClick={() => handleWeeks(item)}
                            className={`m-2 py-1 font-weight-bold rounded ${selectedWeek === item ? ' slot-sel' : ' slot-not-sel'}`} key={index}>{item}</p>)}
                    </div>
                </div>

                <div className="slot-applicable d-flex justify-content-start align-items-baseline">
                    <p style={{ fontSize: "13px" }} className="mb-1 mr-2">Slots will be applicable</p>
                    <div className="mr-2">

                        <DatePicker
                            selected={selectDate}
                            onChange={date => setSelectDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="tomorrow"
                            minDate={new Date()}
                        />

                    </div>
                </div>

                <div className="mt-2">
                    <button onClick={() => {
                        if (weekDay.length !== 0
                            && selectedDays.length !== 0 && selectedWeek) {
                            handleAddSlots(selectedWeek)
                        }
                    }} className="btn btn-sm px-4 btn-primary  ml-1">Generate slots</button>
                </div>

            </div>

            <div className="mt-3 py-2 px-2 bg-white">
                <div className="mt-2">
                    <p className="font-weight-bold">Available Slots</p>
                </div>
                <div>
                    <div className=" ">
                        {
                            newProvSlots.length !==0 ?newProvSlots.sort((a,b) => moment(a.date).format('d')-moment(b.date).format('d')).map((day, index) => {
                               return <div key={index}>
                               {day.slots.length !== 0 &&  <p className="font-weight-bold mb-1 pb-0">{moment(day.date).format('dddd')}</p>}
                                <div className="d-flex flex-wrap">
                                    {day.slots.map(itm => <p
                                     onClick={() => {
                                        handleProvSelSlots(itm, day.date)
                                    }}
                                    className={`mr-2 py-1 font-weight-bold rounded ${dispSelTime.find(dis => dis === day.date + itm) === day.date + itm ? 'slot-sel' : 'slot-not-sel'}`}
                                    key={itm}
                                     style={{ border: "1px solid #143C8C", fontSize: "12px", width: "90px" }}
                                    >{itm}</p>)}
                                </div>
                               </div>
                            }) : display && <p>No slots available </p>
                            
                        }
                       
                    </div>
                </div>


            </div>
            <div className="d-flex justify-content-end mt-2 mb-4">
                <button onClick={() => {
                    if (provSelTimes.length !== 0) {
                        handleProvUpdSlots()
                    }
                }} className="btn btn-sm btn-primary">Add slots</button>
            </div>
        </div>
    )
}

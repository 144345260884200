import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom';
import personicon from "../../../../src/assets/images/personicon.png";
import "./enquiry.style.css";
import opinionbg from "../../../../src/assets/images/opinionrequest-bg.png";
import { FaFile } from 'react-icons/fa';
import { getSingleEnquiryDetails } from '../../../actions/patient/EnquiriesAction'
import { Form, Button, Row, Col, Modal, Spinner, Card, Carousel } from 'react-bootstrap';
import { message } from 'antd';
import { authenticationService } from '../../../component/general/login/login.service';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import Role from '../../../constants/role'
import { compose } from 'redux';
import { connect } from 'react-redux'
// import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
// import { getParsedPatientSlots } from '../../provider/scheduler/SchedulerUtils';
import { get_patient_obj, get_provider_obj, get_provider_user_obj, refreshPage } from './EnquiryUtils';
// import _ from 'lodash';
import { useDropzone } from "react-dropzone";
import { FaTrash } from "react-icons/fa";
import { Formik } from "formik";
import { formikFormalidation } from "../../general/formvalidator/validationFroms";
import * as yup from "yup";
import { FaFileWord } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';

function EditOpinionAdmin(props) {

    const formRef = React.createRef();

    const id = props && props.match && props.match.params && props.match.params.id
    const [allSpecialities, setAllSpecialities] = useState([]);
    const [patient_obj, setpatient_obj] = useState([]);
    const [selected_enquiry, setselected_enquiry] = useState([]);
    const [fileList, setfileList] = useState([]);
    const [allData, setAllData] = useState({
        enquiry_id: id,
        loading: true,
        selected_enquiry: {},
        prevPath: "",
        all_specialities: [],
        suggested_providers: [],
        fileList: [],
        currentUser: [],
        chosen_provider: {},
        visible: false,
        previewVisible: false,
        confirmationModal: false,
        selectedFile: {},
        patient_obj: {},
        // patient_user_obj: props && props.location && props.location.state ? props.location.state.patient_user_obj : { },
        patient_user_obj: {},
        confirmLoading: false,
        isAssigneeRequired: false,
        scheduleloading: false,
        selected_view_document: "",
        showImageViewer: false,
        available_slots: [],
        searchParams: {},
        openModal: true,
        enquiry_message: props && props.sel_enq && props.sel_enq.enquiry_message,
        speciality: props && props.sel_enq && props.sel_enq.speciality.id,
        stage_id: props && props.sel_enq && props.sel_enq.stage.id,
        provider_loading: false,
        checkFileName: []
    })
    const [fileName, setFileName] = useState([]);
    const [stages, setStages] = useState([]);
    const [fileError, setFileError] = useState("");

    // const redirectCheck = (props) => {
    //     if (props.location) {
    //         if (typeof props.location.state === "undefined") {
    //             props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
    //         }
    //     }
    // }

    const removeFile = (file) => {
        const newFiles = [...fileName];
        newFiles.splice(file, 1);
        setFileName(newFiles);
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFileName(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
            // console.log("accepted files>>", acceptedFiles);
            setFileName(fileName.concat(...acceptedFiles));
        },
        [fileName]
    );


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*,.pdf,.doc,.docx",
        maxFiles: "5",
    });

    const previewName = fileName?.map((file, i) => (
        <Card className="ml-3 mt-4"
            key={file.name}
            style={{
                position: "relative",
                backgroundColor: "#F8F8F9",
                height: "50px auto",
                width: "200px",
            }}
        >

            {(file?.type === 'image/jpeg' || file?.type === 'image/png') &&
                <img src={file.preview} alt="opinionImg" width="200px" height="160px" />}
            {file?.type === 'text/plain' && <div className="pb-5"> <FaFileAlt className='fa fa-6x text-secondary' /></div>}

            {(file?.type === 'application/pdf' || file?.type === 'application/msword' || file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                <div className='image pb-5'>
                    <FaFile className='fa fa-6x text-info' />
                </div>
            }

            <Row className="py-2 px-2 dropped-files" style={{ justifyContent: "stretch", alignItems: "center" }}>
                <Col sm={8}>
                    <h6 style={{ fontFamily: "manrope", fontWeight: "500" }}>
                        {file.name}
                    </h6>
                    {file.size}&nbsp;
                </Col>
                <Col sm={4}>
                    <FaTrash className='text-secondary'
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFile(i)}
                    />
                </Col>
            </Row>
        </Card>
    ));

    const documentViewer = (document_url) => {
        setAllData({ ...allData, selected_view_document: document_url, showImageViewer: true })
    }

    const handleClose = () => {
        setAllData({ ...allData, showImageViewer: false })
    }

    const getInitalValues = (_enquiry) => {
        return {
            speciality: (_enquiry && _enquiry.speciality && _enquiry.speciality.id) || [],
            enquiry_message: _enquiry.enquiry_message || "",
            assignee_id: (_enquiry && _enquiry.assignee && _enquiry.assignee.id) || "",
            stage_id: (_enquiry && _enquiry.stage && _enquiry.stage.id) || null,
        }
    }

    const getSuggestedProviders = async (_enquiry) => {
        try {
            setAllData({ ...allData, provider_loading: true });
            let response = await EnquiryListService.getProviderBySpeciality(_enquiry);
            if (response.data.status === "success") {
                setAllData({
                    ...allData,
                    provider_loading: false,
                    suggested_providers: response.data.data
                });
                //this.setState({ suggested_providers: response.data.data, loading: false })
            } else {
                setAllData({ ...allData, suggested_providers: [], provider_loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            setAllData({ ...allData, suggested_providers: [], provider_loading: false })
        }
    }

    // const getSchedulebyProviders = async (item) => {
    //     if (item) {
    //         try {
    //             setAllData({ ...allData, scheduleloading: true });
    //             const provider_id = item && item._id
    //             if (provider_id) {
    //                 let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
    //                 if (response.data.status === "success") {
    //                     if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
    //                         const responseObj = response.data
    //                         const _schedules = responseObj.schedules
    //                         const all_schedule = getParsedPatientSlots(_schedules);
    //                         setTimeout(() => {
    //                             setAllData({
    //                                 ...allData,
    //                                 available_slots: all_schedule,
    //                                 scheduleloading: false,
    //                             })
    //                         }, 100);
    //                     }
    //                 } else {
    //                     setAllData({ ...allData, scheduleloading: false, available_slots: [] })
    //                 }
    //             } else {
    //                 setAllData({ ...allData, scheduleloading: false, available_slots: [] })
    //                 //message.error(response.data.message);
    //             }
    //         } catch (error) {
    //             errorMessage(error)
    //             setAllData({ ...allData, scheduleloading: false, available_slots: [] })
    //         }
    //     }
    // }

    const getvalsfromAPI = async () => {
        try {
            await setAllData({ ...allData, loading: true });
            const currentUser = await authenticationService.currentUserValue;
            // this.setState({ loading: true })
            //const _all_specialities = this.props && this.props.location && this.props.location.state && this.props.location.state.all_specialities
            const _prevPath = await props && props.location && props.location.state && props.location.state.prevPath
            //const _internalusers = this.props && this.props.location && this.props.location.state && this.props.location.state.internalusers
            //const _stages = this.props && this.props.location && this.props.location.state && this.props.location.state.stages
            const _enquiryProps = await props && props.location && props.location.state && props.location.state.selected_enquiry
            let patient_id;
            if (currentUser.usertype === Role.localprovider.name) {
                const _patientProps = await get_patient_obj(_enquiryProps)
                patient_id = _patientProps && _patientProps._id
            } else if (currentUser.usertype === Role.admin.name || currentUser.usertype === Role.internaluser.name) {
                patient_id = currentUser._id
            } else if (currentUser.usertype === Role.patient.name) {
                patient_id = currentUser.usertype_id
            }
            const data = {
                enquiry_id: allData.enquiry_id,
                patient_id: patient_id
            }

            let response = await EnquiryListService.getEnquiryRelatedDetails(data)
            if (response && response.enquiryData) {
                let _stages, _internalusers, _all_specialities;
                if (response && response.stages) {
                    _stages = await response.stages
                }
                if (response && response.specialities) {
                    _all_specialities = await response.specialities
                }
                if (response && response.internalusers) {
                    _internalusers = await response.internalusers
                }
                const _enquiry = await response.enquiryData && response.enquiryData.enquiryInfo
                const _selected_enquiry = await response.enquiryData && response.enquiryData
                const _provider_obj = await get_provider_obj(_selected_enquiry)
                const _provider_user_obj = await get_provider_user_obj(_provider_obj)
                setStages(_stages)
                await setAllData({
                    ...allData,
                    loading: false,
                    selected_enquiry: _enquiry,
                    stages: _stages,
                    all_specialities: _all_specialities,
                    internalusers: _internalusers,
                    prevPath: _prevPath,
                    patient_obj: await response.enquiryData.patientInfo,
                    patient_user_obj: await response.enquiryData.userInfo,
                    provider_obj: _provider_obj,
                    provider_user_obj: _provider_user_obj,
                    currentUser: currentUser,
                    fileList: _enquiry.documents || [],
                    enquiry_message: _enquiry.enquiry_message
                })
                setfileList(_enquiry.documents);
                setAllSpecialities(_all_specialities);
                setpatient_obj(response.enquiryData.patientInfo);
                setselected_enquiry(_enquiry);
                if (currentUser.usertype === Role.admin.name || currentUser.usertype === Role.internaluser.name) {
                    getSuggestedProviders(_enquiry)
                }
                if (formRef && formRef.current) {
                    formRef.current.setFieldsValue(getInitalValues(_enquiry))
                }
            } else {
                setAllData({ ...allData, loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            setAllData({ ...allData, loading: false })
        }

    }


    // const onFinish = async (values) => {
    //     values.preventDefault()
    //     // if (allData.fileList.length === 0) {
    //     //     message.error('Please upload Past Reports')
    //     //     return
    //     // }
    //     let formData = new FormData();
    //     formData.append("speciality_id", allData.speciality || "");
    //     formData.append("enquiry_message", allData.enquiry_message || "");
    //     formData.append("patient_id", allData.patient_obj && allData.patient_obj._id);
    //     formData.append("enquiry_id", allData.selected_enquiry && allData.selected_enquiry._id);
    //     // formData.append("file_count", this.state.fileList && this.state.fileList.length);
    //     for (let i = 0; i < fileName.length; i++) {
    //         const file = fileName[i];

    //         formData.append('file', file);
    //     }

    //     if (allData.currentUser) {
    //         if (allData.currentUser.usertype === Role.admin.name || allData.currentUser.usertype === Role.internaluser.name) {
    //             if (values && values.stage_id) {
    //                 formData.append("stage_id", values.stage_id || "");
    //             }
    //         }
    //         if (allData.currentUser.usertype === Role.admin.name) {
    //             if (values && values.assignee_id) {
    //                 formData.append("assignee_id", values.assignee_id || "");
    //             }
    //         }
    //     }

    //     try {
    //         setAllData({ ...allData, loading: true });
    //         let response = await EnquiryListService.updateEnquiry(formData);

    //         if (response.data.status === "success") {
    //             message.success(response.data.message);
    //             setAllData({ ...allData, loading: false })
    //             props.setOpenModal();
    //             window.location.reload();
    //             props.submitShowHandle(true);
    //         } else {
    //             setAllData({ ...allData, loading: false });
    //             message.error(response.data.message);
    //         }

    //     } catch (error) {
    //         errorMessage(error)
    //         setAllData({ ...allData, loading: false })
    //     }
    // }

    const onFinishFailed = values => {
        values.preventDefault()
        refreshPage()
    }

    // const handleChange = (event) => {
    //     setAllData({ ...allData, [event.target.name]: event.target.value })
    // }


    const handleRemoveFile = async (val) => {
        const newData = {}
        newData.enquiryId = props.urlId;
        newData.filename = val.filename;

        newData.enquiryData = {
            "file_obj": val
        };

        try {
            let response = await EnquiryListService.deleteFile(newData);
            if (response.data.status === "success") {
                const newFileList = []
                for (let i = 0; i < fileList.length; i++) {
                    if (val.filename !== fileList[i].filename) {
                        newFileList.push(fileList[i])
                    }
                }
                // setAllData({ ...allData, fileList: newFileList })
                setfileList(newFileList);
            }
        } catch (err) {

        }
    };



    useEffect(() => {
        getvalsfromAPI()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const opinionSchema = yup.object().shape({
        speciality: formikFormalidation.speciality,
        enquiry_message: formikFormalidation.healthconcern,
        stage: formikFormalidation.stage_id
    });

    return (
        <React.Fragment>
            <Modal
                {...props}
                width={500}
                show={allData.openModal}
                onHide={props.Close}
                backdrop="static"
                keyboard={true}
                centered={false}
                size="xl"
            >
                <Modal.Header style={{ border: "0" }} closeButton>
                    <Modal.Title
                        style={{
                            fontFamily: "manrope",
                            fontWeight: "800", fontSize: "23px"
                        }}
                    >
                        Edit Opinion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <Formik
                            initialValues={{
                                speciality: allData.speciality ? allData.speciality : "",
                                enquiry_message: allData.enquiry_message ? allData.enquiry_message : "",
                                fileName: "",
                                stage: allData.stage_id
                            }}
                            validationSchema={opinionSchema}

                            validate={values => {
                                const errors = {}
                                const img = [...fileName, ...fileList]

                                if (fileList.length === 0 && fileName.length === 0) {
                                    errors.fileName = "Please select file here!"
                                    setFileError(errors.fileName);
                                }
                                else if (img.length > 5) {
                                    errors.fileName = "upload maximum 5 files"
                                    setFileError(errors.fileName);
                                }

                                return errors
                            }}

                            onSubmit={async (values) => {
                                setAllData({ ...allData, loading: true })
                                let formData = new FormData();
                                formData.append("speciality_id", values.speciality || "");
                                formData.append("enquiry_message", values.enquiry_message || "");
                                formData.append("patient_id", patient_obj && patient_obj._id);
                                formData.append("enquiry_id", selected_enquiry && selected_enquiry._id);
                                formData.append("stage_id", await stages.filter(x => x.stage === 'New').map(x => x._id).toString());
                                // formData.append("file_count", this.state.fileList && this.state.fileList.length);
                                for (let i = 0; i < fileName.length; i++) {
                                    const file = fileName[i];

                                    formData.append('file', file);
                                }

                                if (allData.currentUser) {
                                    if (allData.currentUser.usertype === Role.admin.name || allData.currentUser.usertype === Role.internaluser.name) {
                                        if (values && values.stage_id) {
                                            formData.append("stage_id", values.stage_id || "");
                                        }
                                    }
                                    if (allData.currentUser.usertype === Role.admin.name) {
                                        if (values && values.assignee_id) {
                                            formData.append("assignee_id", values.assignee_id || "");
                                        }
                                    }
                                }

                                try {
                                    setAllData({ ...allData, loading: true });
                                    let response = await EnquiryListService.updateEnquiry(formData);

                                    if (response.data.status === "success") {
                                        message.success(response.data.message);
                                        setAllData({ ...allData, loading: false })
                                        props.Close();
                                        window.location.reload();
                                        props.submitShowHandle(true);
                                    } else {
                                        setAllData({ ...allData, loading: false });
                                        message.error(response.data.message);
                                    }

                                } catch (error) {
                                    errorMessage(error)
                                    setAllData({ ...allData, loading: false })
                                }
                            }}
                        >
                            {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => (
                                <fieldset  >
                                    <Form
                                        style={{ width: "100%", display: "flex" }}
                                        className="request-form"
                                        onSubmit={handleSubmit}
                                        noValidate
                                    >

                                        {!props.location.state.enquiry_obj.enquiry_message && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                            <Spinner animation="border" variant="primary" /></div>}
                                        <div>
                                            <Form.Group controlId="addPatient">
                                                <h5 style={{ fontFamily: "manrope", fontWeight: "700", fontSize: "15px", lineHeight: "20px", marginTop: "-15px" }}>
                                                    Is this for you or someone else?
                                                </h5>
                                                <div style={{ flexDirection: "row", display: "flex", width: "600px" }}>
                                                    <div style={{ marginLeft: "4%", textAlign: "center" }}>
                                                        <img src={personicon} alt="person" />
                                                        <h6 style={{ fontWeight: "600", fontSize: "13px", marginTop: "10px" }}>{allData.patient_user_obj && allData.patient_user_obj.displayname}</h6>

                                                        <h6 style={{ fontWeight: "400", fontSize: "13px" }}>(You)</h6>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="formControlsTextara">
                                                <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                                                    What is your health concern?
                                                    <span style={{ color: "red" }}>*</span>
                                                </h5>
                                                <h6 style={{ fontWeight: "400", fontSize: "13px" }}>
                                                    your data will be secured with us
                                                </h6>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={2} style={{ border: "1px solid #CFCFCF", borderRadius: "8px", boxSizing: "border-box", position: "relative", width: "616px" }}
                                                    name="enquiry_message"
                                                    type="text"
                                                    defaultValue={props.location.state.enquiry_obj.enquiry_message}
                                                    value={values.enquiry_message}
                                                    isInvalid={
                                                        errors.enquiry_message && touched.enquiry_message
                                                    }
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.enquiry_message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <div style={{ position: "relative", marginTop: "30px" }}>
                                                <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                                                    Add prescriptions/past records
                                                    <span style={{ color: "red" }}>*</span>
                                                </h5>
                                                <div

                                                    {...getRootProps({ className: "drop-zone", role: "button" })}
                                                >
                                                    <input {...getInputProps()} />
                                                    {isDragActive ? (
                                                        <p
                                                            style={{
                                                                border: "2px dashed #CFCFCF",
                                                                boxSizing: "border-box",
                                                                borderRadius: "8px",
                                                                width: "616px",
                                                                height: "80px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                fontWeight: "600",
                                                                fontSize: "15px",
                                                                color: "#0BC1C2",
                                                            }}
                                                        >
                                                            Drop the files here ...
                                                        </p>
                                                    ) : (
                                                        <>

                                                            <p
                                                                style={{
                                                                    border: "2px dashed #CFCFCF",
                                                                    borderRadius: "8px",
                                                                    width: "616px",
                                                                    height: "110px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    fontWeight: "600",
                                                                    fontSize: "15px",
                                                                    color: "#0BC1C2",
                                                                }}
                                                            >
                                                                Drag and drop or browse files
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                                <p className="d-flex flex-wrap">{previewName}</p>
                                                {fileError && <p style={{ color: "red", fontSize: '13px' }}>{fileError}</p>}
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                                {fileList.map((x) => {
                                                    return <div className='flex-nowrap'> <Card className='ml-3'
                                                        style={{
                                                            position: "relative",
                                                            backgroundColor: "#F8F8F9",
                                                            height: "50px auto",
                                                            width: "200px",
                                                        }}>
                                                        <Row>
                                                            <Col lg={8} md={4} sm={12} xs={12} className="pl-5 py-3">
                                                                {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => {
                                                                    documentViewer(x?.url)
                                                                }} ></img>}
                                                                {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                                {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                                {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                    <div className='image'>
                                                                        <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                                    </div>
                                                                }

                                                            </Col>
                                                            <Col lg={4}>
                                                                <FaTrash className='mt-5 text-secondary' size={20} onClick={() => handleRemoveFile(x)} />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                    </div>
                                                })}
                                                {
                                                    allData.showImageViewer && <Modal show={allData.showImageViewer}
                                                        centered
                                                        onHide={handleClose}
                                                        backdrop="static"
                                                        width={500}>
                                                        <Modal.Header closeButton>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <img src={allData.selected_view_document} alt="documents" width="100%" height="100%" />
                                                        </Modal.Body>
                                                    </Modal>
                                                }
                                            </div>


                                            <div style={{ marginTop: "40px", fontFamily: "manrope" }}>
                                                {" "}
                                                <h5 style={{ fontSize: "15px", fontWeight: "700", fontFamily: 'manrope' }}>
                                                    Which type of doctor did you see for this problem?
                                                    <span style={{ color: "red" }}>*</span>
                                                </h5>



                                                <Form.Group controlId="formControlSelectArea" >
                                                    <Carousel interval={null} indicators={false} className="editOptionCarousel" >
                                                        <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{allSpecialities?.map((item) => {

                                                            return (

                                                                <div className="form-check form-check-inline" style={{ height: "180px", width: "100px" }} >
                                                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                                                        <Card.Body>
                                                                            <Card.Subtitle className="text-start">
                                                                                <div><img src={item.documents[0].url} alt="opinion" /></div>
                                                                                <div><input defaultChecked={allData.speciality === item._id} onChange={handleChange} name="speciality" value={item._id} key={item._id} id={item._id} type="radio"
                                                                                    style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                                                            </Card.Subtitle>
                                                                        </Card.Body>
                                                                        <div>
                                                                            <label className="mt-4" htmlFor={item._id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }).splice(0, 5)}</div></Carousel.Item>

                                                        <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{allSpecialities?.map((item) => {
                                                            return (
                                                                <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                                                        <Card.Body>
                                                                            <Card.Subtitle className="text-start">
                                                                                <div><img src={item.documents[0].url} alt="opinion" /></div>
                                                                                <div><input defaultChecked={allData.speciality === item._id} onChange={handleChange} name="speciality" value={item._id} key={item._id} id={item._id} type="radio"
                                                                                    style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                                                            </Card.Subtitle>
                                                                        </Card.Body>
                                                                        <div>
                                                                            <label className="mt-4" htmlFor={item._id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }).splice(6, 5)}</div></Carousel.Item>

                                                        <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{allSpecialities?.map((item) => {
                                                            return (
                                                                <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                                                        <Card.Body>
                                                                            <Card.Subtitle className="text-start">
                                                                                <div><img src={item.documents[0].url} alt="opinion" /></div>
                                                                                <div><input defaultChecked={allData.speciality === item._id} onChange={handleChange} name="speciality" value={item._id} key={item._id} id={item._id} type="radio"
                                                                                    style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                                                            </Card.Subtitle>
                                                                        </Card.Body>
                                                                        <div>
                                                                            <label className="mt-4" htmlFor={item._id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }).splice(11, 5)}</div></Carousel.Item>

                                                        <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{allSpecialities?.map((item) => {
                                                            return (
                                                                <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                                                        <Card.Body>
                                                                            <Card.Subtitle className="text-start">
                                                                                <div><img src={item.documents[0].url} alt="opinion" /></div>
                                                                                <div><input defaultChecked={allData.speciality === item._id} onChange={handleChange} name="speciality" value={item._id} key={item._id} id={item._id} type="radio"
                                                                                    style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                                                            </Card.Subtitle>
                                                                        </Card.Body>
                                                                        <div>
                                                                            <label className="mt-4" htmlFor={item._id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }).splice(16, 5)}</div></Carousel.Item>

                                                        <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{allSpecialities?.map((item) => {
                                                            return (
                                                                <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                                                        <Card.Body>
                                                                            <Card.Subtitle className="text-start">
                                                                                <div><img src={item.documents[0].url} alt="opinion" /></div>
                                                                                <div><input defaultChecked={allData.speciality === item._id} onChange={handleChange} name="speciality" value={item._id} key={item._id} id={item._id} type="radio"
                                                                                    style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                                                            </Card.Subtitle>
                                                                        </Card.Body>
                                                                        <div>
                                                                            <label className="mt-4" htmlFor={item._id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }).splice(21, 5)}</div></Carousel.Item>

                                                        <Carousel.Item> <div className="default-radio" style={{ width: "580px", marginLeft: "30px" }}>{allSpecialities?.map((item) => {
                                                            return (
                                                                <div className="form-check form-check-inline" style={{ width: '100px', height: "180px" }}>
                                                                    <Card className="radio-1" style={{ width: '65px', height: '65px', borderRadius: '8px', backgroundColor: '#E8F9FD', marginTop: '-50px', marginLeft: '20px' }}>
                                                                        <Card.Body>
                                                                            <Card.Subtitle className="text-start">
                                                                                <div><img src={item.documents[0].url} alt="opinion" /></div>
                                                                                <div><input defaultChecked={allData.speciality === item._id} onChange={handleChange} name="speciality" value={item._id} key={item._id} id={item._id} type="radio"
                                                                                    style={{ display: 'flex', marginLeft: '-20px', marginTop: '-40px' }} /></div>
                                                                            </Card.Subtitle>
                                                                        </Card.Body>
                                                                        <div>
                                                                            <label className="mt-4" htmlFor={item._id} style={{ alignContent: 'center', fontSize: '13px' }} >{item.name}</label>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            );
                                                        }).splice(26, 2)}</div></Carousel.Item>
                                                    </Carousel>
                                                </Form.Group>
                                            </div>

                                            {/* <Row>
                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                    <Form.Group controlId="formBasicSpeciality">
                                                        <Form.Label style={{ fontSize: "15px", fontWeight: "700", fontFamily: 'manrope' }}>Stage </Form.Label>
                                                        <Form.Control as={"select"}
                                                            required
                                                            name="stage"
                                                            value={values.stage}
                                                            className='selectors w-30'
                                                            isInvalid={errors.stage && touched.stage}
                                                            onChange={e => {
                                                                handleChange(e)
                                                                // let speciality_id = e.currentTarget.value;
                                                                // let speciality_name = e.currentTarget.selectedOptions[0].label;
                                                                // this.handleOnChangeSpeciality(speciality_id, speciality_name)
                                                            }}
                                                            onBlur={handleBlur}  >
                                                            {
                                                                stages?.map((item) => {
                                                                    const { stage, _id } = item;
                                                                    return <option value={_id} key={_id}>{stage}</option>
                                                                })
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row> */}

                                            <div style={{ marginTop: "30px", flexDirection: "row" }}>
                                                <Form.Group
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "initial", flexDirection: "row", position: "relative",
                                                    }}
                                                >

                                                    <Button
                                                        variant="primary" className="submit-button" type='submit'
                                                        style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}
                                                    >
                                                        Submit
                                                    </Button>

                                                    <Button
                                                        variant="white" className="cancel-button"
                                                        style={{ marginLeft: "30px", fontSize: "15px", fontWeight: "700" }}
                                                        onClick={onFinishFailed}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                alt="example"
                                                style={{
                                                    position: "absolute",
                                                    width: "350px",
                                                    height: "500px",
                                                    left: "770px",
                                                    top: "-25px",
                                                    opacity: "0.9",
                                                    borderRadius: "20px",
                                                }}
                                                src={opinionbg}
                                            />
                                        </div>
                                    </Form>
                                </fieldset>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleEnquiryDetails: () => dispatch(getSingleEnquiryDetails())
    }
}
const mapStateToProps = state => {
    return {
        enquiryStore: state.enquiryList,
    }
}


//export default withRouter(EnquiryDetailsEditor)
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EditOpinionAdmin);
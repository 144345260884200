import React from 'react'
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { authenticationService } from '../../general/login/login.service';
import Role from '../../../constants/role'
import { AUTHROUTE } from "../../../constants/RouterPath";

const InternalUserDashboard = ({ ...props }) => {
  const currentUser = authenticationService.currentUserValue;
  if (props && props.location && props.location.state) {
    const redirect_url = props.location.state && props.location.state.redirect_url && props.location.state.redirect_url.url
    if (redirect_url) {
      if (currentUser.usertype === Role.internaluser.name) {
        props.history.push({
          pathname: AUTHROUTE.INTERNALUSER.ENQUIRIES_PATH,
        });
      }
      if (currentUser.usertype === Role.admin.name) {
        props.history.push({
          pathname: AUTHROUTE.ADMIN.ENQUIRIES_PATH,
        });
      }
    }
  }
  return (
    <AfterLoginLayout>
      <div className="container-fluid">
        <div className="banner-wrapper">
          <div className="banner-header text-center">
            <h1>Hi, {currentUser && currentUser.displayname} - ({currentUser && currentUser.usertype})</h1>
            <p>Your personal Dashboard coming soon.</p>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <span> A health care provider is an individual health professional or a health facility organization licensed to provide health care diagnosis and treatment services including medication, surgery and medical devices.</span>
            </div>
            <div className="col-lg-12 mb-3">
              <span>A patient is a person who is receiving medical advise from a doctor or hospital.</span>
            </div>
          </div>
        </div>
      </div>

    </AfterLoginLayout>
  )
}

export default InternalUserDashboard

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Divider, Col, Row, Spin, Skeleton, message, Upload, Modal, Select, Form, Button, Alert, Result } from 'antd';
import { Layout } from '../../shared';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { NONAUTHROUTE } from "../../../constants/RouterPath"
import { errorMessage } from '../../../utils/ServiceHandler';
import { authenticationService } from '../../general/login/login.service';
import { get_patient_obj, get_user_obj, get_provider_obj, get_provider_user_obj, get_consulation_obj, DescriptionItem, get_formated_timeslot } from './EnquiryUtils';
import { getParsedPatientSlots } from '../../provider/scheduler/SchedulerUtils';
import { formValidation } from '../../general/formvalidator/form.validation';
import OtpInput from '../../../component/shared/otp/index';
import './enquiry.style.css'
import moment from "moment-timezone";

export class EnquiryPublicView extends Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            enquiry_response: {},
            selected_enquiry: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            consultation_obj: {},
            schedules: [],
            available_slots: [],
            id: this.props && this.props.match && this.props.match.params && this.props.match.params.id,
            confirmationModal: false,
            OTPconfirmationModal: false,
            confirmLoading: false,
            numInputs: 6,
            isDisabled: false,
            hasErrored: false,
            separator: '-',
            isInputNum: false,
            isInputSecure: false,
            placeholder: '',
            otp_val: '',
            schedule_date: '',
            schedule_id: '',
            schedule_slot_id: '',
            selected_slot: '',
            selected_date: '',
            salesOrderId: '',
            session_id: '',
            salesOrderCode: ''
        }
    }
    handleOtpChange = (otp_val) => {
        this.setState({ otp_val });
    };

    OTPPreValidation = () => {
        let errors = false
        if (!this.state.otp_val || (this.state.otp_val && (this.state.otp_val.length < this.state.numInputs))) {
           // message.error("Please enter OTP and proceed")
            errors = true
        }
        return errors
    }
    handleOTPOkModalRemove = async () => {
        if (!this.OTPPreValidation()) {
            this.setState({ confirmLoading: true });
            var data = {
                "userid": this.state && this.state.user_obj && this.state.user_obj._id,
                "verificationcode": this.state && this.state.otp_val,
                "patient_id": this.state && this.state.patient_obj && this.state.patient_obj._id,
                "provider_id": this.state && this.state.provider_obj && this.state.provider_obj._id,
                "selected_slot": this.state && this.state.selected_slot,
                "schedule_slot_id": this.state && this.state.schedule_slot_id,
                "price_id": this.state && this.state.provider_obj && this.state.provider_obj.price_id,
                "amount": this.state && this.state.provider_obj && this.state.provider_obj.price,
                "enquiry_id": this.state && this.state.selected_enquiry && this.state.selected_enquiry._id,
                "schedule_id": this.state && this.state.schedule_id,
                "schedule_date": this.state && this.state.schedule_date,
            };
            try {
                let response = await EnquiryListService.verifyConsulationOTP(data);
                if (response.data.status === "success") {
                    this.setState({ confirmLoading: false, OTPconfirmationModal: false });
                    this.navigateToCheckout(response.data)
                } else {
                    this.setState({ confirmLoading: false });
                    message.error(response.data.message);
                }
            } catch (error) {
                errorMessage(error)
                this.setState({ confirmLoading: false });
            }
        }
    }
    handleOnSelectSlot = (value, event) => {
        if (event && event.key) {
          this.setState({ schedule_slot_id: event.key })
        }
        if (event && event.schedule_obj) {
          if (event.schedule_obj.providerScheduleId) {
            this.setState({ schedule_id: event.schedule_obj.providerScheduleId })
          } if (event.schedule_obj.date) {
            this.setState({ schedule_date: event.schedule_obj.date })
          }if (event.schedule_obj.slot) {
            let _date = moment(event.schedule_obj && event.schedule_obj.slot).tz(authenticationService && authenticationService.user_timezone).format('MMM D, YYYY')
            let time_slot = moment(event.schedule_obj && event.schedule_obj.slot).tz(authenticationService && authenticationService.user_timezone).format('LT z')
            this.setState({ selected_date: _date })
            this.setState({ selected_slot: time_slot })
          }
        }
      }
    // handleOnSelectSlot = (value, event) => {
    //     if (event && event.key) {
    //         this.setState({ schedule_slot_id: event.key })
    //     }
    //     if (event && event.schedule_obj) {
    //         if (event.schedule_obj.providerScheduleId) {
    //             this.setState({ schedule_id: event.schedule_obj.providerScheduleId })
    //         } if (event.schedule_obj.date) {
    //             this.setState({ schedule_date: event.schedule_obj.date })
    //         }
    //     }
    //     if (event && event.children) {
    //         if (event.children.length > 0) {
    //             this.setState({ selected_date: event.children && event.children[0] })
    //             this.setState({ selected_slot: event.children && event.children[2] })
    //         }
    //     }
    // }

    navigateToCheckout(response) {
        this.props.history.push({
            pathname: NONAUTHROUTE.PATIENT.ENQUIRY_SESSION,
            state: {
                enquiry_response: this.state.enquiry_response,
                selected_enquiry: this.state.selected_enquiry,
                patient_obj: this.state.patient_obj,
                user_obj: this.state.user_obj,
                provider_obj: this.state.provider_obj,
                provider_user_obj: this.state.provider_user_obj,
                schedule_slot_id: this.state.schedule_slot_id,
                selected_slot: this.state.selected_slot,
                selected_date: this.state.selected_date,
                schedule_id: this.state.schedule_id,
                schedule_date: this.state.schedule_date,
                salesOrderId: response && response.salesOrderId,
                session_id: response && response.session_id,
                salesOrderCode: response && response.salesOrderCode,
            }
        });
    }

    onFinish = async (values) => {
        //this.navigateToCheckout()
        this.setState({ confirmationModal: true });
    }
    handleOkModalRemove = async () => {
        this.setState({ confirmLoading: true, otp_val: '' });
        var data = {
            "userid": this.state.user_obj && this.state.user_obj._id,
        };
        try {
            let response = await EnquiryListService.generateConsulationOTP(data);
            if (response.data.status === "success") {
                this.setState({ confirmationModal: false, confirmLoading: false, OTPconfirmationModal: true });
            } else {
                this.setState({ confirmationModal: false, confirmLoading: false, OTPconfirmationModal: true });
                message.error(response.data.message);
            }
        } catch (error) {
            //errorMessage(error)
            this.setState({ confirmationModal: false, confirmLoading: false, OTPconfirmationModal: true });
        }
    };
    handleCancelModalRemove = () => {
        this.setState({ confirmationModal: false, OTPconfirmationModal: false });
    }

    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };
    handlePreview = file => {
        if (file && file.filetype && !file.filetype.match('image')) {
            var win = window.open(file.url, '_blank');
            win.focus();
        } else {
            this.setState({
                previewImage: file.url,
                previewVisible: true
            });
        }
    };

    getEnquiryDetails = async (_id) => {
        try {
            this.setState({ loading: true })
            const response = await EnquiryListService.getNonAuthEnquiryDetails(_id)
            if (response && response.data && response.data.status === "success") {
                const _response = response.data && response.data.data
                const _enquiry = _response.enquiryInfo
                const _patient_obj = get_patient_obj(_response)
                const _user_obj = get_user_obj(_response)
                const _provider_obj = get_provider_obj(_response)
                const _provider_user_obj = get_provider_user_obj(_provider_obj)
                const _consultation_obj = get_consulation_obj(_response)
                const _schedules = _response.schedules
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({
                        enquiry_response: _response,
                        selected_enquiry: _enquiry,
                        patient_obj: _patient_obj,
                        user_obj: _user_obj,
                        provider_obj: _provider_obj,
                        provider_user_obj: _provider_user_obj,
                        consultation_obj: _consultation_obj,
                        schedules: _schedules,
                        available_slots: getParsedPatientSlots(_schedules)
                    })
                }, 500)
            } else {
                this.setState({ loading: false })
                message.error("Login to view your Enquiries")
                this.props.history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
            }
        } catch (error) {
            this.setState({ loading: false })
            errorMessage(error)
        }
    }

    onchangeTimeslot = (e) => {
        //this.setState({ value: e });
    }

    componentDidMount() {
        const id = this.state && this.state.id
        this.getEnquiryDetails(id);
    }

    render() {
        const { previewVisible, previewImage } = this.state;
        const showUploadList = ({
            showPreviewIcon: true,
            showRemoveIcon: false,
        });
        const renderConfirmationBlock = () => {
            return (
                <div>
                    <span style={{ fontSize: '13px', color: 'grey' }}>
                        <strong>Order #{this.state && this.state.consultation_obj && this.state.consultation_obj.orderCode}</strong>. Please login to view your Appointments.
                    </span>
                </div>
            )
        }
        return (
            <Layout className="registration" isHeader={true} isFooter={true}>
                <div>
                    <div className="main-wrapper">
                        <section className="section section-search">
                            <div className="container-fluid">
                                <div className="banner-wrapper" style={{ maxWidth: '950px' }}>
                                    <Skeleton loading={this.state.loading} active avatar>
                                        {(() => {
                                            if (this.state && this.state.selected_enquiry) {
                                                if (this.state.consultation_obj && this.state.consultation_obj._id) {
                                                    return <React.Fragment>
                                                        <Row gutter={[16, 24]}>
                                                            <Col className="gutter-row" span={16} xs={24} sm={24} md={24} lg={16} xl={24}>
                                                                <Result
                                                                    status="success"
                                                                    title={"Appointment has been already Booked!"}
                                                                    subTitle={renderConfirmationBlock()}
                                                                    extra={[
                                                                        // <Button type="primary" key="console"
                                                                        //     onClick={() => { this.navigatetoLogin(this.props) }}>
                                                                        //     Login to view your Appointments
                                                                        // </Button>
                                                                    ]}
                                                                />
                                                            </Col>

                                                        </Row>
                                                    </React.Fragment>

                                                }else {
                                                    return <React.Fragment>
                                                        <div className="banner-header">
                                                            {/* <h2>Your Enquiry - {this.state.selected_enquiry && (this.state.selected_enquiry.enquiryexternalid || this.state.selected_enquiry._id)}</h2> */}
                                                            <h2>Please check your Enquiry details below and proceed with Booking.</h2>
                                                        </div>

                                                        <Spin spinning={this.state.loading}>
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    <p className="site-description-item-profile-p">Patient Details</p>
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Name" content={this.state.patient_obj && this.state.patient_obj.displayname} />
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Location" content={this.state.patient_obj && (this.state.patient_obj.location || "-")} />
                                                                        </Col>
                                                                    </Row>
                                                                    {/* <Row>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Mobile" content={this.state.user_obj && (this.state.user_obj.countrycode + " " + this.state.user_obj.phone)} />
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="DOB" content={this.state.patient_obj && (this.state.patient_obj.dob || "")} />
                                                                        </Col>
                                                                    </Row> */}
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Gender" content={this.state.patient_obj && (this.state.patient_obj.gender || "-")} />
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Blood group" content={this.state.patient_obj && (this.state.patient_obj.bloodgroup || "-")} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Height(cms)" content={this.state.patient_obj && (this.state.patient_obj.height || "-")} />
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Weight(kgs)" content={this.state.patient_obj && (this.state.patient_obj.weight || "-")} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Divider />
                                                                    <p className="site-description-item-profile-p">Enquiry Details</p>
                                                                    <Row>
                                                                        <Col span={12}>
                                                                            <DescriptionItem title="Speciality" content={this.state.selected_enquiry && this.state.selected_enquiry.speciality && this.state.selected_enquiry.speciality.name} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <DescriptionItem title="Description" content={(this.state.selected_enquiry && this.state.selected_enquiry.enquiry_message) || ""} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <DescriptionItem title="Past Reports" />
                                                                            <Upload
                                                                                accept=".pdf, .jpg,.png,.jpeg,.doc,.docx"
                                                                                listType="picture-card"
                                                                                fileList={this.state.selected_enquiry && this.state.selected_enquiry.documents}
                                                                                showUploadList={showUploadList}
                                                                                onPreview={this.handlePreview} />

                                                                            {/* <List
                                                                itemLayout="vertical"
                                                                size="small"
                                                                dataSource={this.state.selected_enquiry && this.state.selected_enquiry.documents}
                                                                renderItem={item => (
                                                                    <Button type="link" style={{ paddingLeft: "0px" }}>
                                                                        <a href={item.url} target="_blank" rel="noreferrer">{item.filename}</a>
                                                                    </Button>
                                                                )}
                                                            /> */}
                                                                        </Col>
                                                                    </Row>
                                                                    <Divider />
                                                                    <p className="site-description-item-profile-p">Provider Details</p>
                                                                    {(() => {
                                                                        if (this.state && this.state.selected_enquiry) {
                                                                            if (!this.state.selected_enquiry.provider_id) {
                                                                                return <React.Fragment>
                                                                                    <Col span={24}>
                                                                                        <DescriptionItem title="Provider is not yet alloted.." />
                                                                                    </Col>
                                                                                </React.Fragment>
                                                                            }
                                                                            else {
                                                                                return <React.Fragment>
                                                                                    <Col span={24}>
                                                                                        <DescriptionItem title="Name" content={this.state.provider_obj && "Dr. " + this.state.provider_obj.displayname} />
                                                                                    </Col>
                                                                                    <Col span={24}>
                                                                                        <DescriptionItem title="Experience(yrs)" content={this.state.provider_obj && (this.state.provider_obj.experience || "-")} />
                                                                                    </Col>
                                                                                    <Col span={24}>
                                                                                        <DescriptionItem title="Consulting Fee" content={this.state.provider_obj && (this.state.provider_obj.price || "-")} />
                                                                                    </Col>
                                                                                </React.Fragment>

                                                                            }
                                                                        }
                                                                    })()}
                                                                    <Divider />

                                                                    <p className="site-description-item-profile-p">Available Slots</p>
                                                                    {(() => {
                                                                        if (this.state && this.state.selected_enquiry) {
                                                                            if (this.state.available_slots && this.state.available_slots.length === 0) {
                                                                                return <React.Fragment>
                                                                                    <Col span={24}>
                                                                                        <DescriptionItem title="No Available slots for now" />
                                                                                    </Col>
                                                                                </React.Fragment>
                                                                            }
                                                                            else {
                                                                                return <React.Fragment>
                                                                                    <Form style={{ padding: '0% 0%' }}
                                                                                        {...this.layout}
                                                                                        ref={this.formRef}
                                                                                        name="enquiry"
                                                                                        onFinish={this.onFinish}
                                                                                        onFinishFailed={this.onFinishFailed}
                                                                                    >
                                                                                        <Form.Item
                                                                                            name="timeslot"
                                                                                            type="text"
                                                                                            className='firstname'
                                                                                            rules={formValidation.time_slot}
                                                                                        >
                                                                                            <Select className='selectors' style={{ maxWidth: "600px" }}
                                                                                                onSelect={(value, event) => this.handleOnSelectSlot(value, event)}
                                                                                                allowClear={true}>
                                                                                                {
                                                                                                    this.state && this.state.available_slots.map((option, index) => {
                                                                                                        return <Select.Option
                                                                                                            schedule_obj={option}
                                                                                                            value={option._id}
                                                                                                            key={option._id}>
                                                                                                                {/* {moment(option.slot).format("ll")}-{moment(option.slot).format("LT")} */}
                                                                                                                {get_formated_timeslot(option.slot)}
                                                                                                        </Select.Option>
                                                                                                    })
                                                                                                }
                                                                                            </Select>
                                                                                        </Form.Item>
                                                                                        <Form.Item >
                                                                                            <Button type="primary" htmlType="submit">
                                                                                                Proceed to Booking
                                                                                            </Button>
                                                                                        </Form.Item>
                                                                                    </Form>


                                                                                    {/* <TimeSlot
                                                                        options={getParsedPatientSlots(this.state && this.state.schedules)}
                                                                        onChange={(e) => this.onchangeTimeslot(e)} /> */}

                                                                                </React.Fragment>

                                                                            }
                                                                        }
                                                                    })()}



                                                                </div>
                                                            </div>
                                                            {/* OTP Generation confirmation Modal */}
                                                            <Modal
                                                                title="OTP Generation"
                                                                visible={this.state.confirmationModal}
                                                                onOk={this.handleOkModalRemove}
                                                                onCancel={this.handleCancelModalRemove}
                                                                okText="Ok"
                                                                cancelText="Cancel"
                                                                confirmLoading={this.state.confirmLoading}
                                                            >
                                                                <Alert showIcon={true} message="An OTP will be sent to the registered Email & Mobile Number for Verification." banner />
                                                            </Modal>

                                                            {/* OTP Generation confirmation Modal */}
                                                            <Modal
                                                                title="OTP Verification"
                                                                visible={this.state.OTPconfirmationModal}
                                                                onOk={this.handleOTPOkModalRemove}
                                                                onCancel={this.handleCancelModalRemove}
                                                                okText="Ok"
                                                                cancelText="Cancel"
                                                                width={430}
                                                                maskClosable={false}
                                                                destroyOnClose={true}
                                                                keyboard={false}
                                                                confirmLoading={this.state.confirmLoading}
                                                            >
                                                                <div className="margin-top--small">
                                                                    <p>Please Enter verification code</p>
                                                                    <OtpInput
                                                                        inputStyle="inputStyle"
                                                                        numInputs={this.state.numInputs}
                                                                        isDisabled={this.state.isDisabled}
                                                                        hasErrored={this.state.hasErrored}
                                                                        errorStyle="error"
                                                                        onChange={this.handleOtpChange}
                                                                        separator={<span>{this.state.separator}</span>}
                                                                        isInputNum={false}
                                                                        isInputSecure={this.state.isInputSecure}
                                                                        value={this.state.otp_val}
                                                                        placeholder={this.state.placeholder}
                                                                    />
                                                                </div>
                                                            </Modal>

                                                        </Spin>
                                                    </React.Fragment>
                                                }
                                            }
                                        })()}

                                    </Skeleton>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleImagePreviewCancel}
                >
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                </Modal>
            </Layout>
        )
    }
}


export default withRouter(EnquiryPublicView)
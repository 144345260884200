import React from 'react'
import Footer from './Footer/Footer'
import { Container, Navbar } from 'react-bootstrap'
import HeaderNew from './header'
import logo from "../../assets/images/logo.png";
import whitelogo from '../../assets/images/white-logo.png';


export const Layout = props => (
    <main className={`app-layout ${props.className !== undefined ? props.className : ''}`}>
        {props.isHeader && <HeaderNew backgroundColor={true} />}
        {props.isContactHeader && <HeaderNew backgroundColor={false} />}
        {/* <Container fluid> */}
            {props.children}
        {/* </Container> */}
        {props.isFooter && <Footer />}
    </main>
)

export const FullLayout = props => (
    <main className={`app-layout ${props.className !== undefined ? props.className : ''}`}>
        {props.isHeader && <HeaderNew />}

        <div className={"fulllayout fulllayout-bg-image bg-image"}>
            <div className={"mask"}>           
            <Container fluid>
            <Navbar>
                <Navbar.Brand className="logo" href="/">  
                <img src={whitelogo} className="img-fluid whitelogo" alt="Second Opinion" /> 
                <img src={logo} className="img-fluid colorlogo" alt="Second Opinion" /></Navbar.Brand>
             </Navbar>
                {props.children}
            </Container>
            </div>
        </div>
        {props.isFooter && <Footer />}
    </main>
)

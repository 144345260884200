import React, { Component } from 'react';
import { FullLayout } from '../../shared';
import { Form, Button, Spinner, Row, Col, Card, Container } from 'react-bootstrap';
import { formikFormalidation } from '../../general/formvalidator/validationFroms'
import { login } from './login.service';
import { Link } from 'react-router-dom';
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Formik } from 'formik';
import * as yup from 'yup'

class LoginContainer extends Component {
	constructor(props) {
		super(props)
		if (props && props.location && props.location.search) {
			let tokenSplitter = props.location.search.split('&token=');
			let token = decodeURIComponent(tokenSplitter[1]);
			let redirect = tokenSplitter[0].split('?redirect=')[1];
			this.state = {
				token: token,
				redirect: redirect
			};
		}
		this.state = { ...this.state, loading: false, value: 1, username: "", password: "", validated: false, showPassword: false, }
	}

	handleShowPassword = (passwordProps) => {
		if (passwordProps === 'password') {
			this.setState({ showPassword: !this.state.showPassword })
		}
	}

	render() {

		const schema = yup.object().shape({
			username: formikFormalidation.username,
			password: formikFormalidation.password
		});
		return (

			<Formik
				validationSchema={schema}
				onSubmit={async (values) => {
					this.setState({ loading: true })
					let optionalParams = {
						token: this.state.token,
						redirect: this.state.redirect
					};
					await login(this.props, values, optionalParams);
					this.setState({ loading: false });
				}
				}
				initialValues={{
					username: '',
					password: ''
				}}
			>
				{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					touched,
					isValid,
					errors,
				}) => (
					<FullLayout className="app-login" isHeader={false} isFooter={false}>
						<Container>
							<Row>
								<Col lg={8} md={6} sm={12}>&nbsp;</Col>
								<Col lg={4} md={6} sm={12}>
									<Card className='p-5 rightcol'>
										<Card.Header as="h2" className='px-2'>Sign In</Card.Header>
										<Card.Subtitle className='p-2' >Welcome back to Second Opinion !</Card.Subtitle>
										<Card.Body className='p-2'>
											{this.state.loading &&
												<div className='d-flex justify-content-center flex-nowrap'>
													<div className='position-absolute mx-3 p-4' variant="primary">
														<Spinner animation="border" variant="primary" />
													</div>
												</div>
											}
											<Form noValidate validated={this.state.validated} onSubmit={handleSubmit}>
												<fieldset disabled={this.state.loading}>
													<Form.Group className="mb-3" controlId="formBasicUsername">
														<Form.Control type="text" placeholder="Username" name="username" required value={values.username} onChange={handleChange} isInvalid={!!errors.username} />
														<Form.Control.Feedback type="invalid">
															{errors.username}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group className="mb-2" controlId="formBasicPassword">
														<Form.Control type={this.state.showPassword ? "text" : "password"} placeholder="Password" name="password" required value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
														{!this.state.showPassword && <FaEyeSlash onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
														{this.state.showPassword && <FaEye onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
														<Form.Control.Feedback type="invalid">
															{errors.password}
														</Form.Control.Feedback>
													</Form.Group>
													{/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox" label="Check me out" />
											</Form.Group> */}
													<div className="mb-2 text-right">
														<Link to={NONAUTHROUTE.GENERAL.FORGOTPASSWORDPATH} className="login-link">Forgot password? </Link>
														<br />
													</div>
													<Button className="ml-0 btn-block" variant="primary" type="submit" >
														Sign in
													</Button>
												</fieldset>
											</Form>
											<div className="my-2">Don't have an account? <br />Sign Up as
												<a className="login-link px-2" href={this.state.value === 1 ? NONAUTHROUTE.PATIENT.REGISTRATIONPATH : NONAUTHROUTE.PROVIDER.REGISTRATIONPATH}>
													Patient
												</a>
												<span>| </span>												<a className="login-link px-2" href={this.state.value === 0 ? NONAUTHROUTE.PATIENT.REGISTRATIONPATH : NONAUTHROUTE.PROVIDER.REGISTRATIONPATH}>
													Provider
												</a></div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Container>
					</FullLayout>
				)
				}
			</Formik>
		);
	}
}

export default LoginContainer;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import AppointmentService from '../../../services/patient/Appointment.Service';
import { message } from 'antd';
import { authenticationService } from '../../../component/general/login/login.service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { Link } from 'react-router-dom';
import { getBase64 } from '../enquiry/EnquiryUtils';
import { Form, Row, Col, Button, Modal, Spinner, Card } from 'react-bootstrap';
import { FaFile, FaFileAlt, FaFileWord } from 'react-icons/fa';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { AppointmentModal } from './AppointmentModal';
import './Appointment.css';


export class AppointmentEditor extends Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        const id = this.props && this.props.id;

        this.state = {
            consultation_id: id,
            loading: true,
            consultationInfo: {},
            prevPath: "",
            all_specialities: [],
            suggested_providers: [],
            fileList: [],
            currentUser: [],
            chosen_provider: {},
            visible: false,
            previewVisible: false,
            confirmationModal: false,
            selectedFile: {},
            patient_obj: {},
            patient_user_obj: {},
            selected_view_document: "",
            confirmLoading: false,
            fileError: ""
        }
        // this.redirectCheck(props)
    }



    handlePreview = async file => {
        if (file && file.filetype && !file.filetype.match('image')) {
            var win = window.open(file.url, '_blank');
            win.focus();
        } else {
            if (file.originFileObj) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.setState({
                previewImage: file.preview || file.url,
                previewVisible: true
            });
        }
    };
    handleUpload = ({ fileList }) => {

        this.setState({ fileList });

    };
    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };


    onRemove = (fileList) => {
        this.setState({ selectedFile: fileList })
        return new Promise((resolve, reject) => {
            this.setState({ confirmationModal: true });
            resolve(false)
        })
    };

    documentViewer(document_url) {
        this.setState({ selected_view_document: document_url, showImageViewer: true });
    }
    handleClose() {
        this.setState({ showImageViewer: false });
    }


    getvalsfromAPI = async () => {
        try {
            this.setState({ loading: true });
            const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })
            const _prevPath = this.props && this.props.location && this.props.location.state && this.props.location.state.prevPath

            let response = await AppointmentService.getAppointmentDetails(this.state.consultation_id);

            if (response.data.status === "success") {
                if (response && response.data && response.data.data) {
                    const currentItem = response.data.data
                    const consultationInfo = currentItem && currentItem.consultationInfo
                    if (consultationInfo) {
                        this.setState({
                            consultationInfo: consultationInfo,
                            loading: false,
                            prevPath: _prevPath,
                            currentUser: currentUser,
                            fileList: await consultationInfo.documents || []
                        })
                    } else {
                        this.setState({
                            loading: false,
                        })
                        message.error(response.data.message);
                    }
                }
            } else {
                this.setState({ loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }

    }

    componentDidMount() {
        //this.getValsfromProps()
        this.getvalsfromAPI()
    }

    async pushImage(event) {
        const oldfiles = this.state.fileList;
        if (event.target.files[0].type === 'application/pdf') {
            await oldfiles.push({ filetype: 'application/pdf', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) });
        }
        else if (event.target.files[0].type === 'application/msword') {
            await oldfiles.push({ filetype: 'application/msword', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) })
        }
        else if (event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            await oldfiles.push({ filetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) })
        }
        else
            await oldfiles.push({ filetype: 'image/jpeg', originFileObj: event.target.files[0], url: URL.createObjectURL(event.target.files[0]) });

        this.setState({ fileList: oldfiles });
    }

    onFinish = async () => {
        if (this.state.fileList.length === 0) {
            message.error('Please upload Past Reports')
            return
        }

        let formData = new FormData();
        formData.append("consultation_id", this.state && this.state.consultation_id);
        // formData.append("file_count", this.state.fileList && this.state.fileList.length);
        for (let i = 0; i < this.state.fileList.length; i++) {
            const file = this.state.fileList[i];
            if (file['originFileObj']) {
                formData.append('file', file['originFileObj']);
            }
        }

        try {
                this.setState({ loading: true });
                let response = await AppointmentService.updateDocument(formData);
                if (response.data.status === "success") {
                    message.success(response.data.message);
                    this.setState({ loading: false })
                    window.location.reload();
                    // refreshPage()
                } else {
                    this.setState({ loading: false })
                    message.error(response.data.message);
                }
            
        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }
    onFinishFailed = values => {
    }

    render() {


        const { previewVisible, previewImage } = this.state;
        return (
            <React.Fragment>
                {/* <AfterLoginLayout> */}
                {this.state.loading &&
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-3 p-4' variant="primary">
                            <Spinner animation="border" variant="primary" className='spin' />
                        </div>
                    </div>
                }
                <Row className='main-layout'>
                    <Col lg={9} md={9} sm={12} pt-3>
                        <div className="container-fluid">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={{ pathname: AUTHROUTE.PATIENT.APPOINTMENT_PATH }}>Appointments</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{(this.state.consultationInfo && this.state.consultationInfo.orderCode)}</li>
                                </ol>
                            </nav>
                            <br />
                            <div className='ml-3'>
                                <Card className='leftbottomsection p-4'>
                                    <Row>
                                        <Col className="gutter-row">
                                            <Row className='mobile_row'>
                                            {this.state.fileList.map((x, i) => {
                                                            return <Col lg={4} md={4} sm={6} xs={6} className="p-4" key={i} >
                                                                {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                                {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                                {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                                {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                    <div className='image'>
                                                                        <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                                    </div>
                                                                }
                                                    </Col>
                                                })}
                                                <Col lg={2} md={2} sm={12} xs={12} className="p-3">
                                                    <span className="btn btn-default btn-file" style={{ padding: '38px 26px', border: '1px solid black', backgroundColor: 'white' }}>
                                                        Browse
                                                        <Form.Control type="file" accept=".pdf, .jpg,.png,.jpeg,.doc,.docx"
                                                            required label={'Past Reports'} name="prescription"
                                                            placeholder="Enter email" onChange={e => this.pushImage(e)} style={{ padding: '30px' }} size="60" />
                                                    </span>
                                                </Col>
                                            </Row>



                                        </Col>
                                    </Row>
                                    {this.state.fileError && <p style={{ color: "red", fontSize: '15px' }}>{this.state.fileError}</p>}
                                    <Row>
                                        <Col lg={1} md={1} sm={1}>
                                            <br />
                                            {/* <Form.Control > */}
                                            <Button type="button" style={{}} onClick={this.onFinish}>
                                                Update
                                            </Button>
                                            {/* </Form.Control> */}
                                        </Col>

                                    </Row>

                                    {/* </Form> */}
                                </Card>
                            </div>
                            <Modal
                                visible={previewVisible}
                                footer={null}
                                onCancel={this.handleImagePreviewCancel}
                            >
                                <img alt="example" style={{ width: "100%" }} src={previewImage} />
                            </Modal>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={12} >

                    </Col>
                </Row>
                <AppointmentModal
                    modalData={this.state.modalData}
                    showModal={this.state.showModal}
                    onHide={this.onHideModal}
                    onYesClick={this.onYesModal}
                    onNoClick={this.onHideModal}
                />

                <Modal show={this.state.showImageViewer}
                    centered
                    backdrop="static" onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>

                        <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>


                    </Modal.Body>

                </Modal>
                {/* </AfterLoginLayout> */}
            </React.Fragment >
        )
    }
}



export default withRouter(AppointmentEditor)

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk';
import { handleAlert } from './utils/handleAlert';
import rootReducer from './reducers'

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)
/**
 * ALL store changes
 * 
 */
store.subscribe(() => {
  let storeData = store.getState();
  handleAlert(store);
  if(storeData.storage){
    localStorage.setItem("appState",JSON.stringify(storeData.storage))
  }
  
});
export default store

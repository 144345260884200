import {enquiryAction} from './types'
import EnquiryListService from '../../services/patient/EnquiryList.Service';
import ProviderListService from '../../services/admin/ProviderList.Service';
import UserListService from '../../services/admin/UserList.Service';
import ProfileService from '../../services/provider/Profile.Service';
import { alertActions } from "../alertAction";

/**
 * Get Enquiries list
 * @returns 
 */
export const getEnquiryListDetails = (ownProps) => async (dispatch) => {
  try {
    dispatch(enquiryListDataRequest())
    const enquiryPromise =await EnquiryListService.getEnquiries(ownProps);
    //const stagesPromise =await ProviderListService.getStages();
    //const internalUsersPromise =await UserListService.getInternalUsers();
    //const specialityPromise = ProfileService.getSpecialites();

    //const reponses = await Promise.all([enquiryPromise]);
    const reponses = await Promise.resolve(enquiryPromise);
    //const enquiryResponse = reponses;
    if (reponses.data) {
      const enquiries = enquiryPromise.data && enquiryPromise.data.data
      //const stages = stagesResponse.data && stagesResponse.data.data;
      //const internalusers = internalUsersResponse.data && internalUsersResponse.data.data
      //const specialities = specialityResponse.data && specialityResponse.data.data

      dispatch(enquiryListDataSuccess({ enquiries }))
      return Promise.resolve({ enquiries });
    }else {
      dispatch(enquiryListDataFailure(reponses.data.message));
      dispatch(alertActions.error(reponses.data.message))
      return Promise.reject(reponses.data.message);
    }
  } catch (err) {
    dispatch(enquiryListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * Get Single Enquiries list
 * @returns 
 */
 export const getSingleEnquiryDetails = () => async (dispatch) => {
  try {
    dispatch(enquiryListDataRequest())
    const enquiryPromise =await EnquiryListService.getEnquiries();
    const stagesPromise =await ProviderListService.getStages();
    const internalUsersPromise =await UserListService.getInternalUsers();
    const specialityPromise = ProfileService.getSpecialites();

    const reponses = await Promise.all([enquiryPromise, stagesPromise,internalUsersPromise,specialityPromise]);
    const [enquiryResponse, stagesResponse,internalUsersResponse,specialityResponse] = reponses;
    if (enquiryResponse.data && stagesResponse.data && internalUsersResponse.data && specialityResponse.data) {
      const enquiries = enquiryPromise.data && enquiryPromise.data.data
      const stages = stagesResponse.data && stagesResponse.data.data;
      const internalusers = internalUsersResponse.data && internalUsersResponse.data.data
      const specialities = specialityResponse.data && specialityResponse.data.data

      dispatch(enquiryListDataSuccess({ enquiries, stages: stages,internalusers:internalusers,specialities:specialities }))
      return Promise.resolve({ enquiries, stages: stages,internalusers:internalusers,specialities:specialities });
    }else {
      dispatch(enquiryListDataFailure(reponses.data.message));
      dispatch(alertActions.error(reponses.data.message))
      return Promise.reject(reponses.data.message);
    }
  } catch (err) {
    dispatch(enquiryListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const enquiryListDataRequest = () => {
  return {
    type: enquiryAction.DATA_REQUEST
  }
}

export const enquiryListDataSuccess = data => {
  return {
    type: enquiryAction.DATA_SUCCESS,
    payload: data,
  }
}

export const enquiryListDataFailure = error => {
  return {
    type: enquiryAction.DATA_FAILURE,
    payload: error
  }
}

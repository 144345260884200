
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

const TextField = ({ values, onChange, hadlePastedData, errors }) => React.useMemo(() => {

  
    const handleFoucusForward = (e, pin) => {
      if (e !== null && e.nextSibling && pin && e.name !== "pin6") e.nextSibling.focus()
    }

    const handleFocusBackward = (e) => {
      if (e.target.previousSibling && e.key === "Backspace" && e.target.name === "pin6" && !e.target.value) e.target.previousSibling.focus()
      if (e.key === "Backspace" && e.target.name !== "pin6") e.target.previousSibling.focus()
    }

    const handlePaste = (e) => {
      e.preventDefault()
      const pastedData = e.clipboardData.getData('text/plain').slice(0, 6).split('')
      hadlePastedData(pastedData)

    }
    return (
      <div key="id11">
        <div className="d-flex">
          <input autoComplete="off" type="text" value={values.pin1} onChange={onChange} autoFocus={true} name="pin1" maxLength={1}
            className={`form-control w-100 mr-2 shadow-none text-center ${errors.pin1 && 'border-danger'}`}
            ref={e => handleFoucusForward(e, values.pin1)}
            onPaste={e => handlePaste(e)}
          />
          <input autoComplete="off" id="pin2" value={values.pin2} onChange={onChange} name="pin2" maxLength={1}
            className={`form-control w-100 mr-2 shadow-none text-center ${errors.pin2 && 'border-danger'}`} type="text"
            ref={e => handleFoucusForward(e, values.pin2)}
            onKeyDown={e => handleFocusBackward(e)}
            onPaste={e => handlePaste(e)}

          />
          <input autoComplete="off" value={values.pin3} onChange={onChange} name="pin3" maxLength={1}
            className={`form-control w-100 mr-2 shadow-none text-center ${errors.pin3 && 'border-danger'}`} type="text"
            ref={e => handleFoucusForward(e, values.pin3)}
            onKeyDown={e => handleFocusBackward(e)}
            onPaste={e => handlePaste(e)}
          />
          <input autoComplete="off" value={values.pin4} onChange={onChange} name="pin4" maxLength={1}
            className={`form-control w-100 mr-2 shadow-none text-center ${ errors.pin4 && 'border-danger'}`} type="text"
            ref={e => handleFoucusForward(e, values.pin4)}
            onKeyDown={e => handleFocusBackward(e)}
            onPaste={e => handlePaste(e)}
          />
          <input autoComplete="off" value={values.pin5} onChange={onChange} name="pin5" maxLength={1}
            className={`form-control w-100 mr-2 shadow-none text-center ${ errors.pin5 && 'border-danger'}`} type="text"
            ref={e => handleFoucusForward(e, values.pin5)}
            onKeyDown={e => handleFocusBackward(e)}
            onPaste={e => handlePaste(e)}
          />
          <input autoComplete="off" value={values.pin6} onChange={onChange} name="pin6" maxLength={1}
            className={`form-control w-100 mr-2 shadow-none text-center ${errors.pin6 && 'border-danger'}`} type="text"
            onKeyDown={e => handleFocusBackward(e)}
          />

        </div>

      </div>
    );
  

}, [values.pin1, values.pin2, values.pin3, values.pin4, values.pin5, values.pin6, errors.pin1,
errors.pin2, errors.pin3, errors.pin4, errors.pin5, errors.pin6,onChange,hadlePastedData])


export default TextField
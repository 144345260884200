import React, { Component } from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import './Section2.css'
import man from "../../../../assets/images/man.png";


export class Section2 extends Component {
    render() {

        return (

            <Container className='pl-5 pt-5 pr-5' >
                <Card
                    className='ml-3 '
                    style={{
                        background: "#DEEBE9",

                    }}

                >
                    <Row>
                        <Col sm={12} md={7} >
                            <Card.Body >
                                <h5 className='sec2_head'>ABOUT US</h5>
                                <h3 className='sec2_head1'>  The future of <br />quality healthcare</h3>

                            </Card.Body>
                        </Col>
                        <Col sm={12} md={5}>
                            <Card.Img
                                src={man}
                                alt='doctor'
                                className='sec2_img'
                                height={"350px"}
                            />
                        </Col>
                    </Row>
                </Card>
            </Container>
        )
    }
}

export default Section2
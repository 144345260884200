import React, { Component } from 'react';
import { Layout } from '../../component/shared';


import Section3 from './landingpage/Section3/Section3';
import Section4 from './landingpage/Section4/Section4';

import Section6 from './landingpage/Section6/Section6';






class PatientPage extends Component {

    render() {
        return (
            <Layout className="registration bg-white-header" isHeader={true} isFooter={true}>
                <div className='py-5 px-2 bg-white'></div>
                <div className='section section66 bg-white'>


                    <Section6  {...this.props} />
                    <Section4 />
                    <Section3 />

                </div>
            </Layout>
        );
    }
}
export default PatientPage;
import React, { Component } from 'react'
import { CardGroup, Card, Container } from 'react-bootstrap'
import { BiFile } from "react-icons/bi";
import { BsFillHandIndexFill } from "react-icons/bs";
import { BiCard } from "react-icons/bi";
import './Section2.css';

export class Section2 extends Component {
    render() {
        return (
            <div className='bg-white section2 py-5'>
                <Container>
                    <h5>QUICK SIGNUP</h5>
                    <h3 className='mt-2'>3 steps to get started</h3>
                    <CardGroup className='cardGroup'>
                        <Card className='border-0 s2card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title><BiFile size={36} style={{ color: '#81A0DE' }} /></Card.Title>
                                <Card.Text>
                                    <h6>Provide details</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry<br /> </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='ml-3 border-0 s2card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title><BsFillHandIndexFill size={36} style={{ color: '#81A0DE' }} /> </Card.Title>
                                <Card.Text>
                                    <h6>Choose specialization</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting<br /> </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='ml-3 border-0 s2card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title><BiCard size={36} style={{ color: '#81A0DE' }} /></Card.Title>
                                <Card.Text>
                                    <h6>Upload certificate </h6>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting<br /> </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Container>
            </div>
        )
    }
}

export default Section2

/*eslint-disable*/
let _ = require('underscore');
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

function hasDuplicates(arr) {
  if(Array.isArray(arr)){
    return _.uniq(arr).length !== arr.length;
  }
}
export const formValidation = {
    firstname: [
        {
            required: true,
            message: "Firstname is required!",
        },
        {
            pattern: new RegExp('^[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*(?!\\d)[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*$'),
            message: "Accepts characters, space, hyphen(-) and singlequote(') only",
        },
        {
            max: 100,
            message: "Accepts upto 100 chars",
        }
    ],
    middlename: [
        {
          pattern: new RegExp('^[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*(?!\\d)[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*$'),
          message: "Accepts characters, space, hyphen(-) and singlequote(') only",
        },
        {
          max: 100,
          message: "Accepts upto 100 chars",
        }
      ],
    name: [
        {
            pattern: new RegExp('^[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*(?!\\d)[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*$'),
            message: "Accepts characters, space, hyphen(-) and singlequote(') only",
        },
        {
            max: 100,
            message: "Accepts upto 100 chars",
        }
    ],
    lastname: [
        {
            required: true,
            message: "Lastname is required!",
          },
          {
            pattern: new RegExp('^[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*(?!\\d)[a-zA-Z0-9]+(?:[-\'\\s][a-zA-Z0-9]+)*$'),
            message: "Accepts characters, space, hyphen(-) and singlequote(') only",
          },
          {
            max: 100,
            message: "Accepts upto 100 chars",
          }
    ],
    username: [
        {
            required: true,
            message: "Username is required!",
        },
        {
            pattern: new RegExp('^[a-zA-Z0-9]+$'),
            message: "Accepts alphanumeric characters only",
        },
        {
            max: 100,
            message: "Accepts upto 100 chars",
        }
    ],
    email: [
        {
            type: 'email',
            message: 'Please enter valid email!',
        },
        {
            required: true,
            message: 'Please enter your email!',
        },
    ],
    password: [
        {
            required: true,
            message: "Please enter your password!",
        },
        {
            min: 8,
            pattern: new RegExp('^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
            message: 'Password must contains 8 to 25 characters, uppercase letter, number, and special character'
        },
        {
            max: 25,
            message: 'Password cannot be longer than 25 characters'
        }
    ],
    confirmpassword: [
      {
          required: true,
          message: 'Please confirm your password!',
      },
      {
        min: 8,
        pattern: new RegExp('^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
        message: 'Confirm password must contains 8 to 25 characters, uppercase letter, number, and special character'
    },
      {
        max: 25,
        message: 'Confirm password cannot be longer than 25 characters'
     },
      ({ getFieldValue }) => ({
          validator(_, value) {
              if (!value || getFieldValue('newpassword') === value) {
                  return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
      }),
  ],
  regconfirmpassword: [
    {
        required: true,
        message: 'Please confirm your password!',
    },
    {
      min: 8,
      pattern: new RegExp('^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
      message: 'Confirm password must contains 8 to 25 characters, uppercase letter, number, and special character'
  },
    {
      max: 25,
      message: 'Confirm password cannot be longer than 25 characters'
   },
    ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    }),
],
    verificationcode: [
        {
            required: true,
            message: 'Please input verificationcode!'
        },
        {
            len: 6,
            message: "Value should be 6 character",
        }
    ],
    number: [
        {
            required: true,
            message: "Please enter your Mobile number!",
        },
    ],
    mobilenumber: [
      {
          required: true,
          message: "Please enter your mobile number!",
      },    
      ({ getFieldValue }) => ({
        validator(_, value) {        
          if (value && getFieldValue('mobile') === value ) {          
              const countryCallingCode = parsePhoneNumber(value)?.countryCallingCode;           
              if (!countryCallingCode || !isValidPhoneNumber(value)) {
                return Promise.reject(new Error('Invalid Mobile number format'));  
              }
            return Promise.resolve();
          }
          return Promise.resolve();
        },
      })      
  ],
  mobilenumber_optional: [
    {
        required: false,
        message: "Please enter your mobile number!",
    },    
    ({ getFieldValue }) => ({
      validator(_, value) {        
        if (value && getFieldValue('mobile') === value ) {          
            const countryCallingCode = parsePhoneNumber(value)?.countryCallingCode;           
            if (!countryCallingCode || !isValidPhoneNumber(value)) {
              return Promise.reject(new Error('Invalid Mobile number format'));  
            }
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    })      
],
  emergencycontact: [       
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && getFieldValue('emergencycontact') === value ) {          
            if (!isValidPhoneNumber(value)) {
              return Promise.reject(new Error('Invalid Emergency contact number format'));

            }
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    })      
],
  
  emailChecker: [
    {
      pattern: new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,4}){1,2}$'),
      message: "Please enter valid email!"
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && getFieldValue('email') === value ) {
            let checkEmail = value?.split('@');
            let extensions = checkEmail?.[1]?.split('.');
            if (hasDuplicates(extensions)) {
              return Promise.reject(new Error('Duplicate domains are not allowed!'));

            }
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    })

  ],
  provideremailChecker: [
    {
        required: true,
        message: "Email is required!",
      },
    {
      pattern: new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,4}){1,2}$'),
      message: "Please enter valid email!"
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && getFieldValue('email') === value ) {
            let checkEmail = value?.split('@');
            let extensions = checkEmail?.[1]?.split('.');
            if (hasDuplicates(extensions)) {
              return Promise.reject(new Error('Duplicate domains are not allowed!'));

            }
          return Promise.resolve();
        }
        return Promise.resolve();
      },

    })

  ],
  npi: [
    {
      required: true,
      message: "Please input your NPI!",
    },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: "Accepts Numbers only",
    },
    {
      min: 10,
      message: "Minimum 10 digits",
    },
    {
      max: 10,
      message: "Maximum 10 digits",
    }
  ],

  speciality: [
    {
      required: true,
      message: "Please select your speciality!",
    },
  ],
  taxonomy: [
    {
      required: false,
      message: "Please select your taxonomy!",
    },
  ],
  experience: [
    {
      required: true,
      message: "Please input your experience!",
    },
    {
      pattern: new RegExp("(^100$)|^[1-9]\\d?$"),
      message: "Please input valid experience between 1 to 100",
    },
  ],
  assignee: [
    {
      required: true,
      message: "Please select Assignee!",
    },
  ],
  description: [
    {
      required: true,
      message: "Please enter Description!",
    },
  ],
   //Specific for reset password
  role: [
    {
      required: true,
      message: "Role is required!",
    },
  ],
  newpassword: [
    {
      required: true,
      message: "Please input your password!",
    },
    {
      min: 8,
      pattern: new RegExp('^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
      message: 'Password must contain at least 8 characters, uppercase letter, number, and special character'
    }
  ],
  confirmnewpassword: [
    {
      required: true,
      message: 'Please confirm your password!',
  },
  ({ getFieldValue }) => ({
      validator(_, value) {
          if (!value || getFieldValue('newpassword') === value) {
              return Promise.resolve();
          }
          return Promise.reject(new Error('The two passwords that you entered do not match!'));
      },
  }),
  ],
  time_slot: [
    {
      required: true,
      message: "Please choose atleast one Slot!",
    }
  ],
  dateofbirth: [
    {
      required: true,
      message: "Please input your Date of birth!",
    }
  ],
  bloodgroup: [
    {
      required: false,
      message: "Please input your Blood group!",
    }
  ],
  gender: [
    {
      required: false,
      message: 'Please select Gender!'
    }
  ],
  genderlocalprovider: [
    {
      required: true,
      message: 'Please select Gender!'
    }
  ],
  maritalstatus: [
    {
      required: false,
      message: "Please input your Marital status!",
    }
  ],
  height: [
    {
      required: false,
      message: "Please input your Height!",
    },
    {
      pattern: new RegExp("^([1-9]|[0-9][0-9]|[01][0-9][0-9]|20[0-0])$"),
      message: "Please input valid Height!",
    }  
  ],
  weight: [
    {
      required: false,
      message: "Please input your Weight!",
    },
    {
      pattern: new RegExp("^([1-9]|[0-9][0-9]|[01][0-9][0-9]|20[0-0])$"),
      message: "Please input valid Weight!",
    }  
  ],
  location: [
    {
      required: false,
      message: "Please input your Location!",
    },
    {
      pattern: new RegExp('^[a-zA-Z0-9.]+(?:[-\',\\s][\\s]{0,1}[a-zA-Z0-9]+)*(?!\\d)[a-zA-Z0-9.]+(?:[-\',\\s][a-zA-Z0-9.]+)*$'),
      message: "Accepts characters, space, hyphen(-), dot(.) and singlequote(') only",
    },
    {
      max: 100,
      message: "Accepts upto 100 chars",
    }

  ],
  licenseid: [
    {
        required: true,
        message: "Please input your License No",
    },
],
}


import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { connect } from 'react-redux'
import { getProviderListDetails } from '../../../actions/internaluser/ProviderListAction'
import { message } from 'antd';
import ProviderListService from '../../../services/admin/ProviderList.Service';
//import ProviderVerificationService from '../../../services/internaluser/ProviderList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import CreateProvider from '../../admin/providers/CreateProvider';
import { formikFormalidation } from '../../general/formvalidator/validationFroms';
import "../../provider/profile/providerProfile.style.css"
import UpdateProviderScheduler from '../../admin/providers/UpdateProviderScheduler';
import BootstrapTable from 'react-bootstrap-table-next';
import { formateName } from '../../patient/enquiry/EnquiryUtils';
import { Badge, Button, Modal, Spinner, Form } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Formik } from 'formik';
// import { formikFormalidation } from '../../general/formvalidator/validationFroms';
import * as yup from 'yup'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RiCalendar2Line } from "react-icons/ri";


export class ProviderVerificationComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      npiresult: {},
      all_specialities: [],
      user_taxonomies: [],
      providers: [],
      stages: [],
      selected_provider: {},
      modalisVisible: false,
      loading: false,
      showCreateProviderModal: false,
      provider_status: false,
      userTypeInfo: { firstname: "", lastname: "", NPI: "", experience: "", speciality: "", stage_id: "", taxonomy: "", assignee_id: "", timezone: "" }
    }
    this.handleSpecialityChange = this.handleSpecialityChange.bind(this);
  }


  handleClose = () => {
    this.setState({ modalisVisible: false });
  }

  showCreateProviderModal = () => {
    this.setState({
      showCreateProviderModal: true,
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };

  handleSelectTaxonomy(value) {
    if (value) {
      this.setState({
        userTypeInfo: {
          ...this.state.userTypeInfo,
          taxonomy: value
        }
      });
    }
  }

  handleExpr = (val) => {
    this.setState({
      userTypeInfo: {
        ...this.state.userTypeInfo,
        experience: val
      }
    });
  }
  setDefaultstage = () => {
    this.state.stages?.map((item) => {
      if (item.stage === "New") {
        this.setState({
          userTypeInfo: { ...this.state.userTypeInfo, stage_id: item._id }
        });       
      }
      return true;
    });   
  }
  onFinish = async (values) => {
    let values1 = {
      ...values, speciality: Array.isArray(values.speciality) ? values.speciality : [values.speciality],
      taxonomy: Array.isArray(values.taxonomy) ? values.taxonomy : [values.taxonomy]
    }
    const allFormData = {
      formData: values1,
      currentProvider: this.state.selected_provider,
    }
    try {
      this.setState({ loading: true })
      let response = await ProviderListService.update(allFormData);

      if (response && response.data && response.data.status === "success") {
        message.success(response.data.message);

        this.setState({ loading: false, modalisVisible: false, selected_provider: [] })
        this.getProviderData()
      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }
    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  set_provider_status = (record) => {
    let status = false;
    if (record && record.providerInfo && record.providerInfo.length > 0) {
      let data = record.providerInfo[0];
      status = data.status && data.status === "Active" ? true : false;
    }
    return status
  }

  getnpi = async (npi_id) => {
    try {
      this.setState({ loading: true })
      const response = await ProviderListService.getNPIDetails(npi_id)
      this.setState({ loading: false })
      if (response && response.data && response.data.status === "success") {
        this.setState({ npiresult: response.data })
      } else {
        this.setState({ npiresult: {} })
        message.error("Error while fetching NPI details : " + response.data.message);
      }
    } catch (error) {
      this.setState({ loading: false })
      errorMessage(error)
    }
  }

  getProviderData() {
    this.props.getProviderListDetails().then((response) => {
      if (response.users) {
        let providers = response.users
        let stages = response.stages
        let all_specialities = response.specialities
        this.setState({ providers, stages, all_specialities })
        this.setDefaultstage()
      }
    }).catch(err => {
      errorMessage(err)
    })
  }

  componentDidMount() {
    this.getProviderData()
  }

  handleCancel = () => {
    this.setState({ showCreateProviderModal: false });
  };

  handleModalOk = () => {
    this.setState({ modalisVisible: false });
  };
  // Set Stages
  set_stage = (stage_id) => {
    let stage;
    const filteredResults = this.state.stages.filter(obj => obj._id === stage_id)
    if (filteredResults && filteredResults.length > 0) {
      stage = filteredResults[0] && filteredResults[0]._id
    } else { stage = null }
    return stage
  }

  getFilteredTaxonomies(filteredResults) {
    var filtered_taxonomies = []
    if (filteredResults && filteredResults.length > 0) {
      filteredResults.forEach(e => {
        if ('taxonomies' in e) {
          var taxonomies = e['taxonomies']
          taxonomies.forEach(element => {
            filtered_taxonomies.push(element)
          });
        }
      })
    }
    return filtered_taxonomies
  }
  handleSpecialityChange(id) {
    const filteredResults = this.state.all_specialities.filter(result => id.includes(result._id))
    const user_taxonomies = this.getFilteredTaxonomies(filteredResults)
    this.setState({ user_taxonomies })
    this.formRef.current.setFieldsValue({ taxonomy: [] })
  }

  onChangeSwitch(checked) {
    this.setState({ provider_status: checked });
  }
  handleSelect(value) {
    this.state.all_specialities.map((data) => {
      if (data._id === value) {
        this.setState({ user_taxonomies: data.taxonomies })
      }
      return data;
    })
    this.setState({
      userTypeInfo: {
        ...this.state.userTypeInfo,
        taxonomy: "",
        speciality: value
      }
    });
  }

  getTimezoneList = async () => {
    const responses = await ProviderListService.getTimezoneList();
    if (responses.data.status === "success") {
      this.setState({ timezone: responses.data.data });
    }
  }

  edit_record = (record) => {
    setTimeout(() => {
      if (record && record.providerInfo && record.providerInfo.length > 0) {
        //let stage;
        this.setState({ selected_provider: record });
        this.getTimezoneList();

        let provider_status = this.set_provider_status(record)
        this.setState({ provider_status: provider_status });

        const { npi, experience, speciality, taxonomy } = record.providerInfo[0] || {};
       // stage = this.set_stage(stage_id);

        const filteredResults = this.state.all_specialities.filter(result => speciality && speciality.includes(result._id))
        const user_taxonomies = this.getFilteredTaxonomies(filteredResults)
        this.setState({ user_taxonomies, taxonomy })

        this.getnpi(npi);

        const userInfo = {
          firstname: record.firstname || "",
          lastname: record.lastname || "",
          assignee_id: record.providerInfo[0].assignee_id,
          stage_id: record.providerInfo[0].stage_id || this.state.userTypeInfo.stage_id,
          NPI: npi,
          experience: experience,
          speciality: speciality ? record.providerInfo[0].speciality[0] : "",
          taxonomy: taxonomy ? record.providerInfo[0].taxonomy[0] : "",
          timezone: record.timezone || "",
          provider_status: provider_status || false
        }
        this.setState({ userTypeInfo: userInfo });
      } else {
        message.error("Not a valid Provider")
        this.setState({ modalisVisible: false });
      }
    }, 1);
    this.setState({ modalisVisible: true });
  }
  get_stage = (data) => {
    let stage_name = "";
    if (data && data.stage_id) {
      const filteredResults = this.state.stages.filter(obj => obj._id === data.stage_id)
      if (filteredResults && filteredResults.length > 0) {
        stage_name = filteredResults[0] && filteredResults[0].stage
      } else { stage_name = "-" }
    } else { stage_name = "-" }
    return stage_name
  }

  render() {
    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      lastname: formikFormalidation.lastname,
      NPI: formikFormalidation.npi,
      taxonomy: formikFormalidation.taxonomy,
      speciality: formikFormalidation.speciality,
      experience: formikFormalidation.experience,
      timezone: formikFormalidation.timezone
    });
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );

    const options = {
      sizePerPage: 5,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };

    const columns = [
      {
        text: 'First Name',
        dataField: 'firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              return formateName(record.firstname ? record.firstname : '')
            })
              ()}
          </>
        )
      },
      {
        text: 'Last Name',
        dataField: 'lastname',
        formatter: (text, record) => (
          <>
            {(() => {
              return formateName(record.lastname ? record.lastname : '')
            })
              ()}
          </>
        )
      },
      {
        text: 'NPI',
        dataField: 'npi',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                return (
                  <span>{record.providerInfo[0].npi}</span>
                )
              }
            })
              ()}
          </>
        )
      },
      // {
      //   text: 'Stage',
      //   dataField: 'stage_id',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record.providerInfo && record.providerInfo.length > 0) {
      //           let data = record.providerInfo[0]
      //           let stage_name = this.get_stage(data)
      //           return (
      //             <span>{stage_name}</span>
      //           )
      //         }
      //       })()}
      //     </>
      //   )
      // },
      {
        text: 'Active',
        dataField: 'status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo && record.providerInfo.length > 0) {
                let data = record.providerInfo[0]
                if (data.status === 'Active')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Active </Badge>
                  </React.Fragment>
                  )
                if (data.status === 'Inactive')
                  return (<React.Fragment>
                    <Badge style={{ backgroundColor: '#FBC5C5', color: '#EB4747', fontSize: '14px' }}>Inactive</Badge>
                  </React.Fragment>
                  )
              }
            })
              ()}
          </>
        )
      },
      {
        text: 'Operation',
        dataField: 'operation',
        formatter: (_, current_record) => (
          <>
            {(() => {
              return (
                <Button variant='link' onClick={() => this.edit_record(current_record)}>
                  Edit
                </Button>
              )
            })()}
          </>
        ),
      }
    ];

    return (<React.Fragment>
      <AfterLoginLayout>
        {this.props.providersStore.loading ?
          <div style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
          :
          <div className="container-fluid">
            <React.Fragment>
              <div style={{ marginBottom: 16, float: 'right' }}>
                <Button type="primary" onClick={this.showCreateProviderModal}> Create New Provider</Button>
              </div>
            </React.Fragment>
            <React.Fragment>
              <div className="banner-header">
                <h2 className='ml-3'> Global Providers</h2>
              </div>
            </React.Fragment>
            <Modal          
              show={this.state.modalisVisible}
              onHide={this.handleClose}
              backdrop="static"
              keyboard={true}
              centered={false}
             >
              <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                      Update Provider
                    </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-3'>
                <Tabs
                  defaultActiveKey="provider Information"
                  transition={false}
                  id="fill-tab-example"
                  className="mb-3"
                  fill
                >
                  <Tab eventKey="provider Information" title="Provider Information" className='ml-2'>
                    <Formik
                      validationSchema={schema}
                      enableReinitialize={true}
                      initialValues={this.state.userTypeInfo}
                      onSubmit={async (values) => this.onFinish(values)}
                    >
                      {(({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                        <Form onSubmit={handleSubmit} className='mx-4'>
                          <div className='row'>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold text-dark">
                                First Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <Form.Control
                                name="firstname"
                                onChange={handleChange}
                                value={values.firstname}
                                isInvalid={errors.firstname && touched.firstname}
                              />
                              {
                                errors.firstname && touched.firstname && (
                                  <div className="invalid-feedback">{errors.firstname}</div>
                                )
                              }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold"> Last Name  <span style={{ color: "red" }}>*</span></label>
                              <Form.Control
                                onChange={handleChange}
                                name='lastname' value={values.lastname}
                                isInvalid={errors.lastname && touched.lastname}
                              />
                              {
                                errors.lastname && touched.lastname && (
                                  <div className="invalid-feedback">{errors.lastname}</div>
                                )
                              }
                            </div>
                          </div>


                          <div className='row'>
                            <div className='col form-group mr-2' >
                              <label className="mb-1 font-weight-bold"> NPI  <span style={{ color: "red" }}>*</span></label>
                              <Form.Control type="text"
                                name="NPI"
                                value={values.NPI}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.NPI && touched.NPI} />
                              <span className={errors.NPI && touched.NPI ? 'invalid short-label' : 'short-label'}></span>

                              {
                                errors.NPI && touched.NPI && (
                                  <div className="invalid-feedback">{errors.NPI}</div>
                                )
                              }

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold text-dark">
                                Experience <span style={{ color: "red" }}>*</span>
                              </label>
                              <Form.Control
                                name="experience" onChange={e => { handleChange(e.target.value); this.handleExpr(e.target.value) }} value={values.experience}
                                isInvalid={errors.experience} />

                              {
                                errors.experience && (
                                  <div className="invalid-feedback">{errors.experience}</div>
                                )
                              }
                            </div>
                          </div>

                          <div className='row' style={{"display":"none"}}>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold text-dark">
                                Stage <span style={{ color: "red" }}>*</span>
                              </label>
                              <Form.Control as={"select"}
                                name="stage_id"
                                onChange={handleChange}
                                value={values.stage_id}
                                className='lastname'>

                                <option value=""></option>
                                {
                                  this.state.stages?.map((item) => {
                                    const { stage, _id } = item;
                                    return <option disabled={item.stage === "New"} value={_id} key={_id}>{stage}</option>
                                  })
                                }
                              </Form.Control>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold text-dark">
                                Speciality <span style={{ color: "red" }}>*</span>
                              </label>
                              <Form.Control as={"select"}
                                name="speciality"
                                className='selectors'
                                isInvalid={errors.speciality && touched.speciality}
                                onChange={event => { handleChange(event); this.handleSelect(event.target.value) }}
                                onBlur={handleBlur}
                                value={values.speciality}
                              >
                                <option value="">Select</option>

                                {
                                  this.state.all_specialities?.map((item) => {
                                    const { name, _id } = item;
                                    return <option value={_id} key={_id}>{name}</option>

                                  })
                                }

                              </Form.Control>
                              {!this.state.speciality &&
                                <span className={errors.speciality && touched.speciality ? 'invalid short-label' : 'short-label'}></span>
                              }
                              {
                                errors.speciality && touched.speciality && (
                                  <div className="invalid-feedback">{errors.speciality}</div>
                                )
                              }

                            </div> </div>
                          <div className='row'>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold text-dark">
                                Taxonomy <span style={{ color: "red" }}>*</span>
                              </label>

                              <Form.Control
                                as={"select"}
                                className='selectors'
                                isInvalid={errors.taxonomy}
                                onChange={(value) => { handleChange(value); this.handleSelectTaxonomy(value.target.value) }}
                                onBlur={handleBlur}
                                name="taxonomy"
                                value={values.taxonomy}
                                id={values.taxonomy ? values.taxonomy : ""}
                              >
                                <option value="">Select</option>
                                {
                                  this.state.user_taxonomies?.map((item) => {
                                    return <option value={item._id} key={item._id}>{item.code}</option>
                                  })
                                }

                              </Form.Control>
                              {
                                errors.taxonomy && (
                                  <div className="invalid-feedback">{errors.taxonomy}</div>
                                )
                              }
                            </div></div>

                          <div className='row'>
                            <div className='col form-group mr-2'>
                              <label className="mb-1 font-weight-bold text-dark">
                                TimeZone  <span style={{ color: "red" }}>*</span>
                              </label>
                              <Form.Control as={"select"}
                                name="timezone"
                                className='form-control shadow-none'
                                isInvalid={errors.timezone}
                                onChange={handleChange}
                                value={values.timezone ? values.timezone : ""}
                                id={values.timezone ? values.timezone : ""}>
                                <option value="">Select</option>
                                {
                                  this.state.timezone?.map((item) => {
                                    const { name, _id } = item;
                                    return <option value={_id} key={_id}>{name}</option>
                                  })
                                }
                              </Form.Control>
                              {
                                errors.timezone && <div className="text-danger">{errors.timezone}</div>
                              }
                            </div>
                          </div>
                          <div className='row'>
                                <div className='col form-group mr-2'>
                                  <Form.Check
                                    checked={this.state.provider_status}
                                    type="switch"
                                    id="custom-switch"
                                    name="provider_status"
                                    onChange={event => { handleChange(event); this.onChangeSwitch() }}
                                  /></div></div>
                          <Button
                            variant="primary"
                            type="submit"
                            style={{ width: "100%", marginTop: "10px", fontFamily: "manrope" }}>
                            Update User
                          </Button>
                        </Form>
                      ))}

                    </Formik>
                  </Tab>

                  <Tab eventKey="npi result" title="NPI Result">
                    <div className='mx-4'>
                      <pre className="jsonView">
                        {JSON.stringify(this.state.npiresult, null, 2)}
                      </pre>
                    </div>
                  </Tab>

                  <Tab eventKey="schedule" title="Schedule">
                    <UpdateProviderScheduler className='mx-4'
                      selected_provider={this.state.selected_provider}
                      {...this.props} />
                  </Tab>

                </Tabs>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.showCreateProviderModal}
              onHide={this.handleCancel}
              backdrop="static"
              keyboard={true}
              centered={false}
              >
              <Modal.Header closeButton>
                <Modal.Title>
                  Create Global Provider
                </Modal.Title>
              </Modal.Header>
              <Modal.Body  className='p-5'>
                <CreateProvider
                  {...this.props}
                />
              </Modal.Body>
            </Modal>




            {this.state.providers.length > 0 ? <BootstrapTable
              keyField='_id'
              data={this.state.providers}
              hover={false}
              columns={columns}
              bordered={false}
              pagination={paginationFactory(options)}
            /> :
              <div class="card" style={{ marginTop: "15px" }}>
                <div class="card-body text-center">
                  <RiCalendar2Line size={60} className='text-secondary' />
                  <h6>No Data Found</h6>
                  <small><p className='text-secondary'>Create an provider request, share your health concerns, and connect with expert doctors.
                  </p></small>
                </div>
              </div>}
          </div>}
      </AfterLoginLayout>
    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProviderListDetails: () => dispatch(getProviderListDetails())
  }
}
const mapStateToProps = state => {
  return {
    providersStore: state.providerUserList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderVerificationComponent)
import React, { Component } from 'react'
import { CardGroup, Card, Container } from 'react-bootstrap'
import { BiPhoneCall } from "react-icons/bi";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { BiMessageDots } from "react-icons/bi";
import './Section2.css';

export class Section2 extends Component {
    render() {
        return (
            <div className='bg-white section2 py-5'>
                <Container className='gd'>
                    <h5>QUICK SOLUTION</h5>
                    <h3 className='mt-2'>3 steps to get your solution</h3>
                    <CardGroup className='cardGroup'>
                        <Card className='border-0 card s2card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title><BiPhoneCall size={36} style={{ color: '#81A0DE' }} /></Card.Title>
                                <Card.Text>
                                    <h6>Make an Enquiry</h6>
                                    <p>We’re here to help whenever you feel ill, so make an enquiry and we’ll help you</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='ml-3 border-0 s2card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title><BsFillCalendarCheckFill size={36} style={{ color: '#81A0DE' }} /> </Card.Title>
                                <Card.Text>
                                    <h6>Schedule appointment</h6>
                                    <p>We’ll check your opinion and  connect you to the best doctors </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='ml-3 border-0 s2card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title><BiMessageDots size={36} style={{ color: '#81A0DE' }} /></Card.Title>
                                <Card.Text>
                                    <h6>Get an opinion</h6>
                                    <p>From seasonal allergies to  treatments, we have the resource.</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Container>
            </div>
        )
    }
}

export default Section2
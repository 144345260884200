import React from 'react'
import store from '../../../store';
import { decryptData } from '../../../utils/SecureStorage';
import 'bootstrap/dist/css/bootstrap.min.css'



function getMasterData() {
  if (store.getState().storage && store.getState().storage.master) {
    return decryptData(store.getState().storage.master)
  } else {
    return null
  }
}


export function GenderComponent(props) {
  const masterData = getMasterData()
  const handleGend = e => {
    const gender = masterData.Gender.find(item => item.value === e.target.value)
    props.handleOnSelectGender(gender.code)
  }
  return (
    <React.Fragment>
      {(() => {
        if (masterData && masterData.Gender && masterData.Gender.length > 0) {
          return (
            <div className="d-flex flex-column">
              <label className='font-weight-bold'>Gender</label>
              <div className='d-flex justify-content-even'>
                {masterData.Gender.map((item) => {
                  return (
                    <div key={item._id} className="form-check form-check-inline">
                      <input key={item._id} className="form-check-input" checked={item.code === props.genderValue}
                        onChange={e => handleGend(e)} type="radio" name={item.code} id={item.code} value={item.value} />
                      <label className="form-check-label" htmlFor="inlineRadio1">{item.value}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      })()}
    </React.Fragment>
  );
}
export function GenderComponentrequired(props) {
  const masterData = getMasterData()
  const handleGend = e => {
    const gender = masterData.Gender.find(item => item.value === e.target.value)
    props.handleOnSelectGender(gender.code)
  }
  return (
    <React.Fragment>
      {(() => {
        if (masterData && masterData.Gender && masterData.Gender.length > 0) {
          return (
            <div className="d-flex flex-column">
              <label className='font-weight-bold'>Gender<span style={{ color: "red" }}>*</span></label>
              <div className='d-flex justify-content-even'>
                {masterData.Gender.map((item) => {
                  return (
                    <div key={item._id} className="form-check form-check-inline">
                      <input key={item._id} className="form-check-input" checked={item.code === props.genderValue}
                        onChange={e => handleGend(e)} type="radio" name={item.code} id={item.code} value={item.value} />
                      <label className="form-check-label" htmlFor="inlineRadio1">{item.value}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      })()}
    </React.Fragment>
  );
}
export function BloodGroupComponent(props) {
  const masterData = getMasterData()
  const handleBlood = e => {
    const eventBloodCode = masterData.BloodGroup.find(item => item.value === e.target.value)
    props.onSelectBloodGroup(eventBloodCode.code)
  }
  return (
    <>
      {(() => {
        if (masterData && masterData.BloodGroup && masterData.BloodGroup.length > 0) {
          return <div>
            <label className='font-weight-bold mb-1'>Blood Group</label>
            <select as="select" className='form-control shadow-none'
              onChange={e => handleBlood(e)}>
              {masterData.BloodGroup.map((item) => {
                return <option selected={props.bloodGroupValue === item.code} id={item.code} code={item.code} value={item.value} key={item._id}>{item.value}</option>
              })}
            </select>
          </div>
        }
      })()}
    </>
  );
}

export function MaritalStatusComponent(props) {
  const masterData = getMasterData()

  return (
    <React.Fragment>
      {(() => {
        if (masterData && masterData.MaritalStatus && masterData.MaritalStatus.length > 0) {
          return <div>
            <label className='font-weight-bold mb-1'>Maritial Status</label>
            <select className='form-control shadow-none'
              onChange={e => props.handleOnSelectMaritalStatus(e.target.value)}>
              {masterData.MaritalStatus.map((item) => {
                return <option selected={props.maritalstatusValue === item.code} code={item.code} value={item.code} key={item._id} id={item.code}>{item.value}</option>
              })}
            </select>
          </div>
        }
      })()}
    </React.Fragment>
  );
}
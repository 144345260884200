import ProfileDropDown from "./profile-dropdown";
import OpinionRequest from "../patient/dashboard/OpinionRequest";
import { useState } from "react";
import { authenticationService } from '../general/login/login.service';
import Role from '../../constants/role';
// import { useHistory, useLocation } from 'react-router-dom';

const SearchHeader = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const handleShow = () => { setOpenModal(true) };
    const handleClose = () => { setOpenModal(false) };
    const currentUser = authenticationService.currentUserValue;

    return (

        <div className={props.from ? props.from + ' search-header text-right' : ' search-header text-right'}>
            {props.opinionRequest && currentUser.usertype !== Role.localprovider.name &&
                <div className='createenquiry col-lg-3 mr-3' style={{ zIndex: "100", verticalAlign: "top" }}><button onClick={handleShow} className="btn btn-primary ps-1 m-0"><i className="fa fa-solid fa fa-plus pr-1"></i>New opinion request</button>
                    {openModal && <OpinionRequest displayname={currentUser && currentUser.displayname} patient_id={currentUser && currentUser.usertype_id} openModal={openModal} setOpenModal={handleClose} />}
                </div>
            }
            <div className={props.from === 'dashboard' ? props.from + ' col-lg-3 profiledetails p-0' : ' profiledetails p-0'} style={{ zIndex: "100" }}>
                <ul className="nav header-navbar-rht">
                    <ProfileDropDown view={'desk'} />
                </ul>
            </div>
        </div>
    )
}
export default SearchHeader;
//import moment from 'moment';
import moment from "moment-timezone";
import { authenticationService } from '../../general/login/login.service';

export const get_provider_user_obj = (record) => {
  let _data = "-";
  if (record.providerUserInfo && record.providerUserInfo.length > 0) {
    _data = record.providerUserInfo[0]
  }
  return _data
}

// export const get_speciality_obj = (record) => {
//   let _data = "-";
//   if (record.specialityinfo && record.specialityinfo.length > 0) {
//     _data = record.specialityinfo[0]
//   }
//   return _data
// }
export const get_schedule_details_obj = (record) => {
  let _data = "-";
  if (record.scheduleDetailsInfo) {
    _data = record.scheduleDetailsInfo
  }
  return _data
}
export const get_enquiry_obj = (record) => {
  let _data;
  if (record.enquiryInfo) {
    _data = record.enquiryInfo
  }
  return _data
}
export const get_room_details_obj = (record) => {
  let _data;
  if (record && record.roomInfo) {
    _data = record.roomInfo
  }
  return _data
}

export const parseAppointments = (_schedule) => {
  var upcoming = []
  var past = []
  if (_schedule && _schedule.length > 0) {
    _schedule.forEach(async e => {
      if (e && e.consultationInfo && e.consultationInfo.scheduleDetailsInfo) {
        let slotObj = e.consultationInfo.scheduleDetailsInfo
        if ('date' in slotObj && 'slot' in slotObj) {
          //var now = new Date();
          //const slot = new Date(slotObj['slot'])
          if (moment(slotObj['slot']).isValid()) {
            slotObj['slot'] = moment(slotObj['slot']).format('hh:mm A');
          }

          slotObj['slot'] = slotObj['slot'].replace(".", ":");
          // slotObj['slot'] = moment(slotObj['slot']).format('hh:mm A')
          let now = moment().tz(authenticationService && authenticationService.user_timezone).format();
          const slot = moment(slotObj['date'] + ' ' + slotObj['slot']).tz(authenticationService && authenticationService.user_timezone).format()
          if (slot >= now) {
            upcoming.push(e);
            upcoming = await upcoming.reverse();
          } else {
            past.push(e);
            past = await past.reverse();
          }
        }
      }
    })


    return {
      upcoming: upcoming,
      past: past
    }
  }
}

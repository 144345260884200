import React, { Component } from 'react'
import { Spinner, Row, Col } from 'react-bootstrap';
import { message } from 'antd';
import { Layout } from '../../shared';
import { AUTHROUTE } from "../../../constants/RouterPath"
import { errorMessage } from '../../../utils/ServiceHandler';
import './enquiry.style.css'
import { FaCheckCircle } from "react-icons/fa";
import { authenticationService } from '../../general/login/login.service';
// import moment from 'moment';
// import { get_formated_timeslot } from '../enquiry/EnquiryUtils';

export class AppointmentRescheduleSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            consultationInfo: ""
        }
    }

    getAppointmentDetails = async () => {
        try {
            this.setState({ loading: true })
            const state_vals = this.props && this.props.location && this.props.location.state
            if (state_vals) {
                const patientUserInfo = state_vals && state_vals.patientUserInfo
                const selected_appointment = state_vals && state_vals.selected_appointment
                const consultationInfo = state_vals && state_vals.consultationInfo
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({
                        patientUserInfo: patientUserInfo,
                        selected_appointment: selected_appointment,
                        consultationInfo: consultationInfo,
                        selected_date: this.props && this.props.location && this.props.location.state && this.props.location.state.selected_date,
                        selected_slot: this.props && this.props.location && this.props.location.state && this.props.location.state.selected_slot,
                    })
                }, 500)
            } else {
                this.setState({ loading: false })
                message.error("Date not found")
                //this.navigatetoLogin(this.props)
                this.props.history.push({
                    pathname: AUTHROUTE.PATIENT.DASHBOARDPATH
                });

            }

        } catch (error) {
            this.setState({ loading: false })
            errorMessage(error)
        }
    }

    componentDidMount() {

        this.getAppointmentDetails();
    }

    render() {
        const currentUser = authenticationService.currentUserValue;
        return (

            <Layout className="confirmationAppointment" isHeader={false} isFooter={false}>
                {this.state.loading &&
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-3 p-4' variant="primary">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                }
                <Row className='appointment'>
                    <Col lg={8} md={8} sm={12} className='leftappointment p-2'>
                        <div className='m-5 pt-5'>
                            <Row>
                                <Col>
                                    <div><FaCheckCircle size='5em' style={{ 'color': '#143C8C' }} />
                                        <p>SUCCESS!</p>
                                        <p className='headtext'>Your appointment has been rescheduled.</p>
                                        <p className='destext my-3'>We look forward to assist you with your health concern.</p></div>
                                </Col>
                            </Row>
                            <Row className='my-5 py-4 appointmentdetails'>

                                {this.props && this.props.location.state.selected_date && this.state.consultationInfo &&
                                    <>
                                        <Col>
                                            <div className='title'>PATIENT<div className='py-2'>{this.state.patientUserInfo && this.state.patientUserInfo.firstname}</div></div>
                                        </Col>
                                        <Col>
                                            <div className='title'>BOOKING NUMBER<div className='py-2'>{this.state.consultationInfo.orderCode}</div></div>
                                        </Col>
                                        <Col>
                                            <div className='title'>APPOINTMENT DATE & TIME<div className='py-2'>{this.state.consultationInfo.scheduleDetailsInfo && this.state.selected_date + ' ' + this.state.selected_slot}</div></div>
                                        </Col>
                                    </>}

                            </Row>
                            <Row><Col className='p-0'><a className='btn btn-primary m-0' href={currentUser.usertype === 'patient' ? AUTHROUTE.PATIENT.DASHBOARDPATH : AUTHROUTE.LOCALPROVIDER.DASHBOARDPATH}>Go to Dashboard</a></Col></Row>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} className='rightappointment p-2'>
                        <div className='my-5'>Need help ?<br />
                            <p>Contact us at <a href="mailto:support@gmail.com">support@gmail.com</a> or call us at 9995678777</p></div>
                    </Col>
                </Row>
            </Layout>
        )
    }

}


export default AppointmentRescheduleSuccess;
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux'
import { getEnquiryListDetails } from '../../../actions/patient/EnquiriesAction'
import { message } from 'antd';
import { authenticationService } from '../../general/login/login.service';
import Role from '../../../constants/role'
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { Spinner, Badge, Button, Modal, ModalTitle, Dropdown } from 'react-bootstrap';
import { get_user_obj, get_patient_obj, get_assignee, get_formated_date_only, formateName, get_enquiry_obj, get_assignee_obj } from './EnquiryUtils';
import { getStages, getSpecialities } from '../../../utils/PersistentData';
import SetAssignee from '../../admin/providers/SetAssignee';
import { FaTimes, FaStickyNote, FaRegCalendarAlt, FaRegIdBadge, FaStethoscope } from "react-icons/fa";
import { HiOutlineUsers } from 'react-icons/hi';
import { RiCalendar2Line } from "react-icons/ri";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button className='threedotbutton' variant='link'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </Button>
));


export class EnquiryTableView extends React.Component {
  formRef = React.createRef();
  assigneeFormRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      patient_id: this.props && this.props.patient_id,
      all_specialities: [],
      internalusers: [],
      stages: [],
      selectedRowKeys: [],
      allSelectedRows: [],
      selectedRows: [],
      loading: false,
      showMultiSectionOption: false,
      showCreate: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      currentUser: {},
      assignee_modal: false,
      modalVisible: false,
      viewer_visible: false,
      selected_enquiry: {},
      provider_user_obj: {},
      enquiry_obj: {},
      filterItems: this.props.showMultiSectionOption === true ? ['waiting'] : ['assigned', 'waiting'],
      waiting: [],
      assigned: [],
      ShowFilterList: [],
      // showList: [],
      hasSelected: false,
    }
  }
  handleImagePreviewCancel = () => {
    this.setState({ previewVisible: false });
  };


  handleCancel = () => {
    this.setState({ assignee_modal: false, selectedRows: [] });
    this.setState({ modalVisible: false });
  };

  showDrawer = () => {
    this.setState({
      viewer_visible: true,
    });
  };
  insert = (arr, index, newItem) => [
    ...arr.slice(0, index), newItem, ...arr.slice(index)

  ]

  onClose = () => {
    this.setState({
      viewer_visible: false,
    });
  };
  handleTableFilter = async (data, filterType) => {

    await this.setState({ ShowFilterList: [] });
    await this.setState({
      filterItems: await Array.from(new Set([...this.state.filterItems, data]))
    })
    if (this.state.filterItems.includes('assigned')) {
      await this.setState({ ShowFilterList: await this.state.assigned });
    }
    if (this.state.filterItems.includes('waiting')) {
      await this.setState({ ShowFilterList: await [...this.state.ShowFilterList, ...this.state.waiting] })
    }
    // this.renderFilteredList(data, filterType);
  }



  handleRemoveFilter = async (index) => {
    await this.state.filterItems.splice(index, 1);
    await this.setState({ ShowFilterList: [] });
    if (this.state.filterItems.includes('assigned')) {
      await this.setState({ ShowFilterList: await this.state.assigned });
    }
    if (this.state.filterItems.includes('waiting')) {
      await this.setState({ ShowFilterList: await [...this.state.waiting] })
    }
    if (this.state.filterItems.length === 0) {
      await this.setState({ ShowFilterList: await [...this.state.assigned, ...this.state.waiting] })
    }
    // this.renderFilteredList();
  }

  // renderFilteredList(data) {
  //   this.setState({
  //     showList: data
  //   })

  // }


  handleModalOk = () => {
    this.setState({ assignee_modal: false, selectedRows: [] });
    this.setState({ modalVisible: false });
  };

  handlePreview = file => {
    this.setState({
      previewImage: file.thumbUrl,
      previewVisible: true
    });
  };
  handleUpload = ({ fileList }) => {
    this.setState({ fileList });
  };

  getEnquiries = () => {
    this.handleCancel()
    this.setState({ loading: true })
    this.props && this.props.getEnquiryListDetails(this.props && this.props.patient_id).then(async (response) => {
      let enquiries = [];
      if (response.enquiries)
        enquiries = await (response.enquiries).slice(0, this.props.pageSize)
      let stages = getStages() || []
      let internalusers = response.internalusers
      let all_specialities = getSpecialities() || []

      this.setState({ enquiries, stages, internalusers, all_specialities })
      this.setState({
        waiting: await enquiries.filter(e => {

          return e.providerInfo === null


        })
      })

      this.setState({
        assigned: await enquiries.filter(e => {
          return e.providerInfo !== null
        })
      })
      this.setState({ ShowFilterList: [...this.state.assigned, ...this.state.waiting] })
      this.setState({ loading: false })

    }).catch(err => {
      errorMessage(err)
    })
  }
  setAssignee = (current_record, bulkassign) => {
    if (!bulkassign) {
      this.setState({ selectedRows: [current_record] });
    }
    this.setState({ assignee_modal: true })
  };



  showModal = () => {
    this.setState({
      modalVisible: true,
      fileList: []
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };
  onEnquiryFormFinish = async (values) => {
    if (this.state.fileList.length === 0) {
      message.error('Please upload Prescription')
      return
    }
    let formData = new FormData();
    formData.append("speciality_id", values.speciality || "");
    formData.append("enquiry_message", values.description || "");
    formData.append("patient_id", this.state && this.state.patient_id);

    for (let i = 0; i < this.state.fileList.length; i++) {
      const file = this.state.fileList[i];
      formData.append('file', file['originFileObj']);
    }

    try {
      this.setState({ loading: true });
      let response = await EnquiryListService.createEnquiry(formData)
      if (response.data.status === "success") {
        message.success(response.data.message);
        this.setState({ loading: false, modalVisible: false })
        this.getEnquiries()
      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  onFinishFailed = values => {
  }
  get_enquiry_ids = (selectedRows) => {
    var _ids = []
    if (selectedRows && selectedRows.length > 0) {
      selectedRows.forEach(e => {
        if ('providerinfo' in e) {
          var providerinfo = e['providerinfo']
          providerinfo.forEach(element => {
            _ids.push(element['_id'])
          });
        }
      })
      return _ids
    }
  }




  onSetAssigneeFinish = async (values) => {
    const _ids = this.state.selectedRow
    const allFormData = {
      formData: values,
      enquiry_ids: _ids
    }
    try {
      this.setState({ loading: true })
      let response = await EnquiryListService.bulkUpdateAssignee(allFormData);
      if (response && response.data && response.data.status === "success") {
        message.success(response.data.message);
        this.setState({ loading: false, assignee_modal: false, selectedRows: [] })
        this.getEnquiries()
      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }
    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }
  pick_slot_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/pickslot/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            // prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }

  view_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props && this.props.history && this.props.history.push({
          pathname: "/my/enquiries/view/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            enquiriesEdit: record.consultationInfo.length > 0 ? false : true
            // prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }

  edit_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/enquiry/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            // prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }

  pick_slot_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/pickslot/" + id,
          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            // prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }


  componentDidMount() {
    this.getEnquiries()
    const currentUser = authenticationService.currentUserValue;
    this.setState({
      showMultiSectionOption: this.props && this.props.showMultiSectionOption,
      showCreate: (this.props && this.props.showCreate) || false,
      currentUser: currentUser,
      patient_id: this.props && this.props.patient_id
    })

  }

  componentDidUpdate(prevProps) {
    if (this.props.showCreateEnquiry !== prevProps.showCreateEnquiry) {
      this.getEnquiries()
    }

  }


  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );

    const options = {
      sizePerPage: 10,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };


    const selectRow = {
      mode: 'checkbox',
      //clickToSelect: true,
      onSelectAll: async (isSelect, rows, e) => {
        if (isSelect) {
          this.setState({ selectedRows: await [...rows, ...this.state.selectedRows] });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        } else {
          const { selectedRows } = this.state;
          rows.forEach(row => {
            selectedRows.splice(
              selectedRows.indexOf(row), 1
            );
          });
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        }
        this.state.hasSelected = this.state.selectedRows.length > 0;

      },
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          const { selectedRows } = this.state;
          selectedRows.push(row);
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        } else {
          const { selectedRows } = this.state;
          selectedRows.splice(
            selectedRows.indexOf(row), 1
          );
          this.setState({ selectedRows: selectedRows });
          this.setState({ hasSelected: this.state.selectedRows.length > 0 });

        }
        this.setState({ hasSelected: this.state.selectedRows.length > 0 });

      }
    };



    const internalUser_columns = [
      {
        text: 'REQUESTED BY',
        dataField: 'patientInfo.firstname',
        key: ['patientInfo', '_id'],
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.patientInfo)
                return (<React.Fragment>
                  <span>{formateName(record.patientInfo.firstname)} </span>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <span> _ </span>
                </React.Fragment>
                )
            })()}
          </>
        ),

      },
      {
        text: 'SPECIALITY',
        dataField: 'enquiryInfo.speciality.name'
      },
      {
        text: 'DATE',
        dataField: 'enquiryInfo.createddate',
        formatter: (text, record) => (
          <>
            {(() => {
              let time_format = get_formated_date_only(record.enquiryInfo.createddate)
              return (<React.Fragment>
                <span >{time_format}</span>
              </React.Fragment>
              )
            })()}
          </>
        ),
      },
      {
        text: 'PROVIDER',
        dataField: 'providerInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo)
                return (<React.Fragment>
                  <span>{formateName(record.providerInfo.firstname)} </span>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <span> - </span>
                </React.Fragment>
                )
            })()}
          </>
        ),
      },
      // {
      //   text: 'STAGE',
      //   dataField: 'enquiryInfo.stage.name',
      //   key: ['enquiryInfo', 'stage', 'id'],
      // },
      {
        text: '',
        dataField: '',
        formatter: (text, record) => (
          <Button variant='link' className='p-0' onClick={() => this.edit_record(record)}>
            <FaStethoscope />  Assign provider
          </Button>
        ),
      },

    ]

    const admin_columns = [
      {
        text: 'REQUESTED BY',
        dataField: 'patientInfo.firstname',
        key: ['patientInfo', '_id'],
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.patientInfo)
                return (<React.Fragment>
                  <span>{formateName(record.patientInfo.firstname)} </span>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <span> _ </span>
                </React.Fragment>
                )
            })()}
          </>
        ),

      },
      {
        text: 'SPECIALITY',
        dataField: 'enquiryInfo.speciality.name'
      },
      {
        text: 'DATE',
        dataField: 'enquiryInfo.createddate',
        formatter: (text, record) => (
          <>
            {(() => {
              let time_format = get_formated_date_only(record.enquiryInfo.createddate)
              return (<React.Fragment>
                <span >{time_format}</span>
              </React.Fragment>
              )
            })()}
          </>
        ),
      },
      {
        text: 'PROVIDER',
        dataField: 'providerInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo)
                return (<React.Fragment>
                  <span>{formateName(record.providerInfo.firstname)} </span>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <span> - </span>
                </React.Fragment>
                )
            })()}
          </>
        ),
      },
      {
        text: 'ASSIGNED TO',
        dataField: 'assignee_id',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record) {
                let enquiry_obj = get_enquiry_obj(record)
                let assignee_obj = get_assignee_obj(enquiry_obj)
                let assignee_name = "-"
                if (assignee_obj) { assignee_name = assignee_obj.name }
                return (
                  <span>{assignee_name}</span>
                )
              }
            })()}
          </>
        ),
      },
      {
        text: '',
        dataField: '',
        formatter: (text, record) => (
          <Button variant='link' className='p-0' onClick={() => this.edit_record(record)}>
            <span style={{ padding: "10px" }}><FaStethoscope /></span>  Assign provider
          </Button>
        ),
      },
      {
        text: '',
        dataField: '',
        formatter: (text, record) => (
          <Button variant='link' className='p-0' onClick={() => this.setAssignee(record, false)}>
            <span style={{ padding: "10px" }}><HiOutlineUsers /></span>  Assign to team member
          </Button>
        ),
      },
    ]

    /*    const operations = [
          {
            title: 'Operation',
            dataIndex: 'operation',
            fixed: 'right',
            render: (_, current_record) => (
              <>
                <Button variant='link' onClick={() => this.view_record(current_record)}>
                  View
                </Button>
                <Button variant='link' onClick={() => this.edit_record(current_record)}>
                  Edit
                </Button>
              </>
            ),
          },
        ]
        */

    const columns = [
      {
        text: 'PATIENT',
        dataField: 'patientInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              return formateName(record.patientInfo ? record.patientInfo.firstname : '')
            })
              ()}
          </>
        )
      },
      {
        text: 'DATE',
        dataField: 'enquiryInfo.createddate',
        formatter: (text, record) => (
          <>
            {(() => {



              let time_format = get_formated_date_only(record.enquiryInfo.createddate)
              return (<React.Fragment>
                <span >{time_format}</span>
              </React.Fragment>
              )
            })()}
          </>
        ),

      },
      {
        text: 'ID',
        dataField: 'enquiryInfo.enquiryCode'

      },
      {
        text: 'DOCTOR',
        dataField: 'providerInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo)
                return (<React.Fragment>
                  <span>{formateName(record.providerInfo.firstname)} </span>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <div><p>-</p></div>
                </React.Fragment>
                )
            })()}
          </>
        ),

      },
      {
        text: 'STATUS',
        dataField: 'providerInfo.status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo)
                return (<React.Fragment>
                  <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Assigned </Badge>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <Badge style={{ backgroundColor: '#FFF7BC', color: '#E45826', fontSize: '14px' }}>Not Yet </Badge>
                </React.Fragment>
                )
            })()}
          </>
        ),
      },
      {
        text: ' ',
        dataField: '',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo) {
                return (<React.Fragment>
                  <div className='actionHolder'><Button style={{ alignItems: 'center', padding: '0' }} variant='link' onClick={() => this.pick_slot_record(record)} > Pick a slot </Button>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu style={{ margin: "auto", padding: "0px" }} title="">
                            <Dropdown.Item onClick={() => this.view_record(record)}>
                              <Button variant='link' onClick={() => this.view_record(record)}><u>View</u></Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }
              else {
                return (<React.Fragment>
                  <div className='actionHolder'><p style={{ width: "70px" }}>      _     </p>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu style={{ margin: "auto", padding: "0px" }} title="">
                            <Dropdown.Item onClick={() => this.view_record(record)}>
                              <Button variant='link' onClick={() => this.view_record(record)}><u>View</u></Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }

            })()}
          </>
        ),
      }
    ]



    const { previewVisible, previewImage } = this.state;



    return (
      <React.Fragment>
        {(() => {
          if (this.state.showMultiSectionOption && this.state.currentUser.usertype === Role.admin.name) {
            return <React.Fragment>
              <div style={{ "display": "block" }}>
                <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'font-size': '14px' }} className='rounded-pill ml-0 my-3'>
                  <FaStickyNote /> Un-assigned requests <Badge className='bg-light text-dark' style={{ fontSize: '12px' }}>{this.state.waiting.length}</Badge>
                </Button>
                <Button variant="info" style={{ 'background': '#143C8C', 'border': '1px solid #143C8C', 'font-size': '14px' }} className='rounded-pill ml-0 my-3'>
                  <FaStickyNote /> Assigned requests <Badge className='bg-light text-dark' style={{ fontSize: '12px' }}>{this.state.assigned.length}</Badge>
                </Button>
                <Button variant="link" style={{ 'font-size': '14px', 'color': '#252930', "display": "none" }} className='rounded-pill ml-0 my-3'>
                  <FaRegCalendarAlt /> Under slot selection <Badge className='bg-light text-dark' style={{ fontSize: '12px' }}>{20}</Badge>
                </Button>
                <Button variant="link" style={{ 'font-size': '14px', 'color': '#252930', "display": "none" }} className='rounded-pill ml-0 my-3'>
                  <FaRegIdBadge /> Incomplete profiles <Badge className='bg-light text-dark' style={{ fontSize: '12px' }}>{20}</Badge>
                </Button>
              </div>{this.state.filterItems ? this.state.filterItems.map((e, i) => (
                <div className='float-left'>
                  <Button className='filtertag' key={i} variant="outline-info" onClick={() => this.handleRemoveFilter(i)}>{e} <span><FaTimes /></span></Button>
                </div>
              )) : null
              }
              {this.state.hasSelected}

              < Button variant="link" style={{ color: "#143C8C" }} onClick={() => this.setAssignee('', true)} disabled={!this.state.hasSelected} ><span style={{ padding: "10px" }}><HiOutlineUsers /></span>Assign to team member</Button>
              <span style={{ marginLeft: 8 }}>
                {this.state.hasSelected ? `Selected ${this.state.selectedRows.length} items` : ''}
              </span>


              <React.Fragment>

                <Dropdown className="float-right">
                  <Dropdown.Toggle variant="primary" id="filter-dropdown" style={{ marginright: '40%' }}>
                    Filter
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleTableFilter('assigned', 'addFilter')} >Assigned</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleTableFilter('waiting', 'addFilter')}  >Waiting</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </React.Fragment>

              {this.props.enquiryStore.loading &&
                <div className='d-flex justify-content-center flex-nowrap'>
                  <div className='position-absolute mx-3 p-4' variant="primary">
                    <Spinner animation="border" variant="primary" />
                  </div>
                </div>
              }

              {!this.props.enquiryStore.loading &&
                <BootstrapTable
                  keyField='enquiryInfo._id'
                  data={this.state.filterItems.length === 1 ? (
                    this.state.filterItems[0] === "assigned" ? this.state.assigned : this.state.waiting) : this.state.assigned
                  }
                  columns={admin_columns}
                  pagination={paginationFactory(options)}
                  bordered={false}
                  hover={false}
                  selectRow={selectRow}
                />
              }
            </React.Fragment>



          }

          else if (this.state.currentUser.usertype === Role.internaluser.name) {
            return <React.Fragment>
              {(this.state.assigned.length === 0 && this.state.waiting.length === 0) && (
                this.state.loading ? "" :
                  <div class="card">
                    <div class="card-body text-center">
                      <RiCalendar2Line size={60} className='text-secondary' />
                      <h6>No requests</h6>
                      <small><p className='text-secondary'>Create an opinion request, share your health concerns, and connect with expert doctors.
                      </p></small>
                    </div>
                  </div>
              )
              }
              {(this.state.assigned.length !== 0 || this.state.waiting.length !== 0) && (
                <React.Fragment>
                  {this.state.filterItems ? this.state.filterItems.map((e, i) => (
                    <div className='float-left'>
                      <Button style={{ marginTop: "15px" }} className='filtertag' key={i} variant="outline-info" onClick={() => this.handleRemoveFilter(i)}>{e} <span><FaTimes /></span></Button>

                    </div>
                  )) : null
                  }
                  <React.Fragment>
                    <Dropdown className="float-right">
                      <Dropdown.Toggle variant="primary" id="filter-dropdown" style={{ marginright: '40%', marginTop: "10px" }}>
                        Filter
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.handleTableFilter('assigned', 'addFilter')} >Assigned</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleTableFilter('waiting', 'addFilter')}  >Waiting</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </React.Fragment>

                  {this.props.enquiryStore.loading}
                  {!this.props.enquiryStore.loading &&
                    <BootstrapTable
                      keyField='enquiryInfo._id'
                      data={this.state.ShowFilterList}
                      columns={internalUser_columns}
                      pagination={paginationFactory(options)}
                      bordered={false}
                      hover={false}
                    />

                  }
                </React.Fragment>
              )}
            </React.Fragment>
          }

          else {
            if (this.state.enquiries?.length > 0) {
              return <React.Fragment>
                {this.state.filterItems ? this.state.filterItems.map((e, i) => (
                  <div className='float-left'>
                    <Button style={{ marginTop: "15px" }} className='filtertag' key={i} variant="outline-info" onClick={() => this.handleRemoveFilter(i)}>{e} <span><FaTimes /></span></Button>

                  </div>
                )) : null
                }
                <React.Fragment>
                  <Dropdown className="float-right">
                    <Dropdown.Toggle variant="primary" id="filter-dropdown" style={{ marginright: '40%', marginTop: "10px" }}>
                      Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => this.handleTableFilter('assigned', 'addFilter')} >Assigned</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.handleTableFilter('waiting', 'addFilter')}  >Waiting</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </React.Fragment>
                <BootstrapTable keyField='enquiryInfo._id'
                  data={this.state.filterItems.length === 1 ? (
                    this.state.filterItems[0] === "waiting" ? this.state.waiting : this.state.assigned) : this.state.enquiries
                  }
                  columns={columns}
                  pagination={paginationFactory(options)}
                  bordered={false}
                  hover={false}
                />
              </React.Fragment>

            }



          }



          if (this.props.enquiryStore.loading)
            return (<div style={{ marginTop: "12rem", display: "flex", justifyContent: "center" }}>
              <Spinner animation="border" variant="primary" /></div>)
          if (this.state.assigned.length === 0 && this.state.waiting.length === 0) {
            return (
              this.state.loading ? <div style={{ marginTop: "12rem", display: "flex", justifyContent: "center" }}>
                <Spinner animation="border" variant="primary" /></div> :
                <div class="card" style={{ marginTop: "15px" }}>
                  <div class="card-body text-center">
                    <RiCalendar2Line size={60} className='text-secondary' />
                    <h6>No requests</h6>
                    <small><p className='text-secondary'>Create an opinion request, share your health concerns, and connect with expert doctors.
                    </p></small>
                  </div>
                </div>
            )
          }

        })()}

        { /*
        <Drawer
          width={550}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.viewer_visible}>

          <EnquiryViewer
            selected_enquiry={this.state.selected_enquiry}
            enquiry_obj={this.state.enquiry_obj}
            all_specialities={this.state.all_specialities}
            {...this.props} />

        </Drawer>
      
        <Modal
          width={660}
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          footer={false}
          keyboard={true}
          maskClosable={false}
          destroyOnClose={true}
          centered={true}
          title="Create Enquiry"
        >
          <Spin spinning={this.state.loading}>
            <Form
              style={{ maxWidth: "700px" }}
              className="profile"
              {...this.layout}
              name="createenquiry"
              ref={this.formRef}
              onFinish={this.onEnquiryFormFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                required
                label="Speciality"
                name="speciality"
                rules={formValidation.speciality}
              >
                <Select
                  className='selectors'>
                  {
                    this.state.all_specialities?.map((item) => {
                      const { name, _id } = item;
                      return <Select.Option value={_id} key={_id}>{name}</Select.Option>
                    })
                  }
                </Select>
              </Form.Item>

              <Form.Item
                required
                label="Describe your Problem"
                name="description"
                rules={formValidation.description}>

                <TextArea rows={1} style={{ resize: 'none' }} />

              </Form.Item>
              <br />

              <Form.Item
                required
                label={'Past Reports (Max.' + process.env.REACT_APP_MAX_FILE_COUNT + ' files)'}
                name="prescription">

                <Upload
                  accept=".pdf, .jpg,.png,.jpeg,.doc,.docx"
                  listType="picture-card"
                  maxCount={process.env.REACT_APP_MAX_FILE_COUNT}
                  fileList={fileList}
                  multiple={true}
                  showUploadList={showUploadList}
                  onPreview={this.handlePreview}
                  onChange={this.handleUpload}
                  beforeUpload={() => false}
                >
                  {uploadButton}
                </Upload>


              </Form.Item>

              <br /><br /><br /><br />

              <Form.Item >
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>Create Enquiry</Button>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
*/ }

        <Modal show={this.state.assignee_modal}
          onHide={this.handleCancel} centered
          backdrop="static">
          <Modal.Header closeButton >

            <ModalTitle>
              Set Assignee
            </ModalTitle>
          </Modal.Header>
          <Modal.Body className='p-5'>
            <center >
              {<SetAssignee
                selectedRows={this.state.selectedRows}
                internalUsers={this.state.internalusers}
                onAssigned={this.getEnquiries}
                scope="ENQUIRY"
                {...this.props}
              />}
            </center>
          </Modal.Body>
        </Modal >




        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleImagePreviewCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>


      </React.Fragment>)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEnquiryListDetails: () => dispatch(getEnquiryListDetails(ownProps))
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    enquiryStore: state.enquiryList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnquiryTableView)

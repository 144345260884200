import React from 'react'
import { AfterLoginDashboardLayout } from '../../shared/AfterLoginDashboardLayout';
import ProviderView from './ProviderView';

export class ProviderDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: false
    }
}
  render() {
    return (
      <div>
        <AfterLoginDashboardLayout>
          
              <ProviderView  {...this.props} />
        </AfterLoginDashboardLayout>
      </div>
    )
  }
}

export default ProviderDashboard
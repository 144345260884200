//import React from 'react';
//import { Layout } from '../component/shared';
import { AfterLoginLayout } from '../component/shared/AfterLoginLayout';

//import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Result } from 'antd';

const NoMatch = () => (
    <AfterLoginLayout className="app-404" isHeader={true} isFooter={true}>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."

        />
        {/* <h1>Page not found  404</h1>
        <Link to="/" >Back To Home</Link> */}
    </AfterLoginLayout>
)

export default NoMatch

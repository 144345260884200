import React, { useState } from 'react'
import logo from '../../assets/images/logo.png'
import ProviderSideBar from '../provider/sidebar/ProviderSideBar'
import PatientSideBar from '../patient/sidebar/PatientSideBar'
import InternalUserSideBar from '../admin/sidebar/InternalUserSideBar'
import LocalProviderSideBar from '../localprovider/sidebar/LocalProviderSideBar'
import Role from '../../constants/role'
import { FaBars } from 'react-icons/fa';
import { authenticationService } from '../general/login/login.service';
import { Container, Row, Col } from 'react-bootstrap'
import ProfileDropDown from "./profile-dropdown";
import SearchHeader from './SearchHeader'

export const AfterLoginDashboardLayout = (props) => {
  const currentUser = authenticationService.currentUserValue;
  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className="app inner-layout">
      {(() => {
        if (currentUser && currentUser.usertype === Role.provider.name) {
          return <ProviderSideBar
            image={false}
            collapsed={collapsed}
            rtl={false}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange} />
        }
      })()}

      {(() => {
        if (currentUser && currentUser.usertype === Role.patient.name) {
          return <PatientSideBar
            image={false}
            collapsed={collapsed}
            rtl={false}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange} />
        }
      })()}

      {(() => {
        if (currentUser) {
          if (currentUser.usertype === Role.internaluser.name || currentUser.usertype === Role.admin.name) {
            return <InternalUserSideBar
              image={false}
              collapsed={collapsed}
              rtl={false}
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              handleCollapsedChange={handleCollapsedChange} />
          }
        }
      })()}

      {(() => {
        if (currentUser && currentUser.usertype === Role.localprovider.name) {
          return <LocalProviderSideBar
            image={false}
            collapsed={collapsed}
            rtl={false}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange} />
        }
      })()}
      <Container fluid>

        <Row className='mobile-header'><Col>
          <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
            <FaBars />
          </div>
          <div className="mobile-logo ml-5">
            <img src={logo} className="img-fluid" alt="Second Opinion" />
          </div>
        </Col>
          <Col>
            <div className="mobile-profile text-right">
              <ul>
                <ProfileDropDown view={'mobile'} />
              </ul>
            </div>
          </Col>
        </Row>
        <Row className='second-header'>
          <Col><SearchHeader from={props.from} opinionRequest={currentUser.usertype === Role.patient.name ? true : currentUser.usertype === Role.localprovider.name ? true : false} displayname={currentUser.displayname} /> </Col>
        </Row>
        {props.children}
      </Container>
    </div>

  );
};
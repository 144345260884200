import React, { Component } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { opinionForm } from './OpinionForm.service'
import { formikFormalidation } from '../../../../component/general/formvalidator/validationFroms';
import { Formik } from 'formik';
import * as yup from 'yup'
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import './GetOpinion.css'

export class GetOpinion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: '',
            value: null,
            loading: false,
            newUserVal:false
        }
    }
    onBlur = (e) => {
        if ( !e.currentTarget.contains( e.relatedTarget ) ) {
         
        }
      }
    
    render() {
       
        const schema = yup.object().shape({
            firstname: formikFormalidation.opinionFirstname,
            lastname: formikFormalidation.opinionLastname,
            mobilenumber: formikFormalidation.mobilenumber,
            email: formikFormalidation.emaildr,
            message: formikFormalidation.message
        });
        return (
            <div>
                {this.state.loading &&
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-3 p-4' variant="primary" style={{ marginTop: '290px' }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                }
                <Formik
                    validationSchema={schema}
                    validate={values => {
                        const errors = {};
                        if (!values.mobilenumber) {
                            errors.mobilenumber = "Mobile number is required!";
                        }
                        else {
                            const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
                            if (!countryCallingCode || !isValidPhoneNumber(values.mobilenumber)) {
                                errors.mobilenumber = 'Invalid Mobile number format!';
                            }
                        }
                        return errors;
                    }}
                    onSubmit={async (values) => {
                        const countryCallingCode = parsePhoneNumber(values.mobilenumber) && parsePhoneNumber(values.mobilenumber).countryCallingCode
                        const nationalNumber = parsePhoneNumber(values.mobilenumber) && parsePhoneNumber(values.mobilenumber).nationalNumber
                        this.setState({ formData: values });
                        const allFormData = {
                            formData: this.state.formData,
                            countryCallingCode: "+" + countryCallingCode,
                            nationalNumber: nationalNumber,
                        }
                        this.setState({ loading: true,newUserVal:true });
                        await opinionForm(this.props, allFormData,this.state.newUserVal);
                        this.setState({ loading: false });
                      
                    }
                    }
                    initialValues={{
                        firstname: '',
                        lastname: '',
                        mobilenumber: '',
                        email: '',
                        message: ''
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        ErrorMessage,
                        values,
                        touched,
                        isValid,
                        errors
                    }) => (
                        <Container className={this.state.loading?"blur":""}>
                            <div className='getOpinion' >
                                <Row>
                                    <Col md={6} className='opinioncol1'>
                                        <div className='col1'>
                                            <h5>Get an opinion</h5>
                                            <p className='font-weight-bold mt-3'>How should we send details to you safely?
                                                <span className='ml-1' style={{ color: "red" }}>*</span>
                                            </p>
                                            <fieldset disabled={this.state.loading}>
                                                <Form noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                                    <div className='row no-glutters'>
                                                        <div className='col form-group'>
                                                            <Form.Control type="text"
                                                                className='field'
                                                                placeholder="First name"
                                                                name="firstname" required
                                                                value={values.firstname}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.firstname && touched.firstname}
                                                            />
                                                            {/* <span className={errors.firstname && touched.firstname ? 'invalid short-label' : 'short-label'}>&emsp;First name<span>*</span></span> */}
                                                            {
                                                                errors.firstname && touched.firstname && (
                                                                    <div className="invalid-feedback">{errors.firstname}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col form-group'>
                                                            <Form.Control type="text"
                                                                className='field'
                                                                placeholder="Last name"
                                                                name="lastname" required
                                                                value={values.lastname}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.lastname && touched.lastname}
                                                            />
                                                            {/* <span className={errors.lastname && touched.lastname ? 'invalid short-label' : 'short-label'}>&emsp;Last name<span>*</span></span> */}
                                                            {
                                                                errors.lastname && touched.lastname && (
                                                                    <div className="invalid-feedback">{errors.lastname}</div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='row no-glutters'>
                                                        <div className='col form-group'>
                                                            <Form.Control type="email"
                                                                className='field'
                                                                placeholder="Email"
                                                                name="email"
                                                                required value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.email && touched.email} />
                                                            {/* <span className={errors.email && touched.email ? 'invalid short-label' : 'short-label'}>&emsp;Email Id<span>*</span></span> */}
                                                            {
                                                                errors.email && touched.email && (
                                                                    <div className="invalid-feedback">{errors.email}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='col form-group'>
                                                            <Form.Control as={PhoneInput}
                                                                className='field'
                                                                name="mobilenumber"
                                                                placeholder="Mobile number *"
                                                                international
                                                                defaultCountry="IN"
                                                                countries={['US', 'IN']}
                                                                countryCallingCodeEditable={false}
                                                                limitMaxLength={true} required
                                                                value={values.mobilenumber}
                                                                onChange={e => { values.mobilenumber = e; }}
                                                                onBlur={handleBlur}
                                                                isInvalid={errors.mobilenumber && touched.mobilenumber}
                                                            />
                                                            {/* <span className={errors.mobilenumber && touched.mobilenumber ? 'invalid short-label' : 'short-label'}><span>&nbsp;*</span></span> */}
                                                            {
                                                                errors.mobilenumber && touched.mobilenumber && (
                                                                    <div className="invalid-feedback">{errors.mobilenumber}</div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='mt-4 ml-1'>
                                                        <h6>What is your health concern?
                                                            <span className='ml-1' style={{ color: "red" }}>*</span>
                                                        </h6>
                                                        <p>Your data will be secured with us</p>
                                                        <Form.Control as="textarea" rows={3}
                                                            className='textarea'
                                                            name="message"
                                                            required value={values.message}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.message && touched.message}
                                                        />
                                                        {
                                                            errors.message && touched.message && (
                                                                <div className="invalid-feedback">{errors.message}</div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='mt-3 ml-2 mb-5'>
                                                        <Button variant="primary" className='mr-3 px-3' type="submit">Submit</Button>
                                                        <Button variant='light' className='btn btn-white my-4 px-3' onClick={this.props.handleClose}>Cancel</Button>
                                                      
                                                    </div>
                                                </Form>
                                            </fieldset>
                                        </div>
                                    </Col>
                                    <Col className='col2' md={5}>
                                        <img className='opinionimg' src='./getOpinion.png' alt='opinion' style={{ marginTop: '-30px' }} />
                                    </Col>
                                </Row>

                            </div>

                        </Container>
                    )}
                </Formik>
            </div>
        )
    }
}

export default GetOpinion
import React from 'react';
import { connect } from 'react-redux'
import { getEnquiryListDetails } from '../../../actions/patient/EnquiriesAction'
import { Card, Button } from 'react-bootstrap'
import { authenticationService } from '../../general/login/login.service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { get_user_obj, get_patient_obj, get_assignee } from '../enquiry/EnquiryUtils';
import { getStages, getSpecialities } from '../../../utils/PersistentData';
import { FaUserAlt } from "react-icons/fa";

export class ConfirmAppointments extends React.Component {
  formRef = React.createRef();
  assigneeFormRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props && this.props.patient_id,
      all_specialities: [],
      internalusers: [],
      stages: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: true,
      showMultiSectionOption: false,
      showCreate: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      currentUser: {},
      assignee_modal: false,
      modalVisible: false,
      viewer_visible: false,
      selected_enquiry: {},
      provider_user_obj: {},
      enquiry_obj: {},
      enquiries: [],
    }
  }


  handleCancel = () => {
    this.setState({ assignee_modal: false });
    this.setState({ modalVisible: false });
  };

  handleStatus = (response) => {
    //console.log(response + "response");
  }

  getEnquiries = () => {
    this.handleCancel()
    this.setState({ loading: true });
    //this.props.getEnquiryListDetails(this.state.currentUser.usertype_id).then((response) => {
    this.props && this.props.getEnquiryListDetails(this.props && this.props.patient_id).then((response) => {
      let enquiries = (response.enquiries)
      let stages = getStages() || []
      let internalusers = response.internalusers
      let all_specialities = getSpecialities() || []
      this.setState({ enquiries, stages, internalusers, all_specialities })
    }).catch(err => {
      errorMessage(err)
      this.setState({ loading: false });
    })
  }
  setAssignee = () => {
    this.setState({ assignee_modal: true })
  };

  pick_slot_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/pickslot/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }


  edit_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/enquiry/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }


  componentDidMount() {
    this.getEnquiries()
    const currentUser = authenticationService.currentUserValue;
    this.setState({
      showMultiSectionOption: this.props && this.props.showMultiSectionOption,
      showCreate: (this.props && this.props.showCreate) || false,
      currentUser: currentUser,
      patient_id: this.props && this.props.patient_id,
    })
  }


  render() {
    const statusInfo = []
    const data = this.state.enquiries.length > 0 && this.state.enquiries.filter((item, index) =>
      item.providerInfo !== null && item)

    statusInfo.push(data[0])

    return (
      <React.Fragment>

        <div>
          <Card>
            <h6 className='font-weight-bold'>Confirm Appointment</h6>
            <div className="media mt-3">
              <span className="media-left">
                <FaUserAlt className='mt-1 mr-3  text-light rounded-circle' size={28} />
              </span>
              <div className="media-body">
                <h6 className='font-weight-normal'>{statusInfo && statusInfo[0]?.providerInfo?.firstname.length>15 ? statusInfo[0]?.providerInfo?.firstname.slice(0,15) + '...' : statusInfo[0]?.providerInfo?.firstname }
                  <small><p className='ml-0 font-weight-light'>assigned to you</p></small>
                </h6>
              </div>
            </div>

            <div className="media mt-2">
              <span className="media-left">
                <FaUserAlt className='mt-1 mr-3  text-light rounded-circle' size={28} />
              </span>
              <div className="media-body">
                <div className='font-weight-light'><small>Patient</small>
                  <small><h6 className='ml-0 font-weight-normal'>{statusInfo && statusInfo[0]?.patientInfo?.firstname.length>15 ? (statusInfo[0]?.patientInfo?.firstname.slice(0,15) + '...'): statusInfo[0]?.patientInfo?.firstname }</h6></small>
                </div>
              </div>
            </div>
            <div>
            <Button  onClick={() => this.pick_slot_record(data[0])} variant="outline-info" style={{'border':'1px solid #fff'}} className='text-white btn-sm mt-3 px-4 mx-2 py-1'>Confirm</Button>
            </div>
          
          </Card>
        </div>

      </React.Fragment>)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEnquiryListDetails: () => dispatch(getEnquiryListDetails(ownProps))
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    enquiryStore: state.enquiryList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAppointments)
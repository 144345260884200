import React, { useState, useMemo } from "react";
import { UserContext } from "../hooks/UserContext";
import { BrowserRouter, Switch } from 'react-router-dom';

import {
  PatientRegistration,
  ProviderRegistration,
  Home,
  ProviderDashboard,
  PatientDashboard,
  OpinionSuccess,
  InternalUserDashboard,
  PatientProfile,
  EnquiryPatientView,
  NoMatch,
  Otp,
  PatientTermsConditions,
  PatientPrivacyPolicy,
  ProviderTermsConditions,
  ProviderPrivacyPolicy,
  ForgotPassword,
  ResetPassword,
  ProviderProfile,
  UserListComponent,
  ProviderListComponent,
  LocalProviderAdminView,
  InternalResetPassword,
  InternalUserProviderListComponent,
  EnquiryIUView,
  EnquiryAdminView,
  EnquiryDetailsEditor,
  ProviderScheduler,
  EnquiryPublicView,
  EnquirySessionCheckout,
  AppointmentConfirmation,
  AppointmentEditor,
  AppointmentPatientView,
  AppointmentProviderView,
  PreJoinScreen,
  LocalProviderDashboard,
  ListPatientComponent,
  ViewPatientDetails,
  AppointmentLocalProviderView,
  EnquiryPickSlot,
  AppointmentConfirmationAuth,
  AppointmentView,
  AppointmentReschedule,
  AppointmentCancel,
  AppointmentRescheduleSuccess,
  EnquiryDetailsView,
  EnquiryEditView,
  AppointmentProviderViewer,
  AddUser,
  GetOpinion,
  OpinionConfirmationPage,
  AppointmentProviderReschedule,
  AppointmentProviderRescheduleSuccess,
  AdminPatientView,
  SchedulerView
} from '../pages'

//import ResetPassword from "./component/general/forgotpassword/resetpassword";

import LandingPage from '../pages/landing/index'
import AboutPage from '../pages/about/index'
import PatientPage from '../pages/patients/index'

import DoctorPage from '../pages/doctor/index'
import ContactPage from '../pages/contact/index'
import LoginContainer from '../component/general/login/login.jsx'
import PublicRoute from '../hooks/PublicRoute'
import PrivateRoute from '../hooks/PrivateRoute'
import Role from '../constants/role'
import { AUTHROUTE, NONAUTHROUTE } from "../constants/RouterPath";
//import DoctorDashboard from './component/provider/dashboard/index'



function AppRouter() {
  const [user, setUser] = useState(null);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (

    <BrowserRouter>
      <UserContext.Provider value={value}>

        <Switch>

          {/* General Public Routes */}
          <PublicRoute restricted={true} component={LandingPage} path={NONAUTHROUTE.GENERAL.LANDINGPATH} exact />
          <PublicRoute restricted={true} component={AboutPage} path={NONAUTHROUTE.GENERAL.ABOUTUSPATH} exact />
          <PublicRoute restricted={true} component={PatientPage} path={NONAUTHROUTE.GENERAL.PATIENTPATH} exact />

          <PublicRoute restricted={true} component={DoctorPage} path={NONAUTHROUTE.GENERAL.DOCTORPATH} exact />
          <PublicRoute component={ContactPage} path={NONAUTHROUTE.GENERAL.CONTACTUSPATH} exact />
          <PublicRoute restricted={true} component={LoginContainer} path={NONAUTHROUTE.GENERAL.LOGINPATH} exact />
          <PublicRoute restricted={true} component={Otp} path={NONAUTHROUTE.GENERAL.REGSITRATIONOTPPATH} exact />
          <PublicRoute restricted={true} component={ForgotPassword} path={NONAUTHROUTE.GENERAL.FORGOTPASSWORDPATH} exact />
          <PublicRoute restricted={true} component={ResetPassword} path={NONAUTHROUTE.GENERAL.RESETPASSWORDPATH} exact />
          <PublicRoute restricted={true} component={GetOpinion} path={NONAUTHROUTE.GENERAL.GETOPINION} exact />
          <PublicRoute restricted={true} component={OpinionConfirmationPage} path={NONAUTHROUTE.GENERAL.OPINIONCONFIRMATION} exact />

          {/* Provider Public Routes */}
          <PublicRoute restricted={true} component={ProviderRegistration} path={NONAUTHROUTE.PROVIDER.REGISTRATIONPATH} exact />
          <PublicRoute restricted={true} component={ProviderTermsConditions} path={NONAUTHROUTE.PROVIDER.TERMSPATH} exact />
          <PublicRoute restricted={true} component={ProviderPrivacyPolicy} path={NONAUTHROUTE.PROVIDER.PRIVACYPOLICYPATH} exact />

          {/* Patient Public Routes */}
          <PublicRoute restricted={true} component={PatientRegistration} path={NONAUTHROUTE.PATIENT.REGISTRATIONPATH} exact />
          <PublicRoute restricted={true} component={PatientTermsConditions} path={NONAUTHROUTE.PATIENT.TERMSPATH} exact />
          <PublicRoute restricted={true} component={PatientPrivacyPolicy} path={NONAUTHROUTE.PATIENT.PRIVACYPOLICYPATH} exact />
          <PublicRoute restricted={true} component={EnquiryPublicView} path={NONAUTHROUTE.PATIENT.ENQUIRY_DETAILS} />
          <PublicRoute restricted={true} component={EnquirySessionCheckout} path={NONAUTHROUTE.PATIENT.ENQUIRY_SESSION} exact />
          <PublicRoute restricted={true} component={AppointmentConfirmation} path={NONAUTHROUTE.PATIENT.APPOINTMENT_CONFIRMATION} exact />

          <PrivateRoute restricted={true} component={AppointmentReschedule} path={AUTHROUTE.PATIENT.APPOINTMENT_rechedule_PATH} exact />
          <PrivateRoute restricted={true} component={AppointmentCancel} path={AUTHROUTE.PATIENT.APPOINTMENT_cancel_PATH} exact />
          <PrivateRoute restricted={true} component={AppointmentRescheduleSuccess} path={AUTHROUTE.PATIENT.APPOINTMENT_rechedule_sucess_PATH} exact />
          {/* InternalUser Public Routes */}
          <PublicRoute restricted={true} component={InternalResetPassword} path={NONAUTHROUTE.INTERNALUSER.INTERNALRESETPASSWORDPATH} exact />

          {/* Provider Private Routes */}
          <PrivateRoute component={ProviderDashboard} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.DASHBOARDPATH} exact />
          <PrivateRoute component={ProviderProfile} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.PROFILEPATH} exact />
          <PrivateRoute component={ProviderScheduler} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.SCHEDULERPATH} exact />
          <PrivateRoute component={AppointmentProviderView} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.APPOINTMENT_PATH} exact />
          <PrivateRoute component={AppointmentProviderViewer} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.APPOINTMENT_PROVIDER} exact />
          <PrivateRoute component={SchedulerView} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.CALENDER_VIEW} exact />
          <PrivateRoute component={AppointmentProviderReschedule} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.RESCHEDULE_VIEW} exact />
          <PrivateRoute component={AppointmentProviderRescheduleSuccess} roles={[Role.provider.name]} path={AUTHROUTE.PROVIDER.RESCHEDULE_SUCCESS} exact />

          {/* Video Call Routes */}
          <PrivateRoute component={PreJoinScreen} roles={[Role.patient.name, Role.provider.name, Role.localprovider.name]} path={AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN} exact />

          {/* Patient Private Routes */}
          <PrivateRoute component={PatientDashboard} roles={[Role.patient.name]} path={AUTHROUTE.PATIENT.DASHBOARDPATH} exact />
          <PrivateRoute component={PatientProfile} roles={[Role.patient.name]} path={AUTHROUTE.PATIENT.PROFILEPATH} exact />
          <PrivateRoute component={EnquiryPatientView} roles={[Role.patient.name]} path={AUTHROUTE.PATIENT.ENQUIRIES_PATH} exact />
          <PrivateRoute component={EnquiryDetailsEditor} roles={[Role.patient.name, Role.admin.name, Role.internaluser.name, Role.localprovider.name]} path={AUTHROUTE.PATIENT.VIEW_ENQUIRY} exact />
          <PrivateRoute component={EnquiryPickSlot} roles={[Role.patient.name, Role.admin.name, Role.internaluser.name, Role.localprovider.name]} path={AUTHROUTE.PATIENT.ENQUIRYPICKSLOT} exact />
          <PrivateRoute component={AppointmentPatientView} roles={[Role.patient.name]} path={AUTHROUTE.PATIENT.APPOINTMENT_PATH} exact />
          <PrivateRoute component={AppointmentEditor} roles={[Role.patient.name, Role.admin.name, Role.internaluser.name, Role.localprovider.name]} path={AUTHROUTE.PATIENT.VIEW_APPOINTMENT} exact />
          <PrivateRoute component={AppointmentConfirmationAuth} roles={[Role.patient.name, Role.admin.name, Role.internaluser.name, Role.localprovider.name]} path={AUTHROUTE.PATIENT.APPOINTMENT_CONFIRMATION_AUTH} exact />
          <PrivateRoute component={AppointmentView} roles={[Role.patient.name, Role.localprovider.name]} path={AUTHROUTE.PATIENT.APPOINTMENT_View} exact />
          <PrivateRoute component={EnquiryDetailsView} roles={[Role.patient.name, Role.localprovider.name]} path={AUTHROUTE.PATIENT.ENQUIRIES_VIEW} exact />
          <PrivateRoute component={EnquiryEditView} roles={[Role.patient.name]} path={AUTHROUTE.PATIENT.ENQUIRIES_EDITS} exact />
          <PrivateRoute component={OpinionSuccess} roles={Role.patient.name} path={AUTHROUTE.PATIENT.OPINION_SUCCESS} exact />
          {/* InternalUsers Private Routes */}
          <PrivateRoute component={InternalUserDashboard} roles={[Role.internaluser.name, Role.admin.name]} path={AUTHROUTE.INTERNALUSER.DASHBOARDPATH} exact />
          <PrivateRoute component={InternalUserProviderListComponent} roles={[Role.internaluser.name]} path={AUTHROUTE.INTERNALUSER.LIST_MY_PROVIDERS} exact />
          <PrivateRoute component={EnquiryIUView} roles={[Role.internaluser.name]} path={AUTHROUTE.INTERNALUSER.ENQUIRIES_PATH} exact />


          {/* Admin Private Routes */}
          <PrivateRoute component={UserListComponent} roles={[Role.admin.name]} path={AUTHROUTE.ADMIN.LISTINTERNALUSERS} exact />
          <PrivateRoute component={ProviderListComponent} roles={[Role.admin.name]} path={AUTHROUTE.ADMIN.LISTPROVIDERS} exact />
          <PrivateRoute component={EnquiryAdminView} roles={[Role.admin.name]} path={AUTHROUTE.ADMIN.ENQUIRIES_PATH} exact />
          <PrivateRoute component={LocalProviderAdminView} roles={[Role.admin.name, Role.internaluser.name]} path={AUTHROUTE.ADMIN.LISTLOCALPROVIDERS} exact />
          <PrivateRoute component={AddUser} roles={[Role.internaluser.name, Role.admin.name]} path={AUTHROUTE.INTERNALUSER.ADD_USER} exact />
          <PrivateRoute component={AdminPatientView} roles={[Role.admin.name]} path={AUTHROUTE.ADMIN.PATIENT} exact />
          {/* Local Provider Private Routes */}
          <PrivateRoute component={LocalProviderDashboard} roles={[Role.localprovider.name]} path={AUTHROUTE.LOCALPROVIDER.DASHBOARDPATH} exact />
          <PrivateRoute component={ListPatientComponent} roles={[Role.localprovider.name]} path={AUTHROUTE.LOCALPROVIDER.PATIENTPATH} exact />
          <PrivateRoute component={ViewPatientDetails} roles={[Role.localprovider.name]} path={AUTHROUTE.LOCALPROVIDER.VIEW_PATIENT} exact />
          <PrivateRoute component={AppointmentLocalProviderView} roles={[Role.localprovider.name]} path={AUTHROUTE.LOCALPROVIDER.APPOINTMENT_PATH} exact />

          {/* General Private Routes */}
          <PrivateRoute component={Home} path={AUTHROUTE.GENERAL.HOMEPATH} />
          <PrivateRoute component={NoMatch} path={AUTHROUTE.GENERAL.NOMATCH} />

        </Switch>
      </UserContext.Provider>

    </BrowserRouter>
  );
}

export default AppRouter;

import React, { Component } from 'react'
import { Form, Button, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import { FullLayout } from '../../shared';
import { forgotpassword } from './forgotpassword.service'
import { Link } from 'react-router-dom';
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { Formik } from 'formik';
import * as yup from 'yup'
import { formikFormalidation } from '../../general/formvalidator/validationFroms'

export class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = { loading: false }
    }

    render() {
        const schema = yup.object().shape({
            username: formikFormalidation.username,
        });

        return (
            <Formik
                validationSchema={schema}

                const onSubmit={async (values) => {
                    this.setState({ loading: true });
                    await forgotpassword(this.props, values);
                    this.setState({ loading: false });
                }
                }

                initialValues={{
                    username: ''
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (

                    <FullLayout className="app-login" isHeader={false} isFooter={false}>
                        <Container>
                            <Row>
                                <Col lg={8} md={6} sm={12}>&nbsp;</Col>
                                <Col lg={4} md={6} sm={12}>
                                    <Card className='p-5 rightcol'>
                                        <Card.Header as="h2" className='px-2'>Forgot Password</Card.Header>
                                        <Card.Body className='p-2'>
                                            {this.state.loading &&
                                                <div className='d-flex justify-content-center flex-nowrap'>
                                                    <div className='position-absolute mx-3 p-4' variant="primary">
                                                        <Spinner animation="border" variant="primary" />
                                                    </div>
                                                </div>
                                            }
                                            <Form noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                                <fieldset disabled={this.state.loading}>
                                                    <Form.Group className="mb-3" controlId="formBasicUsername">
                                                        <Form.Control type="text" placeholder='Username' name="username" required value={values.username} onChange={handleChange} isInvalid={!!errors.username} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.username}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Button className="ml-0 btn-block" variant="primary" type="submit" >
                                                        Submit
                                                    </Button>
                                                </fieldset>
                                            </Form>
                                            <div className="text-right mt-4">
                                                <Link to={NONAUTHROUTE.GENERAL.LOGINPATH} className="login-link" disabled={this.state.loading}>Know your password? </Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </FullLayout>
                )
                }
            </Formik>
        );
    }
}


export default ForgotPassword
import { GET, POST } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";
import { authenticationService } from '../../component/general/login/login.service';
import moment from 'moment';

/**
 * Get Local Patient List information 
 */
function getLocalPatients() {
  let currentUser = authenticationService.currentUserValue
  let url = APIENDPOINT.LOCALPROVIDER.LIST_LOCAL_PATIENTS + "/" + currentUser._id;
  return GET(url);
}
function getPatients() {
  let url = APIENDPOINT.ADMIN.PATIENT;
  return GET(url);
}
/**
 * Get Profile details informations
 * @param {} users and details 
 */
function getUserProfile(_id) {
  let url = APIENDPOINT.PROFILE_DETAILS + "/" + _id;
  return GET(url);
}

const createLocalPatient = async (formDetails) => {
  var dateOfBirth = moment(formDetails.dateOfBirth).format("MM-DD-YYYY");
  var data = {
    "firstname": formDetails.firstname,
    "lastname": formDetails.lastname,
    "middlename": formDetails.middlename || "",
    "phone": formDetails.nationalNumber,
    "countrycode": formDetails.countryCallingCode,
    "email": formDetails.email,
    "dob": dateOfBirth,
    "bloodgroup": formDetails.bloodgroup || "",
    "gender": formDetails.gender || "",
    "maritalstatus": formDetails.maritalstatus || "",
    "height": formDetails.height || "",
    "weight": formDetails.weight || "",
    "location": formDetails.location || "",
  };

  return POST(APIENDPOINT.LOCALPROVIDER.CREATE_LOCAL_PATIENT, data)
}

const localpatientListService = {
  getPatients:getPatients,
  getLocalPatients: getLocalPatients,
  createLocalPatient: createLocalPatient,
  getUserProfile: getUserProfile
}
export default localpatientListService;
import React from 'react';
import "../../provider/profile/providerProfile.style.css"
// import { message } from 'antd';
import { Card, Row, Col } from 'react-bootstrap';
import moment from "moment";
// import { parseAppointments } from '../../patient/appointment/Utils';
import { get_day_from_datetime, formateName, get_formated_time_only_provider, get_formated_date_only_provider } from '../../patient/enquiry/EnquiryUtils';
import { FaUserAlt } from "react-icons/fa";
import { BsFillAlarmFill } from "react-icons/bs";

export class ProviderCard extends React.Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            consultations: [],
            past: props?.past ? props?.past : [],
            upcoming: props?.upcoming ? props?.upcoming : [],
            previewVisible: false,
            previewImage: "",
            fileList: [],
            currentUser: {},
            viewer_visible: false,
            reschedule_visible: false,
            selected_appointment: {}
        }

    }

    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };

    handleCancel = () => {
    };

    showDrawer = () => {
        this.setState({
            viewer_visible: true,
        });
    };

    view_record = (record) => {
        setTimeout(() => {
            if (record) {
                this.setState({ selected_appointment: record, viewer_visible: true });
            }
        }, 1);
    }

    reschedule_record = (record) => {
        setTimeout(() => {
            if (record) {
                this.setState({ selected_appointment: record, reschedule_visible: true });
            }
        }, 1);
    }

    onClose = () => {
        this.setState({
            viewer_visible: false,
            reschedule_visible: false
        });
    };

    // handleResponse = (response) => {
    //     if (response.data.status === "success") {
    //         if (response && response.data && response.data.data) {
    //             if (response.data.data.length > 0) {
    //                 const _consultation = parseAppointments(response.data.data)
    //                 this.setState({
    //                     upcoming: _consultation && _consultation.upcoming,
    //                     past: _consultation && _consultation.past,
    //                     loading: false
    //                 })
    //             }
    //         }
    //     } else {
    //         this.setState({ loading: false })
    //         message.error(response.data.message);
    //     }
    // }

    // getAppointments = async () => {
    //     try {
    //         this.setState({ loading: true });
    //         const currentUser = authenticationService.currentUserValue;
    //         this.setState({ loading: true })

    //         let response = await AppointmentService.getProviderAppointments(currentUser.usertype_id);
    //         this.handleResponse(response)
    //     } catch (error) {
    //         errorMessage(error)
    //         this.setState({ loading: false })
    //     }
    // }



    showModal = () => {
        this.setState({
            modalVisible: true,
            fileList: []
        });
    };

    componentDidMount() {
        // this.getAppointments()
    }



    render() {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        return (
            <React.Fragment>
                <div>
                    <Row className='no-gutters'>
                        <Col>
                            <Card className='mb-5' style={{ width: '139px', background: '#143C8C', minHeight: '111px' }}>
                                <Card.Body >
                                    <Card.Subtitle className="mb-2 text-white" style={{ fontSize: '13px' }}>Appointments</Card.Subtitle>
                                    <Card.Text>
                                        <h6 className='text-white mt-3' style={{ fontSize: '19px' }}>{this.state.upcoming.length}</h6>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ width: '139px', background: '#143C8C', minHeight: '111px' }}>
                                <Card.Body >
                                    <Card.Subtitle className="mb-2 text-white" style={{ fontSize: '13px' }}>Completed</Card.Subtitle>
                                    <Card.Text>
                                        <h6 className='text-white mt-3' style={{ fontSize: '19px' }}>{this.state.past.length}</h6>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ width: '380px', background: '#143C8C', minHeight: '111px' }}>
                                <Card.Body>
                                    {this.state.upcoming.length !== 0 ?
                                        <Card.Subtitle className="mb-2 text-white" style={{ fontSize: '14px' }}>Next Appointment</Card.Subtitle> :
                                        <Card.Subtitle className="mb-2 text-white" style={{ fontSize: '14px' }}>No Appointment</Card.Subtitle>}
                                    <div>
                                        {this.state.upcoming.map((data, id) =>
                                            <div key={id} onMouseOver={() => this.view_record(data)}>
                                                <Row>
                                                    <Col lg={5} md={5} sm={5} className='pr-2' >
                                                        <div className="media">
                                                            <span className="media-left pt-1">
                                                                <FaUserAlt className='mr-2  rounded-circle p-1' style={{ background: '#488FB1', color: '#143C8C' }} size={30} />
                                                            </span>
                                                            <div className="media-body d-flex">
                                                                <div className='font-weight-light text-white'><small>PATIENT</small>
                                                                    <h6 className='ml-0 font-weight-bold text-white' style={{ fontSize: '13px' }}>{formateName(data?.patientInfo?.userInfo?.firstname)}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={7} md={7} sm={7} className='pl-2'>
                                                        <div className="media">
                                                            <span className="media-left">
                                                                <BsFillAlarmFill className='rounded-circle  p-1 mt-1' style={{ background: '#488FB1', color: '#143C8C' }} size={30} />
                                                            </span>

                                                            <div className="media-body d-flex">

                                                                <div className='font-weight-bold text-white ml-2' style={{ fontSize: '13px' }}>
                                                                    {days[moment(data?.consultationInfo?.scheduleDetailsInfo?.slot).day()]}
                                                                    <div className=' font-weight-light small text-white' style={{ fontSize: '11px' }}>{(get_day_from_datetime(data?.consultationInfo?.scheduleDetailsInfo && `${moment(data.consultationInfo.scheduleDetailsInfo.date, 'YYYY-MM-DD HH:mm A').format()}`))}</div>
                                                                    <div className=' font-weight-light small text-white' style={{ fontSize: '11px' }}>{(get_formated_date_only_provider(data?.consultationInfo?.scheduleDetailsInfo && `${moment(data.consultationInfo.scheduleDetailsInfo.date, 'YYYY-MM-DD HH:mm A').format()}`))}</div>
                                                                </div>

                                                            </div>
                                                            <div className='font-weight-bold small  text-white' style={{ fontSize: '11px' }}> {get_formated_time_only_provider(data?.consultationInfo?.scheduleDetailsInfo && `${moment(data.consultationInfo.scheduleDetailsInfo.date + ' ' + data.consultationInfo.scheduleDetailsInfo.slot, 'YYYY-MM-DD HH:mm A').format()}`)}{data.consultationInfo.timename}</div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        ).slice(0,1)}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment >)
    }
}

export default ProviderCard

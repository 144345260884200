import React, { Component } from 'react';
import { FullLayout } from '../../shared';
import { Form, Button, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import { formikFormalidation } from '../../general/formvalidator/validationFroms'
import { patientRegister } from './patient-registration.service';
//import { FaStethoscope, FaUser } from "react-icons/fa";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { Formik } from 'formik';
import * as yup from 'yup'
import { Link } from 'react-router-dom'


import './registrationform.css'
class PatientRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: '',
            value: null,
            loading: false,
            showPassword: false,
            showConfirmPassword: false,

        }
    }
    handleShowPassword = (passwordProps) => {
        if (passwordProps === 'password') {
            this.setState({ showPassword: !this.state.showPassword })
        } else {
            this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
        }
    }

    // handleValue = (valueType) => {
    //     this.setState({ value: valueType });
    //     //console.log('valueType', valueType);
    //     //console.log('stateValue', this.state.value)
    // }

    render() {

        const schema = yup.object().shape({
            firstname: formikFormalidation.firstname,
            middlename: formikFormalidation.middlename,
            lastname: formikFormalidation.lastname,
            mobilenumber: formikFormalidation.mobilenumber,
            email: formikFormalidation.email,
            username: formikFormalidation.username,
            password: formikFormalidation.password,
            confirmpassword: formikFormalidation.confirmpassword,
            agreement: formikFormalidation.agreement
        });

        return (

            <Formik
                validationSchema={schema}
                validate={values => {
                    const errors = {};
                    if (!values.mobilenumber) {
                        errors.mobilenumber = "Mobile number cannot be empty.";
                    } else {
                        const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
                        if (!countryCallingCode || !isValidPhoneNumber(values.mobilenumber)) {
                            errors.mobilenumber = 'Invalid Mobile number format!';
                        }
                    }
                    return errors;
                }}
                onSubmit={async (values) => {
                    const countryCallingCode = parsePhoneNumber(values.mobilenumber) && parsePhoneNumber(values.mobilenumber).countryCallingCode
                    const nationalNumber = parsePhoneNumber(values.mobilenumber) && parsePhoneNumber(values.mobilenumber).nationalNumber
                    this.setState({ formData: values });
                    const allFormData = {
                        formData: this.state.formData,
                        countryCallingCode: "+" + countryCallingCode,
                        nationalNumber: nationalNumber,
                    }
                    this.setState({ loading: true });
                    await patientRegister(this.props, allFormData);
                    this.setState({ loading: false });
                }
                }
                initialValues={{
                    firstname: '',
                    middlename: '',
                    lastname: '',
                    mobilenumber: '',
                    email: '',
                    username: '',
                    password: '',
                    confirmpassword: '',
                    agreement: false
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    ErrorMessage,
                    values,
                    touched,
                    isValid,
                    errors
                }) => (
                    <FullLayout className="app-login" isHeader={false} isFooter={false}>
                        <Container>
                            <Row>
                                <Col lg={8} md={6} sm={12}>&nbsp;</Col>
                                <Col lg={4} md={6} sm={12}>
                                    <Card className='p-5 rightcol registerclass'>
                                        <Card.Header as="h2" className='px-2'>Sign Up</Card.Header>
                                        <Card.Subtitle className='p-2' >Welcome to Second Opinion !</Card.Subtitle>
                                        <Card.Body className='p-2'>
                                            {this.state.loading &&
                                                <div className='d-flex justify-content-center flex-nowrap'>
                                                    <div className='position-absolute mx-3 p-4' variant="primary">
                                                        <Spinner animation="border" variant="primary" />
                                                    </div>
                                                </div>
                                            }
                                            {/* <ToggleButtonGroup className='my-3 btn-block' type="radio" name="options" defaultValue={this.state.value} onChange={this.handleValue}>
                                                <ToggleButton id="tbg-radio-1" className="m-0 btn-sm" value={1} >
                                                    <FaUser style={{ width: "1.5rem" }} />I'm a patient
                                                </ToggleButton>
                                                <ToggleButton id="tbg-radio-2" className="m-0 btn-sm" value={2} >
                                                    <FaStethoscope style={{ width: "1.5rem" }} /> I'm a doctor
                                                </ToggleButton>
                                            </ToggleButtonGroup> */}
                                            <Form noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                                <fieldset disabled={this.state.loading}>

                                                    <Form.Group className="mb-3" controlId="formBasicFirstname" >

                                                        <Form.Control type="text"
                                                            placeholder="First name *"
                                                            name="firstname" required
                                                            value={values.firstname}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.firstname && touched.firstname}
                                                        />
                                                        <span className={errors.firstname && touched.firstname ? 'invalid short-label' : 'short-label'}>First name<span>*</span></span>
                                                        {
                                                            errors.firstname && touched.firstname && (
                                                                <div className="invalid-feedback">{errors.firstname}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicMiddlename">
                                                        <Form.Control type="text"
                                                            placeholder="Middle name"
                                                            name="middlename" required
                                                            value={values.middlename}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.middlename && touched.middlename} />
                                                        <span class="short-label">Middle name</span>

                                                        {
                                                            errors.middlename && touched.middlename && (
                                                                <div className="invalid-feedback">{errors.middlename}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicLastname">
                                                        <Form.Control type="text"
                                                            placeholder="Last name"
                                                            name="lastname" required
                                                            value={values.lastname}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.lastname && touched.lastname} />
                                                        <span className={errors.lastname && touched.lastname ? 'invalid short-label' : 'short-label'}>Last name</span>
                                                        {
                                                            errors.lastname && touched.lastname && (
                                                                <div className="invalid-feedback">{errors.lastname}</div>
                                                            )
                                                        }
                                                    </Form.Group>

                                                    <Form.Group className="mb-3 mobilenumber" controlId="formBasicMobilenumber">
                                                        <Form.Control as={PhoneInput}
                                                            name="mobilenumber" required
                                                            placeholder="Mobile number *"
                                                            international
                                                            defaultCountry="IN"
                                                            countries={['US', 'IN']}
                                                            countryCallingCodeEditable={false}
                                                            limitMaxLength={true} 
                                                            value={values.mobilenumber}
                                                            onChange={e => { values.mobilenumber = e; }}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.mobilenumber && touched.mobilenumber}
                                                        />
                                                        <span className={errors.mobilenumber && touched.mobilenumber ? 'invalid short-label' : 'short-label'}><span>*</span></span>
                                                        {
                                                            errors.mobilenumber && touched.mobilenumber && (
                                                                <div className="invalid-feedback">{errors.mobilenumber}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicEmailid">
                                                        <Form.Control type="email"
                                                            placeholder="Email"
                                                            name="email"
                                                            required value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.email && touched.email} />
                                                        <span className={errors.email && touched.email ? 'invalid short-label' : 'short-label'}>Email</span>
                                                        {
                                                            errors.email && touched.email && (
                                                                <div className="invalid-feedback">{errors.email}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicUsername">
                                                        <Form.Control type="text"
                                                            placeholder="Username *"
                                                            name="username" required
                                                            value={values.username}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.username && touched.username} />
                                                        <span className={errors.username && touched.username ? 'invalid short-label' : 'short-label'}>Username<span>*</span></span>
                                                        {
                                                            errors.username && touched.username && (
                                                                <div className="invalid-feedback">{errors.username}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicUsername">
                                                        <Form.Control type={this.state.showPassword ? "text" : "password"}
                                                            placeholder="Create password *"
                                                            name="password" required
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.password && touched.password} />
                                                        <span className={errors.password && touched.password ? 'invalid short-label' : 'short-label'}>Create password <span>*</span></span>
                                                        {!this.state.showPassword && <FaEyeSlash onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                        {this.state.showPassword && <FaEye onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                        {
                                                            errors.password && touched.password && (
                                                                <div className="invalid-feedback">{errors.password}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicUsername">
                                                        <Form.Control type={this.state.showConfirmPassword ? "text" : "password"}
                                                            placeholder="Confirm password *"
                                                            name="confirmpassword" required
                                                            value={values.confirmpassword}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={errors.confirmpassword && touched.confirmpassword} />
                                                        <span className={errors.confirmpassword && touched.confirmpassword ? 'invalid short-label' : 'short-label'}>Confirm password<span>*</span></span>
                                                        {!this.state.showConfirmPassword && <FaEyeSlash onClick={() => this.handleShowPassword("confirmpassword")} className="loginpasswordeye" />}
                                                        {this.state.showConfirmPassword && <FaEye onClick={() => this.handleShowPassword("confirmpasssword")} className="loginpasswordeye" />}                                                        {
                                                            errors.confirmpassword && touched.confirmpassword && (
                                                                <div className="invalid-feedback">{errors.confirmpassword}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type='checkbox'
                                                            id='checkbox'
                                                            name='agreement'
                                                            value={values.agreement}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={(errors.agreement && touched.agreement ? ' is-invalid' : '')}
                                                            label={
                                                                <div>
                                                                    <span>I have read the</span>
                                                                    <Link to={NONAUTHROUTE.PATIENT.TERMSPATH}> Terms and of Use </Link>
                                                                    <span>&</span>
                                                                    <Link to={NONAUTHROUTE.PATIENT.PRIVACYPOLICYPATH}> Privacy policy</Link>
                                                                </div>}
                                                        />
                                                        {
                                                            errors.agreement && touched.agreement && (
                                                                <div className="invalid-feedback">{errors.agreement}</div>
                                                            )
                                                        }
                                                    </Form.Group>
                                                    <Button className="ml-0 btn-block" variant="primary" type="submit" >
                                                        Sign up now
                                                    </Button>
                                                </fieldset>
                                            </Form>
                                            <div className='my-2'>Already have an account? <a className="login-link" href={NONAUTHROUTE.GENERAL.LOGINPATH}>Sign in</a></div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </FullLayout>
                )}
            </Formik>
        );
    }
}

export default PatientRegistration;
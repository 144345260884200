import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { AiFillCheckCircle } from "react-icons/ai";
import '../../landingpage/Section3/Section3.css'
import dr from "../../../../assets/images/dr.png";
import './Section3.css';
export class Section3 extends Component {
    render() {
        return (
            <div className='section3'>
                <Container>
                    <Row className='section3Row  '>
                        <Col lg="6" className='col1'>
                            <div className='imageholder'>
                                <img src={dr} alt='dr-img' />
                            </div>
                        </Col>
                        <Col lg="6" className='col2'>
                            <div className='contentholder'>
                                <p>FEATURES</p>
                                <h2 className='text-blue'>Why to choose us</h2>
                                <Row className='text-white mt-4 data1 no-gutters'>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='textalign'>Easy patient <br /> &nbsp;   &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;    data  manage</span></p>
                                    </Col>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='textalign'>Appoinment <br />  &nbsp;   &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;     Reminders</span></p>
                                    </Col>
                                </Row>

                                <Row className='textalign mt-3 data2 no-gutters'>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='textalign'>Hassle free re-<br /> &nbsp;   &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; scheduling</span></p>
                                    </Col>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='textalign'>Data privacy</span></p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Section3
export const userListAction = {
    DATA_REQUEST:'DATA_REQUEST',
    DATA_SUCCESS:'DATA_SUCCESS',
    DATA_FAILURE:'DATA_FAILURE'
}
export const providerListAction = {
    DATA_REQUEST:'DATA_REQUEST',
    DATA_SUCCESS:'DATA_SUCCESS',
    DATA_FAILURE:'DATA_FAILURE'
}

export const localPatientListAction = {
    DATA_REQUEST:'DATA_REQUEST',
    DATA_SUCCESS:'DATA_SUCCESS',
    DATA_FAILURE:'DATA_FAILURE'
}
import React, { Component } from 'react'
import { Card, Button } from 'react-bootstrap'
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { authenticationService } from '../../general/login/login.service';
import { getStartDate, getParsedSlots } from '../scheduler/SchedulerUtils';
import moment from 'moment';
import { RiCalendar2Line } from "react-icons/ri";

export class MySchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      loading: false,
      //blocked_times: [new Date('2021-09-06T03:30:00.000Z'), new Date('2021-09-04T04:30:00.000Z')],
      blocked_times: [],
      taken_times: [],
      start_date: getStartDate(),
      allScedules: []
    }
  }

  handleSchedulerChange = newSchedule => {
    this.setState({ schedule: newSchedule })
  }

  getProviderSchedules = async () => {
    try {
      const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true });
      const provider_id = currentUser && currentUser.usertype_id
      let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
      if (response.data.status === "success") {
        if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
          const responseObj = response.data
          const _schedules = responseObj.schedules
          const all_schedule = getParsedSlots(_schedules);

          this.setState({
            schedule: response && response.data && response.data.schedules,
            blocked_times: all_schedule && all_schedule.booked_slots,
            taken_times: all_schedule && all_schedule.taken_slots,
            loading: false,
            allScedules: all_schedule
          })
        }
      } else {
        this.setState({ loading: false })
        //message.error(response.data.message);
      }

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  // renderCustomDateCell = (time, selected, innerRef) => (
  //   <div style={{ textAlign: 'center' }} ref={innerRef}>
  //     {selected ? '✅' : '❌'}
  //   </div>
  // )

  componentDidMount() {
    this.getProviderSchedules()
  }

  render() {
    const tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')
    const tomorrowSlots = this.state.schedule &&
      this.state.schedule.filter((item, index) => moment(item.day).format('YYYY-MM-DD') === tomorrowDate && item)
    return (
      <div>
        {tomorrowSlots.length !== 0 ?
          <div>
            <Card style={{ width: '20rem' }}>
              <h5 className='font-weight-bold mt-3 ml-3'>My Schedule</h5>
              <p className='ml-3'>Morning session</p>

              {tomorrowSlots.map((data, id) =>
                <div key={id} className='ml-3'>
                  <div>
                    <div className="d-flex flex-wrap">{data?.slots.sort((a, b) => a.slot.localeCompare(b.slot)).map((slots, id) =>
                      <div key={id} className=''>
                        {slots.slot.includes('AM') &&
                          <div>
                            {slots.status === 3 ?
                              <Button className='btn my-1 btn-sm text-white text-center mr-2' style={{ border: '1px solid #f01414', backgroundColor: '#f01414' }}>{slots.slot}</Button> :
                              <Button className='btn my-1 btn-sm text-white text-center mr-2' style={{ color: '#143C8C', border: '1px solid #143c8c', backgroundColor:'#143C8C' }}>{slots.slot}</Button>}
                          </div>
                        }
                      </div>
                    )}
                    </div>
                  </div>
                </div>
              )}

              <p className='ml-3 mt-2'>Evening session </p>
              {tomorrowSlots.map((data, id) =>
                <div key={id}>
                  <div>
                    <div className='ml-3 mb-3 d-flex flex-wrap'>{data?.slots.sort((a, b) => a.slot.localeCompare(b.slot)).map((item, id) =>
                      <div key={id}>
                        {item.slot.includes('PM') &&
                          <div>
                            {item.status === 3 ?
                              <Button className='btn my-1 btn-sm text-white text-center mr-2' style={{ border: '1px solid #f01414', backgroundColor: '#f01414'}}>{item.slot}</Button> :
                              <Button className='btn my-1 btn-sm text-white text-center mr-2' style={{ color: '#143C8C', border: '1px solid #143c8c', backgroundColor:'#143C8C'}}>{item.slot}</Button>}
                          </div>
                        }

                      </div>)}
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
          :
          <div class="card">
            <div class="card-body text-center">
              <h6 className='text-left'>My Schedule</h6>
              <RiCalendar2Line size={60} className='text-secondary mt-3' />
              <p className='font-weight-bold'>No appointments</p>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default MySchedule
import React, { Component } from 'react'
import { Col, Card, Container } from 'react-bootstrap'
import { NONAUTHROUTE } from '../../../../constants/RouterPath';
import './Section4.css'
import doct1 from "../../../../assets/images/doct1.png";
import doct2 from "../../../../assets/images/doct2.png";
import doct3 from "../../../../assets/images/doct3.png";


import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", color: "red" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", color: "red" }}
            onClick={onClick}
        />
    );
}


export class Section4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false,
            visible: true
        }
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        arrows: false,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        arrows: false,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        nextArrow: <SampleNextArrow />,
                        prevArrow: <SamplePrevArrow />,
                    }
                }
            ]
        };
        return (
            <div className='bg-white section-4' >
                <Container>
                    <h5 className='container_1'>OUR TEAM</h5>
                    <h3 className='mt-3'>The minds behind the vision</h3>

                    <div className='h-scrollholder'>
                        <Slider {...settings}>
                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body >
                                        <Card.Title>
                                            <img
                                                src={doct1}
                                                alt='get opinion' style={{ width: '12rem' }} />
                                        </Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}> <h6>Dr.Dae-seong</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>Family practice</a>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct2}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}><h6>Dr.Austin fernado</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>General surgery</a>
                                    </Card.Body>
                                </Card>
                            </Col>



                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct3}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}><h6>Dr.Frank junior</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>Internal Medicine</a>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct2}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}><h6>Dr.Austin fernado</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>General surgery</a>
                                    </Card.Body>
                                </Card>
                            </Col>



                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct1}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}> <h6>Dr.Dae-seong</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>Family practice</a>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct2}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}><h6>Dr.Austin fernado</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>General surgery</a>
                                    </Card.Body>
                                </Card>
                            </Col>



                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct3}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}><h6>Dr.Frank junior</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>Internal Medicine</a>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='cards'>
                                <Card style={{ width: '15rem', height: '15rem' }} className='cardAnimate'>
                                    <Card.Body>
                                        <Card.Title>
                                            <img
                                                src={doct2}
                                                alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                        <Card.Subtitle style={{ marginTop: '10px' }}><h6>Dr.Austin fernado</h6></Card.Subtitle>
                                        <a href={NONAUTHROUTE.GENERAL.LOGINPATH}>General surgery</a>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Slider>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Section4
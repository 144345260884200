import './App.scss';
import React, { Component } from 'react';
import { message } from 'antd';
import Room from './Room';
import { Spinner } from 'react-bootstrap';
import { get_provider_user_obj, get_room_details_obj } from '../patient/appointment/Utils';
import { get_provider_obj, get_patient_obj, get_patient_user_obj } from '../patient/enquiry/EnquiryUtils';
import { authenticationService } from '../../component/general/login/login.service';
import TwilioService from '../../services/general/Twilio.Service';
import { errorMessage } from '../../utils/ServiceHandler';
import { NONAUTHROUTE } from "../../constants/RouterPath";
const { connect } = require('twilio-video');

class VideoComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentItem: {},
      user_obj: {},
      patient_obj: {},
      provider_obj: {},
      provider_user_obj: {},
      room_details_obj: {},
      identity: '',
      accessToken: null,
      room: null,
      loading: false,
      roomName: ''
    }

    this.inputRef = React.createRef();

    this.joinRoom = this.joinRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.updateIdentity = this.updateIdentity.bind(this);
    this.removePlaceholderText = this.removePlaceholderText.bind(this);
  }

  async joinRoom() {
    try {
      const accessToken = this.state && this.state.accessToken
      if (accessToken) {
        const room = await connect(accessToken, {
          name: this.state && this.state.roomName,
          audio: true,
          video: true
        });

        this.setState({ loading: false });
        this.setState({ room: room });
      } else {
        message.error('Could not connect to Twilio, as there is issue in Token.')
      }
    } catch (err) {
      console.log(err);
    }
  }

  leaveRoom() {
    this.setState({ room: null });
  }

  updateIdentity(event) {
    this.setState({
      identity: event.target.value
    });
  }

  removePlaceholderText() {
    this.inputRef.current.placeholder = '';
  }

  redirectCheck(props) {
    if (props.location) {
      if (typeof props.location.state === "undefined") {
        props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
      }
    }
  }
  getTwilioToken = async () => {
    try {
      this.setState({ loading: true });
      const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true })
      var data = {
        "identity": currentUser && currentUser.displayname,
        "room": this.state && this.state.roomName,
      };
      let response = await TwilioService.fetch_token(data);
      if (response.data.status === "success") {
        if (response && response.data) {
          setTimeout(() => {
            this.setState({
              identity: currentUser && currentUser.displayname,
              accessToken: response && response.data && response.data.data && response.data.data.token
            })
          }, 100);
          setTimeout(() => {

            this.joinRoom()
          }, 100);
        }
      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }
    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  getvalsfromAPI = () => {
    this.setState({ loading: true })
    const currentItem = this.props && this.props.location && this.props.location.state && this.props.location.state.currentItem
    const consultationInfo = this.props && this.props.location && this.props.location.state && this.props.location.state.consultationInfo
    const currentUser = authenticationService.currentUserValue;
    if (currentItem) {
      const patient_obj = get_patient_obj(currentItem)
      const user_obj = get_patient_user_obj(currentItem)
      const provider_obj = get_provider_obj(currentItem)
      const provider_user_obj = get_provider_user_obj(currentItem)
      const room_details_obj = get_room_details_obj(consultationInfo)
      this.setState({
        currentItem: currentItem,
        user_obj: user_obj,
        patient_obj: patient_obj,
        provider_obj: provider_obj,
        provider_user_obj: provider_user_obj,
        room_details_obj: room_details_obj,
        roomName: room_details_obj.uniqueName,
        currentUser: currentUser,
      })
      setTimeout(() => {
        this.getTwilioToken()
      }, 100);

    } else {
      this.redirectCheck(this.props)
    }
  }

  componentDidMount() {
    this.getvalsfromAPI()
  }

  render() {
    // const disabled = this.state.identity === '' ? true : false;

    return (
      <React.Fragment>

        {this.state.loading ? <div className='d-flex justify-content-center flex-nowrap'>
          <div className='position-absolute p-4' variant="primary" style={{ marginRight: '190px' }}>
            <Spinner animation="border" variant="primary" />
          </div>
        </div> :
          <div className="app">
            {this.state.room === null
              ? <div className="lobby">
                {/* <Button type="primary" disabled={disabled} htmlType="submit" style={{ width: 'auto' }} onClick={this.joinRoom}>
                Join Room ({this.state && this.state.currentUser && this.state.currentUser.displayname})
              </Button> */}
              </div>
              : <Room leaveRoom={this.leaveRoom} room={this.state.room} {...this.props}  />
            }
          </div>
        }
      </React.Fragment >)
  }
}

export default VideoComponent;

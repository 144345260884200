import React, { Component } from 'react';
import { Form, Button, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import { formikFormalidation } from '../../../../component/general/formvalidator/validationFroms';
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { NONAUTHROUTE } from '../../../../constants/RouterPath';
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { Formik } from 'formik';
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import logo from "../../../../assets/images/logo.png";
import moment from 'moment';
import { patientRegister } from '../../../../component/patient/registration/patient-registration.service';
import './GetOpinion.css';

class PatientSignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: '',
            value: null,
            loading: false,
            showPassword: false,
            showConfirmPassword: false,
            userDetails: this.props.location.state.detail.formData && { ...this.props.location.state.detail.formData, agreement: false }

        }
    }
    handleShowPassword = (passwordProps) => {
        if (passwordProps === 'password') {
            this.setState({ showPassword: !this.state.showPassword })
        } else {
            this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
        }
    }

    render() {
        const schema = yup.object().shape({
            firstname: formikFormalidation.firstname,
            middlename: formikFormalidation.middlename,
            lastname: formikFormalidation.lastname,
            mobilenumber: formikFormalidation.mobilenumber,
            email: formikFormalidation.email,
            username: formikFormalidation.username,
            password: formikFormalidation.password,
            confirmpassword: formikFormalidation.confirmpassword,
            agreement: formikFormalidation.agreement
        });

        return (

            <Formik
                validationSchema={schema}

                validate={values => {
                    const errors = {};
                    if (!values.mobilenumber) {
                        errors.mobilenumber = "Mobile number is required!";
                    } else {
                        const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
                        if (!countryCallingCode || !isValidPhoneNumber(values.mobilenumber)) {
                            errors.mobilenumber = 'Invalid Mobile number format!';
                        }
                    }
                    return errors;
                }}
                onSubmit={async (values) => {
                    const countryCallingCode = parsePhoneNumber(values.mobilenumber) && parsePhoneNumber(values.mobilenumber).countryCallingCode
                    const nationalNumber = parsePhoneNumber(values.mobilenumber) && parsePhoneNumber(values.mobilenumber).nationalNumber
                    this.setState({ formData: values });
                    const allFormData = {
                        formData: this.state.formData,
                        countryCallingCode: "+" + countryCallingCode,
                        nationalNumber: nationalNumber,
                    }
                    this.setState({ loading: true });
                    localStorage.setItem("newUserVal", this.props.location.state.newUserVal, "user_entered_concern", this.props.location.state.user_entered_concern)
                    await patientRegister(this.props, allFormData);
                    this.setState({ loading: false });
                }
                }
                initialValues={this.state.userDetails && this.state.userDetails}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    ErrorMessage,
                    values,
                    touched,
                    isValid,
                    errors
                }) => (
                    <div>
                        <Row>
                            <Col md={7} style={{ paddingTop: '120px', paddingBottom: '210px', backgroundColor: '#E5E5E5' }}>
                                <Container>
                                    <img src={logo} className="img-fluid pb-5" alt="Logo" style={{ mixBlendMode: 'darken', marginLeft: '30px' }} />
                                    <h6 style={{ fontSize: '13px', color: '#143C8C', marginLeft: '42px' }}>GET STARTED!</h6>
                                    <h5 className='mt-3' style={{ fontSize: '20px', marginLeft: '40px' }}>Your concern has been received.</h5>
                                    <p className='mt-3' style={{ marginLeft: '40px' }}>Inorder to assign a doctor we need more information. Please sign up.</p>
                                    <Card className="p-2 mt-5 userCard"
                                        style={{ height: "auto" }}>
                                        <Row className="py-2 px-2 card2">
                                            <Col>
                                                <h6 className="text-secondary" style={{ fontSize: '13px' }} >CONCERN</h6>
                                                <p className='font-weight-bold'>{this.props.location.state.response.userData.enquiry_message}</p>
                                            </Col>
                                            <Col>
                                                <h6 className="text-secondary" style={{ fontSize: '13px' }}>SUBMITTED DATE</h6>
                                                <p className='font-weight-bold'>{moment(this.props.location.state.response.updatedDate).format('MMMM Do YYYY')}</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Container>
                            </Col>



                            <Col md={5} className='px-5' style={{ paddingTop: '80px', backgroundColor: 'white' }}>
                                <Container>
                                    {this.state.loading &&
                                        <div className='d-flex justify-content-center flex-nowrap'>
                                            <div className='position-absolute mx-3 p-4' variant="primary">
                                                <Spinner animation="border" variant="primary" />
                                            </div>
                                        </div>
                                    }
                                    <h2>Sign Up</h2>
                                    <Card.Subtitle className='pt-2 pb-4' >Welcome to Second Opinion !</Card.Subtitle>
                                    <Form className='registerclass' noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                        <fieldset disabled={this.state.loading}>

                                            <Form.Group className="mb-3" controlId="formBasicFirstname" >

                                                <Form.Control type="text"
                                                    className='field'
                                                    placeholder="First name *"
                                                    name="firstname" required
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.firstname}
                                                />
                                                <span className={errors.firstname ? 'invalid short-label' : 'short-label'}>First name<span>*</span></span>
                                                {
                                                    errors.firstname && touched.firstname && (
                                                        <div className="invalid-feedback">{errors.firstname}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicMiddlename">
                                                <Form.Control type="text"
                                                    className='field'
                                                    placeholder="Middle name"
                                                    name="middlename" required
                                                    value={values.middlename}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.middlename && touched.middlename} />
                                                <span className="short-label">Middle name</span>

                                                {
                                                    errors.middlename && touched.middlename && (
                                                        <div className="invalid-feedback">{errors.middlename}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicLastname">
                                                <Form.Control type="text"
                                                    className='field'
                                                    placeholder="Last name"
                                                    name="lastname" required
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.lastname && touched.lastname} />
                                                <span className={errors.lastname && touched.lastname ? 'invalid short-label' : 'short-label'}>Last name</span>
                                                {
                                                    errors.lastname && touched.lastname && (
                                                        <div className="invalid-feedback">{errors.lastname}</div>
                                                    )
                                                }
                                            </Form.Group>

                                            <Form.Group className="mb-3 mobilenumber" controlId="formBasicMobilenumber">
                                                <Form.Control as={PhoneInput}
                                                    className='field'
                                                    name="mobilenumber"
                                                    placeholder="Mobile number *"
                                                    international
                                                    defaultCountry="IN"
                                                    countries={['US', 'IN']}
                                                    countryCallingCodeEditable={false}
                                                    limitMaxLength={true} required
                                                    value={values.mobilenumber}
                                                    onChange={e => { values.mobilenumber = e; }}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.mobilenumber}
                                                />
                                                <span className={errors.mobilenumber && touched.mobilenumber ? 'invalid short-label' : 'short-label'}><span>*</span></span>
                                                {
                                                    errors.mobilenumber && (
                                                        <div className="invalid-feedback">{errors.mobilenumber}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmailid">
                                                <Form.Control type="email"
                                                    className='field'
                                                    placeholder="Email"
                                                    name="email"
                                                    required value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.email} />
                                                <span className={errors.email && touched.email ? 'invalid short-label' : 'short-label'}>Email</span>
                                                {
                                                    errors.email && touched.email && (
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Control type="text"

                                                    placeholder="Username *"
                                                    name="username" required
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.username} />
                                                <span className={errors.username ? 'invalid short-label' : 'short-label'}>Username<span>*</span></span>
                                                {
                                                    errors.username && (
                                                        <div className="invalid-feedback">{errors.username}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Control type={this.state.showPassword ? "text" : "password"}
                                                    placeholder="Create password *"
                                                    name="password" required
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.password} />
                                                <span className={errors.password ? 'invalid short-label' : 'short-label'}>Create password <span>*</span></span>
                                                {!this.state.showPassword && <FaEyeSlash onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                {this.state.showPassword && <FaEye onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                {
                                                    errors.password && (
                                                        <div className="invalid-feedback">{errors.password}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                <Form.Control type={this.state.showConfirmPassword ? "text" : "password"}
                                                    className='field'
                                                    placeholder="Confirm password *"
                                                    name="confirmpassword" required
                                                    value={values.confirmpassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={errors.confirmpassword} />
                                                <span className={errors.confirmpassword ? 'invalid short-label' : 'short-label'}>Confirm password<span>*</span></span>
                                                {!this.state.showConfirmPassword && <FaEyeSlash onClick={() => this.handleShowPassword("confirmpassword")} className="loginpasswordeye" />}
                                                {this.state.showConfirmPassword && <FaEye onClick={() => this.handleShowPassword("confirmpasssword")} className="loginpasswordeye" />}                                                        {
                                                    errors.confirmpassword && (
                                                        <div className="invalid-feedback">{errors.confirmpassword}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    type='checkbox'
                                                    id='checkbox'
                                                    name='agreement'
                                                    value={values.agreement}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={(errors.agreement && touched.agreement ? ' is-invalid' : '')}
                                                    label={
                                                        <div>
                                                            <span>I have read the</span>
                                                            <Link to={NONAUTHROUTE.PATIENT.TERMSPATH}> Terms and of Use </Link>
                                                            <span>&</span>
                                                            <Link to={NONAUTHROUTE.PATIENT.PRIVACYPOLICYPATH}> Privacy policy</Link>
                                                        </div>}
                                                />
                                                {
                                                    errors.agreement && (
                                                        <div className="invalid-feedback">{errors.agreement}</div>
                                                    )
                                                }
                                            </Form.Group>
                                            <Button className="ml-0 btn-block" variant="primary" type="submit" >
                                                Sign up now
                                            </Button>
                                        </fieldset>
                                    </Form>
                                    <div className='my-2'>Already have an account? <a className="login-link" href={NONAUTHROUTE.GENERAL.LOGINPATH}>Sign in</a></div>
                                </Container>
                            </Col>
                        </Row >
                    </div>
                )
                }
            </Formik>
        );
    }
}

export default PatientSignUp;
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import ViewDetails from './ViewDetails';
import details from '../../assets/images/details.png'
import './Details.css'

function Details(props) {

    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <div>
       <div> <img className='detailsIcon' src={details} alt="document" onClick={handleClick} /></div>

            <Modal style={{ backdropFilter: "brightness(0.5)" }} show={showModal} onHide={handleClose}
                centered
                aria-labelledby="example-modal-sizes-title-sm"
                animation={false}
                className='detailsModal'
            >
                <Modal.Header style={{ border: "0", marginTop:"-15px" }} closeButton >
                </Modal.Header>
                <Modal.Body className=''><ViewDetails {...props} /></Modal.Body>
            </Modal>
        </div>
    )
}

export default Details
import {userListAction} from '../../actions/admin/types'
  
  const initialState = {
    loading: false,
    users: [],
  }
  
  const UserListReducer = (state = initialState, action) => {
    switch (action.type) {
      case userListAction.DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case userListAction.DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          users: action.payload.users,
        }
      case userListAction.DATA_FAILURE:
        return {
          loading: false,
          users: [],
        }
      default: return state
    }
  }
  
  export default UserListReducer
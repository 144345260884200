import {localPatientListAction} from '../../actions/admin/types'
  
  const initialState = {
    loading: false,
    patients: [],
  }
  
  const LocalPatientListReducer = (state = initialState, action) => {
    switch (action.type) {
      case localPatientListAction.DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case localPatientListAction.DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          patients: action.payload.users,
        }
      case localPatientListAction.DATA_FAILURE:
        return {
          loading: false,
          patients: [],
        }
      default: return state
    }
  }
  
  export default LocalPatientListReducer
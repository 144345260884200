import { GET, GET_NONAUTH, PUT, POST, POST_NONAUTH, DELETE } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";
import { authenticationService } from '../../component/general/login/login.service';
import Role from "../../constants/role";
import ProfileService from '../../services/provider/Profile.Service';
import UserListService from '../../services/admin/UserList.Service';

function getPatientIDFromProps(ownProps) {
  let patient_id = ""
  if (ownProps && ownProps.patient_id) {
    patient_id = ownProps.patient_id
  } else if (ownProps && ownProps.location && ownProps.location.state && ownProps.location.state.selected_record) {
    patient_id = ownProps.location.state.selected_record._id
  }
  return patient_id
}
/**
 * Get Encryted id 
 *
 */
function getEncryptedID(enquiry_id) {
  let url = APIENDPOINT.PATIENT.ENCRYPT_ID + "/" + enquiry_id;
  return GET_NONAUTH(url);
}


/**
 * Get Enquiries List information 
 */

function getEnquiries(ownProps) {
  const currentUser = authenticationService.currentUserValue;
  let patient_id = getPatientIDFromProps(ownProps);
  let userType = currentUser.usertype
  if (currentUser.usertype === Role.localprovider.name || currentUser.usertype === Role.patient.name) {
    userType = Role.patient.name
  }
  let defaultUrl = APIENDPOINT.PATIENT.LISTENQUIRIES + "/" + userType
  var url = defaultUrl + "/" + patient_id;
  return GET(url);
}
/**
 * Get Single Enquiry details 
 */
function getEnquiryDetails(data) {
  const enquiry_id = data && data.enquiry_id
  const patient_id = data && data.patient_id
  const currentUser = authenticationService.currentUserValue;
  let url = APIENDPOINT.PATIENT.LISTENQUIRIES + "/" + currentUser.usertype + "/" + patient_id + "?guid=" + enquiry_id;
  return GET(url);
}

/**
 * Get Single Enquiry details 
 */
function getNonAuthEnquiryDetails(enquiry_id) {
  let url = APIENDPOINT.PATIENT.NON_AUTH_GET_ENQUIRY + "/" + enquiry_id;
  return GET_NONAUTH(url);
}
/**
 * Get Provider's by Speciality 
 */
function getProviderBySpeciality(enquiry) {
  const _speciality_id = enquiry && enquiry.speciality && enquiry.speciality.id
  let url = APIENDPOINT.PROVIDER.GET_PROVIDER_BY_SPECIALITY + _speciality_id
  return GET(url);
}
/**
 * Get Stages List information 
 */
function getStages() {
  let url = APIENDPOINT.ADMIN.GETSTAGES;
  return GET(url);
}

/**
 * Multiple Update Assignee for Patient Enquiries
 */
const bulkUpdateAssignee = async (dataObj) => {
  //const formData = dataObj.formData
  const select_user_obj = dataObj && dataObj.select_user_obj
  const enquiry_ids = dataObj.enquiry_ids
  var data = {
    "enquiry_ids": enquiry_ids || [],
    "assignee_id": select_user_obj._id || ""
  };
  return PUT(APIENDPOINT.ADMIN.BULK_ENQUIRY_ASSIGNEE, data)
}
/**
 * Create a New Patient Enquiry
 */
const createEnquiry = async (formData) => {
  return POST(APIENDPOINT.PATIENT.LISTENQUIRIES, formData)
}

/**
 * Update Patient Enquiry Details
 */
const updateEnquiry = async (formData) => {
  return PUT(APIENDPOINT.PATIENT.UPDATE_ENQUIRY, formData)
}

/**
 * Mapping Provider for Patient Enquiry
 */
const setEnquiryProvider = async (data) => {
  return PUT(APIENDPOINT.ADMIN.PROVIDER_ASSIGNEE, data)
}

/**
 * Soft delete a file from Patient Enquiry
 */

const deleteFile = async (formData) => {
  const enquiryexternalid = formData && formData.enquiryId
  const file_name = formData && formData.filename
  const url = APIENDPOINT.PATIENT.REMOVE_FILE + "/" + enquiryexternalid + "/" + file_name
  return DELETE(url, formData)
}

const removeFile = async (formData) => {
  const enquiryexternalid = formData.enquiry && formData.enquiry.enquiryexternalid
  const file_name = formData.file_obj && formData.file_obj.filename
  const url = APIENDPOINT.PATIENT.REMOVE_FILE + "/" + enquiryexternalid + "/" + file_name
  return DELETE(url, formData)
}
/**
 * Generate OTP for Booking Consultation 
 */
function generateConsulationOTP(formData) {
  let url = APIENDPOINT.PATIENT.GENERATE_CONSULTATION_OTP;
  return POST_NONAUTH(url, formData)
}
/**
 * Patient Verify OTP and save to Session Cart
 */
function verifyConsulationOTP(formData) {
  let url = APIENDPOINT.PATIENT.VERIFY_CONSULTATION_OTP;
  return POST_NONAUTH(url, formData)
}

function generateOrdercode(formData) {
  let url = APIENDPOINT.PATIENT.GENERATE_ORDER_CODE;
  return POST_NONAUTH(url, formData)
}
/**
 * Patient confirm Appointment Booking from Session cart
 */
function confirmAppointmentBooking(formData) {
  let url = APIENDPOINT.PATIENT.CONFIRM_APPOINTMENT_BOOKING;
  return POST_NONAUTH(url, formData)
}

function searchProviders(searchParams) {
  const _searchQuery_params = searchParams && searchParams.speciality_id + "?searchKey=" + searchParams.searchkey_value
  let url = APIENDPOINT.PROVIDER.SEARCH_PROVIDERS + _searchQuery_params
  return GET(url);
}

const getEnquiryRelatedDetails = async (data) => {
  try {
    const stagesPromise = getStages();
    const internalUsersPromise = UserListService.getInternalUsers();
    const enquiryPromise = getEnquiryDetails(data)
    const specialityPromise = ProfileService.getSpecialites();

    const reponses = await Promise.all([stagesPromise, internalUsersPromise, enquiryPromise, specialityPromise]);
    let enquiryData;
    const [stagesResponse, internalUsersResponse, enquiryResponse, specialityResponse] = reponses;
    if (stagesResponse.data && internalUsersResponse.data && enquiryResponse.data) {
      const stages = stagesResponse.data && stagesResponse.data.data;
      const internalusers = internalUsersResponse.data && internalUsersResponse.data.data
      const specialities = specialityResponse.data && specialityResponse.data.data
      if (enquiryResponse.data.data && enquiryResponse.data.data.length > 0) {
        enquiryData = enquiryResponse.data && enquiryResponse.data.data[0]
      }

      return Promise.resolve({ stages: stages, internalusers: internalusers, enquiryData: enquiryData, specialities: specialities });
    } else {
      return Promise.reject(reponses.data.message);
    }
  } catch (err) {
    return Promise.reject(err);
  }
}
const enquiryListService = {
  getEnquiries: getEnquiries,
  getNonAuthEnquiryDetails: getNonAuthEnquiryDetails,
  getEnquiryDetails: getEnquiryDetails,
  getStages: getStages,
  bulkUpdateAssignee: bulkUpdateAssignee,
  createEnquiry: createEnquiry,
  getProviderBySpeciality: getProviderBySpeciality,
  updateEnquiry: updateEnquiry,
  setEnquiryProvider: setEnquiryProvider,
  removeFile: removeFile,
  generateConsulationOTP: generateConsulationOTP,
  verifyConsulationOTP: verifyConsulationOTP,
  confirmAppointmentBooking: confirmAppointmentBooking,
  searchProviders: searchProviders,
  getEnquiryRelatedDetails: getEnquiryRelatedDetails,
  getEncryptedID: getEncryptedID,
  generateOrdercode: generateOrdercode,
  deleteFile: deleteFile
}
export default enquiryListService;
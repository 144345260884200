import React, { useEffect, useState } from "react";
import IMG01 from "../shared/profile.png";
import Dropdown from "react-bootstrap/Dropdown";
import { logout, isLogin, authenticationService } from '../general/login/login.service';
import { NONAUTHROUTE, AUTHROUTE } from "../../constants/RouterPath";
import Role from '../../constants/role'
import { formateName } from '../patient/enquiry/EnquiryUtils';

const ProfileDropDown = (props) => {
  const [state, setState] = useState(false)
  const currentUser = authenticationService.currentUserValue;

  useEffect(() => setState(isLogin()), [props])

  const handleLogout = () => {
    logout();
    setState(false)
  }

  return (
    <Dropdown className="user-drop nav-item dropdown has-arrow logged-item">
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        <img
          className="rounded-circle"
          src={IMG01}
         
          width="31"
          alt={currentUser && currentUser.displayname}
        /> {props.view !== 'mobile' ? state && currentUser && 'Hi, ' + formateName(currentUser.displayname) : ''}
      </Dropdown.Toggle>

      <Dropdown.Menu>    
      {(() => {
        if (currentUser && currentUser.usertype === Role.patient.name) {
            return <Dropdown.Item href={AUTHROUTE.PATIENT.PROFILEPATH}> Profile </Dropdown.Item>    
        } else if(currentUser && currentUser.usertype === Role.provider.name) {
          return <Dropdown.Item href={AUTHROUTE.PROVIDER.PROFILEPATH}> Profile </Dropdown.Item> 
        }
        })()}
           <Dropdown.Item onClick={() => handleLogout()} href={NONAUTHROUTE.GENERAL.LANDINGPATH}>Logout</Dropdown.Item>
      </Dropdown.Menu>

    </Dropdown>
  );
};

export default ProfileDropDown;

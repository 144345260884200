import React, { Component } from 'react'
import { Layout } from '../../shared';
import userListService from '../../../services/admin/UserList.Service'
import { Spinner, Form, Button, Container } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup'
import { formikFormalidation } from '../../general/formvalidator/validationFroms';
import { FaEyeSlash, FaEye } from "react-icons/fa";

export class InternalResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: this.props && this.props.match && this.props.match.params && this.props.match.params.id,
            showPassword: false,
            showConfirmPassword: false,
        }
    }

    handleShowPassword = (passwordProps) => {
        if (passwordProps === 'password') {
            this.setState({ showPassword: !this.state.showPassword })
        } else {
            this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
        }
    }
    
    componentDidMount() {
        const id = this.state && this.state.id
        userListService.verifyActivationlink(this.props && this.props.history, id)
    }

    render() {
        const schema = yup.object().shape({
            password: formikFormalidation.password,
            confirmpassword: formikFormalidation.confirmpassword,
        });
        return (
            <Layout className="app-login" isContactHeader={true} isFooter={true}>
                <div className="content bg-white">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="account-content">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-md-12 col-lg-6 login-right">
                                            <div className="login-header center">
                                                <h2 className='my-4 ml-3'>Reset Password</h2>
                                            </div>
                                            <Formik
                                                validationSchema={schema}
                                                onSubmit={
                                                    async (values) => {
                                                        const id = this.state.id
                                                        const allValues = {
                                                            formData: values,
                                                            id: id
                                                        }
                                                        this.setState({
                                                            loading: true
                                                        })
                                                        await userListService.internalResetPassword(allValues, this.props.history);
                                                       
                                                        this.setState({
                                                            loading: false
                                                        })
                                                    }
                                                }
                                                initialValues={{password:'',confirmpassword:''}}
                                            >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    ErrorMessage,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors
                                                }) => (
                                                    <div>
                                                                <Container>
                                                                    {this.state.loading &&
                                                                        <div className='d-flex justify-content-center flex-nowrap'>
                                                                            <div className='position-absolute mx-3 p-4' variant="primary">
                                                                                <Spinner animation="border" variant="primary" />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                  
                                                                    <Form className='registerclass' noValidate validated={this.state.validated} onSubmit={handleSubmit}>
                                                                        <fieldset disabled={this.state.loading}>
                                                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                                                <Form.Control type={this.state.showPassword ? "text" : "password"}
                                                                                    placeholder="Create password *"
                                                                                    name="password" required
                                                                                    value={values.password}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    isInvalid={errors.password} />
                                                                                <span className={errors.password ? 'invalid short-label' : 'short-label'}>Create password <span>*</span></span>
                                                                                {!this.state.showPassword && <FaEyeSlash onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                                                {this.state.showPassword && <FaEye onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                                                {
                                                                                    errors.password && (
                                                                                        <div className="invalid-feedback">{errors.password}</div>
                                                                                    )
                                                                                }
                                                                            </Form.Group>
                                                                            <Form.Group className="mb-3" controlId="formBasicUsername">
                                                                                <Form.Control type={this.state.showConfirmPassword ? "text" : "password"}
                                                                                    placeholder="Confirm password *"
                                                                                    name="confirmpassword" required
                                                                                    value={values.confirmpassword}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    isInvalid={errors.confirmpassword} />
                                                                                <span className={errors.confirmpassword ? 'invalid short-label' : 'short-label'}>Confirm password<span>*</span></span>
                                                                                {!this.state.showConfirmPassword && <FaEyeSlash onClick={() => this.handleShowPassword("confirmpassword")} className="loginpasswordeye" />}
                                                                                {this.state.showConfirmPassword && <FaEye onClick={() => this.handleShowPassword("confirmpasssword")} className="loginpasswordeye" />}                                                        {
                                                                                    errors.confirmpassword && (
                                                                                        <div className="invalid-feedback">{errors.confirmpassword}</div>
                                                                                    )
                                                                                }
                                                                            </Form.Group>
                                                                          
                                                                            <Button className="ml-0 btn-block mb-5 mt-4" variant="primary" type="submit" >
                                                                                Submit
                                                                            </Button>
                                                                        </fieldset>
                                                                    </Form>
                                                                </Container>
                                                    </div>
                                                )
                                                }
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default InternalResetPassword
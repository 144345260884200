import React, { Component } from 'react'
import { Card } from 'react-bootstrap'

export class KarmaImpact extends Component {
    render() {
        return (
            <div>
                <Card style={{backgroundColor:'#C3DBD9', width: '20rem'}}>
                    <Card.Body>
                        <h5>Karma impact</h5>
                        <p>
                          Your Karma is bloomimg. You have helped us to collect 20% more fund than last month.
                        </p>
                       <h6>Your karmic position</h6>
                    <dl>
                        
                    </dl>
                    </Card.Body>
                </Card>
                
            </div>
        )
    }
}

export default KarmaImpact
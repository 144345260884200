import { initialDataAction } from './types'
import ProviderListService from '../../services/admin/ProviderList.Service';
import ProfileService from '../../services/provider/Profile.Service';
import { alertActions } from "../alertAction";
import store from '../../store';
import { storageActions } from "../../actions/storageAction";
import { encryptData } from '../../utils/SecureStorage';
import MasterDataService from '../../services/general/Masters.Service';

/**
 * Get all Initial Data
 * @returns 
 */
export const getInitialData = () => async (dispatch) => {
  try {
    dispatch(initialDataRequest())
    //const internalUsersPromise = await UserListService.getInternalUsers();
    const stagesPromise = await ProviderListService.getStages();
    const specialityPromise = ProfileService.getSpecialites();
    const masterDataPromise = await MasterDataService.fetchMasterData();

    const reponses = await Promise.all([stagesPromise, specialityPromise, masterDataPromise]);
    const [stagesResponse, specialityResponse, masterDataResponse] = reponses;
    if (stagesResponse.data && specialityResponse.data && masterDataResponse.data) {
      const stages = stagesResponse.data && stagesResponse.data.data;
      const specialities = specialityResponse.data && specialityResponse.data.data
      const masterData = masterDataResponse.data && masterDataResponse.data.data

      store.dispatch(storageActions.add('stages', encryptData(stages)))
      store.dispatch(storageActions.add('specialities', encryptData(specialities)))
      store.dispatch(storageActions.add('master', encryptData(masterData)))
      dispatch(initialDataSuccess({ stages: stages, specialities: specialities, masters: masterData }))

      return Promise.resolve({ stages: stages, specialities: specialities, masters: masterData });
    } else {
      dispatch(initialDataFailure(reponses.data.message));
      dispatch(alertActions.error(reponses.data.message))
      return Promise.reject(reponses.data.message);
    }
  } catch (err) {
    dispatch(initialDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const initialDataRequest = () => {
  return {
    type: initialDataAction.DATA_REQUEST
  }
}

export const initialDataSuccess = data => {
  return {
    type: initialDataAction.DATA_SUCCESS,
    payload: data,
  }
}

export const initialDataFailure = error => {
  return {
    type: initialDataAction.DATA_FAILURE,
    payload: error
  }
}

import { storageTypes } from './types';

export const storageActions = {
    add,
    update,
    deleteKey
};

function add(key,value) {
    return { type: storageTypes.ADD, key,value };
}

function update(key,value) {
    return { type: storageTypes.UPDATE, key,value };
}

function deleteKey(key) {
    return { type: storageTypes.DELETE,key };
}
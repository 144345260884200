import React, { Component } from 'react'
import { get_formated_time_only, get_formated_date_only } from './EnquiryUtils';
import { Row, Col, Card, Modal, Spinner, Button } from 'react-bootstrap';
import { FaFile } from 'react-icons/fa';
import "./enquiry.style.css";
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AfterLoginDashboardLayout } from '../../shared/AfterLoginDashboardLayout';
import { AUTHROUTE } from '../../../constants/RouterPath';
import { PatientHelpRightBar } from '../sidebar/PatientHelpRightBar';
import { authenticationService } from '../../general/login/login.service';
import EditOpinion from './EditOpinion';
import { getEnquiryListDetails } from '../../../actions/patient/EnquiriesAction';
import { connect } from 'react-redux';
import enquiryListService from '../../../services/patient/EnquiryList.Service';
import { FaFileWord } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import Role from '../../../constants/role';

class EnquiryDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      selected_view_document: "",
      showImageViewer: false,
      selected_enquiry: { enquiryInfo: {}, patientInfo: {}, providerInfo: {}, userInfo: {} },
      openModel: false,
      submitShow: false,
      loading: true,
    }
  }

  // this.props && this.props.location && this.props.location.state && this.props.location.state.selected_enquiry
  currentUser = authenticationService.currentUserValue;
  documentViewer(document_url) {
    this.setState({ selected_view_document: document_url, showImageViewer: true });
  }
  // handleClose() {
  //   this.setState({ showImageViewer: false });
  //   this.setState({ showEditModal: false });
  // }

  closeImg = () => {
    this.setState({ showEditModal: false })
  }
  onShowModel = (value) => {
    this.setState({ showEditModal: false });
  }
  onHideModal = () => {
    this.setState({ showEditModal: false });
  }
  showEditModal = () => {
    this.setState({
      showEditModal: true,
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };
  setEdit = () => {
    this.setState({ showEditModal: true })
  };


  handleImgClose = () => {
    this.setState({ showImageViewer: false })
  }

  handleShow = () => {
    this.setState({ openModel: true })
  }

  handleClose = () => {
    this.setState({ openModel: false })
  }

  submitShowHandle = (value) => {
    this.setState({ submitShow: value })
  }

  reloadPage = () => {
    window.location.reload()
    this.setState({ openModal: false })
    window.location.reload()
    this.setState({ openModal: true })

  }
  // edit_record = (record) => {
  //   setTimeout(() => {
  //     if (record) {
  //       this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
  //       const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
  //       this.props.history.push({
  //         pathname: "/my/enquiries/edits/" + id,

  //         state: {
  //           selected_enquiry: record,
  //           enquiry_obj: record && record.enquiryInfo,
  //           all_specialities: this.state.all_specialities,
  //           internalusers: this.state.internalusers,
  //           stages: this.state.stages,
  //           assignee: get_assignee(record, this.state.internalusers),
  //           patient_obj: get_patient_obj(record),
  //           patient_user_obj: get_user_obj(record),
  //           currentUser: this.state.currentUser,
  //           prevPath: this.props && this.props.location && this.props.location.pathname
  //         }
  //       });
  //     }
  //   }, 1);
  // }

  getNewEnqDetails = async () => {
    this.setState({ loading: true })
    const ownProps = { patient_id: this.props.location.state.patient_obj._id }
    const res = await enquiryListService.getEnquiries(ownProps)
    if (res.data.status === "success") {
      // const newEnqInfo = res.data.data[1]
      // this.setState({ selected_enquiry: newEnqInfo })
      // this.setState({ loading: false })

      const result = res.data.data.filter(data => data.enquiryInfo._id === this.props.location.state.selected_enquiry.enquiryInfo._id)

      this.setState({ selected_enquiry: result && result[0], enquiriesEdit: this.props.location.state.enquiriesEdit });
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getNewEnqDetails()
    this.props && getEnquiryListDetails(this.props.location.state.patient_obj._id)
  }


  render() {
    const currentUser = authenticationService.currentUserValue;
    return (
      <AfterLoginDashboardLayout>
        <Row>
          <Col className='mx-3'>
            <div>
              <h4 className='breadcrumb-heading'><Link to={{ pathname: currentUser.usertype === Role.patient.name ? AUTHROUTE.PATIENT.ENQUIRIES_PATH : AUTHROUTE.LOCALPROVIDER.PATIENTPATH }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link>My request </h4>
              <div className='breadcrumb-topic'>Our team is working on assigning a right doctor to you within 24 hours</div>
            </div>
          </Col>
        </Row>

        <Row className='main-layout md-no-reverse pt-3'>
          <Col lg={9} md={9} sm={12} pt-3 >
            {this.state.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

              <Spinner animation="border" variant="primary" /></div> : null}
            <div className='ml-3 mr-3'>
              <Card className='leftbottomsection'>
                <Row className='p-4'>
                  <Col lg={4} md={4} sm={12}>
                    <div className='breadcrumb-topics'>PATIENT</div>
                    <div className='data mt-2'>{this.state.selected_enquiry.patientInfo && this.state?.selected_enquiry?.patientInfo?.firstname}</div>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div className='breadcrumb-topics'> CODE</div>
                    <div className='data mt-2'> {this.state.selected_enquiry.patientInfo && this.state?.selected_enquiry.enquiryInfo.enquiryCode}</div>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div className='breadcrumb-topics'> SUBMITTED DATE</div>
                    <div className='data mt-2'>{this.state.selected_enquiry.patientInfo && `${get_formated_date_only(this.state.selected_enquiry.patientInfo && this.state?.selected_enquiry.enquiryInfo.createddate)},${get_formated_time_only(this.state?.selected_enquiry.enquiryInfo.createddate)}`}</div>
                  </Col>

                </Row>
                <Row><Col><hr /></Col></Row>
                <Row className='p-4' style={{ height: 'auto' }}>
                  <Col lg={4} md={4} sm={12} pt-3>
                    <div className='breadcrumb-topics'>CONCERN</div>
                    <div className='data mt-2'>{this.state.selected_enquiry.enquiryInfo && this.state?.selected_enquiry?.enquiryInfo?.enquiry_message}</div>
                  </Col>
                </Row>
                <Row><Col><hr /></Col></Row>
                <Row className='p-4'>
                  <Col lg={10} md={10} sm={12}>
                    <div className='breadcrumb-topics'>DETAILS </div>
                    <Col className="gutter-row">
                      <Row className='mobile_row'>

                        {this.state.selected_enquiry.patientInfo && this.state.selected_enquiry.enquiryInfo.documents && this.state.selected_enquiry.enquiryInfo?.documents.map((x, index) => {
                          return <Col lg={4} md={4} sm={6} xs={6} className="p-4" key={index}>
                            {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                            {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                            {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                            {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                              <div className='image'>
                                <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                              </div>
                            }
                          </Col>
                        })}
                      </Row></Col>
                  </Col>
                  <Col lg={2} md={2} sm={12} >

                    <div>
                      {this.state.openModel && <EditOpinion enquiryDetails={this.state.selected_enquiry} reloadPage={this.reloadPage} urlId={this.props.match.params.id} id={this.state?.selected_enquiry?.enquiryInfo?._id} enquiriesEdit={this.state.enquiriesEdit} openModal={this.state.openModel} setOpenModal={this.handleClose} submitShowHandle={this.submitShowHandle} />}
                    </div>
                    <h6><Button disabled={this.state.loading} onClick={this.handleShow} variant='link'>Edit</Button>
                    </h6>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} className='px-2 appointment-help'>
            <PatientHelpRightBar showText={true} />

          </Col>
        </Row>


        <div className='cancel'>
          {/* <a href=''> Cancel request</a> */}
        </div>



        {/* <Modal
          // size="lg"
          show={this.state.showEditModal}
          onHide={this.handleClose.bind(this)}
          // onHide={() => setShow(false)}
          dialogClassName=" large-modal-window"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Edit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EnquiryEditView id={this.state?.selected_enquiry?.enquiryInfo?._id} />
          </Modal.Body>
        </Modal> */}
        <Modal show={this.state.showImageViewer}
          centered
          backdrop="static" onHide={this.handleImgClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <img src={this.state.selected_view_document} alt=" " style={{ width: '100%' }}></img>
          </Modal.Body>
        </Modal>
      </AfterLoginDashboardLayout >
    )
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEnquiryListDetails: () => dispatch(getEnquiryListDetails(ownProps))
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    enquiryStore: state.enquiryList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnquiryDetailsView)
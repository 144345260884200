import React, { Component } from 'react';
import { Layout } from '../../component/shared';

import Section2 from './landingpage/Section2/Section2';
import Section3 from './landingpage/Section3/Section3';
import Section4 from './landingpage/Section4/Section4'
import Section5 from './landingpage/Section5/Section5';
import Section6 from './landingpage/Section6/Section6';
import Section7 from './landingpage/Section7/Section7';





class AboutPage extends Component {

    render() {
        return (
            <Layout className="registration" isContactHeader={true} isFooter={true}>
                 <div className='py-1 px-1 bg-white'><hr/></div>
                <div className='section  bg-white'>
            <Section2/> 
            <Section6 />  
            <Section3 /> 
            <Section4 />
            <Section5 />         
            <Section7 />
                </div>
            </Layout>
        );
    }
}
export default AboutPage;
import React from 'react';
import { connect } from 'react-redux'
import { getProviderListDetails } from '../../../actions/admin/ProviderListAction'
import { message } from 'antd';
import { Button, Form, Spinner } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import ProviderListService from '../../../services/admin/ProviderList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { getStartDate } from '../../provider/scheduler/SchedulerUtils';
import 'react-phone-number-input/style.css';
import { refreshPage } from '../../patient/enquiry/EnquiryUtils';
import { Formik } from 'formik';
import providerListService from '../../../services/admin/ProviderList.Service';
import { formikFormalidation } from '../../general/formvalidator/validationFroms';
import * as yup from 'yup'

export class CreateProvider extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      schedule: [],
      all_specialities: [],
      value: '',
      blocked_times: [],
      start_date: getStartDate(),
      userTypeInfo: { firstname: "", middlename: "", lastname: "", NPI: "", email: "", mobilenumber: "", username: "", speciality: "", timezone: "" }
    }
  }

  schedulerOnChange = newSchedule => {
    this.setState({ schedule: newSchedule })
  }
  getTimezoneList = async () => {
    const responses = await ProviderListService.getTimezoneList();
    if (responses.data.status === "success") {
      this.setState({ timezone: responses.data.data });
    }
  }
  getSpecialitesList = async () => {
    let all_specialities_data = await providerListService.getSpecialites();
    this.setState({ all_specialities: all_specialities_data.data.data })
  }


  onFinish = async (values) => {
    let values1 = {
      ...values, speciality: Array.isArray(values.speciality) ? values.speciality : [values.speciality],
    }
    const { mobilenumber = "" } = values;
    const countryCallingCode = parsePhoneNumber(mobilenumber)?.countryCallingCode
    const nationalNumber = parsePhoneNumber(mobilenumber)?.nationalNumber

    if (mobilenumber && countryCallingCode && !isValidPhoneNumber(mobilenumber)) {
      message.error("Invalid Phone number format")
    } else {
      const allFormData = {
        formData: values1,
        countryCallingCode: countryCallingCode && "+" + countryCallingCode,
        nationalNumber: nationalNumber
      }
      try {
        this.setState({ loading: true });
        let response = await ProviderListService.createNewProvider(allFormData);
        if (response.data.status === "success") {
          refreshPage()
          message.success(response.data.message);
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })
          message.error(response.data.message);
        }
      } catch (error) {
        errorMessage(error)
        this.setState({ loading: false })
      }

    }
  }

  componentDidMount() {
    this.getSpecialitesList();
    this.getTimezoneList();
  }

  render() {
    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      middlename: formikFormalidation.middlename,
      lastname: formikFormalidation.lastnamedr,
      NPI: formikFormalidation.npi,
      mobilenumber: formikFormalidation.mobilenumber,
      email: formikFormalidation.emaildr,
      username: formikFormalidation.username,
      speciality: formikFormalidation.speciality,

    });
    return (<React.Fragment>
      <div className="card-container">
        {this.state.loading &&
          <div className='d-flex justify-content-center flex-nowrap'>
            <div className='position-absolute mx-3 p-4' variant="primary" style={{ marginTop: '290px' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        }
        <Formik
          validationSchema={schema}
          initialValues={this.state.userTypeInfo}
          validate={values => {
            const errors = {};

            if (!values.mobilenumber) {
              errors.mobilenumber = "Mobile number cannot be empty.";
            } else {
              const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
              if (!countryCallingCode || !isValidPhoneNumber(values.mobilenumber)) {
                errors.mobilenumber = 'Invalid Mobile number format!';
              }
            }
            return errors;
          }}
          onSubmit={async (values) => this.onFinish(values)}

        >
          {(({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
            <Form onSubmit={handleSubmit}>

              <div className='row'>

                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    First Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    name="firstname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstname}
                    isInvalid={errors.firstname && touched.firstname}
                  />

                  {
                    errors.firstname && touched.firstname && (
                      <div className="invalid-feedback">{errors.firstname}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    Middle Name
                  </label>
                  <Form.Control type="text"
                    name="middlename"
                    value={values.middlename}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.middlename && touched.middlename} />
                  {
                    errors.middlename && touched.middlename && (
                      <div className="invalid-feedback">{errors.middlename}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold"> Last Name  <span style={{ color: "red" }}>*</span></label>
                  <Form.Control
                    onChange={handleChange}
                    name='lastname' 
                    value={values.lastname}
                    onBlur={handleBlur}
                    isInvalid={errors.lastname && touched.lastname} />

                  {
                    errors.lastname && touched.lastname && (
                      <div className="invalid-feedback">{errors.lastname}</div>
                    )
                  }

                </div>
              </div>


              <div className='row'>
                <div className='col form-group mr-2' >
                  <label className="mb-1 font-weight-bold"> NPI  <span style={{ color: "red" }}>*</span></label>
                  <Form.Control type="text"

                    name="NPI"
                    value={values.NPI}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.NPI && touched.NPI} />
                  {/* <span className={errors.NPI && touched.NPI ? 'invalid short-label' : 'short-label'}></span> */}

                  {
                    errors.NPI && touched.NPI && (
                      <div className="invalid-feedback">{errors.NPI}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>

                  <label className="mb-1 font-weight-bold"> Mobile Number  <span style={{ color: "red" }}>*</span></label>
                  <Form.Control as={PhoneInput}
                    name="mobilenumber"
                    placeholder="Mobile number *"
                    international
                    defaultCountry="IN"
                    countries={['US', 'IN']}
                    countryCallingCodeEditable={false}
                    limitMaxLength={true} required
                    value={values.mobilenumber}
                    onChange={e => { values.mobilenumber = e; }}
                    onBlur={handleBlur}
                    isInvalid={errors.mobilenumber && touched.mobilenumber}
                  />
                  <span className={errors.mobilenumber && touched.mobilenumber ? 'invalid short-label' : 'short-label'}></span>
                  {
                    errors.mobilenumber && touched.mobilenumber && (
                      <div className="invalid-feedback">{errors.mobilenumber}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    Email  <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    name="email" onChange={handleChange} 
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={errors.email && touched.email} />
                  {
                    errors.email && touched.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )
                  }


                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    User Name  <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    name="username" onChange={handleChange} 
                    onBlur={handleBlur}
                    value={values.username}
                    isInvalid={errors.username && touched.username} />
                  {
                    errors.username && touched.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )
                  }


                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    Speciality <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control as={"select"}
                    name="speciality"
                    className='selectors'
                    isInvalid={errors.speciality && touched.speciality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.speciality}
                  >
                    <option value="">Select</option>

                    {
                      this.state.all_specialities?.map((item) => {
                        const { name, _id } = item;
                        return <option value={_id} key={_id}>{name}</option>

                      })
                    }

                  </Form.Control>
                  {!this.state.speciality &&
                    <span className={errors.speciality && touched.speciality ? 'invalid short-label' : 'short-label'}></span>
                  }
                  {
                    errors.speciality && touched.speciality && (
                      <div className="invalid-feedback">{errors.speciality}</div>
                    )
                  }

                </div> </div>



              <Button
                variant="primary"
                type="submit"
                style={{ width: "100%", marginTop: "10px", marginLeft: "-5px", fontFamily: "manrope" }}

              >
                Submit
              </Button>
            </Form>
          ))}

        </Formik>
      </div>

    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProviderListDetails: () => dispatch(getProviderListDetails())
  }
}
const mapStateToProps = state => {
  return {
    providersStore: state.providerUserList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProvider)
import React from 'react';
import "../../provider/profile/providerProfile.style.css"
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import AppointmentTableView from './AppointmentTableView';
import { authenticationService } from '../../../component/general/login/login.service';

export class AppointmentPatientView extends React.Component {
  render() {
    const currentUser = authenticationService.currentUserValue;
    return (
      <React.Fragment>
        <AfterLoginLayout>
          <div className="container-fluid">
            <div className="banner-header">
              <h4>My Appointments</h4>
            </div>

            <AppointmentTableView
              patient_id={currentUser && currentUser.usertype_id}
              {...this.props} />

          </div>
        </AfterLoginLayout>
      </React.Fragment>)
  }
}

export default AppointmentPatientView
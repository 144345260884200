import {enquiryAction} from '../../actions/patient/types'
  
  const initialState = {
    loading: false,
    enquiries: [],
    stages: [],
    internalusers: [],
    specialities: []
  }
  
  const EnquiryListReducer = (state = initialState, action) => {
    switch (action.type) {
      case enquiryAction.DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case enquiryAction.DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          enquiries: action.payload.enquiries,
          stages: action.payload.stages,
          internalusers: action.payload.internalusers,
          specialities: action.payload.specialities,
        }
      case enquiryAction.DATA_FAILURE:
        return {
          loading: false,
          enquiries: [],
          stages: [],
          internalusers: [],
          specialities: []
        }
      default: return state
    }
  }
  
  export default EnquiryListReducer
import React, { Component } from 'react'
import { Spinner, Row, Col } from 'react-bootstrap';
import { message } from 'antd';
import { Layout } from '../../shared';
import { AUTHROUTE } from "../../../constants/RouterPath"
import { errorMessage } from '../../../utils/ServiceHandler';
import '../../patient/enquiry/enquiry.style.css'
import { FaCheckCircle } from "react-icons/fa";
import { authenticationService } from '../../general/login/login.service';
import moment from 'moment';

export class AppointmentProviderRescheduleSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
            consultationInfo: ""
        }
    }

    getAppointmentDetails = async () => {
        try {
            this.setState({ loading: true })
            const state_vals = this.props && this.props.location && this.props.location.state
            if (state_vals) {
                const patientUserInfo = state_vals && state_vals.patientUserInfo
                const selected_appointment = state_vals && state_vals.selected_appointment
                const consultationInfo = state_vals && state_vals.consultationInfo
                const fileList = this.props?.location?.state?.consultationInfo?.documents
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({
                        patientUserInfo: patientUserInfo,
                        selected_appointment: selected_appointment,
                        consultationInfo: consultationInfo,
                        fileList: fileList || [],
                        selected_date: this.props && this.props.location && this.props.location.state && this.props.location.state.selected_date,
                        selected_slot: this.props && this.props.location && this.props.location.state && this.props.location.state.selected_slot,
                    })
                }, 500)
            } else {
                this.setState({ loading: false })
                message.error("Date not found")
                //this.navigatetoLogin(this.props)
                this.props.history.push({
                    pathname: AUTHROUTE.PATIENT.DASHBOARDPATH
                });

            }

        } catch (error) {
            this.setState({ loading: false })
            errorMessage(error)
        }
    }

    componentDidMount() {

        this.getAppointmentDetails();
    }

    render() {
        const currentUser = authenticationService.currentUserValue;
        const datee = this.props && this.props.location.state.consultationInfo && moment(this.props.location.state.consultationInfo.scheduleDetailsInfo.date + " " + this.props.location.state.consultationInfo.scheduleDetailsInfo.slot).format()
        const newDate = new Date(datee)
        let PreDate = this.props.location.state.selected_date + " " + this.props.location.state.selected_slot.slice(0, -3);
        let dates = moment(PreDate).tz(this.props.location.state.selected_slot.substring(this.props.location.state.selected_slot.Length - 3));
        let PreviousDate = moment(dates).format("llll");
        return (

            <Layout className="confirmationAppointment" isHeader={false} isFooter={false}>
                {this.state.loading &&
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-3 p-4' variant="primary">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                }
                <Row className='appointment'>
                    <Col lg={8} md={8} sm={12} className='leftappointment p-2'>
                        <div className='m-5 pt-5'>
                            <Row>
                                <Col>
                                    <div><FaCheckCircle size='5em' style={{ 'color': '#143C8C' }} />
                                        <p>SUCCESS!</p>
                                        <p className='headtext'>Your rescheduling request has been confirmed!</p>
                                        <p className='destext my-3'>Dr.{this.props?.location?.state?.providerUserInfo?.firstname} Your appointment has been rescheduled.</p></div>
                                </Col>
                            </Row>

                            <Col lg={12} md={12} sm={12} className='rightcontainer'>
                                <Row className='py-1 ' style={{ borderBottom: "1px solid #ccc" }}>
                                    {this.state.consultationInfo &&
                                        <>
                                            <Col lg={3} md={3} sm={12}>
                                                <div className='breadcrumb-topics'>TICKET NO</div><div className='resdata mt-2'>{this.state.consultationInfo.orderCode}</div>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <div className='breadcrumb-topics'>BOOKING NUMBER</div><div className='resdata mt-2'>{this.state.consultationInfo.orderCode}</div>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <div className='breadcrumb-topics'>PROVIDED DATE & TIME</div><div className='resdata mt-2'>{this.state.selected_date && this.state.selected_slot && moment(newDate).format('llll')}</div>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <div className='breadcrumb-topics'>NEW DATE & TIME</div><div className='resdata mt-2'>{this.props && PreviousDate}</div>
                                            </Col>
                                        </>}
                                </Row>

                                <Row className='py-2'>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='breadcrumb-topics'>PATIENT</div>

                                        <div className='resdata mt-2'>{this.props?.location?.state?.patientUserInfo?.firstname}</div>
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='breadcrumb-topics'>CONCERN</div>
                                        <div className='resdata mt-2'>{this.props?.location?.state?.enquiryInfo?.enquiry_message}</div>
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='breadcrumb-topics'>DETAILS</div>
                                        {this.props?.location?.state?.consultationInfo?.documents.map((data) => {
                                            return <div><img src="/file.svg" alt="SVG"></img>{data.filename}</div>
                                        })
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Row><Col className='p-0 mt-4'><a className='btn btn-primary m-0' href={currentUser.usertype === 'provider' ? AUTHROUTE.PROVIDER.DASHBOARDPATH : AUTHROUTE.PATIENT.DASHBOARDPATH}>Go to Dashboard</a></Col></Row>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} className='rightappointment p-2'>
                        <div className='my-5'>Need help ?<br />
                            <p>Contact us at <a href="mailto:support@gmail.com">support@gmail.com</a> or call us at 9995678777</p></div>
                    </Col>
                </Row>
            </Layout>
        )
    }

}


export default AppointmentProviderRescheduleSuccess;
import { GET, POST } from "../../api";
import { APIENDPOINT } from "../../constants/APIEndpoint";

function getPatientFeedbackQues() {
    return GET(APIENDPOINT.PATIENT.FEEDBACK_QUESTIONS)
}

function postPatientFeedBack(data) {
    return POST(APIENDPOINT.PATIENT.FEEDBACK_POST_PATIENT,data)
}

function postProviderFeedback(formData){
    return POST(APIENDPOINT.PROVIDER.FEEDBACK_POST_PROVIDER,formData)
}

const patientFeedbackService = {
    getPatientFeedbackQues: getPatientFeedbackQues,
    postPatientFeedBack: postPatientFeedBack,
    postProviderFeedback:postProviderFeedback,
}

export default patientFeedbackService
import React, { Component } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import videoimagedr from "../../../../assets/images/videoimagedr.png";
import './Section7.css'

export class Section7 extends Component {
    render() {
        return (
            <div className='py-3 section7'>
                <Container>
                    <h6 className='py-2'>REVIEWS</h6>
                    <h3 className='pb-4'>Our doctors feedback <br /> about us</h3>
                    <Card className='border-0 card' >
                        <Row>
                            <Col md={4} lg={5}  >
                                <Card.Img variant="top" src={videoimagedr} alt='video' />
                            </Col>
                            <Col className='feedbackbg'>
                                <Card.Body className='this-card-body lquotbg'>
                                    <div className='cardText drarrow'>
                                        <h5>  Creating exceptional customer <br /> experiences is central to ‘Second <br /> opinion’. Easy booking and rescheduling. <br />Thank you for making it simple and <br /> hassle-free.</h5>
                                        <p>Sarah John</p>
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Section7
import React, { Component } from 'react';
import { Layout } from '../../component/shared';
import Section1 from './landingpage/Section1/Section1';
import Section2 from './landingpage/Section2/Section2';
import Section3 from './landingpage/Section3/Section3';
import Section5 from './landingpage/Section5/Section5';
import Section6 from './landingpage/Section6/Section6';
import Section7 from './landingpage/Section7/Section7';





class DoctorPage extends Component {

    render() {
        return (
            <Layout className="providerpage bg-white-header" isHeader={true} isFooter={true}>
                <div className='section  bg-white'>
                    <Section1 />
                    <Section2 />
                    <Section3 />
                    <Section5 />
                    <Section6 />
                    <Section7 />
                </div>
            </Layout>
        );
    }
}
export default DoctorPage;
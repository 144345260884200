import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../component/general/login/login.service';
import {AUTHROUTE} from "../constants/RouterPath";


const PublicRoute = ({ component: Component, restricted, ...rest }) => (
    <Route {...rest} render={props => (isLogin() && restricted ? <Redirect to={{pathname: AUTHROUTE.GENERAL.HOMEPATH, state: { from: props.location }}} /> : <Component {...props} />)} />
)


export default PublicRoute;
import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export class UpdateAvailability extends Component {
    render() {
        return (
            <div style={{marginTop:'10px', width: '20rem'}}>
                <Card style={{backgroundColor:'#C3DBD9', width:''}}>
                    <Card.Body>
                        <h6>Update your availability</h6>
                        <p>
                          Please set your convenient slots for the upcoming week in your calender
                        </p>
                        <Link className=' btn btn-outline-info align-items-center font-weight-bold' to='/my-schedule'>Choose my slots</Link>
                    </Card.Body>
                    
                </Card>
            </div>
        )
    }
}

export default UpdateAvailability
import { message } from 'antd';
import axios from 'axios';
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { APIENDPOINT } from "../../../constants/APIEndpoint";

// OTP-VALIDATION
export const validateOTP = (props, details) => {
  var data = {}
  if (details.emailotpsent && details.phoneotpsent) {
    data = {
      "userid": details && details.user_id,
      "emailotp": details && details.emailotp,
      "phoneotp": details && details.phoneotp,
    };
  } else if (details.emailotpsent && !details.phoneotpsent) {
    data = {
      "userid": details && details.user_id,
      "emailotp": details && details.emailotp,
    };
  } else if (!details.emailotpsent && details.phoneotpsent) {
    data = {
      "userid": details && details.user_id,
      "phoneotp": details && details.phoneotp,
    };
  }


  // else{
  //   data = {
  //     "userid": details && details.user_id,
  //     "emailotp": details && details.phoneotp,
  //   };
  // }


  var config = {
    method: 'POST',
    url: process.env.REACT_APP_API_URL + APIENDPOINT.REGISTRATION_OTP,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    data: data
  };

  axios(config)
    .then(function (response) {
      if (response.data.status === 400) {
        message.error('Error : ' + response.data)
        return
      } if (response.data.status === "success") {
        //redirect to Login page
        props.history.push({
          pathname: NONAUTHROUTE.GENERAL.LOGINPATH,
        });
        message.destroy("")
        message.success(response.data && response.data.message);
      } else {
        message.destroy("")
        message.error('Failed :' + response.data.message)
      }
    })
    .catch(function (err) {
      if (err.response) {
        console.log("client received an error response (5xx, 4xx)", err);
      } else if (err.request) {
        console.log("client never received a response, or request never left", err);
      } else {
        // anything unknown
        console.log("Error : ", err);
      }
    });
}

export const resendOtp = async (usrId) => {
  const data ={
    "user_id":usrId
  }
  var config = {
    method: 'POST',
    url: process.env.REACT_APP_API_URL + APIENDPOINT.RESEND_OTP,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    data,
  };

  let stausInfo = ""

  await axios(config).then(res => {
    if(res && res.data.status === "success" &&( res.data.emailotpsent || res.data.phoneotpsent)){
      message.success("OTP sent")
      stausInfo= "success"
    } else stausInfo= "failure"
  }).catch(err => console.log("err",err))

return stausInfo
}
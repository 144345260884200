/* Authentication actions */
import { message } from 'antd';
import axios from 'axios';
import btoa from 'btoa';
import {NONAUTHROUTE} from "../../../constants/RouterPath";
import {APIENDPOINT} from "../../../constants/APIEndpoint";


// Trigger Forgot Password Service call
export const forgotpassword = async(props,formDetails) => {
    var data = {
         "username":btoa(formDetails.username)
      };
      var config = {
        method: 'POST',
        url: process.env.REACT_APP_API_URL+APIENDPOINT.FORGOT_PASSWORD,
        headers: { 
          'Content-Type': 'application/json;charset=utf-8'
        },
        data : data
      };

      await axios(config)
        .then(function (response) {
          if (response.data.status === 400){
            message.error('Password Update Failed')
            return
          }if (response.data.status === "success"){
            props.history.push({
              pathname: NONAUTHROUTE.GENERAL.RESETPASSWORDPATH,
              state: {
                 detail: formDetails,
                 response:response.data}
            });
            message.success(response.data.message);
         }else {
           message.error('Failed :'+response.data.message)
          }
        })
        .catch(function (err) {
          if (err.response) {
            console.log("client received an error response (5xx, 4xx)",err);
          } else if (err.request) {
            console.log("client never received a response, or request never left",err);
          } else {
            // anything else
            console.log("Error : ",err);
          }
          message.error(err.message)
        });        
}
import * as yup from 'yup'


export const formikFormalidation = {
  username: yup.string().required('Username  cannot be empty.').min(3).max(30).matches(/^[a-zA-Z0-9]+$/, "Accepts alphanumeric characters only"),
  password: yup.string().required('Password cannot be empty.').matches(/^(?=.*?[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/,
    "Password must contains 8 to 25 characters, uppercase letter, number, and special character"
  ).min(8).max(25, "Password cannot be longer than 25 characters"),
  firstname: yup.string()
    .matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .min(2, "Names must have at least 2 characters!")
    .max(100, "Names can't be longer than 100 characters!")
    .required("First name cannot be empty."),
  middlename: yup.string()
  .matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .min(1, "Names must have at least 1 characters!")
    .max(100, "Names can't be longer than 100 characters!"),
  lastname: yup.string()
  .matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .min(1, "Names must have at least 1 characters!")
    .max(100, "Names can't be longer than 100 characters!"),
  lastnamedr: yup.string()
  .matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .min(1, "Names must have at least 1 characters!")
    .max(100, "Names can't be longer than 100 characters!")
    .required("Last name cannot be empty."),
  npi: yup.string()
    .matches('^[0-9]+$', "Accepts numbers only")
    .min(10, "Minimum 10 digits")
    .max(10, "Maximum 10 digits")
    .required("NPI cannot be empty."),
  licenseid: yup.string()
    .matches('^[a-zA-Z0-9]+$', "Accepts alphanumeric characters only")
    .max(20, "Maximum 20 digits")
    .required("Licenseid cannot be empty."),
  email: yup.string()
    .email("Must be a valid email address!")
    .max(100, "Email must be less than 100 characters!"),
  emaildr: yup.string().required("Email cannot be empty!")
    .email("Must be a valid email address!")
    .max(100, "Email must be less than 100 characters!"),
  mobilenumber: yup.string()
    .required("Mobile number cannot be empty."),
  confirmpassword: yup.string().required('Confirm password cannot be empty.').oneOf([yup.ref('password'), null], "Passwords must match"),
  verificationcode: yup.string().required('Please input verificationcode!').max(6).matches(/^[a-z0-9]+$/),
  agreement: yup.bool().oneOf([true], 'Should accept agreement'),
  healthconcern: yup.string().required("Enter your concern!"),
  prescription: yup.mixed().required("Prescription is required!"),
  pin1: yup
    .string()
    .required("pin1")
    .matches(/[a-z0-9]+$/, "Enter only alphabets or numerics"),
  pin2: yup
    .string()
    .required("pin2")
    .min(1)
    .matches(/[a-z0-9]+$/, "Enter only alphabets or numerics"),
  pin3: yup
    .string()
    .required("pin3")
    .min(1)
    .matches(/[a-z0-9]+$/, "Enter only alphabets or numerics"),
  pin4: yup
    .string()
    .required("pin4")
    .min(1)
    .matches(/[a-z0-9]+$/, "Enter only alphabets or numerics"),
  pin5: yup
    .string()
    .required("pin5")
    .min(1)
    .matches(/[a-z0-9]+$/, "Enter only alphabets or numerics"),
  pin6: yup
    .string()
    .required("pin6")
    .min(1)
    .matches(/[a-z0-9]+$/, "Enter only alphabets or numerics"),
  selectedDate: yup.string().required("Please select date!"),
  speciality: yup.string().required("Speciality cannot be empty!"),
  timezone: yup.string().required("Timezone cannot be empty!"),
  taxonomy: yup.string().required("Taxonomy cannot be empty!"),
  message: yup.string()
    .required("Message cannot be empty!"),
  query: yup.string().required("Please enter your query!"),
  fullname: yup.string().matches('^([a-zA-Z]{0,})[a-zA-Z0-9-]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .max(100, "Names can't be longer than 100 characters!")
    .required("Full name cannot be empty!"),
  provider_id: yup.string().required("please enter provider_id"),
  opinionFirstname: yup.string()
  .matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .min(2, "2 characters must!")
    .max(100, "Names can't be longer than 100 characters!")
    .required("First name cannot be empty."),
  opinionLastname: yup.string()
  .matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only ")
    .min(1, "Names must have at least 1 characters!")
    .max(100, "Names can't be longer than 100 characters!")
    .required("Last name cannot be empty."),
  location: yup.string().matches('^[a-zA-Z][a-zA-Z0-9- ]+$', "Accepts alphanumeric characters, and hyphen(-) only "),
  weight: yup.string().notRequired().nullable().matches('^([1-9]|[0-9][0-9]|[01][0-9][0-9]|20[0-0])$', 'must be a valid number!'),
  height: yup.string().notRequired().nullable().matches('^([1-9]|[0-9][0-9]|[01][0-9][0-9]|20[0-0])$', 'must be a valid number!'),
  dob: yup.string().required('Please enter or select the date'),
  phone: yup.string().required("Must enter a phone number!"),
  emergencycontact: yup.string(),
  experience: yup.number().required("Experience number cannot be empty!")
    .max(100, 'experience must be less than or equal to 100.')
    .test(
      'Is positive?',
      'experience must be a positive number greater than 0',
      (value) => value > 0
    ),

  assignee_id: yup.string().nullable().required("Please select assignee!"),
  stage_id: yup.string().nullable().required("Please select stage!")


}


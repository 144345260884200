import React from 'react';
import '../appointment/Appointment.css';
export class PatientHelpRightBar extends React.Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };

    render() {
        return (
            <React.Fragment>
                <div className='appointmenttop pt-4' style={{ 'marginLeft': '20px', 'marginTop': '20px' }}>
                    {this?.props?.showText && <div className='pt-4'><p className='instruction-a ml-2 mt-3'>Need help ?</p>
                        <p className='ml-2 mt-3'>Contact us at <a href="mailto:support@gmail.com">support@gmail.com</a> or call us at 9995678777</p>
                    </div>}
                </div>
                {/* </Col> */}
            </React.Fragment >)
    }
}

export default PatientHelpRightBar
/* General Utils  */
import store from '../store';
import { decryptData } from '../utils/SecureStorage';

export const getStages = () => {
  if (store.getState().storage && store.getState().storage.stages) {
    return decryptData(store.getState().storage.stages)
  } else {
    return null
  }
}

export const getSpecialities = () => {
  if (store.getState().storage && store.getState().storage.specialities) {
    return decryptData(store.getState().storage.specialities)
  } else {
    return null
  }
}
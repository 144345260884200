import React from 'react';
import "../../provider/profile/providerProfile.style.css"
import { message } from 'antd';
import { Row, Col, Button, Badge, Spinner, Dropdown } from 'react-bootstrap';
import { authenticationService } from '../../general/login/login.service';
import AppointmentService from '../../../services/provider/Appointment.Service';
import { parseAppointments } from '../../patient/appointment/Utils';
import { get_formated_date_only, formateName, get_formated_time_only_provider } from '../../patient/enquiry/EnquiryUtils';
import { FaVideo } from 'react-icons/fa';
import { AUTHROUTE } from '../../../constants/RouterPath';
import { get_provider_user_obj } from '../../patient/appointment/Utils';
import { get_provider_obj, get_patient_obj, get_patient_user_obj, get_speciality_obj } from '../../patient/enquiry/EnquiryUtils';
import { get_room_details_obj } from '../../patient/appointment/Utils';
import RightSideBar from '../sidebar/RightSideBar';
import ProviderCard from './ProviderCard';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { get_schedule_details_obj } from '../../patient/appointment/Utils';
import NoAppointments from './NoAppointments';
import moment from 'moment';
import '../../patient/dashboard/dashboard.css'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant='link'
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </Button>
));
export class ProviderView extends React.Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            consultations: [],
            past: [],
            upcoming: [],
            previewVisible: false,
            previewImage: "",
            fileList: [],
            currentUser: {},
            viewer_visible: false,
            reschedule_visible: false,
            selected_appointment: {}
        }
    }

    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };


    view_record = (record) => {
        setTimeout(() => {
            if (record) {
                this.setState({ selected_appointment: record, viewer_visible: true });
            }
        }, 1);
    }

    handleResponse = async (response) => {
        if (response.data.status === "success") {
          if (response && response.data && response.data.data) {
            if (response.data.data.length > 0) {
              const _consultation = parseAppointments(response.data.data)
              this.setState({
                upcoming: await _consultation && _consultation.upcoming.map(x => {
                  x.shown_status = 'upcoming';
                  return x;
                }),
                past: await _consultation && _consultation.past.map(x => {
                  x.shown_status = 'closed';
                  return x;
                }),
                loading: false
              });
              this.state.upcoming && this.state.upcoming.sort((a, b) => {
                const date1 = new Date(`${a?.consultationInfo?.scheduleDetailsInfo?.date}${" "}${a?.consultationInfo?.scheduleDetailsInfo?.slot}`).getTime()
                const date2 = new Date(`${b?.consultationInfo?.scheduleDetailsInfo?.date}${" "}${b?.consultationInfo?.scheduleDetailsInfo?.slot}`).getTime()
                return date1 - date2;
    
          })
              this.setState({
                showFilterList: this.state.upcoming
              })
             
            }
          }
        } else {
          this.setState({ loading: false })
          message.error(response.data.message);
        }
      }

    getAppointments = async () => {
        try {
            this.setState({ loading: true });
            const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })

            let response = await AppointmentService.getProviderAppointments(currentUser.usertype_id);
            this.handleResponse(response)
            // if (response.data.status === "success") {
            //   if (response && response.data && response.data.data) {
            //     if (response.data.data.length > 0) {
            //       const _consultation = parseAppointments(response.data.data)
            //       this.setState({
            //         upcoming: _consultation && _consultation.upcoming,
            //         past: _consultation && _consultation.past,
            //         loading: false
            //       })

            //     }
            //   }
            // } else {
            //   this.setState({ loading: false })
            //   message.error(response.data.message);
            // }
        } catch (error) {
            // errorMessage(error)
            this.setState({ loading: false })
        }
    }



    showModal = () => {
        this.setState({
            modalVisible: true,
            fileList: []
        });
    };

    componentDidMount() {
        this.getAppointments()
        this.getVals()
    }

    getVals() {
        const currentItem = this.state.selected_appointment
        const currentUser = authenticationService.currentUserValue;
        //const all_specialities = this.props && this.props.all_specialities
        const patient_obj = get_patient_obj(currentItem)
        const user_obj = get_patient_user_obj(currentItem)
        const provider_obj = get_provider_obj(currentItem)
        const provider_user_obj = get_provider_user_obj(currentItem)
        const speciality_obj = get_speciality_obj(this.state.selected_appointment.consultationInfo)

        this.setState({
            currentItem: currentItem,
            consultationInfo: this.state.selected_appointment.consultationInfo,
            user_obj: user_obj,
            patient_obj: patient_obj,
            provider_obj: provider_obj,
            provider_user_obj: provider_user_obj,
            currentUser: currentUser,
            speciality_obj: speciality_obj
            //all_specialities: all_specialities
        })
    }


    navigatetoVideoCall = () => {
        const room_details_obj = get_room_details_obj(this.state && this.state.selected_appointment.consultationInfo)
        if (room_details_obj) {
            this.props && this.props.history && this.props.history.push({
                pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
                state: {
                    currentItem: this.state.currentItem,
                    consultationInfo: this.state.selected_appointment.consultationInfo,
                    user_obj: this.state.user_obj,
                    patient_obj: this.state.patient_obj,
                    provider_obj: this.state.provider_obj,
                    provider_user_obj: this.state.provider_user_obj,
                    currentUser: this.state.currentUser,
                }
            });
        } else {
            message.error("Issue while initiating the Video call. Room details not available")
        }
    };


    render() {
        const currentUser = authenticationService.currentUserValue;
        this.props.location.state = { upcoming: this.state.upcoming, past: this.state.past };
        const columns = [
            {
                text: 'PATIENT',
                dataField: 'patientUserInfo.firstname',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            return formateName(record.patientUserInfo ? record.patientUserInfo.firstname : '')
                        })
                            ()}
                    </>
                )
            },
            {
                text: 'TIME',
                dataField: 'scheduleDates',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)
                            const verifyDateFormat = moment(schedule_obj.slot)
                            let attDateAndTime = ""
                            if (verifyDateFormat.isValid()) {
                                attDateAndTime = schedule_obj.slot
                            } else {
                                attDateAndTime = moment(schedule_obj.date + ' ' + schedule_obj.slot, 'YYYY-MM-DD HH:mm A').format()
                            }
                            let time_format = get_formated_time_only_provider(attDateAndTime)
                            // let relative_hrs = get_relative_time(schedule_obj.slot)
                            return (<React.Fragment>
                                <span>{time_format}{record.consultationInfo.timename}</span>
                            </React.Fragment>
                            )
                        })()}
                    </>
                ),
            },
            {
                text: 'DATE',
                dataField: 'scheduleDate',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)
                            const verifyDateFormat = moment(schedule_obj.slot)
                            let attDateAndTime = ""
                            if (verifyDateFormat.isValid()) {
                                attDateAndTime = schedule_obj.slot
                            } else {
                                attDateAndTime = moment(schedule_obj.date + ' ' + schedule_obj.slot, 'YYYY-MM-DD HH:mm A').format()
                            }
                            let time_format = get_formated_date_only(attDateAndTime)
                            return (<React.Fragment>
                                <span>{time_format}</span>
                            </React.Fragment>
                            )
                        })()}
                    </>
                ),
            },
            // {
            //     text: 'DETAILS',
            //     dataField: 'enquiryInfo.enquiry_message',
            //     // key: ['patientInfo', '_id'],
            // },
            {
                text: 'STATUS',
                dataField: '',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            if (record.consultationInfo.scheduleDetailsInfo.status === 3)
                                return (<React.Fragment>
                                    <Badge className='px-2' style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Upcoming</Badge>
                                </React.Fragment>
                                )
                        })()}
                    </>
                )
            },

            {
                text: '',
                dataField: '',
                formatter: (text, record) => (
                    <>
                        {(() => {
                            if (record.shown_status === 'upcoming')
                                return (<React.Fragment>
                                    <div className='actionHolder' onMouseOver={() => this.view_record(record)}>
                                        <div className="joinBtn">
                                            < FaVideo className='text-info mx-2' />
                                            <Button variant='link' style={{ "padding": "0px" }} onClick={this.navigatetoVideoCall.bind(this, record)}> Join </Button>
                                        </div>
                                        <div className="joinHolder">
                                            <div className="moreBtn">
                                                <Dropdown>
                                                    <Dropdown.Toggle as={CustomToggle} />
                                                    <Dropdown.Menu title="">
                                                        <Dropdown.Item>
                                                            <Link to={{ pathname: AUTHROUTE.PROVIDER.APPOINTMENT_PROVIDER, state: { selected_appointment: record } }}>View  </Link>
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                )
                        })()}
                    </>
                ),
                // key: ['patientInfo', '_id'],
            },

        ]

        return (

            <React.Fragment>
                <div className='ml-3'>
                    {this.state.upcoming.length !== 0 ?
                        <Row className='main-layout'>

                            <Col lg={8} md={8} sm={12} pt-3>

                                <div className='ml-2'>
                                    <h3 className='mb-1'>Welcome {currentUser && currentUser.displayname}</h3>
                                    <p className='text-secondary font-weight-normal mb-4' style={{ fontSize: '18px' }}>Check the latest updates on your account!</p>
                                </div>
                                <ProviderCard upcoming={this.state.upcoming} past={this.state.past} />

                                <h4 className='mb-3 ml-2'>Your Appointments</h4>
                                <div>
                                    <div className=''>
                                        <BootstrapTable keyField='_id'
                                            data={(this.state.upcoming)}
                                            hover={true}
                                            columns={columns}
                                            bordered={false}
                                        />
                                    </div>


                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <u>
                                            <Link className='align-items-center font-weight-bold' to='/provider/appointments'>View more</Link>
                                        </u>
                                    </div>
                                </div>
                            </Col>

                            <Col>
                                <RightSideBar />
                            </Col>
                        </Row> :
                        <div>
                            {this.state.loading ?
                                <div className='d-flex justify-content-center flex-nowrap'>
                                    <div className='position-absolute mx-3 p-4' variant="primary">
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                </div>
                                :
                                <NoAppointments />}
                        </div>
                    }

                </div>
            </React.Fragment >)
    }
}

export default ProviderView
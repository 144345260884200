import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { getPatientListDetails } from '../../../actions/localprovider/PatientListAction';
import { connect } from 'react-redux'

import "../../provider/profile/providerProfile.style.css"
import { errorMessage } from '../../../utils/ServiceHandler';
import { CreatePatient } from './CreatePatient'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner, Button, Modal } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';

export class ListPatientComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patients: {},
      modalVisible: false,
      loading: false,
      showCreateModal: false
    }
  }


  handleCancel = () => {
    this.setState({ modalisVisible: false });
    this.setState({ showCreateModal: false });
  };
  handleModalOk = () => {
    this.setState({ modalisVisible: false });
  };

  showCreateModal = () => {
    this.setState({
      showCreateModal: true,
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };

  getLocalPatientsData = () => {
    this.setState({ loading: true })
    this.handleCancel();
    this.props.getPatientListDetails().then((response) => {
      this.setState({ patients: response && response.patients, loading: false })
    }).catch(err => {
      errorMessage(err)
      this.setState({ loading: false })
    })

  }

  componentDidMount() {
    this.getLocalPatientsData()
  }

  view_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_record: record });
        const id = record._id
        this.props.history.push({
          pathname: "/patient/view/" + id,
          state: {
            localPatientStore: this.props.localPatientStore,
            selected_record: record,
            prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }

  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 5)} className="load-more">Load more</Button>
      </div>
    );

    const options = {
      sizePerPage: 5,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };

    const columns = [
      {
        text: 'First Name',
        dataField: 'firstname',
        key: 'firstname',
      },
      {
        text: 'Last Name',
        dataField: 'lastname',
        key: 'lastname',
      },
      {
        text: 'Gender',
        dataField: 'gender',
        // key: 'gender',
        formatter: (_, current_record) => (
          <>
            {(() => {
              return current_record.gender.toLowerCase();
            })()}
          </>
        ),
      },
      {
        text: 'Location',
        dataField: 'location',
        key: 'location',
      },
      {
        text: 'Operation',
        dataField: 'operation',
        formatter: (_, current_record) => (
          <>
            {(() => {
              return (

                <Button variant='link' onClick={() => this.view_record(current_record)}>
                  View
                </Button>
              )
            })()}
          </>
        ),
      },

    ];


    return (<React.Fragment>
      <AfterLoginLayout>

        <div className="container-fluid">

          <div className="banner-header">
            <h2>Patients</h2>
          </div>
          {this.state.patients.length > 0 &&
            <div style={{ marginBottom: 16, float: 'right' }}>
              <Button type="primary" onClick={this.showCreateModal}> Create New Patient</Button>
            </div>}
          {this.state.loading &&
            <div className='d-flex justify-content-center flex-nowrap'>
              <div style={{ marginTop: "12rem", display: "flex", justifyContent: "center" }} className='position-absolute mx-3 p-4' variant="primary">
                <Spinner animation="border" variant="primary" className='spin' />
              </div>
            </div>
          }

          {this.state.patients.length > 0 &&
            <BootstrapTable keyField='_id' loading={this.props.localPatientStore.loading}
              data={this.state.patients} hover={false} bordered={false}

              pagination={paginationFactory(options)}
              rowKey={record => record._id}
              columns={columns} />
          }

          {this.state.patients.length === undefined && !this.state.loading &&
            <div>
              <div style={{ marginBottom: 16, float: 'right', marginTop: '-56px' }}>
                <Button type="primary" onClick={this.showCreateModal}> Create New Patient</Button>
              </div>
              <div className="card" style={{ marginTop: '56px' }} >
                <div className="card-body text-center">
                  <FaUserAlt size={60} className='text-secondary' />
                  <h6 style={{ paddingTop: '28px' }}>No patient details found</h6>

                </div>

              </div>
            </div>
          }
          <Modal
            keyboard={true}
            centered={true}
            // backdrop='static'
            show={this.state.showCreateModal}
            onHide={this.handleCancel}
          >

            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Create Patient
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className='p-5'>

              {<CreatePatient
                onSuccess={this.getLocalPatientsData}
                {...this.props}
              />}
            </Modal.Body>
            {/* <ViewPatientDetails
              patient_id={this.state && this.state.selected_record}
              {...this.props}
            /> */}

          </Modal>

        </div>


      </AfterLoginLayout>
    </React.Fragment>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getPatientListDetails: () => dispatch(getPatientListDetails())
  }
}
const mapStateToProps = state => {
  return {
    localPatientStore: state.localPatientsList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPatientComponent)
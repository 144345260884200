import { combineReducers } from 'redux';
import ProfileReducer from './provider/profileReducer';
import PatientProfile from "./patient/profileReducer";
import alertReducer from "./alertReducer";
import storage from './storageReducer';

import InitialDataReducer from './general/initialDataReducer';
import UserListReducer from './admin/userListReducer';
import ProviderListReducer from './admin/providerListReducer';
import InternalUserProviderListReducer from './internaluser/providerListReducer';
import EnquiryListReducer from './patient/enquiryListReducer';
import LocalPatientListReducer from './localprovider/localPatientListReducer'



const rootReducer = combineReducers({
  providerProfileData : ProfileReducer,
  patientProfileData : PatientProfile,
  internalUserList : UserListReducer,
  providerUserList : ProviderListReducer,
  internalUserProviderList : InternalUserProviderListReducer,
  enquiryList : EnquiryListReducer,
  alertMessage: alertReducer,
  localPatientsList : LocalPatientListReducer,
  initialData : InitialDataReducer,
  storage
})

export default rootReducer
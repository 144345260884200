import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaUserAlt, FaCalendarAlt,FaCalendarPlus, FaCalendarMinus } from 'react-icons/fa';
import '../../../assets/scss/sidebar.scss';
import { AUTHROUTE } from "../../../constants/RouterPath";
import logo from '../../../assets/images/logo.png';

const ProviderSideBar = ({ collapsed, rtl, toggled, handleToggleSidebar }) => {

  //const intl = useIntl();
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="sidebar-header">
          <img src={logo} className="img-fluid" alt="Second Opinion" />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FaTachometerAlt />} suffix={<span className="badge red">NEW</span>}>
            <Link to={AUTHROUTE.PROVIDER.DASHBOARDPATH}>Dashboard</Link>
          </MenuItem>
          <MenuItem icon={<FaUserAlt />}>
            <Link to={AUTHROUTE.PROVIDER.PROFILEPATH}>Profile
            </Link>
          </MenuItem>
          <MenuItem icon={<FaCalendarPlus />}>
            <Link to={AUTHROUTE.PROVIDER.SCHEDULERPATH}> Add slots
            </Link>
          </MenuItem>
          <MenuItem icon={<FaCalendarMinus />}>
            <Link to={AUTHROUTE.PROVIDER.CALENDER_VIEW}> My slots
            </Link>
          </MenuItem>
          <MenuItem icon={<FaCalendarAlt />}>
            <Link to={AUTHROUTE.PROVIDER.APPOINTMENT_PATH}>Appointments
            </Link>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>

        <div
          className="sidebar-btn-wrapper">
          <small>
            © 2021 www.secondopinion.com
          </small>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default ProviderSideBar;

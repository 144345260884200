/* Authentication actions */
import { message } from 'antd';
import axios from 'axios';
import btoa from 'btoa';
//import { BehaviorSubject } from 'rxjs';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { APIENDPOINT } from "../../../constants/APIEndpoint";
import store from '../../../store';
import { storageActions } from "../../../actions/storageAction";
import Role from '../../../constants/role'
import { encryptData, decryptData } from '../../../utils/SecureStorage';
import moment from "moment-timezone";
import { getInitialData } from '../../../actions/general/InitialDataAction'


//
//import { alertActions } from '../../../actions/alertAction';



export const authenticationService = {

  get currentUserValue() {
    if (store.getState().storage && store.getState().storage.currentUser) {
      return decryptData(store.getState().storage.currentUser)
    } else {
      return null
    }
  },
  get user_timezone() {
    // const pdtTimeZo = 'America/Los_Angeles'
    // const edtTimeZo = 'America/New_York'
    // const mdtTimeZone = 'America/Denver'
    // const hstTimeZone = "pacific/honolulu"
    // const selctedTimeZo = localStorage.getItem("timeZone")
    //var user_timezone = "Australia/Sydney"
    //var user_timezone = "America/New_York"
    let user_timezone = "";
    if (store.getState().storage && store.getState().storage.currentUser) {
      let user_Data = decryptData(store.getState().storage.currentUser)
      if (user_Data.usertype === Role.provider.name) {
        user_timezone = 'America/New_York'
      }
      else { user_timezone = "Asia/Kolkata" }
    } else { user_timezone = moment.tz.guess(true) || "Asia/Kolkata" }
    return user_timezone
  }
};



// Trigger LOGIN Service call
export const login = async (props, formDetails, optionalParams = '') => {
  var data = {
    "name": btoa(formDetails.username),
    "password": btoa(formDetails.password)
  };
  var config = {
    method: 'POST',
    url: process.env.REACT_APP_API_URL + APIENDPOINT.SIGNIN,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    data: data
  };

  await axios(config)
    .then(function (response) {
      if (response.data.status === 400) {
        message.error('Login Failed')
        return
      } if (response.data.status === "success") {
        //  Role based redirection 

        store.dispatch(storageActions.add("auth", response.data.authToken));
        store.dispatch(storageActions.add("currentUser", encryptData(response.data.user)))

        store.dispatch(getInitialData())

        if (response.data.user && response.data.user.usertype) {
          if (response.data.user.usertype === Role.provider.name) {
            if (response.data.user && response.data.user.timezone && response.data.user.timezone[0] && response.data.user.timezone[0].name)
              localStorage.setItem('timeZone', response.data.user.timezone[0].name)
            props.history.push({
              pathname: AUTHROUTE.PROVIDER.DASHBOARDPATH,
              state: {
                response: response.data
              }
            });

          } if (response.data.user.usertype === Role.patient.name) {
            if (optionalParams !== '' && optionalParams.token !== undefined && optionalParams.redirect !== undefined) {
              if (optionalParams.redirect === 'patient') {
                props.history.push({
                  pathname: AUTHROUTE.PATIENT.DASHBOARDPATH,
                  state: {
                    response: response.data,
                    pathname: '/patient/pickslot/' + optionalParams.token,
                    token: optionalParams.token
                  }
                });
              }
            } else {
              props.history.push({
                pathname: AUTHROUTE.PATIENT.DASHBOARDPATH,
                state: {
                  response: response.data
                }
              });
            }
          }
          if (response.data.user.usertype === Role.localprovider.name) {
            if(optionalParams !== '' && optionalParams.token !== undefined && optionalParams.redirect !== undefined ){
              if(optionalParams.redirect === "patient"){
                props.history.push( '/patient/pickslot/' +  optionalParams.token)
              }
            } else {
              props.history.push({
                pathname: AUTHROUTE.LOCALPROVIDER.DASHBOARDPATH,
                state: {
                  response: response.data
                }
              });
            }
            
          }
          if (response.data.user.usertype === Role.internaluser.name || response.data.user.usertype === Role.admin.name) {
            props.history.push({
              pathname: AUTHROUTE.INTERNALUSER.DASHBOARDPATH,
              state: {
                response: response.data
              }
            });
          }
        }
        message.success('Login Success. Welcome ' + response.data.user.displayname)
        //store.dispatch(alertActions.success('Login Success. Welcome ' + response.data.user.displayname))
      } else {
        message.error('Login Failed :' + response.data.message)
      }
    })
    .catch(function (err) {
      if (err.response) {
        console.log("client received an error response (5xx, 4xx)", err);
      } else if (err.request) {
        console.log("client never received a response, or request never left", err);
      } else {
        // anything else
        console.log("Error : ", err);
      }
      message.error(err.message)
    });
}
export const clearLocalStore = () => {
  store.dispatch(storageActions.deleteKey("auth"));
  store.dispatch(storageActions.deleteKey("currentUser"));
  localStorage.removeItem('appState')
}
// LOGOUT
export const logout = () => {
  clearLocalStore()
  //message.success('You have been logged out..')  
}

// LOGIN STATUS
export const isLogin = () => {
  if (store.getState().storage && store.getState().storage.auth) {
    return true;
  } else {
    clearLocalStore()
    return false;
  }
}
import React, { Component } from 'react'
import { Card, Container } from 'react-bootstrap'
import ProfileService from '../../../../services/provider/Profile.Service'
import './Section8.css'

export class Section8 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            all_specialities: [],
            speciality: '',
            showMore: false,
            visible: true
        }
    }

    getSpecialitesList = async () => {
        let all_specialities_data = await ProfileService.getSpecialites();
        this.setState({ all_specialities: all_specialities_data.data.data })
    }

    componentDidMount() {
        this.getSpecialitesList();
    }


    render() {
        return (
            <div className='py-5 section8'>
                <Container>
                    <div className="heading-holder">
                        <h5 className='text-info small font-weight-bold'>HEALTH CONCERN</h5>
                        <h3 className='mt-3'>Consult top doctors online <br /> for any health concern</h3>
                    </div>

                    <div className='h-scrollholder'>
                        <div className='scroll-row mr-2'>
                            {
                                this.state.all_specialities?.map((item) => {
                                    const { name, _id } = item;
                                    return (
                                        <div className='mt-4'>
                                            <Card className='mx-3 my-3 text-center' key={_id} style={{ width: '15rem', height: '13rem' }}>
                                                <Card.Body>
                                                    <Card.Title><img className='align-items-center' style={{ width: '70px', height: '70px' }} src={item.documents[0].url} alt='medicine' /></Card.Title>
                                                    <Card.Footer style={{ border: "0", marginTop: '31px', marginLeft: '20px' }}>{name}</Card.Footer>
                                                </Card.Body>
                                            </Card>
                                        </div>)
                                }).slice(0, 8)
                            }
                        </div>
                    </div>


                    <div className="btn-holder mt-4">
                        {this.state.visible && <button type="button" className="btn btn-primary px-5" onClick={() => this.setState({ showMore: true, visible: false })}>View All</button>}
                    </div>

                    <div className='h-scrollholder'>
                        <div className='scroll-row mr-2'> {this.state.showMore &&
                            this.state.all_specialities?.map((item) => {
                                const { name, _id } = item;
                                return (
                                    <div>
                                        <Card className='mx-3 my-3 text-center' key={_id} style={{ width: '15rem', height: '13rem' }}>
                                            <Card.Body>
                                                <Card.Title><img src={item.documents[0].url} alt='medicine' style={{ width: '70px', height: '70px' }} /></Card.Title>
                                                <Card.Footer style={{ border: "0", marginTop: '30px', marginLeft: '18px' }}>{name}</Card.Footer>
                                            </Card.Body>
                                        </Card>
                                    </div>)
                            }).slice(9, this.state.all_specialities.length - 1)
                        }</div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Section8
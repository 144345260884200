import { alertTypes } from '../actions/types';

function alert(state = {}, action) {
  switch (action.type) {
    case alertTypes.SUCCESS:
      return {
        type: alertTypes.SUCCESS,
        message: action.message
      };
    case alertTypes.ERROR:
      return {
        type: alertTypes.ERROR,
        message: action.message
      };
    case alertTypes.CLEAR:
      return {};
    default:
      return state
  }
}
export default alert
import { message } from 'antd';
import React, { Component } from 'react';
import './App.scss';
import Participant from './Participant';
import Endcall from '../../assets/images/Endcall.png'
import { Modal } from 'react-bootstrap';
import Feedback from './Feedback';
import './Feedback.css';
import { authenticationService } from '../general/login/login.service';
import Details from './Details';


class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values()),
      showModal: false,
      newUserVal: false
    }

    this.leaveRoom = this.leaveRoom.bind(this);
  }

  
  componentDidMount() {
    // Add event listeneres for future participants coming or going
    this.props.room.on('participantConnected', participant => this.addParticipant(participant));
    this.props.room.on('participantDisconnected', participant => this.removeParticipant(participant));

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  componentWillUnmount() {
    this.leaveRoom();
  }

  leaveRoom() {
    this.setState({ showModal: true, newUserVal: true })
    this.props.room.disconnect();
    // this.props.leaveRoom();
  }

  handleClose = (e) => {
    const currentUser = authenticationService.currentUserValue;
    this.setState({ showModal: false })
    this.props.room.disconnect();
    this.props.leaveRoom();
    if (currentUser.usertype === "patient") {
      this.props.history.push("/patient-dashboard")}
      else if 
      (currentUser.usertype === "localprovider"){
      this.props.history.push("/local-dashboard")
    } 
    else { this.props.history.push("/provider-dashboard")}
     
    
    //  window.location.reload()
  }

  patientFormClose = () => {

  }

  addParticipant(participant) {
    message.success("Participant " + participant.identity + " has joined the Room")

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant]
    })
  }

  removeParticipant(participant) {
    //console.log(`${participant.identity} has left the room`)
    message.error("Participant " + participant.identity + " has left the Room")

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(p => p.identity !== participant.identity)
    })
  }



  render() {
    let localclassname = this.state.remoteParticipants.length === 0 ? 'localparticipants fullwidth' : 'localparticipants videoshort';
    let remoteclassname = this.state.remoteParticipants.length >= 1 ? 'remoteparticipants fullwidth' : 'remoteparticipants videoshort';
    return (
      <div className="room">
        <div className="participants">
          <div className={localclassname}>
            <Participant key={this.props.room.localParticipant.identity} localParticipant="true" participant={this.props.room.localParticipant} />
          </div>
          {
            this.state.remoteParticipants.map(participant =>
              <Participant className={remoteclassname} key={participant.identity} participant={participant} />
            )
          }
        </div>
        {/* <Button id="leaveRoom" type="success-color" htmlType="submit" style={{ width: 'auto' }} onClick={this.leaveRoom}> */}

        <div style={{ backgroundColor: "white", padding: "12px" }}><img className='center-button mb-2' src={Endcall} alt="BigCo Inc. logo" onClick={this.leaveRoom} />
        </div>
        
        <div style={{ backgroundColor: "white" , marginTop:'-40px', marginLeft:'580px'}}>
        <Details {...this.props} handleClose={this.handleClose}  />
        </div>
        {/* </Button> */}

        <Modal show={this.state.showModal} onHide={this.handleClose}

          aria-labelledby="example-modal-sizes-title-sm"
          animation={false}
          backdrop="static"
          keyboard={true}
          centered={false}
          size="sm"
          className='modalInfo'
        >
          <Modal.Header style={{ border: "0", marginTop: '-10px' }} closeButton>
          </Modal.Header>
          <Modal.Body className=''><Feedback {...this.props} handleClose={this.handleClose} /></Modal.Body>
        </Modal>
      </div>
    );
  }

}

export default Room;
import React, { Component } from 'react'
import { Row, Col, Card, Container, Modal, Button } from 'react-bootstrap'
import GetOpinion from '../GetOpinion/GetOpinion';
import ProfileService from '../../../../services/provider/Profile.Service'
import './Section4.css'


export class Section4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false,
            visible: true,
            showModal: false,
            newUserVal: false,
            all_symptoms: []
        }
    }

    getSymptomsList = async () => {
        let all_specialities_data = await ProfileService.getSymptoms();
        this.setState({ all_symptoms: all_specialities_data.data.data })
    }

    componentDidMount() {
        this.getSymptomsList();
    }

    render() {
        const handleClose = (e) => {
            this.setState({ showModal: false })
        }

        return (
            <div className='bg-white p-5 section4'>

                <Container>
                    <h5>HEALTH CONCERN</h5>
                    <h1>Consult top doctors online for any health concern</h1>
                    <div className='h-scrollholder'>

                        <div style={{ 'gap': '1rem' }} className='scroll-row mr-2'>
                            {
                                this.state.all_symptoms?.map((item) => {
                                    const { name, _id } = item;
                                    return (
                                        <div className='scroll-col mt-4'>
                                            <div className='no-gutters mt-5 cards '>
                                                <div>
                                                    <Card key={_id} className='cardAnimate e-h-card'>
                                                        <Card.Body>
                                                            <Card.Title><img src={item.documents[0].url} alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                                            <Card.Subtitle style={{ marginBottom: '80px', width: '18ch' }}> <h6>{name}</h6></Card.Subtitle>
                                                            <Button className='button-link' style={{ position: 'absolute', top: '88%', left: '6%' }} variant='link' onClick={() => this.setState({ showModal: true, newUserVal: true })}>Get an opinion</Button>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>)
                                }).slice(0, 4)
                            }
                        </div>
                    </div>
                    <div className="btn-holder mt-4">
                        {this.state.visible && <button type="button" className="btn btn-primary px-5" onClick={() => this.setState({ showMore: true, visible: false })}>View All</button>}
                    </div>
                    <div className='h-scrollholder'>
                        <div style={{ 'gap': '1rem' }} className='scroll-row mr-2'> {this.state.showMore &&
                            this.state.all_symptoms?.map((item) => {
                                const { name, _id } = item;
                                return (
                                    <div>

                                        <Row className='no-gutters mt-5 cards'>
                                            <Col>
                                                <Card style={{ width: '15rem', height: '20rem' }} key={_id} className='cardAnimate'>
                                                    <Card.Body>
                                                        <Card.Title><img src={item.documents[0].url} alt='get opinion' style={{ width: '12rem' }} /></Card.Title>
                                                        <Card.Subtitle style={{ marginBottom: '80px' }}> <h6>{name}</h6></Card.Subtitle>
                                                        <Button className='button-link' style={{ position: 'absolute', top: '88%', left: '6%' }} variant='link' onClick={() => this.setState({ showModal: true, newUserVal: true })}>Get an opinion</Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>)
                            }).slice(5, this.state.all_symptoms.length - 1)
                        }</div>
                    </div>

                </Container>

                <Modal show={this.state.showModal} onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    centered={false}
                    size="xl"
                    className='modalData'
                >
                    <Modal.Header style={{ border: "0" }} closeButton>
                    </Modal.Header>
                    <Modal.Body><GetOpinion {...this.props} handleClose={handleClose} /></Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default Section4
import React, { useState, useCallback, useEffect } from 'react'
import { Form, Button, Spinner, Container, Card } from 'react-bootstrap';
// import { Formik } from 'formik';
// import * as yup from 'yup'
// import { formikFormalidation } from '../general/formvalidator/validationFroms'
import { FaStar, FaCloudUploadAlt } from "react-icons/fa";
import { authenticationService } from '../general/login/login.service';
import { FaUserAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { FaTrash } from "react-icons/fa";
import patientFeedbackService from '../../services/patient/PatientFeedback.Service';
import { message } from 'antd';
import { useHistory } from 'react-router-dom'


function Feedback(props) {

    //provider
    const currentUser = authenticationService.currentUserValue;
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState([]);
    const [provMsg, setProvMsg] = useState("")
    const [confRebook, setConfRebook] = useState("")
    const [expected, setExpected] = useState("")
    const providerQues = [
        "Add prescriptions / past records",
        "What is your opinion?",
        "Lorem ipsum?",
        "When do you expect to see this patient"
    ]

    //patient
    const stars = Array(5).fill(0)
    const [overAllExper, setOverAllExper] = useState(0)
    const [prblmDoubt, setPrblmDoubt] = useState("")
    const [patientRatings, setPatientRatings] = useState(0)

    const [commentMsg, setCommentMsg] = useState("")
    const [overAllExpHoverVal, setOverAllExpHoverVal] = useState(null)
    const [patientHoverVal, setPatientHoverVal] = useState(null)
    const [allQuestions, setAllQuestions] = useState({ patientQues: [], provQues: [] })

    const history = useHistory()

    const removeFile = (file) => {
        const newFiles = [...fileName];
        newFiles.splice(file, 1);
        setFileName(newFiles);
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            setFileName(fileName.concat(...acceptedFiles));
        },
        [fileName]
    );


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*,.pdf,.doc,.docx",
        maxFiles: "5",
    });

    const previewName = fileName?.map((file, i) => (
        <Card
            key={file.name}
            style={{
                backgroundColor: "#F8F8F9",
                height: "50px auto",
            }}
        >
            <div className=" d-flex justify-content-between py-2 px-4 align-items-baseline">
                <div>
                    <h6 style={{ fontFamily: "manrope", fontWeight: "500" }}>
                        {file.name}
                    </h6>
                    {file.size}&nbsp;B
                </div>
                <div className='mt-2'>
                    <FaTrash
                        style={{ cursor: "pointer" }}
                        onClick={() => removeFile(i)}
                    />
                </div>
            </div>
        </Card>
    ));

    useEffect(() => {
        const getPatientQuestions = async () => {
            setLoading(true)
            const res = await patientFeedbackService.getPatientFeedbackQues()
            if (res.data.status === "success" && res.data.data.length > 0) {
                setLoading(false)
                setAllQuestions({
                    ...allQuestions, patientQues: res.data.data.filter(que => que.QuestionFlowType === "PATIENT"),
                    provQues: res.data.data.filter(que => que.QuestionFlowType === "PROVIDER")
                })
            } else setLoading(true)
        }
        getPatientQuestions()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickPatientExp = (rating) => {
        setOverAllExper(rating)
    }
    const handleHoverPatientExp = (value) => {
        setOverAllExpHoverVal(value)
    }

    const handlePatientClickRatings = (rating) => {
        setPatientRatings(rating)
    }
    const handlePatientHoverRatings = (value) => {
        setPatientHoverVal(value)
    }

    const handlePatientFeedback = e => {
        e.preventDefault()
        const newFeedback = []
        const answeredQues = [overAllExper, prblmDoubt, patientRatings, commentMsg]
        if(overAllExper!==0||patientRatings!==0 || prblmDoubt.length !==0 ||commentMsg.length!==0){
            allQuestions.patientQues.forEach((item, index) => {
                let patientid = props.location.state.consultationInfo.patientId ? props.location.state.consultationInfo.patientId : currentUser.usertype_id;              
                let data = {
                    consultation_id: props.location.state.consultationInfo.id,
                    patient_id: patientid ,
                    meta_question_id: item._id,
                    answer: answeredQues[index].toString() === "0" ? "" : answeredQues[index].toString()

                }

                newFeedback.push(data)

            })
            const sendFeedback = async () => {

                try {
                    const response = await patientFeedbackService.postPatientFeedBack(newFeedback)
                    if (response.data.status === "success") {
                        props.handleClose()
                        message.success(response.data.message)
                        //history.push("/patient-dashboard")
                        if (currentUser.usertype === "patient") {
                            this.props.history.push("/patient-dashboard")}
                            else if 
                            (currentUser.usertype === "localprovider"){
                            this.props.history.push("/local-dashboard")
                          } 
                          else { this.props.history.push("/provider-dashboard")}
                    } 
                } catch (err) {
                    console.log(err)
                }
            }
            sendFeedback()
        }



    }

    const handleProviderOpinion = e => {
        e.preventDefault()
        let formData = new FormData()

        formData.append("consultation_id", props && props.location.state.consultationInfo.id)
        formData.append("provider_id", currentUser.usertype_id)
        formData.append("question", providerQues[0])
        if (fileName.length === 0) {
            formData.append("answer", [])
        } else {
            for (let i = 0; i < fileName.length; i++) {
                const file = fileName[i];
                formData.append("answer", file);
            }
        }

        formData.append("question", providerQues[1])
        formData.append("answer", provMsg ? provMsg : [])
        formData.append("question", providerQues[2])
        formData.append("answer", confRebook ? confRebook : [])
        formData.append("question", providerQues[3])
        formData.append("answer", expected ? expected : [])


        const sendData = async () => {
            const proFeedRes = await patientFeedbackService.postProviderFeedback(formData)
            if (proFeedRes.data.status === "success") {
                setLoading(false)
                props.handleClose()
                history.push("/provider-dashboard")
                message.success(proFeedRes.data.message)
            }
        }
        if (expected.length > 0 || confRebook.length > 0 || provMsg.length > 0 || fileName.length > 0) {
            sendData()
        }
    }

    const load_element = <div className='d-flex justify-content-center flex-nowrap'>
        <div className='position-absolute mx-3 p-4' variant="primary">
            <Spinner animation="border" variant="primary" />
        </div>
    </div>

    return (
        <div>
           {(currentUser.usertype === "patient" || currentUser.usertype === "localprovider") ?
                <div>
                    <Container>
                        {loading && load_element}
                        {allQuestions.patientQues.length !== 0 &&
                            <Form noValidate onSubmit={handlePatientFeedback}>
                                <h5 style={{marginTop:"-40px", fontSize:'20px'}}>Your opinion matter to us</h5>
                                <p style={{ fontSize:'13px' }}>We work super hard to serve you better and would love to <br /> know how you would rate your experience.</p>
                                <fieldset disabled={loading}>
                                    <Form.Group>
                                        <div><p className='font-weight-bold mt-3' style={{ fontSize: '13px' }}>1. {allQuestions.patientQues[0].Question} ?</p></div>
                                        <div className='d-flex'>
                                            {stars.map((_, index) => {
                                                return (
                                                    <FaStar key={index} size={24} className='ml-2'
                                                        style={{ cursor: "pointer", marginTop: "-5px", marginBottom: '20px' }}
                                                        onClick={() => handleClickPatientExp(index + 1)}
                                                        onMouseOver={() => handleHoverPatientExp(index + 1)}
                                                        onMouseLeave={() => handleHoverPatientExp(null)}
                                                        color={(overAllExper || overAllExpHoverVal) > index ? "#ffc107" : "e4e5e9"}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Form.Group>
                                    {/* {errors.experiencFeedback && <div className='mt-0 pt-0' style={{ color: "#dc3545", fontSize: "0.8rem" }}>{errors.experiencFeedback}</div>} */}

                                    <Form.Group className='mt-3 mb-0 pb-0'>
                                        <p className='font-weight-bold mb-1' style={{ fontSize: '13px',marginTop: "-5px" }}>2.{allQuestions.patientQues[1].Question}</p>
                                        <div className=' d-flex justifiy-content-between align-items-center mb-2 ml-2'>

                                            <div className='mr-4'>
                                                <input style={{ cursor: "pointer", fontSize: '13px' }} className='mr-2' onChange={e => setPrblmDoubt(e.target.value)} name='prblmDoubt' value="true" type="radio" />
                                                <label>Yes</label>
                                            </div>
                                            <div>
                                                <input style={{ cursor: "pointer", fontSize: '13px' }} className='mr-2' onChange={e => setPrblmDoubt(e.target.value)} name='prblmDoubt' value="false" type="radio" />
                                                <label>No</label>
                                            </div>
                                        </div>

                                    </Form.Group>

                                    <Form.Group className='mt-3'>
                                        <div><p className='font-weight-bold' style={{ fontSize: '13px' }}>3. {allQuestions.patientQues[2].Question}</p></div>
                                        <div className='d-flex'>
                                            {stars.map((_, index) => {
                                                return (
                                                    <FaStar key={index} size={24} className='ml-2'
                                                        style={{ cursor: "pointer", marginTop: "-5px", marginBottom: '20px' }}
                                                        onClick={() => handlePatientClickRatings(index + 1)}
                                                        onMouseOver={() => handlePatientHoverRatings(index + 1)}
                                                        onMouseLeave={() => setPatientHoverVal(null)}
                                                        color={(patientRatings || patientHoverVal) > index ? "#ffc107" : "e4e5e9"}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </Form.Group>

                                    <div className='mt-3'>
                                        <p className='font-weight-bold mb-1' style={{ fontSize: '13px' }}>4. {allQuestions.patientQues[3].Question} </p>
                                        <textarea

                                            rows={2}
                                            type="textarea"
                                            placeholder='Type something...'
                                            className='form-control shadow-none text-area-style mt-2 ml-1 font-weight-bold'
                                            name="message"
                                            required value={commentMsg}
                                            onChange={e => setCommentMsg(e.target.value)}
                                            style={{ fontSize: '13px', width: "339px" }}
                                        />
                                    </div>

                                    <div className='mt-5' style={{ flexDirection: "row" }}>
                                        <Form.Group
                                            style={{
                                                display: "flex",
                                                justifyContent: "initial", flexDirection: "row", position: "relative",
                                                marginLeft:'5px'
                                            }}
                                        >
                                            <Button
                                                variant="primary" className="submit-button"
                                                type="submit"
                                                style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}
                                            >
                                                Submit
                                            </Button>

                                            <Button
                                                variant="link" className="cancel-button text-dark"
                                                style={{ marginLeft: "10px", fontSize: "15px", fontWeight: "700" }}
                                                onClick={props.handleClose}
                                            >
                                                Not now
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </fieldset>
                            </Form>
                        }
                    </Container>
                </div>
                :
                <div>
                    <Container>
                        {loading && load_element}
                        <Form noValidate onSubmit={handleProviderOpinion}>
                            <h5>Summarize your opinion here</h5>
                            <fieldset disabled={loading}>
                                <Card className='my-3 w-100' style={{ width: '340px', background: '#EFF3F4' }}>
                                    <Card.Body >
                                        <div className='d-flex '>
                                            <div >
                                                <FaUserAlt className='mr-2  rounded-circle p-1' style={{ background: '#e4e5e9', color: '#143C8C' }} size={40} />
                                            </div>

                                            <div >
                                                <p>{currentUser.displayname}</p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <div>
                                    <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>
                                        {providerQues[0]}
                                    </h5>
                                    <div
                                        {...getRootProps({ className: "drop-zone", role: "button" })}>
                                        <input {...getInputProps()} name="sel_files" />
                                        {isDragActive ? (
                                            <p className='drop-zone-style'
                                                style={{
                                                    border: "2px dashed #CFCFCF",
                                                    boxSizing: "border-box",
                                                    borderRadius: "8px",
                                                    width: "100%",
                                                    height: "80px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontWeight: "600",
                                                    fontSize: "15px",
                                                    color: "#0BC1C2",
                                                }}
                                            >
                                                Drop the files here ...
                                            </p>
                                        ) : (
                                            <>

                                                <p
                                                    style={{
                                                        border: "2px dashed #CFCFCF",
                                                        borderRadius: "8px",
                                                        width: "100%",
                                                        height: "110px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        fontWeight: "600",
                                                        fontSize: "15px",
                                                        color: "#0BC1C2",
                                                    }}
                                                >
                                                    <span className='mr-2'><FaCloudUploadAlt color='#b6e8f1' size={30} /></span>
                                                    Drag and Drop or browse files
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    {fileName.length > 0 &&
                                        <>
                                            <p>{previewName}</p>
                                        </>
                                    }
                                </div>
                                <div className='mt-4'>
                                    <p className='font-weight-bold'> {providerQues[1]} </p>
                                    <textarea
                                        rows={4}
                                        placeholder='Type your opinion'
                                        className='form-control shadow-none text-area-style'
                                        name="provComment"
                                        value={provMsg}
                                        onChange={e => setProvMsg(e.target.value)}
                                    />
                                </div>

                                <div className='mt-4'>
                                    <h5 style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}>{providerQues[2]}</h5>
                                    <div className='mt-3'>
                                        <input name='re_book' onChange={e => setConfRebook(e.target.value)} value="true" style={{ verticalAlign: "middle" }} className='mr-3' type="checkbox" />
                                        <label style={{ fontSize: "14px" }}>I want re-book with this patient</label>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <h5 className='mb-0' style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}> {providerQues[3]}
                                    </h5>
                                    <p style={{ fontSize: "14px" }}>This helps in notifying the patient</p>
                                    <div>
                                        {
                                            ["After 1 week", "After 1 month", "Custom"].map((ans, index) => {
                                                return (
                                                    <div key={index} className='mb-3'>
                                                        <input name='expected_to_see' checked={ans === expected} onChange={e => setExpected(e.target.value)} value={ans} style={{ verticalAlign: "middle" }} type="checkbox" className='mr-3' /> <span style={{ fontSize: "14px" }}>{ans}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div style={{ flexDirection: "row", marginTop: "25px" }}>
                                    <Form.Group
                                        style={{
                                            display: "flex",
                                            justifyContent: "initial", flexDirection: "row", position: "relative",
                                        }}
                                    >
                                        <Button
                                            variant="primary" className="submit-button"
                                            type="submit"
                                            style={{ fontFamily: "manrope", fontSize: "15px", fontWeight: "700" }}
                                        >
                                            Submit
                                        </Button>

                                        <Button
                                            variant="white" className="cancel-button"
                                            style={{ marginLeft: "30px", fontSize: "15px", fontWeight: "700" }}
                                            onClick={props.handleClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Form.Group>
                                </div>
                            </fieldset>
                        </Form>
                    </Container>
                    {/* )}
                    </Formik> */}
                </div>}
        </div>
    )
}

export default Feedback
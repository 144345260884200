import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import './Section7.css'
import impact from "../../../../assets/images/impact.png";

export class Section7 extends Component {
    render() {
        return (
            <div className='bg-white py-3 section_7'>
                <Container className='d-flex align-items-center'>


                    <Row className='sec7_row d-flex'>

                        <Col sm={12} lg={7} className="impact">
                            <img className='sec7_img'
                                src={impact}
                                alt='young-lady' width='881px' />
                        </Col>
                        <Col className='align-self-center justify-content-center' sm={12} lg={5} >

                            <label className='contentheading '>PROGRAMS</label><br />
                            <label className='contentheading1'>Impacting the world with small steps</label><br />
                            <label className='contentheading2'> We run summer camps at third world countries to enhance their living practises.Post covid we have been trying to run vaccination centers to make them available to everybody.</label>


                        </Col>

                    </Row>

                </Container>
            </div>
        )
    }
}

export default Section7
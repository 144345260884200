import React, { Component } from 'react'
import { Row, Col, Container, Modal } from 'react-bootstrap';
import '../../../landing/landingPage/Section1/Section1.css';
import GetOpinion from '../../../landing/landingPage/GetOpinion/GetOpinion';



import pat1 from "../../../../assets/images/pat1.png";
import './Section6.css'
export class Section6 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            newUserVal: false
        }
    }

    render() {
        const handleClose = (e) => {
            this.setState({ showModal: false })
        }

        return (
            <div className='section6'>
                <Container>
                    <Row>
                        <Col lg='6' className='section6Col'>
                            <h1 className='mt-5 heading'>Get expert <br /> opinion from <br /><span style={{ color: '#0BC1C2' }}> verified </span>doctors</h1>
                            <p className='mt-4 cols'>Connect to specialists through audio/video calls to get a second opinion.We have the best doctors with proven
                                experience for all your health concerns.</p>
                            {/* <Button className='btn btn-primary btn-lg my-4 px-4' style={{fontSize:'16px'}} href={NONAUTHROUTE.PROVIDER.REGISTRATIONPATH}>Get started for FREE</Button> */}
                        </Col>
                        <Col lg='6' className='section6Col2'>

                            <img className='consultDoc' src={pat1} alt='maleDoctor' />

                        </Col>
                    </Row>
                </Container>
                <Modal show={this.state.showModal} onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    centered={false}
                    size="xl"
                    className='modalData'
                >
                    <Modal.Header style={{ border: "0" }} closeButton>
                    </Modal.Header>
                    <Modal.Body><GetOpinion {...this.props} handleClose={handleClose} /></Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default Section6
import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { connect } from 'react-redux'
import { getUserListDetails } from '../../../actions/admin/UserListAction'
import { Modal, message, Spin } from 'antd';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import paginationFactory from 'react-bootstrap-table2-paginator';

import userListService from '../../../services/admin/UserList.Service'
import Role from '../../../constants/role'
import './Userlist.style.css'
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { formateName, handleOnSelectStage } from '../../patient/enquiry/EnquiryUtils';

import { AUTHROUTE } from '../../../constants/RouterPath';
import { errorMessage } from '../../../utils/ServiceHandler';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Formik } from 'formik';




export class UserListComponent extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      selectedRowKeys: [],
      selectedRows: [],
      users: [],
      modalVisible: false,
      loading: false,
      createUser: true,
      hasSelected: false,
      userTypeInfo: { firstname: "", lastname: "", email: "", phone: "", usertype: "", username: "" },
      load: false,
      isdisabled: false

    }
  }
  showModal = () => {
    this.setState({
      modalVisible: true,
      createUser: true,
    });
    setTimeout(() => {
      this.formRef && this.formRef.current && this.formRef.current.resetFields();
    }, 100);
  };
  showAlert = () => {
    message.warning("coming soon")
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };


  onFinish = async (values) => {
    const { phone, firstname, lastname, email, usertype, username } = values;
    const countryCallingCode = parsePhoneNumber(phone)?.countryCallingCode
    if (phone && countryCallingCode && !isValidPhoneNumber(phone)) {
      message.error("Invalid Phone number format")
    } else {
      const countryCallingCode = parsePhoneNumber(phone)?.countryCallingCode


      const allFormData = {
        "firstname": firstname,
        "middlename": "",
        "lastname": lastname,
        "email": email,
        "phone": phone ? phone : "",
        "countrycode": countryCallingCode ? countryCallingCode : "",
        "usertype": usertype ? usertype : "",
        "username": username ? username : ""
      }
      try {
        this.setState({ loading: true });
        if (this.state.createUser) {
          let response = await userListService.createInternalUser(allFormData);
          if (response.data.status === "success") {
            message.success(response.data.message);
            this.setState({ loading: false, modalVisible: false })
            this.getUserlist()
          } else {
            this.setState({ loading: false })
            message.error(response.data.message);
          }
        }

      } catch (error) {
        errorMessage(error)
        this.setState({ loading: false })
      }


    }
  }
  onFinishFailed = values => {
  }
  edit = (id) => {
    var record = this.state.users.find(e => e._id === id);
    if (record) {

      let userInfo = {

        "firstname": record.firstname || "",
        "lastname": record.lastname || "",
        "email": record.email || "",
        "username": record.username || "",
        "phone": record.phone || "",
        "usertype": record.usertype || "",
      }
      this.setState({ userTypeInfo: userInfo });
    } else {

      this.setState({ modalVisible: false });
    }


    this.setState({
      createUser: false,
      modalVisible: true,
      isdisabled: true
    });
  }

  getUserlist = () => {
    this.props.getUserListDetails().then((userData) => {

      let users = userData.users
      this.setState({
        users,
        load: true
      })
    }).catch(err => {
      errorMessage(err)
    })
  }


  componentDidMount() {
    this.getUserlist()
    this.setState({
      showMultiSectionOption: this.props && this.props.showMultiSectionOption,
      showCreate: (this.props && this.props.showCreate) || false,

    });
  }
  handleOnSelectStage = (value, event) => {
    this.setState({ isAssigneeRequired: handleOnSelectStage(value, event) })
  }

  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );

    const options = {
      sizePerPage: 5,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }]
    };

    const allAdminType = [

      {

        dataField: 'displayname',
        text: 'Name',
        formatter: (value) => formateName(value)
      },
      {

        dataField: "usertype",
        text: 'User Type'

      },
      {

        dataField: "email",
        text: 'Email',
        formatter: (value) => value.length > 15 ? <div className='mr-5'>{value.slice(0, 18)}<br />{value.slice(18, 39)}<br />{value.slice(39)}</div> : value
      },
      {

        dataField: "phone",
        text: 'Mobile',
        formatter: (value) => !!value ? value : "--"

      },

      {
        text: ' ',
        dataField: '_id',
        formatter: (current_record) => (

          <Button variant="link" style={{ marginLeft: "15px" }} onClick={() => this.edit(current_record)}>
            View
          </Button>


        ),

      },


    ]




    return (<React.Fragment>
      <AfterLoginLayout>
        {this.state.load ?
          <div className="container-fluid">
            <div className="banner-header">
              <h3>Internal Users</h3>
            </div>

            <Modal
              visible={this.state.modalVisible}
              onCancel={this.handleCancel}
              footer={false}
              keyboard={true}
              maskClosable={false}
              destroyOnClose={true}
              centered={true}
              title={this.state.createUser ? "Create User" : "View User"}
            >

              <Spin spinning={this.state.loading}>

                <Formik
                  initialValues={this.state.userTypeInfo}
                  onSubmit={async (values) => this.onFinish(values)}

                >
                  {(({ values, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>

                      <div className='row'>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold text-dark">
                            First Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <Form.Control
                            disabled={this.state.isdisabled}
                            name="firstname" onChange={handleChange} value={values.firstname}
                          />

                        </div>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold">Last Name</label>
                          <Form.Control
                            onChange={handleChange}
                            disabled={this.state.isdisabled}
                            name='lastname' value={values.lastname} />

                        </div>
                      </div>
                      <div className='row'>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold text-dark">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <Form.Control
                            disabled={this.state.isdisabled}
                            name="email" onChange={handleChange} value={values.email}
                          />

                        </div>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold">Mobile<span style={{ color: "red" }}>*</span></label>
                          <Form.Control
                            disabled={this.state.isdisabled}
                            onChange={handleChange}
                            name='phone' value={values.phone} />

                        </div>
                      </div>
                      <div className='row'>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold text-dark">
                            User Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <Form.Control
                            disabled={this.state.isdisabled}
                            name="username" onChange={handleChange} value={values.username}
                          />

                        </div>
                        <div className='col form-group mr-2'>
                          <label className="mb-1 font-weight-bold">User Type<span style={{ color: "red" }}>*</span></label>
                          <select className='form-control shadow-none'
                            onChange={handleChange}
                            name='usertype'
                            value={values.usertype}
                            disabled={this.state.isdisabled}
                          >
                            <option value={Role.admin.name}>{Role.admin.name}</option>
                            <option value={Role.internaluser.name}>{Role.internaluser.name}</option>
                          </select>
                        </div>
                      </div>
                      {(() => {
                        if (this.state.createUser) {
                          return <button className='btn btn-primary' type="submit"> Create User</button>
                        }
                      })()}
                    </Form>
                  ))}

                </Formik>
              </Spin>
            </Modal>
           

            <div style={{ justifyContent: "right", display: "flex" }}>
              <Link className='btn btn-primary px-5' to={{ pathname: AUTHROUTE.INTERNALUSER.ADD_USER }}> + Add internal user
              </Link>
            </div>
          


            <span style={{ marginLeft: 8 }}>
              {this.state.hasSelected ? `Selected ${this.state.selectedRows.length} items` : ''}
            </span>
            {this.state.users &&
              <BootstrapTable keyField='_id'
                data={this.state.users}
                columns={allAdminType}
                bordered={false}
                hover={false}
                pagination={paginationFactory(options)}
                rowKey={record => record._id} />
            }

          </div> :
          <div className='container d-flex justify-content-center align-items-center' style={{}}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
      </AfterLoginLayout>
    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserListDetails: () => dispatch(getUserListDetails())
  }
}
const mapStateToProps = state => {
  return {
    usersStore: state.internalUserList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListComponent)
import React, { useEffect, useState } from 'react'
import { authenticationService } from '../general/login/login.service';
import AppointmentService from '../../services/provider/Appointment.Service';
import appointmentService from '../../services/patient/Appointment.Service';
import { calculate_age } from '../patient/enquiry/EnquiryUtils';
import { Row, Spinner, Col, Card, Modal } from 'react-bootstrap';
import { FaUserAlt } from "react-icons/fa";
import { FaFileWord } from 'react-icons/fa';
import { FaFileAlt, FaFile } from 'react-icons/fa';
// import localpatientListService from '../../services/localprovider/PatientList.Service';

function ViewDetails(props) {

    //patient
    const [loading, setLoading] = useState(false);
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selected_view_document, setSelected_view_document] = useState("");
    const [patientInfo, setPatientInfo] = useState({});
    const [patientEnquiryInfo, setPatientEnquiryInfo] = useState({});
    const [patientConsultationInfo, setPatientConsultationInfo] = useState({});

    //provider
    const [providerConsultationInfo, setProviderConsultationInfo] = useState({});
    const [providerUserInfo, setProviderUserInfo] = useState({});
    const [providerEnquiryInfo, setProviderEnquiryInfo] = useState({});

    //localprovider
    const [localConsultationInfo, setlocalConsultationInfo] = useState({});
    const [localUserInfo, setlocalUserInfo] = useState({});
    const [localEnquiryInfo, setlocalEnquiryInfo] = useState({});

    const currentUser = authenticationService.currentUserValue;
    const patient_id = props?.location?.state?.currentUser?.usertype_id;
    const patientUserInfo_id = props?.location?.state?.currentUser?._id;
    const getPatientAppointment = async () => {
        try {
            setLoading(true)
            let response = await appointmentService.getPatientAppointments(patient_id);
            if (response.data.status === "success") {
                setLoading(false)
                const result = response.data.data.filter(data => data.consultationInfo.scheduleDetailsInfo?._id === props.location.state.consultationInfo.scheduleDetailsInfo._id)
                setProviderConsultationInfo(result && result[0].consultationInfo);
                setProviderUserInfo(result && result[0].providerUserInfo);
                setProviderEnquiryInfo(result && result[0].enquiryInfo);
            } else setLoading(true)
        } catch (error) {
            setLoading(false)
        }
    }

    const getLocalPatientAppointment = async () => {
        try {
            setLoading(true)
            let response = await appointmentService.getLocalPatientAppointments(patientUserInfo_id);
            if (response.data.status === "success") {
                setLoading(false)
                const result = response.data.data.filter(data => data.consultationInfo.scheduleDetailsInfo?._id === props.location.state.consultationInfo.scheduleDetailsInfo._id)
                setlocalConsultationInfo(result && result[0].providerInfo);
                setlocalUserInfo(result && result[0].consultationInfo);
                setlocalEnquiryInfo(result && result[0].enquiryInfo);
            } else setLoading(true)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        const getProviderAppointment = async () => {
            setLoading(true)
            const res = await AppointmentService.getProviderAppointments(currentUser.usertype_id);
            if (res.data.status === "success") {
                setLoading(false)
                const result = res.data.data.filter(data => data.consultationInfo.scheduleDetailsInfo?._id === props.location.state.consultationInfo.scheduleDetailsInfo._id)
                setPatientInfo(result && result[0].patientInfo)
                setPatientEnquiryInfo(result && result[0].enquiryInfo)
                setPatientConsultationInfo(result && result[0].consultationInfo)
            } else setLoading(true)
        }
        getPatientAppointment()
        getProviderAppointment()
        getLocalPatientAppointment()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const load_element = <div className='d-flex justify-content-center flex-nowrap'>
    //     <div className='position-absolute mx-5 p-4' variant="primary">
    //         <Spinner animation="border" variant="primary" />
    //     </div>
    // </div>

    const documentViewer = (document_url) => {
        setSelected_view_document(document_url);
        setShowImageViewer(true);
    }



    const handleClose = () => {
        setShowImageViewer(false)
    }

    return (
        <div>
            {loading &&
                <Card className='border-0' style={{ width: '300px', height: '200px' }}>
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-5 p-4' variant="primary">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                </Card>
            }
            <div>
                {currentUser.usertype === "provider" ?
                    <div>
                        {!loading &&
                            <div style={{ marginTop: '-40px' }}>
                                <div className='d-flex'>
                                    <div >
                                        <FaUserAlt className='mr-2  rounded-circle p-1' style={{ background: '#e4e5e9', color: '#143C8C' }} size={50} />
                                    </div>

                                    <div className='ml-2 mt-2'>
                                        <p style={{ fontSize: '13px' }} className='font-weight-bold'>{patientInfo.displayname}</p>
                                        <p style={{ marginTop: '-15px', fontSize: '11px' }}><Row>
                                            {patientInfo?.gender?.length > 4 ? <Col className='text-lowercase mr-4'>{patientInfo.gender}</Col> :
                                                <Col className='text-lowercase'>{patientInfo.gender}</Col>}
                                            {patientInfo?.dob !== null ?
                                                <Col style={{ marginLeft: '-200px' }}>,&nbsp;{calculate_age(patientInfo?.dob)}Yrs</Col> : ""}
                                        </Row>
                                        </p>

                                        <Card className='my-3' style={{ width: '280px', height: '73px', background: '#EFF3F4', marginLeft: '-60px' }}>
                                            <Card.Body >
                                                <p className='font-weight-bold' style={{ fontSize: '13px', marginTop: '-10px' }}>Concern</p>
                                                <p style={{ fontSize: '12px', marginTop: '-10px' }}>I have {patientEnquiryInfo?.enquiry_message}</p>
                                            </Card.Body>
                                        </Card>

                                        <Card className="mt-2"
                                            style={{
                                                position: "relative",
                                                backgroundColor: "#EFF3F4",
                                                height: "50px auto",
                                                width: "280px",
                                                marginLeft: "-56px"
                                            }}
                                        >
                                            <p className='my-3 ml-3 font-weight-bold' style={{ fontSize: '13px' }}>Attached files</p>
                                            <div className='d-flex flex-wrap'>
                                                {patientConsultationInfo?.documents?.map((file, i) => (
                                                    <Row>
                                                        <Col>
                                                            {(file?.filetype === 'image/jpeg' || file?.filetype === 'image/png') && <img className='ml-3 mb-3' style={{ border: "1px solid #ccc", cursor: "pointer" }} src={file?.url} alt=" " width={50} height={50} onClick={() => {
                                                                documentViewer(file?.url)
                                                            }} ></img>}
                                                            {file?.filetype === 'application/octet-stream' && <div> <a href={file?.url} target="_blank" rel="noreferrer" download><FaFileWord className='fa fa-3x text-secondary ml-3 mb-3' /></a></div>}
                                                            {file?.filetype === 'text/plain' && <div> <a href={file?.url} target="_blank" rel="noreferrer" download><FaFileAlt className='fa fa-3x text-secondary ml-3 mb-3' /></a></div>}

                                                            {(file?.filetype === 'application/pdf' || file?.filetype === 'application/msword' || file?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                <div className='image ml-3'>
                                                                    <a href={file?.url} target="_blank" rel="noreferrer" download><FaFile className='fa fa-3x mb-3' /></a>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                ))
                                                }
                                            </div>
                                        </Card>

                                        {
                                            showImageViewer && <Modal show={showImageViewer}
                                                centered
                                                onHide={handleClose}
                                                backdrop="static"
                                                width={500}>
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <img src={selected_view_document} alt="documents" width="100%" height="100%" />
                                                </Modal.Body>
                                            </Modal>
                                        }

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    currentUser.usertype === "localprovider" ?
                        <div>
                            {!loading &&
                                <div style={{ marginTop: '-40px' }}>
                                    <div className='d-flex'>

                                        <div >
                                            <FaUserAlt className='mr-2 rounded-circle p-1' style={{ background: '#e4e5e9', color: '#143C8C' }} size={50} />
                                        </div>

                                        <div className='ml-3 mt-2'>
                                            <p style={{ fontSize: '13px' }} className='font-weight-bold'>{localConsultationInfo.displayname}</p>
                                            <p style={{ marginTop: '-15px' }}><Row>
                                                <Col className='text-lowercase' style={{ fontSize: '12px' }}>{localEnquiryInfo?.speciality?.name}</Col>
                                            </Row>
                                            </p>


                                            <Card className='mt-4' style={{ width: '280px', height: '73px', background: '#EFF3F4', marginLeft: '-60px' }}>
                                                <Card.Body >
                                                    <p className='font-weight-bold' style={{ fontSize: '13px', marginTop: '-10px' }}>Concern</p>
                                                    <p style={{ fontSize: '11px', marginTop: '-10px' }}> My patient {currentUser.displayname.substring(0, currentUser.displayname.indexOf(' '))} has {localEnquiryInfo?.enquiry_message}</p>
                                                </Card.Body>
                                            </Card>

                                            <Card
                                                style={{
                                                    position: "relative",
                                                    backgroundColor: "#EFF3F4",
                                                    height: "50px auto",
                                                    width: "280px",
                                                    marginLeft: "-56px",
                                                    marginTop: "-20px",
                                                }}
                                            >
                                                <p className=' my-3 ml-3 font-weight-bold' style={{ fontSize: '13px' }}>Attached files</p>
                                                <div className='d-flex flex-wrap'>
                                                    {localUserInfo?.documents?.map((file, i) => (
                                                        <Row>
                                                            <Col>
                                                                {(file?.filetype === 'image/jpeg' || file?.filetype === 'image/png') && <img className='ml-3 mb-3' style={{ border: "1px solid #ccc", cursor: "pointer" }} src={file?.url} alt=" " width={50} height={50} onClick={() => {
                                                                    documentViewer(file?.url)
                                                                }} ></img>}
                                                                {file?.filetype === 'application/octet-stream' && <div> <a href={file?.url} target="_blank" rel="noreferrer" download><FaFileWord className='fa fa-3x text-secondary ml-3 mb-3' /></a></div>}
                                                                {file?.filetype === 'text/plain' && <div> <a href={file?.url} target="_blank" rel="noreferrer" download><FaFileAlt className='fa fa-3x text-secondary ml-3 mb-3' /></a></div>}

                                                                {(file?.filetype === 'application/pdf' || file?.filetype === 'application/msword' || file?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                    <div className='image ml-3'>
                                                                        <a href={file?.url} target="_blank" rel="noreferrer" download><FaFile className='fa fa-3x mb-3' /></a>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    ))
                                                    }
                                                </div>
                                            </Card>
                                            {
                                                showImageViewer && <Modal show={showImageViewer}
                                                    centered
                                                    onHide={handleClose}
                                                    backdrop="static"
                                                    width={500}>
                                                    <Modal.Header closeButton>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <img src={selected_view_document} alt="documents" width="100%" height="100%" />
                                                    </Modal.Body>
                                                </Modal>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            {!loading &&
                                <div style={{ marginTop: '-40px' }}>
                                    <div className='d-flex'>

                                        <div >
                                            <FaUserAlt className=' rounded-circle p-1' style={{ background: '#e4e5e9', color: '#143C8C' }} size={50} />
                                        </div>

                                        <div className='ml-3 mt-2'>
                                            <p style={{ fontSize: '13px' }} className='font-weight-bold'>{providerUserInfo.firstname}</p>
                                            <p style={{ marginTop: '-15px' }}><Row>
                                                <Col className='text-lowercase' style={{ fontSize: '12px' }}>{providerConsultationInfo?.speciality?.name}</Col>
                                            </Row>
                                            </p>


                                            <Card className='mt-4' style={{ width: '280px', height: '73px', background: '#EFF3F4', marginLeft: '-60px' }}>
                                                <Card.Body >
                                                    <p className='font-weight-bold' style={{ fontSize: '13px', marginTop: '-10px' }}>Concern</p>
                                                    <p style={{ fontSize: '11px', marginTop: '-10px' }}> My patient {currentUser.displayname.substring(0, currentUser.displayname.indexOf(' '))} has {providerEnquiryInfo?.enquiry_message}</p>
                                                </Card.Body>
                                            </Card>

                                            <Card
                                                style={{
                                                    position: "relative",
                                                    backgroundColor: "#EFF3F4",
                                                    height: "50px auto",
                                                    width: "280px",
                                                    marginLeft: "-56px",
                                                    marginTop: "-20px",
                                                }}
                                            >
                                                <p className=' my-3 ml-3 font-weight-bold' style={{ fontSize: '13px' }}>Attached files</p>
                                                <div className='d-flex flex-wrap'>
                                                    {providerConsultationInfo?.documents?.map((file, i) => (
                                                        <Row>
                                                            <Col>
                                                                {(file?.filetype === 'image/jpeg' || file?.filetype === 'image/png') && <img className='ml-3 mb-3' style={{ border: "1px solid #ccc", cursor: "pointer" }} src={file?.url} alt=" " width={50} height={50} onClick={() => {
                                                                    documentViewer(file?.url)
                                                                }} ></img>}
                                                                {file?.filetype === 'application/octet-stream' && <div> <a href={file?.url} target="_blank" rel="noreferrer" download><FaFileWord className='fa fa-3x text-secondary ml-3 mb-3' /></a></div>}
                                                                {file?.filetype === 'text/plain' && <div> <a href={file?.url} target="_blank" rel="noreferrer" download><FaFileAlt className='fa fa-3x text-secondary ml-3 mb-3' /></a></div>}

                                                                {(file?.filetype === 'application/pdf' || file?.filetype === 'application/msword' || file?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                    <div className='image ml-3'>
                                                                        <a href={file?.url} target="_blank" rel="noreferrer" download><FaFile className='fa fa-3x mb-3' /></a>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    ))
                                                    }
                                                </div>
                                            </Card>
                                            {
                                                showImageViewer && <Modal show={showImageViewer}
                                                    centered
                                                    onHide={handleClose}
                                                    backdrop="static"
                                                    width={500}>
                                                    <Modal.Header closeButton>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <img src={selected_view_document} alt="documents" width="100%" height="100%" />
                                                    </Modal.Body>
                                                </Modal>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>}
            </div>
        </div>
    )
}

export default ViewDetails
import { GET } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";

/**
 * Get Master Data 
 */
function fetchMasterData(data) {
  let url = APIENDPOINT.GET_MASTER_DATA;
  return GET(url, data);
}

const masterDataService = {
  fetchMasterData: fetchMasterData,
}
export default masterDataService;
import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import {  message } from 'antd';
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { authenticationService } from '../../general/login/login.service';
import { getStartDate, getParsedSlots, parse_timeslot } from './SchedulerUtils';
import './providerScheduler.css'
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import NewSched from './NewSched';
import profileService from '../../../services/provider/Profile.Service';

class ProviderScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      loading: false,
      //blocked_times: [new Date('2021-09-06T03:30:00.000Z'), new Date('2021-09-04T04:30:00.000Z')],
      blocked_times: [],
      taken_times: [],
      start_date: getStartDate(),
      //
      booked_slots: [],
      taken_slots:[],
      timeZones:[],
      providTimzo:"",
      showViewSlots:false,
      resSched:[],
      usertype:authenticationService.currentUserValue
    }
  }

  handleSchedulerChange = newSchedule => {
    this.setState({ schedule: newSchedule })
  }

  handleShowView = (visble) => {
    this.setState({showViewSlots:visble})
  }

  updateScheduler = async (newSlotz) => {
    const currentUser = authenticationService.currentUserValue;
    const slotzz = [...newSlotz,...this.state.schedule]
    const addds=[]
    for(let obj in slotzz){
       const fou = addds.find(item => JSON.stringify(item) === JSON.stringify(slotzz[obj]))
      if(fou){
        
      } else {
        addds.push(slotzz[obj])
      }
    }

   const all_Slotz = [...addds]
    try {
      const _parsedslots = parse_timeslot(all_Slotz && all_Slotz,this.state.providTimzo && this.state.providTimzo,newSlotz,this.state.resSched)
      this.setState({ loading: true })
      var data = {
        provider_id: currentUser && currentUser.usertype_id,
        schedules: _parsedslots
      }
      let response = await ProviderScheduleService.saveProviderSchedule(data);
      if (response.data.status === "success") {
        message.success(response.data.message);
        this.setState({ loading: false, modalVisible: false })
        this.getProviderSchedules()

      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }

    } catch (error) {
      
      errorMessage(error)
      this.setState({ loading: false })
    }
  };
  getProviderSchedules = async () => {

    try {
      const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true});
      const provider_id = currentUser && currentUser.usertype_id
      let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
      const timeZ = await profileService.getProfile()
      const timezoness = await ProviderScheduleService.getAllTimeZones()
      
      this.setState({timeZones:timezoness.data.data,providTimzo:timeZ.data.user[0].timezone })
      if (response.data.status === "success") {
        if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
          const responseObj = response.data
          const _schedules = responseObj.schedules
          const all_schedule = getParsedSlots(_schedules,this.state.timeZones);
          const taken_slots=[]
          response.data.schedules.map(item =>
            item.slots.map(itm => itm.status === 1 && taken_slots.push(itm.slot)
            )
          )
          const booked_slots = all_schedule.booked_slots.map(item => moment(item).format())
          this.setState({
            schedule: all_schedule && all_schedule.available_slots,
            blocked_times: all_schedule && all_schedule.booked_slots,
            taken_times: all_schedule && all_schedule.taken_slots,
            resSched:response.data.schedules,
            booked_slots,
            taken_slots,
            loading: false
          })
        }
      } else {
        this.setState({ loading: false })
        //message.error(response.data.message)
      }

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    this.getProviderSchedules()
  }

  handleDataCell = (datee, selected, refSetter) => {
   const da = moment(datee).format("hh:mm A")
    return <div className=''>
      <p className={`${ this.state.booked_slots.includes(moment(datee).format()) ? 
        `slot-booked py-2 font-weight-bold` : `${selected ? 
        'selectedSlot py-2 font-weight-bold' :'notSelected py-2 font-weight-bold'}`}
         ${this.state.taken_slots}`}>
        {da}
      </p>
    </div>
  }

  renderTimeLab = (tim) => {
    return <p></p>
  }

  render() {
    const slotsData = {booked_slots:this.state.blocked_times,taken_slots:this.state.taken_slots,schedule:this.state.schedule}

    return (<React.Fragment>
      <AfterLoginLayout>
        
        <div>
          {this.state.loading ?
            <div className='d-flex justify-content-center'>
              <div>
                <Spinner animation="border " variant="primary" />
              </div>
            </div>
            : <div className="container-fluid ">
              <div>
                  <NewSched
                 timeZones={this.state.timeZones}
                 updateScheduler={this.updateScheduler}
                 proTimzo={this.state.providTimzo}
                 allSlotsData={slotsData}
                 showViewFunc = {this.handleShowView}
                 usertype={this.state.usertype && this.state.usertype.usertype}
               />
              </div>
            
            </div>}
          
        </div>

      </AfterLoginLayout>

    </React.Fragment>
    )
  }
}
export default ProviderScheduler
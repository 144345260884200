import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { AiFillCheckCircle } from "react-icons/ai";
import './Section3.css';
import help1 from "../../../../assets/images/help1.png";
import help2 from "../../../../assets/images/help2.png";


export class Section3 extends Component {
    render() {
        return (
            <div className='section-3 py-4'>

                <Container className='header-main'>
                    <Row className='section-3Row mb-6 '>
                        <Col sm={5}>
                            <img className='im1'
                                src={help1}
                                alt='young-lady' padding-left="30px" width="547px" />
                        </Col>
                        <Col className='col2' sm={5}>
                            <p>FOR PATIENTS</p>
                            <h3 className='text-white'>Hassle- free way to connect with doctors</h3>
                            <Row className='text-white mt-4  data1 no-gutters'>

                                <Col>
                                    <Row className='sec3_row1'>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-3 checkCircle' />
                                        </Col>
                                        <Col className='text'sm={9}>
                                            <span className='text-white'>Online booking</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-3 checkCircle' />
                                        </Col>
                                        <Col className='text' sm={9}>
                                            <span className='text-white'>Qualified Doctors</span>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className='text-white mt-4  data1 no-gutters'>

                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-3 checkCircle' />
                                        </Col>
                                        <Col className='text' sm={9}>
                                            <span className='text-white'>Private Support</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-3  checkCircle' />
                                        </Col>
                                        <Col className='text' sm={9}>
                                            <span className='text-white'>Free followup</span>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </Container>
                <Container>
                    <Row className='section-3Row  '>

                        <Col className='col3' sm={5}>
                            <p>FOR DOCTORS</p>
                            <h3 className='text-white'>Seamless way to <br />grow your practice</h3>
                            <Row className='text-white mt-4  data1 no-gutters'>

                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-2 checkCircle' />
                                        </Col>
                                        <Col sm={9}>
                                            <span className='text-white'>Hassle free re-scheduling</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-2 checkCircle' />
                                        </Col>
                                        <Col sm={9}>
                                            <span className='text-white'>Easy patients data manage</span>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className='text-white mt-4  data1 no-gutters'>

                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-2 checkCircle' />
                                        </Col>
                                        <Col sm={9}>
                                            <span className='text-white'>Appiontments Remainders</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col sm={3}>
                                            <AiFillCheckCircle size={30} className='mx-2 checkCircle' />
                                        </Col>
                                        <Col sm={9}>
                                            <span className='text-white'>Data privacy</span>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                        <Col sm={5}>
                            <img className='im2'
                                src={help2}
                                alt='young-lady' padding-left="30px" width="547px" />
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }
}

export default Section3
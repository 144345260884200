import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import "../../provider/profile/providerProfile.style.css"
import EnquiryTableView from '../../patient/enquiry/EnquiryTableView';
import { authenticationService } from '../../../component/general/login/login.service';

export class EnquiryAdminView extends React.Component {

  render() {
    const currentUser = authenticationService.currentUserValue;
    return (<React.Fragment>
      <AfterLoginLayout>
        <div className="container-fluid">
          <div className="banner-header">
            <h4>Opinion requests</h4>
          </div>

          <EnquiryTableView
            showMultiSectionOption={true}
            showCreate={false}
            patient_id={currentUser && currentUser._id}
            {...this.props}
          />
        </div>
      </AfterLoginLayout>
    </React.Fragment>)
  }
}

export default EnquiryAdminView
import { message } from 'antd';
import { GET, POST } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";
import store from '../../store';
import { storageActions } from "../../actions/storageAction";
import { authenticationService } from '../../component/general/login/login.service';
import { encryptData } from '../../utils/SecureStorage';
import { errorMessage } from '../../utils/ServiceHandler';


/**
 * Get Profile details informations
 * @param {} users and speciality details 
 */
function getProfile() {
  let currentUser = authenticationService.currentUserValue
  let url = APIENDPOINT.PROFILE_DETAILS + "/" + currentUser._id;
  return GET(url);
}

function getSpecialites() {
  let url = APIENDPOINT.SPECIALITY_DETAILS;
  return GET(url)
}

function getSymptoms() {
  let url = APIENDPOINT.SYMPTOMS_DETAILS;
  return GET(url)
}

function getTimezoneList() {
  let url = APIENDPOINT.PROVIDER.GET_TIMEZONES;
  return GET(url)
}

const updateProfile = async (formDetails) => {
  const userObj = authenticationService.currentUserValue
  var data = {
    "firstname": formDetails.firstname,
    "middlename": formDetails.middlename,
    "lastname": formDetails.lastname,
    "email": formDetails.email,
    "phone": formDetails.phone,
    "countrycode": formDetails.countrycode,
    "speciality": formDetails.speciality,
    "taxonomy": formDetails.taxonomy,
    "usertype": userObj?.usertype,
    "experience": formDetails.experience,
    "npi": formDetails.NPI,
    "user_id": userObj?._id,
    "usertype_id": userObj?.usertype_id,
    "status": formDetails.status,
    "timezone": formDetails.timezone,
  };
  await POST(APIENDPOINT.PROFILE_DETAILS, data).then((response) => {
    localStorage.setItem("timeZone", formDetails.timezone)
    if (response.data.status === "success") {
      message.success(response.data.message);
      store.dispatch(storageActions.update("currentUser", encryptData(response.data.data.user)))
    } else {
      message.error(response.data.message);
    }
  }).catch((err) => {
    errorMessage(err)
  });
}

const profileService = {
  update: updateProfile,
  getProfile: getProfile,
  getSpecialites: getSpecialites,
  getTimezoneList: getTimezoneList,
  getSymptoms: getSymptoms
}
export default profileService;
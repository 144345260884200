import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Spinner, Row, Col } from 'react-bootstrap';
import { message } from 'antd';
import { Layout } from '../../shared';
import { AUTHROUTE } from "../../../constants/RouterPath"
import { errorMessage } from '../../../utils/ServiceHandler';
import './enquiry.style.css'
import { FaCheckCircle } from "react-icons/fa";
import { authenticationService } from '../../general/login/login.service';


export class AppointmentConfirmationAuthu extends Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                //this.navigatetoLogin(props)
            }
        }
        this.state = {
            loading: false,
            selected_enquiry: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            schedules: [],
            schedule_slot_id: '',
            selected_slot: '',
            selected_date: '',
            salesOrderId: '',
            session_id: '',
            salesOrderCode: '',
            schedule_date: '',
            schedule_id: '',
        }
    }

    getEnquiryDetails = async () => {
        try {
            this.setState({ loading: true })
            const state_vals = this.props && this.props.location && this.props.location.state
            if (state_vals) {
                const _enquiry = state_vals && state_vals.selected_enquiry
                const _patient_obj = state_vals && state_vals.patient_obj
                const _user_obj = state_vals && state_vals.user_obj
                const _provider_obj = state_vals && state_vals.provider_obj
                const _provider_user_obj = state_vals && state_vals.provider_user_obj
                const _schedules = _enquiry.schedules
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({
                        selected_enquiry: _enquiry,
                        patient_obj: _patient_obj,
                        user_obj: _user_obj,
                        provider_obj: _provider_obj,
                        provider_user_obj: _provider_user_obj,
                        schedules: _schedules,
                        schedule_slot_id: state_vals && state_vals.schedule_slot_id,
                        selected_date: state_vals && state_vals.selected_date,
                        selected_slot: state_vals && state_vals.selected_slot,
                        salesOrderId: state_vals && state_vals.salesOrderId,
                        session_id: state_vals && state_vals.session_id,
                        salesOrderCode: state_vals && state_vals.salesOrderCode,
                        schedule_id: state_vals && state_vals.schedule_id,
                        schedule_date: state_vals && state_vals.schedule_date,
                    })
                }, 500)
            } else {
                this.setState({ loading: false })
                message.error("Date not found")
                //this.navigatetoLogin(this.props)
                this.props.history.push({
                    pathname: AUTHROUTE.PATIENT.DASHBOARDPATH
                });

            }

        } catch (error) {
            this.setState({ loading: false })
            errorMessage(error)
        }
    }

    componentDidMount() {

        this.getEnquiryDetails();
    }

    render() {
        const currentUser = authenticationService.currentUserValue;

        return (
            <Layout className="confirmationAppointment" isHeader={false} isFooter={false}>
                {this.state.loading &&
                    <div className='d-flex justify-content-center flex-nowrap'>
                        <div className='position-absolute mx-3 p-4' variant="primary" style={{ marginTop: "15rem" }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                }
                <Row className='appointment'>
                    <Col lg={8} md={8} sm={12} className='leftappointment p-2'>
                        <div className='m-5 pt-5'>
                            <Row>
                                <Col>
                                    <div><FaCheckCircle size='5em' style={{ 'color': '#143C8C' }} />
                                        <p>SUCCESS!</p>
                                        <p className='headtext'>Thank you for choosing Second Opinion. <br /> Your appointment has been scheduled.</p>
                                        <p className='destext my-3'>We will notify you five minutes before the appointment. Ensure that you are in a distraction free environment and have stable internet connection.</p></div>
                                </Col>
                            </Row>
                            <Row className='my-5 py-4 appointmentdetails'>

                                <Col>
                                    <div className='title'>PATIENT<div className='py-2'>{this.state.patient_obj && this.state.patient_obj.displayname}</div></div>
                                </Col>
                                <Col>
                                    <div className='title'>BOOKING NUMBER<div className='py-2'>{this.state.salesOrderCode && this.state.salesOrderCode}</div></div>
                                </Col>
                                <Col>
                                    <div className='title'>APPOINTMENT DATE & TIME<div className='py-2'>{this.state.schedule_date && this.state.schedule_date + ' ' + this.state.selected_slot}</div></div>
                                </Col>

                            </Row>
                            <Row><Col className='p-0'><a className='btn btn-primary m-0' href={currentUser.usertype === 'patient' ? AUTHROUTE.PATIENT.DASHBOARDPATH : AUTHROUTE.LOCALPROVIDER.DASHBOARDPATH}>Go to Dashboard</a></Col></Row>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} className='rightappointment p-2'>
                        <div className='my-5'>Need help ?<br />
                            <p>Contact us at <a href="mailto:support@gmail.com">support@gmail.com</a> or call us at 9995678777</p></div>
                    </Col>
                </Row>
            </Layout>
        )
    }
}
export default withRouter(AppointmentConfirmationAuthu)
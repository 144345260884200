/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/antd/dist/antd.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './utils/Route';
import { Provider } from 'react-redux';
import store from './store';

require('./assets/css/all.min.css')
require('./assets/css/fontawesome.min.css')
require('./assets/css/style.css')
import '../node_modules/react-phone-number-input/style.css'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppRouter />
        </Provider>
    </React.StrictMode>

    , document.getElementById('root'));


import { GET } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";

/**
 * Get All Provider Apppointments 
 */
function getProviderAppointments(id) {
  let url = APIENDPOINT.PROVIDER.LISTAPPOINTMENTS+id;
  return GET(url);
}

const appointmentService = {
  getProviderAppointments: getProviderAppointments,
}
export default appointmentService;
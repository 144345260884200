import React, { Component } from 'react'
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import Role from '../../../constants/role'
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { message } from 'antd';
import userListService from '../../../services/admin/UserList.Service'
import { errorMessage } from '../../../utils/ServiceHandler';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import * as yup from 'yup'

import { formikFormalidation } from '../../general/formvalidator/validationFroms'






export class AddUser extends Component {


  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      selectedRowKeys: [],
      selectedRows: [],
      users: [],
      modalVisible: false,
      loading: false,
      createUser: true,
      hasSelected: false,
      userTypeInfo: { firstname: "", lastname: "", email: "", phone: "", usertype: "", username: "" },
      load: false

    }
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        load: true
      })


    }, (1000));
  }
  getInitalValues(userTypeInfo) {
    const { firstname, lastname, email, phone, username, usertype, countrycode } = userTypeInfo || {};


    this.setState({
      userTypeInfo: {
        ...this.state.userTypeInfo, firstname: firstname, lastname: lastname,
        email: email, load: true,
        phone: `${countrycode}${phone}`,
        username: username, usertype, countrycode: countrycode,

      },
    })
  }
  onFinish = async (values) => {
    const { phone, firstname, lastname, email, usertype, username } = values;
    const nationalNumber = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).nationalNumber
    this.setState({ formData: values });
    const countryCallingCode = parsePhoneNumber(phone)?.countryCallingCode
    if (phone && countryCallingCode && !isValidPhoneNumber(phone)) {
      message.error("Invalid Phone number format")
    } else {
      const countryCallingCode = parsePhoneNumber(phone)?.countryCallingCode


      const allFormData = {
        "firstname": firstname,
        "middlename": "",
        "lastname": lastname,
        "email": email,
        "phone": nationalNumber ? nationalNumber : "",
        "countrycode": countryCallingCode ? countryCallingCode : "",
        "usertype": usertype ? usertype : "",
        "username": username ? username : ""
      }
      try {
        this.setState({ loading: true });
        if (this.state.createUser) {
          let response = await userListService.createInternalUser(allFormData);
          if (response.data.status === "success") {
            message.success(response.data.message);
            this.setState({ loading: false, modalVisible: false })
            this.getUserlist()
          } else {
            this.setState({ loading: false })
            message.error(response.data.message);
          }
        }

      } catch (error) {
        errorMessage(error)
        this.setState({ loading: false })
      }


    }
  }
  render() {

    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      middlename: formikFormalidation.middlename,
      lastname: formikFormalidation.lastnamedr,
      email: formikFormalidation.emaildr,
      username: formikFormalidation.username,
      usertype: formikFormalidation.usertype,
      phone: formikFormalidation.phone,

    })

    return (


      <AfterLoginLayout>

        {this.state.load ?

          <div className="container-fluid px-5">
            <div>
              <div style={{ 'width': "100%" }} className="row">
                <div className="pb-3 col-lg-12 col-md-12 col-sm-12">
                  <h3 className="text-left">Add New Users</h3>
                </div>
              </div>

            </div>

            <div className='p-5 profilescreen '>

              <Formik
                validationSchema={schema}
                initialValues={this.state.userTypeInfo}
                validate={values => {
                  const errors = {};
                  if (!values.phone) {
                    errors.phone = "Mobile number cannot be empty.";
                  } else {
                    const countryCallingCode = parsePhoneNumber(values.phone)?.countryCallingCode;
                    if (!countryCallingCode || !isValidPhoneNumber(values.phone)) {
                      errors.phone = 'Invalid Mobile number format!';
                    }
                  }
                  return errors;
                }}

                onSubmit={(values) => {
                  // console.log("values", values)
                  // const countryCallingCode = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).countryCallingCode
                  // const nationalNumber = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).nationalNumber
                  // this.setState({ formData: values });
                  // const allFormData = {
                  //   formData: this.state.formData,
                  //   countryCallingCode: "+" + countryCallingCode,
                  //   nationalNumber: nationalNumber,
                  // }

                  // console.log("values", values)
                  // await userListService.createInternalUser(allFormData);
                  this.onFinish(values)
                }
                }


              >
                {(({ values, errors, handleSubmit, touched, handleChange, handleBlur }) => (
                  <Form noValidate onSubmit={handleSubmit}>

                    <div className='col-12'>
                      <div className='row'>

                        <div className='col-5 form-group mr-2'>
                          <label className="mb-1 font-weight-bold text-dark">
                            First Name<span style={{ color: "red" }}>*</span>
                          </label>

                          <Form.Control
                            name="firstname"
                            onChange={handleChange}
                            value={values.firstname}
                            isInvalid={errors.firstname && touched.firstname}

                          />

                          {
                            errors.firstname && touched.firstname && (
                              <div className="text-danger">{errors.firstname}</div>
                            )
                          }
                        </div>
                        <div className='col-5 form-group mr-2'>
                          <label className="mb-1 font-weight-bold">Last Name<span style={{ color: "red" }}>*</span></label>

                          <Form.Control
                            name='lastname' required
                            onChange={handleChange}
                            value={values.lastname}
                            isInvalid={errors.lastname && touched.lastname}
                          />
                          {
                            errors.lastname && touched.lastname && (
                              <div className="text-danger">{errors.lastname}</div>
                            )
                          }

                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-5 form-group mr-2'>
                          <label className="mb-1 font-weight-bold">Email<span style={{ color: "red" }}>*</span></label>
                          <Form.Control isInvalid={errors.email && touched.email} autoComplete='off' className='shadow-none'
                            onChange={handleChange} name='email' value={values.email} />
                          {errors.email && touched.email && (<div className="text-danger">{errors.email}</div>)}

                        </div>
                        <div className='col-5 form-group mr-2'>

                          <label className='font-weight-bold mb-1'>Mobile<span style={{ color: "red" }}>*</span></label>
                          <Form.Control as={PhoneInput} className="w-100"
                            label="Mobile" required
                            name="phone"
                            placeholder="Enter Mobile number"
                            // style={values.phone && isValidPhoneNumber(values.phone) ? { border: '1px solid #d9d9d9' } : { border: '1px solid red' }}
                            international
                            defaultCountry="IN"
                            countries={['US', 'IN']}
                            countryCallingCodeEditable={false}
                            limitMaxLength={true}
                            value={values.phone}
                            onChange={e => values.phone = e}
                            onBlur={handleBlur}
                            isInvalid={errors.phone && touched.phone}
                          />
                          {errors.phone && touched.phone && <div className="text-danger">{errors.phone}</div>}

                        </div>
                      </div>


                      <div className='row'>
                        <div className='col-5 form-group mr-2'>
                          <label className="mb-1 font-weight-bold text-dark">
                            User Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <Form.Control isInvalid={errors.username && touched.username} autoComplete='off' className='shadow-none'
                            name='username' value={values.username} onChange={handleChange} />
                          {errors.username && touched.username && (<div className="text-danger">{errors.username}</div>)}


                        </div>
                        <div className='col-5 form-group mr-2'>
                          <label className="mb-1 font-weight-bold">User Type<span style={{ color: "red" }}>*</span></label>
                          <select className='form-control shadow-none'
                            onChange={handleChange}
                            name='usertype'
                            value={values.usertype}

                          >
                            <option value=''>select</option>
                            <option value={Role.admin.name}>{Role.admin.name}</option>
                            <option value={Role.internaluser.name}>{Role.internaluser.name}</option>
                            {errors.usertype && <div className="text-danger">{errors.usertype}</div>}
                          </select>
                        </div>
                      </div>

                    </div>




                    {(() => {
                      if (this.state.createUser) {
                        return <button className='btn btn-primary' type="submit"> Create User</button>
                      }
                    })()}
                  </Form>
                ))}

              </Formik>
            </div>

          </div>
          : <div className='container d-flex justify-content-center align-items-center' style={{}}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
      </AfterLoginLayout>
    )
  }
}

export default AddUser
import { initialDataAction } from '../../actions/general/types'

const initialState = {
  loading: false,
  stages: [],
  specialities: [],
  masters: []
}

const InitialDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case initialDataAction.DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case initialDataAction.DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        stages: action.payload.stages,
        specialities: action.payload.specialities,
        masters: action.payload.masters
      }
    case initialDataAction.DATA_FAILURE:
      return {
        loading: false,
        stages: [],
        specialities: [],
        masters: []
      }
    default: return state
  }
}

export default InitialDataReducer
import React, { Component } from 'react'
import OtpInput from '../../../component/shared/otp/index';
import './otp.style.css';
import { resendOtp, validateOTP } from './otp.service';
import { message } from 'antd';
import { FullLayout } from '../../shared';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Col, Container, Row } from 'react-bootstrap';
import OtpTimer from './OtpTimer';

export class Otp extends Component {
  constructor(props) {
    super(props);
    if (props.location) {
      if (typeof props.location.state === "undefined") {
        props.history.push('/')
      }
    }

    this.state = {
      phoneotp: '',
      emailotp: '',
      numInputs: 6,
      separator: '-',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      isInputSecure: false,
      minLength: 0,
      maxLength: 40,
      placeholder: '',
      data: '',
      emailotpsent: false,
      phoneotpsent: false,
      user_id: '',
      counter:45,
    };
  }

  handleEmailOtpChange = (emailotp) => {
    this.setState({ emailotp });
  };

  handlePhoneOtpChange = (phoneotp) => {
    this.setState({ phoneotp });
  };

  clearPhoneOtp = () => {
    this.setState({ phoneotp: '' });
  };

  clearEmailOtp = () => {
    this.setState({ emailotp: '' });
  };
  checkValidation = () => {
    let errors = false
    if (this.state.phoneotpsent && this.state.emailotpsent) {
      if ((this.state.emailotp.length < this.state.numInputs) && (this.state.phoneotp.length < this.state.numInputs)) {
        message.destroy("")
        message.error("Please enter both Email & Phone OTP and proceed")
        errors = true
      }
      else if (this.state.emailotp.length < this.state.numInputs) {
        message.destroy("")
        message.error("Please enter Email OTP and proceed")
        errors = true
      } else if (this.state.phoneotp.length < this.state.numInputs) {
        message.destroy("")
        message.error("Please enter Phone OTP and proceed")
        errors = true
      }

      if (parseInt(this.state.emailotp) === 0 || this.state.emailotp === "000000" || parseInt(this.state.phoneotp) === 0 || this.state.phoneotp === "000000") {
        message.destroy("")
        message.error("Please enter Valid OTP")
        errors = true;
      }
    }
    return errors
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.checkValidation()) {
      validateOTP(this.props, this.state)
    }
  };

  handleResendOtp = async () => {
    const id = this.props && this.props.location.state && this.props.location.state.response.user_id
   const status = await resendOtp(id)
   if(status === "success"){
    this.setState({counter:45})
   }
  }

  handleCountValue = (val) => {
    this.setState({counter:this.state.counter - 1})
  }

  componentDidMount() {
    this.setState({
      emailotpsent: this.props.location.state && this.props.location.state.response && this.props.location.state.response.emailotpsent,
      phoneotpsent: this.props.location.state && this.props.location.state.response && this.props.location.state.response.phoneotpsent,
      user_id: this.props.location.state && this.props.location.state.response && this.props.location.state.response.user_id,
    })
    if(this.state.counter > 0){
      setInterval(() => this.setState({counter:this.state.counter-1}),1000)
    }
  }

  render() {
    const {
      phoneotp,
      emailotp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      isInputSecure,
      placeholder,
      emailotpsent,
      phoneotpsent,
    } = this.state;
    return (
      <FullLayout>
        <Container>
          <Row>
            <Col lg={8} md={6} sm={12}>
              &nbsp;
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Card className="p-5 rightcol">
                <Card.Header as="h2" className="px-2 card-header">
                  OTP Verification
                </Card.Header>
                <form onSubmit={this.handleSubmit}>
                  {(() => {
                    if (emailotpsent) {
                      return (
                        <div>
                          <p>Enter email verification code</p>
                          <OtpInput
                            inputStyle="inputStyle"
                            numInputs={numInputs}
                            isDisabled={isDisabled}
                            hasErrored={hasErrored}
                            errorStyle="error"
                            onChange={this.handleEmailOtpChange}
                            separator={<span>{separator}</span>}
                            isInputNum={isInputNum}
                            isInputSecure={isInputSecure}
                            shouldAutoFocus
                            value={emailotp}
                            placeholder={placeholder}
                          />
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (phoneotpsent) {
                      return (
                        <div className="mt-2">
                          <p>Enter phone verification code</p>
                          <OtpInput
                            inputStyle="inputStyle"
                            numInputs={numInputs}
                            isDisabled={isDisabled}
                            hasErrored={hasErrored}
                            errorStyle="error"
                            onChange={this.handlePhoneOtpChange}
                            separator={<span>{separator}</span>}
                            isInputNum={isInputNum}
                            isInputSecure={isInputSecure}
                            value={phoneotp}
                            placeholder={placeholder}
                            
                          />
                        </div>
                      );
                    }
                  })()}
                  <p className='font-weight-bold mt-4' style={{fontSize:"14px"}}>{
                    this.props.location.state && this.props.location.state.response && (this.props.location.state.response.emailotpsent || this.props.location.state.response.phoneotpsent) && this.state.counter > 0 
                    ? <OtpTimer  handleCount = {this.handleCountValue} count={this.state.counter} />:<span>Didn't received an OTP?<span onClick={() => this.handleResendOtp()} className='ml-2' style={{color:"#143C8C",cursor:"pointer"}}>ResendOTP</span></span>
                  }</p>
                  {(() => {
                    if (phoneotpsent || emailotpsent) {
                      return (
                        <div className="d-flex justify-content-center ml-2 mr-2 align-items-center w-100 mt-4">
                          <button className="btn btn-primary w-50 ">
                            SUBMIT OTP
                          </button>
                        </div>
                      );
                    }
                  })()}
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
      </FullLayout>

    )
  }
}

export default Otp;
import React from 'react'
import { authenticationService, logout } from '../component/general/login/login.service';
import Role from '../constants/role'
import { NONAUTHROUTE, AUTHROUTE } from "../constants/RouterPath";
import { matchPath } from "react-router";

export class Home extends React.Component {
  constructor(props) {
    super(props);
    let match = this.getPreviousLocation(props)
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {
      logout()
      props.history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
    }
    if (currentUser && currentUser.usertype === Role.patient.name) {
      props.history.push({
        pathname: AUTHROUTE.PATIENT.DASHBOARDPATH,
        state: {
          redirect_url: match,
        }
      });
    }
    if (currentUser && currentUser.usertype === Role.provider.name) {
      props.history.push(AUTHROUTE.PROVIDER.DASHBOARDPATH)
    }
    if (currentUser) {
      if (currentUser.usertype === Role.internaluser.name || currentUser.usertype === Role.admin.name) {
        props.history.push({
          pathname: AUTHROUTE.INTERNALUSER.DASHBOARDPATH,
          state: {
            redirect_url: match,
          }
        });
      }
    }
    if (currentUser && currentUser.usertype === Role.localprovider.name) {
      props.history.push(AUTHROUTE.LOCALPROVIDER.DASHBOARDPATH)
    }
  }

  getPreviousLocation(props) {
    if (props && props.location && props.location.state) {
      let _state = props.location.state
      if (_state && _state.from && _state.from.pathname) {
        const match = matchPath(_state.from.pathname, {
          path: NONAUTHROUTE.PATIENT.ENQUIRY_DETAILS,
          exact: true,
          strict: false
        });
        return match
      }
    }
  }
  render() {
    return <h1>HomePage</h1>
  }
}

export default Home

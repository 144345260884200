import React from 'react';
import '../appointment/Appointment.css';
import { Button } from 'react-bootstrap';
import OpinionRequest from '../dashboard/OpinionRequest';
import { authenticationService } from '../../general/login/login.service';
import { BsArrowRight } from 'react-icons/bs';

export class NeedHelp extends React.Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        this.state = {
            currentUser: authenticationService.currentUserValue,
            openModal: false
        }
    }

    handleShow = () => { this.setState({ openModal: true }) };
    handleClose = () => { this.setState({ openModal: false }) };


    render() {
        return (
            <React.Fragment>
                <div className='leftdown px-2'>
                    <div className='examsteth'>
                        <div className='needtext'>Need a second opinion ?</div>
                        <div className='needsubtext'>Consult with our doctors
                            for any health concerns you have</div>
                        <Button variant='link' onClick={this.handleShow}><span className='needendtext'>Get an opinion</span> {this.state.openModal} <BsArrowRight /></Button>
                    </div>
                    {this.state.openModal && <OpinionRequest displayname={this.state.currentUser && this.state.currentUser.displayname} patient_id={this.state.currentUser && this.state.currentUser.usertype_id} openModal={this.state.openModal} setOpenModal={this.handleClose} />}
                </div>
                {/* </Col> */}
            </React.Fragment >)
    }
}

export default NeedHelp
import React from 'react';
import { connect } from 'react-redux'
import { getLocalProviderListDetails } from '../../../actions/admin/LocalProviderListAction';
import { message } from 'antd';
// import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import ProviderListService from '../../../services/admin/LocalProviderList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import 'react-phone-number-input/style.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { formikFormalidation } from '../../general/formvalidator/validationFroms';
import * as yup from 'yup'


export class CreateLocalProvider extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: '',
      userTypeInfo: { firstname: "", middlename: "", lastname: "", licenseid: "", email: "", mobilenumber: "", usertype: "", username: "" }
    }
  }

  onSuccess = async () => {
    this.props.onSuccess()
  }

  onFinish = async (values) => {
    const { mobilenumber = "" } = values;
    const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
    const nationalNumber = parsePhoneNumber(mobilenumber)?.nationalNumber

    if (mobilenumber && countryCallingCode && !isValidPhoneNumber(mobilenumber)) {
      message.error("Invalid Phone number format")
    } else {
      const allFormData = {
        formData: values,
        countryCallingCode: countryCallingCode && "+" + countryCallingCode,
        nationalNumber: nationalNumber
      }
      try {
        this.setState({ loading: true });
        let response = await ProviderListService.createNewLocalProvider(allFormData);
        if (response.data.status === "success") {
          this.onSuccess()
          message.success(response.data.message);
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })
          message.error(response.data.message);
        }
      } catch (error) {
        errorMessage(error)
        this.setState({ loading: false })
      }
    }
  }
  onFinishFailed = values => {
  }

  render() {
    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      middlename: formikFormalidation.middlename,
      lastname: formikFormalidation.lastnamedr,
      licenseid: formikFormalidation.licenseid,
      mobilenumber: formikFormalidation.mobilenumber,
      email: formikFormalidation.emaildr,
      username: formikFormalidation.username,

    });

    return (<React.Fragment>
      <div className="card-container">
        {this.state.loading &&
          <div className='d-flex justify-content-center flex-nowrap'>
            <div className='position-absolute mx-3 p-4' variant="primary" style={{ marginTop: '290px' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        }
        <Formik
          validationSchema={schema}
          initialValues={this.state.userTypeInfo}
          validate={values => {
            const errors = {};

            if (!values.mobilenumber) {
              errors.mobilenumber = "Mobile number cannot be empty.";
            } else {
              const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
              if (!countryCallingCode || !isValidPhoneNumber(values.mobilenumber)) {
                errors.mobilenumber = 'Invalid Mobile number format!';
              }
            }
            return errors;
          }}
          onSubmit={async (values) => this.onFinish(values)}


        >
          {(({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
            <Form onSubmit={handleSubmit}>

              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    First Name  <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    name="firstname" onChange={handleChange} value={values.firstname}
                    isInvalid={errors.firstname && touched.firstname}
                    onBlur={handleBlur}
                  />

                  {
                    errors.firstname && touched.firstname && (
                      <div className="invalid-feedback">{errors.firstname}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    Middle Name
                  </label>
                  <Form.Control type="text"
                    name="middlename"
                    value={values.middlename}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.middlename && touched.middlename} />
                  {
                    errors.middlename && touched.middlename && (
                      <div className="invalid-feedback">{errors.middlename}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold"> Last Name  <span style={{ color: "red" }}>*</span> </label>
                  <Form.Control
                    onChange={handleChange}
                    name='lastname' 
                    value={values.lastname}
                    isInvalid={errors.lastname && touched.lastname} 
                    onBlur={handleBlur}
                    />                   
                  {
                    errors.lastname && touched.lastname && (
                      <div className="invalid-feedback">{errors.lastname}</div>
                    )
                  }

                </div>
              </div>


              <div className='row'>
                <div className='col form-group mr-2' >
                  <label className="mb-1 font-weight-bold"> Licenseid  <span style={{ color: "red" }}>*</span> </label>
                  <Form.Control
                    onChange={handleChange}
                    name='licenseid' value={values.licenseid}
                    onBlur={handleBlur}
                    isInvalid={errors.licenseid && touched.licenseid} />
                  {
                    errors.licenseid && touched.licenseid && (
                      <div className="invalid-feedback">{errors.licenseid}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold"> Mobile Number  <span style={{ color: "red" }}>*</span> </label>
                  <Form.Control as={PhoneInput}
                    name="mobilenumber"
                    placeholder="Mobile number *"
                    international
                    defaultCountry="IN"
                    countries={['US', 'IN']}
                    countryCallingCodeEditable={false}
                    limitMaxLength={true} required
                    value={values.mobilenumber}

                    onChange={e => { values.mobilenumber = e; }}
                    onBlur={handleBlur}
                    isInvalid={errors.mobilenumber && touched.mobilenumber}
                  />
                  {
                    errors.mobilenumber && touched.mobilenumber && (
                      <div className="invalid-feedback">{errors.mobilenumber}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    Email  <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    name="email" onChange={handleChange} value={values.email}
                    isInvalid={errors.email && touched.email} 
                    onBlur={handleBlur}
                    />                    
                  {
                    errors.email && touched.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )
                  }

                </div>
              </div>
              <div className='row'>
                <div className='col form-group mr-2'>
                  <label className="mb-1 font-weight-bold text-dark">
                    User Name  <span style={{ color: "red" }}>*</span>
                  </label>
                  <Form.Control
                    name="username" onChange={handleChange} 
                    onBlur={handleBlur}
                    value={values.username}
                    isInvalid={errors.username && touched.username} />                    
                  {
                    errors.username && touched.username && (
                      <div className="invalid-feedback">{errors.username}</div>
                    )
                  }
                </div>              
              </div>

              <Button
                variant="primary"
                type="submit"
                style={{ width: "100%", marginTop: "10px", marginLeft: "-5px", fontFamily: "manrope" }}
              >
                Submit
              </Button>
            </Form>
          ))}

        </Formik>      

      </div>

    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLocalProviderListDetails: () => dispatch(getLocalProviderListDetails())
  }
}
const mapStateToProps = state => {
  return {
    providersStore: state.providerUserList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLocalProvider)
import React, { Component } from 'react'
import { CardGroup, Card, Container } from 'react-bootstrap'
import './Section3.css';
import clock from "../../../../assets/images/patients/clock.png";

import Enquiryicon from "../../../../assets/images/patients/Enquiryicon.png";
import Scheduleicon from "../../../../assets/images/patients/Scheduleicon.png";
import Opinionicon from "../../../../assets/images/patients/Opinionicon.png";





export class Section3 extends Component {
    render() {
        return (
            <div className='bg-white mvsec section-3 py-5'>
                <Container>
                    <h5>QUICK SOLUTION</h5>
                    <Card.Title>
                        <Card.Img
                            className='clock'
                            src={clock}
                            alt='doctor'
                            style={{ height: "30px", width: "15px" }}
                        />

                    </Card.Title>
                    <h2 className=' head-1'>3 steps to get your solution</h2>
                    <CardGroup className='cardGroup'>
                        <Card className='border-0 card'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title>
                                    <Card.Img
                                        src={Enquiryicon}
                                        alt='doctor'
                                        style={{ height: "40px", width: "40px" }}

                                    />

                                </Card.Title>
                                <Card.Text>
                                    <h6>Make an Enquiry</h6>
                                    <p>We’re here to help whenever you feel ill,<br /> so make an enquiry and we’ll help you</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='ml-3 border-0'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title>
                                    <Card.Img
                                        src={Scheduleicon}
                                        alt='doctor'
                                        style={{ height: "40px", width: "37px" }}
                                    />

                                </Card.Title>
                                <Card.Text>
                                    <h6>Schedule appointment</h6>
                                    <p>We’ll check your opinion and <br /> connect you to the best doctors </p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className='ml-3 border-0'>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <Card.Title>
                                    <Card.Img
                                        src={Opinionicon}
                                        alt='doctor'
                                        style={{ height: "40px", width: "40px" }}
                                    />

                                </Card.Title>
                                <Card.Text>
                                    <h6>Get an opinion</h6>
                                    <p>From seasonal allergies to <br /> treatments, we have the resource.</p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Container>
            </div>
        )
    }
}

export default Section3
import React from 'react';
import { connect } from 'react-redux'
import { getEnquiryListDetails } from '../../../actions/patient/EnquiriesAction'
import { Badge, Button, Dropdown } from 'react-bootstrap'
import { authenticationService } from '../../general/login/login.service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { get_user_obj, get_patient_obj, get_assignee, get_formated_date_only, formateName } from '../enquiry/EnquiryUtils';
import { getStages, getSpecialities } from '../../../utils/PersistentData';
import { RiCalendar2Line } from "react-icons/ri";
import './dashboard.css'
import BootstrapTable from 'react-bootstrap-table-next';
import OpinionRequest from './OpinionRequest';


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button className='threedotbutton' variant='link'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </Button>
));


export class MyRequests extends React.Component {
  formRef = React.createRef();
  assigneeFormRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      patient_id: this.props && this.props.patient_id,
      all_specialities: [],
      internalusers: [],
      stages: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: true,
      showMultiSectionOption: false,
      showCreate: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      currentUser: {},
      assignee_modal: false,
      modalVisible: false,
      viewer_visible: false,
      selected_enquiry: {},
      provider_user_obj: {},
      enquiry_obj: {},
      enquiries: [],
    }
  }


  handleCancel = () => {
    this.setState({ assignee_modal: false });
    this.setState({ modalVisible: false });
  };

  handleStatus = (response) => {
    //console.log(response + "response");
  }

  getEnquiries = () => {
    this.handleCancel()
    this.setState({ loading: true });
    //this.props.getEnquiryListDetails(this.state.currentUser.usertype_id).then((response) => {
    this.props && this.props.getEnquiryListDetails(this.props && this.props.patient_id).then((response) => {
      let enquiries = (response.enquiries).slice(0, this.props.pageSize)
      let stages = getStages() || []
      let internalusers = response.internalusers
      let all_specialities = getSpecialities() || []
      this.setState({ enquiries, stages, internalusers, all_specialities })
    }).catch(err => {
      errorMessage(err)
      this.setState({ loading: false });
    })
  }
  setAssignee = () => {
    this.setState({ assignee_modal: true })
  };

  view_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props && this.props.history && this.props.history.push({
          pathname: "/my/enquiries/view/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            enquiriesEdit: record.consultationInfo.length > 0 ? false : true

            // prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }


  edit_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/enquiry/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            prevPath: this.props && this.props.location && this.props.location.pathname,
          }
        });
      }
    }, 1);
  }
  pick_slot_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_enquiry: record, enquiry_obj: record && record.enquiryInfo, });
        const id = record && record.enquiryInfo && record.enquiryInfo.enquiryexternalid
        this.props.history.push({
          pathname: "/patient/pickslot/" + id,

          state: {
            selected_enquiry: record,
            enquiry_obj: record && record.enquiryInfo,
            all_specialities: this.state.all_specialities,
            internalusers: this.state.internalusers,
            stages: this.state.stages,
            assignee: get_assignee(record, this.state.internalusers),
            patient_obj: get_patient_obj(record),
            patient_user_obj: get_user_obj(record),
            currentUser: this.state.currentUser,
            prevPath: this.props && this.props.location && this.props.location.pathname
          }
        });
      }
    }, 1);
  }

  componentDidMount() {
    this.getEnquiries()
    const currentUser = authenticationService.currentUserValue;
    this.setState({
      showMultiSectionOption: this.props && this.props.showMultiSectionOption,
      showCreate: (this.props && this.props.showCreate) || false,
      currentUser: currentUser,
      patient_id: this.props && this.props.patient_id,
    })
  }
  handleShow = () => { this.setState({ openModal: true }) };
  handleClose = () => { this.setState({ openModal: false }) };

  render() {

    // const { getLength } = this.props
    // getLength(this.state.enquiries.length);

    const columns = [
      {
        text: 'PATIENT',
        dataField: 'patientInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              return formateName(record.patientInfo.firstname)
            })()}
          </>
        ),
      },
      {
        text: 'DATE',
        dataField: 'scheduleDate',
        formatter: (text, record) => (
          <>
            {(() => {
              let time_format = get_formated_date_only(record.enquiryInfo.createddate)
              return (<React.Fragment>
                <span>{time_format}</span>
              </React.Fragment>
              )
            })()}
          </>
        ),
      },
      {
        text: 'DOCTOR',
        dataField: 'providerInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record?.providerInfo?.status === "Active")
                return (<React.Fragment>
                  <div >
                    {formateName(record.providerInfo.firstname)}
                  </div>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <div className='text-center'>
                    <p>-</p>
                  </div>
                </React.Fragment>
                )
            })()}
          </>
        ),
      },
      {
        text: 'STATUS',
        dataField: 'providerInfo.status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record?.providerInfo?.status === "Active")
                return (<React.Fragment>
                  <div>
                    <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Assigned</Badge>
                  </div>
                </React.Fragment>
                )
              else
                return (<React.Fragment>
                  <div>
                    <Badge className='px-2' style={{ backgroundColor: '#FFF7BC', color: '#E45826', fontSize: '14px' }}>Not yet</Badge>
                  </div>
                </React.Fragment>
                )
            })()}
          </>
        ),
      },
      /* {
         text: '',
         dataField: '',
         formatter: (text, record) => (
           <>
             {(() => {
               if (record?.providerInfo?.status === "Active")
                 return (<React.Fragment>
                   <div>
                     <Button style={{ alignItems: 'center' }} variant='link' onClick={() => this.pick_slot_record(record)} > Pick a slot </Button>
                   </div>
                 </React.Fragment>
                 )
             })()}
           </>
         ),
         // key: ['patientInfo', '_id'],
       } , */

      {
        text: ' ',
        dataField: '',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.providerInfo) {
                return (<React.Fragment>
                  <div className='actionHolder'><Button style={{ alignItems: 'center', padding: "0" }} variant='link' onClick={() => this.pick_slot_record(record)} > Pick a slot </Button>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              <Button variant='link' onClick={() => this.view_record(record)}>View</Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }
              else {
                return (<React.Fragment>

                  <div className='actionHolder'><p style={{ width: "70px" }}>      _     </p>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              <Button variant='link' onClick={() => this.view_record(record)}>View</Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }

            })()}
          </>
        ),
      },
    ]
    return (
      <React.Fragment>
        {this.state.enquiries.length !== 0 ?
          <div>
            <h4 className='text-left mt-5 font-weight-bold'>My requests</h4>
            <p className='text-left'>Select item to manage and view details</p>
            <BootstrapTable keyField='enquiryInfo._id'
              wrapperClasses="mytable-responsive"
              data={(this.state.enquiries).slice(0, 5)}
              hover={false}
              columns={columns}
              bordered={false}
            />

            <div className='mb-4' style={{ display: "flex", justifyContent: "center" }}>
              <u><a className='align-items-center font-weight-bold ostextcolor' href='/my/enquiries'>View more</a></u>
            </div>
          </div>
          :
          <div>
            {this.state.loading ? "" :
              <div class="card">
                <div class="card-body text-center">
                  <RiCalendar2Line size={60} className='text-secondary' />
                  <h6>No requests</h6>
                  <small><p className='text-secondary'>Create an opinion request, share your health concerns, and connect with expert doctors.
                    Meanwhile you can<Button variant='link' onClick={this.handleShow}>create opinion request.{this.state.openModal}</Button></p></small>
                </div>
                {this.state.openModal && <OpinionRequest displayname={this.state.currentUser && this.state.currentUser.displayname} patient_id={this.state.currentUser && this.state.currentUser.usertype_id} openModal={this.state.openModal} setOpenModal={this.handleClose} />}

              </div>}
          </div>
        }
      </React.Fragment>)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEnquiryListDetails: () => dispatch(getEnquiryListDetails(ownProps))
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    enquiryStore: state.enquiryList,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRequests)
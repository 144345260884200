import React from "react";
import { connect } from "react-redux";

import { getPatientListDetails } from "../../../actions/localprovider/PatientListAction";
import { message } from "antd";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import localPatientListService from "../../../services/localprovider/PatientList.Service";
import { errorMessage } from "../../../utils/ServiceHandler";

import {
  BloodGroupComponent,
  GenderComponentrequired,
  MaritalStatusComponent,
} from "../../patient/profile/Utils";
import { Button, Form, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { formikFormalidation } from "../../general/formvalidator/validationFroms";
import * as yup from "yup";
import moment from "moment";
export class CreatePatient extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 },
  };
  constructor(props) {
    super(props);
    this.state = {

      loading: false,
      value: "",
      createPatientInfo: {
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        mobilenumber: "",
        dob: "",

      },
      gender: '',
      bloodgroup: "",
      maritalstatus: '',
      genderError: false
    };
  }
  genderCheck = () => {
    this.setState({ genderError: true });
  }

  onSuccess = async () => {
    this.props.onSuccess();
  };

  getInitalValues(user) {
    const { firstname, middlename, lastname, email, mobilenumber, countrycode, localPatientInfo } = user || {};

    const { bloodgroup, gender, dob, maritalstatus, height, weight, location } = localPatientInfo?.[localPatientInfo.length - 1] || {}
    const ddmmyy = moment(dob, 'MM-DD-YYYY').format('YYYY-MM-DD');
    this.setState({
      user: {
        ...this.state.user, firstname: firstname, lastname: lastname,
        email: email, middlename: middlename, loading: true,
        mobilenumber: `${countrycode}${mobilenumber}`, countrycode: countrycode, dob: ddmmyy, height: height ? height : "",
        weight: weight ? weight : "", location: location ? location : ""
      }
      , bloodgroup: bloodgroup ? bloodgroup : "", gender: gender ? gender : "", maritalstatus: maritalstatus ? maritalstatus : ""
    })
  }

  handleOnSelectGender = (value) => {
    this.setState({ gender: value });
  };
  handleOnSelectBloodGroup = (value) => {
    this.setState({ bloodgroup: value })
  };

  handleOnSelectMaritalStatus = (value) => {
    this.setState({ maritalstatus: value })
  };

  onFinish = async (values) => {
    const { mobilenumber = "" } = values;
    const countryCallingCode =
      parsePhoneNumber(mobilenumber)?.countryCallingCode;
    const nationalNumber = parsePhoneNumber(mobilenumber)?.nationalNumber;
    if (
      mobilenumber &&
      countryCallingCode &&
      !isValidPhoneNumber(mobilenumber)
    ) {
      message.error("Invalid Phone number format");
    } else {
      const { gender, bloodgroup, maritalstatus } = this.state;
      const { dob, lastname, firstname, middlename, weight, height, email, location } = values;
      const allFormData = {
        countrycode: countryCallingCode && "+" + countryCallingCode,
        phone: nationalNumber,
        bloodgroup: bloodgroup,
        maritalstatus: maritalstatus,
        dateOfBirth: dob,
        gender: gender,
        lastname: lastname,
        firstname: firstname,
        middlename: middlename,
        weight: weight,
        email: email,
        height: height,
        location: location,

      };
      try {
        this.setState({ loading: true });
        let response = await localPatientListService.createLocalPatient(
          allFormData
        );
        if (response.data.status === "success") {
          this.onSuccess();
          message.success(response.data.message);
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          // message.error(response.data.message);
        }
      } catch (error) {
        errorMessage(error);
        this.setState({ loading: false });
      }
    }
  };




  componentDidMount() {
    // this.props.getPatientListDetails().then((patientData) => {
    //   this.getInitalValues(patientData?.patients)
    // }).catch(err => {
    //   errorMessage(err)
    // })
  }

  render() {
    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      middlename: formikFormalidation.middlename,
      lastname: formikFormalidation.lastnamedr,
      mobilenumber: formikFormalidation.mobilenumber,
      email: formikFormalidation.email,
      dob: formikFormalidation.dob,
      location: formikFormalidation.location,
      weight: formikFormalidation.weight,
      height: formikFormalidation.height,
    });

    return (
      <React.Fragment>
        <div className="card-container">
          {this.state.loading &&
            <div className='d-flex justify-content-center flex-nowrap'>
              <div className='position-absolute mx-3 p-4' variant="primary">
                <Spinner animation="border" variant="primary" className='spin' />
              </div>
            </div>
          }
          <Formik
            validationSchema={schema}
            initialValues={this.state.createPatientInfo}
            validate={values => {
              const errors = {};

              if (!values.mobilenumber) {
                errors.mobilenumber = "Mobile number cannot be empty.";
              } else {
                const countryCallingCode = parsePhoneNumber(values.mobilenumber)?.countryCallingCode;
                if (!countryCallingCode || !isValidPhoneNumber(values.mobilenumber)) {
                  errors.mobilenumber = 'Invalid Mobile number format!';
                }
              }

              return errors;
            }}

            onSubmit={async (values) => { this.onFinish(values) }}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold text-dark">
                      First Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Control
                      name="firstname"
                      onChange={handleChange}
                      value={values.firstname}
                      isInvalid={errors.firstname && touched.firstname}
                    />

                    {errors.firstname && touched.firstname && (
                      <div className="invalid-feedback">
                        {errors.firstname}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold text-dark">
                      Middle Name
                    </label>
                    <Form.Control
                      type="text"
                      name='middlename'
                      value={values.middlename}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.middlename && touched.middlename}
                    />

                    {errors.middlename && touched.middlename && (
                      <div className="invalid-feedback">
                        {errors.middlename}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold">
                      {" "}
                      Last Name <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <Form.Control
                      onChange={handleChange}
                      name="lastname"
                      value={values.lastname}
                      isInvalid={errors.lastname && touched.lastname}
                    />

                    {errors.lastname && touched.lastname && (
                      <div className="invalid-feedback">
                        {errors.lastname}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold">
                      {" "}
                      Mobile Number <span style={{ color: "red" }}>
                        *
                      </span>{" "}
                    </label>
                    <Form.Control
                      as={PhoneInput}
                      name="mobilenumber"
                      placeholder="Mobile number *"
                      international
                      defaultCountry="US"
                      countries={["US", "IN"]}
                      countryCallingCodeEditable={false}
                      limitMaxLength={true}
                      required
                      value={values.mobilenumber}
                      onChange={(e) => {
                        values.mobilenumber = e;
                      }}
                      onBlur={handleBlur}
                      isInvalid={errors.mobilenumber && touched.mobilenumber}
                    />
                    {/* <span className={errors.mobilenumber && touched.mobilenumber ? 'invalid short-label' : 'short-label'}></span> */}
                    {errors.mobilenumber && touched.mobilenumber && (
                      <div className="invalid-feedback">
                        {errors.mobilenumber}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold text-dark">
                      Email
                    </label>
                    <Form.Control
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={errors.email && touched.email}
                    />

                    {errors.email && touched.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold">
                      Date Of Birth<span style={{ color: "red" }}>*</span>
                    </label>
                    <Form.Control
                      isInvalid={errors.dob && touched.dob}
                      autoComplete="off"
                      type="date" max={moment().format("YYYY-MM-DD")}
                      name="dob"
                      value={values.dob}
                      onChange={handleChange}
                      className="shadow-none"
                    />
                    {errors.dob && touched.dob && (
                      <div className="invalid-feedback">{errors.dob}</div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col form-group mr-2">
                    <GenderComponentrequired
                      genderValue={this.state.gender}
                      handleOnSelectGender={this.handleOnSelectGender}
                    />{!this.state.gender && this.state.genderError === true && <div style={{ fontSize: "12px", color: "#E55251" }} className="mt-1 ">select gender!</div>}
                  </div>
                </div>

                <div className="row">
                  <div className="col form-group mr-2">
                    <BloodGroupComponent
                      bloodGroupValue={this.state.bloodgroup}
                      onSelectBloodGroup={this.handleOnSelectBloodGroup}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <MaritalStatusComponent
                      maritalstatusValue={this.state.maritalstatus}
                      handleOnSelectMaritalStatus={
                        this.handleOnSelectMaritalStatus
                      }
                    />
                    {/* {!maritalstatus && <div className='text-danger'>Please selecet Maritial status</div>} */}
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold">
                      Height(cm)
                    </label>
                    <Form.Control
                      isInvalid={errors.height}

                      className={`form-control shadow-none ${errors.height && "border-danger"
                        }`}
                      name="height"
                      value={values.height}
                      onChange={handleChange}
                    />
                    {errors.height && (
                      <div style={{ fontSize: "12px" }} className="text-danger">{errors.height}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold">
                      Weight(kg)
                    </label>
                    <Form.Control
                      isInvalid={errors.weight}

                      name="weight"
                      className={`form-control shadow-none ${errors.weight && "border-danger"
                        }`}
                      value={values.weight}
                      onChange={handleChange}
                    />
                    {errors.weight && (
                      <div style={{ fontSize: "12px" }} className="text-danger">{errors.weight}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col form-group mr-2">
                    <label className="mb-1 font-weight-bold">Location</label>
                    <Form.Control

                      className="shadow-none"
                      type="text"
                      name="location"
                      value={values.location}
                      onChange={handleChange}
                    />
                    {errors.location && <div style={{ fontSize: "12px" }} className="text-danger">{errors.location}</div>}
                  </div>
                </div>


                <Button
                  variant="primary" onClick={this.genderCheck}
                  type="submit"
                  style={{ width: "100%", fontFamily: "manrope", marginLeft: "-3px" }}
                >
                  Create
                </Button>
              </Form>
            )}
          </Formik>

        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPatientListDetails: () => dispatch(getPatientListDetails()),
  };
};
const mapStateToProps = (state) => {
  return {
    localpatientStore: state.localPatientList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatient);

import { storageTypes } from '../actions/types';
const state = localStorage.getItem("appState");
const initialState = state ? JSON.parse(state) : {};

function storage(state = initialState, action) {
    switch (action.type) {
        case storageTypes.ADD:
            let value = {}
            value[action.key] = action.value;
            return Object.assign({}, state, value);
        case storageTypes.DELETE:
            let actualState = Object.assign({},state);
            delete actualState[action.key]
            return Object.assign({}, actualState);
        case storageTypes.UPDATE:
            let obj = {}
            obj[action.key] = action.value;
            return Object.assign({}, state, obj);
        default:
            return state
    }
}
export default storage
import React, { Component } from 'react'
import { Row, Col, Container, Modal, Button } from 'react-bootstrap';
import GetOpinion from '../GetOpinion/GetOpinion';
import './Section1.css'

export class Section1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            newUserVal: false
        }
    }

    render() {
        const handleClose = (e) => {
            this.setState({ showModal: false })
        }
        return (
            <div className='bg-white section1 py-5'>
                <div className='mvsection'>
                    <Container>
                        <Row>
                            <Col lg="6" className='section1Col'>
                                <h1 className='mt-5 col-xs-3'>We provide the <br /><span style={{ color: '#0BC1C2' }}> best </span>online <br />consultation </h1>
                                <p className='mt-4 col-lg-12'>Our skilled doctors have tremendous experience with wide range of health
                                    issues to serve the needs of our patients. Book an appointment with us to get an opinion.</p>
                                <Button className='section btn btn-primary btn-lg my-4 px-4' style={{ fontSize: '16px' }} onClick={() => this.setState({ showModal: true, newUserVal: true })}>Get an opinion</Button>
                            </Col>
                            <Col lg="6" className='section1Col2'>
                                {/* <div>
                                <img className='appointment' src='/Appointment.png' alt='appointment' />
                            </div>
                            <div className='mt-3' style={{ marginTop: '-100px' }}>
                                <img className='consult' src='/consult.png' alt='consult' />
                            </div>
                            <div style={{ marginLeft: '-100px', marginTop: '-100px' }}>
                                <img className='onCall' src='/onCall.png' alt='onCall' />
                            </div> */}
                                <img className='consultDoctor' src='/consultDoctor.png' alt='consultDoctor' />
                            </Col>
                        </Row>
                    </Container>

                </div>
                <Modal show={this.state.showModal} onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    centered={false}
                    size="xl"
                    className='modalData'
                >
                    <Modal.Header style={{ border: "0" }} closeButton>
                    </Modal.Header>
                    <Modal.Body><GetOpinion {...this.props} handleClose={handleClose} /></Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default Section1
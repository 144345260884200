import { message } from 'antd';
import { alertActions } from '../actions/alertAction';
import { alertTypes } from '../actions/types';



export function handleAlert(store) {
    let alert = store.getState().alertMessage;
    if (alert.message) {
        switch (alert.type) {
            case alertTypes.SUCCESS:
              
                message.success(alert.message)
                break;
            case alertTypes.ERROR:
              message.error(alert.message);
                break;
            default:
            // do nothing
        }
     store.dispatch(alertActions.clear());
    }
}


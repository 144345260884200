import React, { Component } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import './Section7.css'

export class Section7 extends Component {
    render() {
        return (
            <div className='bg-white py-3 section7'>
                <Container>
                    <h6 className='py-2'>REVIEWS</h6>
                    <h3 className='pb-4'>Our patients feedback <br /> about us</h3>
                    <Card className='border-0 card' >
                        <Row className='no-gutters md-reverse'>
                            <Col md={4} lg={5}  >
                                <Card.Img variant="top" src="/videoImage.png" alt='video' />
                            </Col>
                            <Col className='feedbackbg'>
                                <Card.Body className='this-card-body lquotbg '>
                                    <div className='cardText drarrow '>
                                        <h5>  Creating exceptional customer <br /> experiences is central to ‘Second <br /> opinion’. Easy booking and rescheduling. <br />Thank you for making it simple and <br /> hassle-free.</h5>
                                        <p>Sorah Jhon</p>
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Section7
import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { AiFillCheckCircle } from "react-icons/ai";
import './Section3.css';
export class Section3 extends Component {
    render() {
        return (
            <div className='section3'>
                <Container>
                    <Row className='section3Row  '>

                        <Col lg="6" className='col1'>
                            <div className='imageholder mt-4'>
                                <img src='/YoungLady.png' alt='young-lady' />
                            </div>
                        </Col>
                        <Col lg="6" className='col2'>
                            <div className='contentholder'>
                                <p>Features</p>
                                <h3 className='text-white'>Why to choose us</h3>
                                <Row className='text-white mt-4 data1 no-gutters'>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='text-white'>Online booking</span></p>
                                    </Col>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='text-white'>Qualified Doctors</span></p>
                                    </Col>
                                </Row>

                                <Row className='text-white mt-3 data2 no-gutters'>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='text-white'>Private Support</span></p>
                                    </Col>
                                    <Col>
                                        <p><AiFillCheckCircle size={30} className='mx-2 checkCircle' /><span className='text-white'>Free followup</span></p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Section3
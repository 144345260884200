import moment from "moment-timezone";
import getSymbolFromCurrency from 'currency-symbol-map'
import { authenticationService } from '../../general/login/login.service';

export const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

export const get_speciality = (data, all_specialities) => {
    let _name = "";
    if (data && data.speciality_id) {
        const filteredResults = all_specialities.filter(obj => obj._id === data.speciality_id)
        if (filteredResults && filteredResults.length > 0) {
            _name = filteredResults[0] && filteredResults[0].name
        } else { _name = "-" }
    } else { _name = "-" }
    return _name
}
export const get_stage = (data, stages) => {
    let stage_name = "";
    if (data && data.stage_id) {
        const filteredResults = stages.filter(obj => obj._id === data.stage_id)
        if (filteredResults && filteredResults.length > 0) {
            stage_name = filteredResults[0] && filteredResults[0].stage
        } else { stage_name = "-" }
    } else { stage_name = "-" }
    return stage_name
}
export const get_assignee = (data, internalusers) => {
    let displayname = "";
    if (data && data.assignee_id) {
        const filteredResults = internalusers && internalusers.filter(obj => obj._id === data.assignee_id)
        if (filteredResults && filteredResults.length > 0) {
            displayname = filteredResults[0] && filteredResults[0].displayname
        } else { displayname = "-" }
    } else { displayname = "-" }
    return displayname
}

export const refreshPage = () => {
    window.location.reload();
}

export const get_patient_obj = (record) => {
    let _data;
    if (record && record.patientInfo) {
        _data = record.patientInfo
    }
    return _data
}
export const get_user_obj = (record) => {
    let _data;
    if (record && record.userInfo) {
        _data = record.userInfo
    }
    return _data
}
export const get_patient_user_obj = (record) => {
    let _data;
    if (record && record.patientUserInfo) {
        _data = record.patientUserInfo
    }
    return _data
}
export const get_provider_obj = (record) => {
    let _data;
    if (record && record.providerInfo) {
        _data = record.providerInfo
    }
    return _data
}
export const get_provider_user_obj = (record) => {
    let _data;
    if (record && record.users && record.users.length > 0) {
        _data = record.users[0]
    }
    return _data
}
export const get_formated_date = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).tz(authenticationService.user_timezone).format('LLL z')
        }
    }
    return parsed_date
}

const PDT = '12.30';
const EDT = '9.30';
const MDT = '11.30';
const HST = '15.30';
export const get_formated_patient_ist_datetime = (date, time, zone) => {
    let PreDate = date + " " + time;
    let values = "";
    if (zone === "PDT") values = PDT;
    if (zone === "EDT") values = EDT;
    if (zone === "MDT") values = MDT;
    if (zone === "HST") values = HST;
    let dates = moment(PreDate, 'YYYY-MM-DD hh:mm:ss A').add(values.split(".")[0], "hours").add(values.split(".")[1], "minutes").tz(authenticationService && authenticationService.user_timezone);
    return moment(dates).format("llll");
}

export const get_formated_patient_ist_date = (date, time, zone) => {
    let PreDate = date + " " + time;
    let values = "";
    if (zone === "PDT") values = PDT;
    if (zone === "EDT") values = EDT;
    if (zone === "MDT") values = MDT;
    if (zone === "HST") values = HST;
    let dates = moment(PreDate, 'YYYY-MM-DD hh:mm:ss A ').add(values.split(".")[0], "hours").add(values.split(".")[1], "minutes").tz(authenticationService && authenticationService.user_timezone);

    return moment(dates).format("MMM DD,YYYY");
}
export const new_get_formated_patient_ist_date = (date, time, zone) => {
    let PreDate = date + " " + time;
    let values = "";
    if (zone === "PDT") values = PDT;
    if (zone === "EDT") values = EDT;
    if (zone === "MDT") values = MDT;
    if (zone === "HST") values = HST;
    let dates = moment(PreDate, 'YYYY-MM-DD hh:mm:ss A ').add(values.split(".")[0], "hours").add(values.split(".")[1], "minutes").tz(authenticationService && authenticationService.user_timezone);

    return moment(dates).format();
}
export const get_formated_provider_ist_time = (date, time, zone) => {
    let PreDate = date + " " + time;
    // let values = zone;
    let dates = moment(PreDate, 'YYYY-MM-DD hh:mm:ss A ');
    // let res = dates.toLocaleString('en-US', { timeZone: 'America/New_York' })
    return moment(dates).format("hh:mm A z");
}

export const get_formated_provider_ist_date = (date, time, zone) => {
    let PreDate = date + " " + time;
    // let values = zone;
    let dates = moment(PreDate, 'YYYY-MM-DD hh:mm:ss A ');

    return moment(dates).format("MMM DD,YYYY");
}
export const get_formated_patient_ist_time = (date, time, zone) => {
    let PreDate = date + " " + time;
    let values = "";
    if (zone === "PDT") values = PDT;
    if (zone === "EDT") values = EDT;
    if (zone === "MDT") values = MDT;
    if (zone === "HST") values = HST;
    let dates = moment(PreDate, 'YYYY-MM-DD hh:mm:ss A ').add(values.split(".")[0], "hours").add(values.split(".")[1], "minutes").tz(authenticationService && authenticationService.user_timezone);
    // let res = dates.toLocaleString('en-US', { timeZone: 'America/New_York' })
    return moment(dates).format("hh:mm A z");
}
export const get_formated_timeslot = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).tz(authenticationService.user_timezone).format('LLL z')
        }
    }
    return parsed_date
}
export const get_formated_timeslot_provider = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).format('LLL z')
        }
    }
    return parsed_date
}
export const get_formated_time_only = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).tz(authenticationService.user_timezone).format('h:mm A z')
        }
    }
    return parsed_date
}
export const get_formated_date_only = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).tz(authenticationService.user_timezone).format('MMM D, Y')
        }
    }
    return parsed_date
}

export const get_formated_time_only_provider = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).format('h:mm A z')
        }
    }
    return parsed_date
}
export const get_formated_date_only_provider = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).format('MMM D, Y')
        }
    }
    return parsed_date
}
export const get_relative_time = (date) => {
    let parsed_date = "-";
    if (date) {
        if (authenticationService.user_timezone) {
            parsed_date = moment(date).tz(authenticationService.user_timezone).format('LLL z')
        }
    }
    return parsed_date
}
export const get_currency_symbol = (currency) => {
    return getSymbolFromCurrency(currency)
}
export const get_price_with_curr = (price) => {
    return getSymbolFromCurrency('INR') + price
}
export const get_consulation_obj = (record) => {
    let _data;
    if (record.consultationInfo && record.consultationInfo.length > 0) {
        _data = record.consultationInfo[0]
    }
    return _data
}
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export const disableFutureDates = (current) => {
    return current && current > moment().endOf('day');
}
export const handleOnSelectStage = (value, event) => {
    let isAssigneeRequired = false
    if (event && event.select_obj) {
        if (event.select_obj.stage) {
            let stage = event.select_obj.stage
            if (stage !== "New") {
                isAssigneeRequired = true
            } else {
                isAssigneeRequired = false
            }
            return isAssigneeRequired
        }
    }
}
export const get_enquiry_obj = (record) => {
    let _data;
    if (record && record.enquiryInfo) {
        _data = record.enquiryInfo
    }
    return _data
}
export const get_stage_obj = (record) => {
    let _data;
    if (record && record.stage) {
        _data = record.stage
    }
    return _data
}
export const get_speciality_obj = (record) => {
    let _data;
    if (record && record.speciality) {
        _data = record.speciality
    }
    return _data
}
export const get_assignee_obj = (record) => {
    let _data;
    if (record && record.assignee) {
        _data = record.assignee
    }
    return _data
}
export const get_mobile_data = (record) => {
    let _data = "-";
    if (record && record.countrycode && record.phone) {
        _data = record.countrycode + " " + record.phone
    }
    return _data
}

// const getDayOfWeek = (date) => 
// export const get_day_from_datetime = (date) => {
//     let d = new Date();
//     let weekday = new Array(7);
//     weekday[0] = "Sunday";
//     weekday[1] = "Monday";
//     weekday[2] = "Tuesday";
//     weekday[3] = "Wednesday";
//     weekday[4] = "Thursday";
//     weekday[5] = "Friday";
//     weekday[6] = "Saturday";
//     // return daysOfWeek[date.getDay()];
//     return weekday[d.getDay()];
// }
export const get_day_from_datetime = (date) => {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null :
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}
export const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
}

export const formateName = (name) => {
    return name ? name.length > 15 ? (name.slice(0, 15) + '...') : name : ""
}


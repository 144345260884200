import { GET, POST,PUT } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";

/**
 * Get All Patient Apppointments 
 */
function getPatientAppointments(id) {
  let url = APIENDPOINT.PATIENT.LISTAPPOINTMENTS+id;
  return GET(url);
}

/**
 * Get All Local Patient Apppointments 
 */
 function getLocalPatientAppointments(id) {
  let url = APIENDPOINT.LOCALPROVIDER.LISTAPPOINTMENTS+id;
  return GET(url);
}

/**
 * Reschedule Consultations/Appointment
 */
 function rescheduleAppointments(formData) {
  return POST(APIENDPOINT.PATIENT.RESCHEDULE_CONSULATION, formData)
}
/**
 * Add/Delete Consultations documents
 */
const updateDocument = async (formData) => {
  return PUT(APIENDPOINT.PATIENT.UPDATE_CONSULTATION, formData)
}
/**
 * Get single Consultation details
 */
function getAppointmentDetails(consultation_id) {
  let url = APIENDPOINT.PATIENT.APPOINTMENT_DETAIL_BY_ID+consultation_id;
  return GET(url);
}
const appointmentService = {
  getPatientAppointments: getPatientAppointments,
  rescheduleAppointments:rescheduleAppointments,
  updateDocument:updateDocument,
  getAppointmentDetails:getAppointmentDetails,
  getLocalPatientAppointments:getLocalPatientAppointments
}
export default appointmentService;
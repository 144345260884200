import React from 'react';
import { message } from 'antd';
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { getStartDate, getParsedSlots, parse_timeslot } from '../../provider/scheduler/SchedulerUtils';
import { get_provider_obj } from '../../patient/enquiry/EnquiryUtils';
import { Spinner } from 'react-bootstrap';
import NewSched from '../../provider/scheduler/NewSched'
import providerListService from '../../../services/admin/ProviderList.Service'
import { authenticationService } from '../../general/login/login.service';

class UpdateProviderScheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      loading: false,
      blocked_times: [],
      taken_times: [],
      start_date: getStartDate(),
      selected_provider: props && props.selected_provider && get_provider_obj(props.selected_provider),
      timeZoneId: "",
      userType: authenticationService.currentUserValue.usertype,
      timeZones: [],
      afterLoad: false
    }
  }

  handleSchedulerChange = newSchedule => {
    this.setState({ schedule: newSchedule })
  }

  updateScheduler = async (newSlotz) => {
    const slotzz = [...newSlotz, ...this.state.schedule]
    const addds = []
    for (let obj in slotzz) {
      const fou = addds.find(item => JSON.stringify(item) === JSON.stringify(slotzz[obj]))
      if (fou) {

      } else {
        addds.push(slotzz[obj])
      }
    }
    const all_Slotz = [...addds]
    try {
      const _parsedslots = parse_timeslot(all_Slotz && all_Slotz, this.state.timeZoneId && this.state.timeZoneId, newSlotz, this.state.resSched)
      this.setState({ loading: true })
      var data = {
        provider_id: this.props.selected_provider && this.props.selected_provider.providerInfo[0]._id,
        schedules: _parsedslots
      }
      let response = await ProviderScheduleService.saveProviderSchedule(data);
      if (response.data.status === "success") {
        message.success(response.data.message);
        this.setState({ loading: false, modalVisible: false })
        this.getProviderSchedules()

      } else {
        this.setState({ loading: false })
        message.error(response.data.message);
      }


    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  };
  getProviderSchedules = async () => {
    try {
      this.setState({ loading: true });
      const provider_id = this.props && this.props.selected_provider && this.props.selected_provider.providerInfo[0]._id
      let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
      if (response.data.status === "success") {
        if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
          const responseObj = response.data
          const _schedules = responseObj.schedules
          const all_schedule = getParsedSlots(_schedules);
          this.setState({
            schedule: all_schedule && all_schedule.available_slots,
            blocked_times: all_schedule && all_schedule.booked_slots,
            taken_times: all_schedule && all_schedule.taken_slots,
            loading: false
          })
        }
      } else {
        this.setState({ loading: false })
        //message.error(response.data.message);
      }

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }

  getAllTimezones = async () => {
    try {
      const res = await providerListService.getTimezoneList()
      this.setState({ timeZones: res.data.data })
      const { selected_provider } = this.props && this.props
      if (selected_provider && selected_provider.timezone !== (null || "undefined" || "")) {
        const provSelTimzone = res.data.data.filter(timeZone => timeZone._id === selected_provider.timezone)
        if (provSelTimzone && provSelTimzone[0] && provSelTimzone[0]._id)
          this.setState({ timeZoneId: provSelTimzone[0]._id, afterLoad: true })
        else
          this.setState({ timeZoneId: "", afterLoad: true })
      } else this.setState({ timeZoneId: "", afterLoad: true })
    } catch (err) {
      errorMessage(err)
      // console.log(err)
    }
  }

  componentDidMount() {
    this.getProviderSchedules()
    this.getAllTimezones()
  }

  render() {
    const slotsData = { booked_slots: this.state.blocked_times, taken_slots: this.state.taken_slots, schedule: this.state.schedule }
    return (<React.Fragment>
      <div>
        {this.state.loading ?
          <div className='d-flex justify-content-center'>
            <div>
              <Spinner animation="border " variant="primary" />
            </div>
          </div>
          : this.state.afterLoad && <div className="container-fluid ">
            <div>
              <NewSched
                timeZones={this.state.timeZones}
                updateScheduler={this.updateScheduler}
                proTimzo={this.state.timeZoneId}
                allSlotsData={slotsData}
                usertype={this.state.userType && this.state.userType}
              // showViewFunc = {this.handleShowView}
              />
            </div>

          </div>}

      </div>

    </React.Fragment>
    )
  }
}
export default UpdateProviderScheduler
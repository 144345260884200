import { profileAction } from '../../actions/patient/types'
  
  const initialState = {
    loading: false,
    users: {}
  }
  
  const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case profileAction.PROFILE_DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case profileAction.PROFILE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          users: action.payload.users
        }
      case profileAction.PROFILE_DATA_FAILURE:
        return {
          loading: false,
          users: {},
        }
      default: return state
    }
  }
  
  export default ProfileReducer
import React, { Component } from 'react'
import KarmaImpact from '../dashboard/KarmaImpact'
import MySchedule from '../dashboard/MySchedule'
import UpdateAvailability from '../dashboard/UpdateAvailability'

export class RightSideBar extends Component {
  render() {
    return (
      <div>
        <UpdateAvailability />
        <MySchedule />
        <KarmaImpact />
      </div>
    )
  }
}

export default RightSideBar

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import $ from "jquery";
import { isLogin } from '../general/login/login.service';
import ProfileDropDown from "./profile-dropdown";
import { NONAUTHROUTE } from "../../constants/RouterPath";
import { Container, Navbar } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

const HeaderNew = (props) => {
  const [state, setState] = useState(false)

  useEffect(() => setState(isLogin()), [props])

  // const onHandleMobileMenu = () => {
  //   var root = document.getElementsByTagName("html")[0];
  //   root.classList.add("menu-opened");
  // };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  useEffect(() => {
    $(".main-nav a").on("click", function (e) {
      if ($(this).parent().hasClass("has-submenu")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("submenu")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("submenu");
        $(this).next("ul").slideDown(350);
        $(this).addClass("submenu");
      } else if ($(this).hasClass("submenu")) {
        $(this).removeClass("submenu");
        $(this).next("ul").slideUp(350);
      }
    });
  }, []);

  return (
    <div>
      {props.backgroundColor ?
        <div className="header-nav" >
        <Navbar>
          <Container>
            <Navbar.Brand href="/"><img src={logo} className="img-fluid" alt="Logo" style={{ mixBlendMode: 'darken' }} /></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <div>
                <ul className="main-nav">
                  {/* <li className={`has-submenu`}>
                mix-blend-mode: darken;
              <Link to="/">Home</Link>
            </li> */}
                  {/* <li>
              <a href={NONAUTHROUTE.GENERAL.ABOUTUSPATH}>About</a>
            </li> */}
                  <li>
                    <a href={NONAUTHROUTE.GENERAL.CONTACTUSPATH} className='d-none d-sm-block'>Contact Us</a>
                    <a href={NONAUTHROUTE.GENERAL.CONTACTUSPATH} className='d-block d-sm-none'><FaPhoneAlt className="phoneAlt"  size={20} /></a>
                  </li>

                  {!state && (
                    <>
                      <li className="login-link" onClick={() => onhandleCloseMenu()}>
                        <Link to={NONAUTHROUTE.GENERAL.LOGINPATH}>Login / Register</Link>
                      </li>
                    </>
                  )}

                </ul>
              </div>
              <ul className="nav header-navbar-rht">

                {state ? (
                  <>
                    <ProfileDropDown />
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <Link to={NONAUTHROUTE.GENERAL.LOGINPATH} className="nav-link header-login">
                        Login / Register{" "}
                      </Link>
                    </li>{" "}
                  </>
                )}
              </ul>

            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>:
      
       <div className="header-nav bg-white" >
       <Navbar>
         <Container>
           <Navbar.Brand href="/"><img src={logo} className="img-fluid" alt="Logo" style={{ mixBlendMode: 'darken' }} /></Navbar.Brand>
           <Navbar.Toggle />
           <Navbar.Collapse className="justify-content-end">
             <div>
               <ul className="main-nav">
                 {/* <li className={`has-submenu`}>
               mix-blend-mode: darken;
             <Link to="/">Home</Link>
           </li> */}
                 {/* <li>
             <a href={NONAUTHROUTE.GENERAL.ABOUTUSPATH}>About</a>
           </li> */}
                 <li>
                   <a href={NONAUTHROUTE.GENERAL.CONTACTUSPATH} className='d-none d-sm-block'>Contact Us</a>
                   <a href={NONAUTHROUTE.GENERAL.CONTACTUSPATH} className='d-block d-sm-none'><FaPhoneAlt size={20} /></a>
                 </li>

                 {!state && (
                   <>
                     <li className="login-link" onClick={() => onhandleCloseMenu()}>
                       <Link to={NONAUTHROUTE.GENERAL.LOGINPATH}>Login / Register</Link>
                     </li>
                   </>
                 )}

               </ul>
             </div>
             <ul className="nav header-navbar-rht">

               {state ? (
                 <>
                   <ProfileDropDown />
                 </>
               ) : (
                 <>
                   <li className="nav-item">
                     <Link to={NONAUTHROUTE.GENERAL.LOGINPATH} className="nav-link header-login">
                       Login / Register{" "}
                     </Link>
                   </li>{" "}
                 </>
               )}
             </ul>

           </Navbar.Collapse>
         </Container>
       </Navbar>
     </div>}   
    </div>
  );
};

export default HeaderNew;

import {providerListAction} from './types'
import ProviderListService from '../../services/admin/ProviderList.Service';
//import UserListService from '../../services/admin/UserList.Service';
import ProfileService from '../../services/provider/Profile.Service';
import { alertActions } from "../alertAction";

/**
 * Get all Provider list
 * @returns 
 */
export const getProviderListDetails = () => async (dispatch) => {
  try {
    dispatch(providerListDataRequest())
    const providerPromise =await ProviderListService.getProviders();
    const stagesPromise =await ProviderListService.getStages();
    //const internalUsersPromise =await UserListService.getInternalUsers();
    const specialityPromise = ProfileService.getSpecialites();

    const reponses = await Promise.all([providerPromise, stagesPromise,specialityPromise]);
    const [providerResponse, stagesResponse,specialityResponse] = reponses;
    if (providerResponse.data && stagesResponse.data && specialityResponse.data) {
      const users = providerResponse.data && providerResponse.data.data
      const stages = stagesResponse.data && stagesResponse.data.data;
      //const internalusers = internalUsersResponse.data && internalUsersResponse.data.data
      const specialities = specialityResponse.data && specialityResponse.data.data

      dispatch(providerListDataSuccess({ users, stages: stages,specialities:specialities }))
      return Promise.resolve({ users, stages: stages,specialities:specialities });
    }else {
      dispatch(providerListDataFailure(reponses.data.message));
      dispatch(alertActions.error(reponses.data.message))
      return Promise.reject(reponses.data.message);
    }
  } catch (err) {
    dispatch(providerListDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const providerListDataRequest = () => {
  return {
    type: providerListAction.DATA_REQUEST
  }
}

export const providerListDataSuccess = data => {
  return {
    type: providerListAction.DATA_SUCCESS,
    payload: data,
  }
}

export const providerListDataFailure = error => {
  return {
    type: providerListAction.DATA_FAILURE,
    payload: error
  }
}

import React, { useState, useRef } from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { RiLinkedinFill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import './Footer.css'
import { Formik } from 'formik';
import * as yup from 'yup'
import { formikFormalidation } from '../../general/formvalidator/validationFroms'
import { Form, Button, Container, Overlay, CloseButton, Row, Col } from 'react-bootstrap';
import { email } from './Footer.service';
import useWindowSize from './UseWindowSize';

function Footer(props) {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [submitMsg, setSubmitMsg] = useState(false);
    const windowSize = useWindowSize()


    const schema = yup.object().shape({
        email: formikFormalidation.emaildr
    });

    const handleClose = () => {
        setSubmitMsg(false)
    }
    return (
        <div>{
            windowSize.width > 500 ? (<footer className="page-footer font-small blue pt-4 footerSection">

                <div className="container text-center text-md-left">

                    <div className="row">

                        <div className="text-white col-md-4 mt-md-0 mt-3 col1">
                            <img src='/FooterIcon.png' className="my-3 logo" alt="Logo" />
                            <p className="text-uppercase">62389 Dooley Summit Suite 705 <br />
                                New York, NY- 30010</p>
                            <p><FaPhoneAlt size={16} className='mr-3' />+1 336-663-1531</p>
                            <p><AiOutlineMail size={16} className='mr-3' />info@secondopinion.com</p>
                            <AiFillFacebook size={20} className='mr-2' style={{ color: '#4267B2' }} /><RiLinkedinFill size={20} className='mr-2' style={{ color: '#0077B5' }} /><AiOutlineTwitter size={20} className='mr-2' style={{ color: '#03A9F4' }} />
                        </div>



                        <div className="col-md-2 col2">
                            <ul className="list-unstyled">
                                <li className='mb-3'>
                                    <a href="/" className='text-white'>Home</a>
                                </li>
                                <li className='mb-3'>
                                    <a href={NONAUTHROUTE.GENERAL.PATIENTPATH} className='text-white'>Patient</a>
                                </li>
                                <li className='mb-3'>
                                    <a href={NONAUTHROUTE.GENERAL.DOCTORPATH} className='text-white'>Doctor</a>
                                </li>
                            </ul>

                        </div>
                        <div className="col-md-2 col3">
                            <ul className="list-unstyled">
                                <li className='mb-3'>
                                    <a href={NONAUTHROUTE.GENERAL.ABOUTUSPATH} className='text-white about'>About</a>
                                </li>
                                <li className='mb-3'>
                                    <a href={NONAUTHROUTE.GENERAL.CONTACTUSPATH} className='text-white'>Contact</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-4 col4">
                            <h4 className="text-white">Subscribe to our newsletter <br />& stay updated</h4>

                            <Formik
                                validationSchema={schema}
                                onSubmit={async (values) => {
                                    setLoading(true)
                                    await email(props, values, setSubmitMsg);
                                    setLoading(false)
                                }
                                }
                                initialValues={{
                                    email: ''
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                    <Container>
                                        <Form noValidate onSubmit={handleSubmit} className='form-inline'>
                                            <fieldset disabled={loading}>

                                                <div className="form-group mb-2 mt-3">
                                                    <Row>
                                                        <Col md={7}>
                                                            <Form.Group className="mb-3" style={{ marginLeft: '-10px' }} controlId="formBasicEmail">
                                                                <Form.Control style={{ backgroundColor: '#000', color: 'white' }} type="text" placeholder="Email" name="email" required value={values.email} onChange={handleChange} isInvalid={errors.email} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {/* {errors.email} */}
                                                                    {errors.email && <div className="text-danger">{errors.email}</div>}

                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md={5}>
                                                            <Button className="ml-4 btn-block" variant="primary" type="submit" ref={target} onClick={() => setShow(!show)} >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                </div>

                                                {/* <button type="submit" className="btn btn-primary ml-2 mb-2 px-4 d-none d-sm-block"  ref={target} onClick={() => setShow(!show)}>Submit</button>
                                                <button type="submit" className="btn btn-primary mb-2 px-4 d-block d-sm-none" style={{ marginLeft: '-10px' }}  ref={target} onClick={() => setShow(!show)}><FaAngleRight /></button> */}




                                                <Overlay target={target.current} show={submitMsg}
                                                    placement="top">
                                                    {(props) => (
                                                        <div className='mt-5'
                                                            {...props}
                                                            style={{
                                                                position: 'fixed',
                                                                backgroundColor: 'white',
                                                                padding: '1rem 1.5rem',
                                                                color: 'black',
                                                                width: '34%',
                                                                marginLeft: '65%',
                                                                bottom: '9%',
                                                                borderRadius: '10px',
                                                                // position: 'absolute',
                                                                // backgroundColor: 'white',
                                                                // padding: '6px 10px',
                                                                // color: 'black',
                                                                // borderRadius: 6.5,
                                                                // ...props.style,
                                                                // width: '450px',
                                                                // marginLeft: '-50px',
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col md={3}>
                                                                    {/* <AiFillMail size={80} className='ml-4 mt-4 ' style={{ color: '#5174B8' }} /> */}
                                                                    <img className='mr-2 mt-3' src='/email.png' alt='email' />
                                                                </Col>

                                                                <Col>
                                                                    <CloseButton onClick={handleClose} />
                                                                    <h4 className='mt-4' style={{ fontFamily: 'manrope' }}>Thanks for subscribing!</h4>
                                                                    <p className='mb-4' style={{ fontFamily: 'manrope' }}>Check your inbox for health tips and have us along on your health and wellness journey</p>
                                                                </Col>
                                                            </Row>


                                                        </div>
                                                    )}
                                                </Overlay>
                                            </fieldset>
                                        </Form>
                                    </Container>
                                )
                                }
                            </Formik>
                            {/* 
                                <Modal show={this.state.showModal} onHide={handleClose}
                                    backdrop="static"
                                    keyboard={true}
                                    centered={false}
                                    size="xl"
                                    className='modalData'
                                >
                                    <Modal.Header style={{ border: "0" }} closeButton>
                                    </Modal.Header>
                                    <Modal.Body><h3>Thanks for subscribing!</h3>
                                    <p>Check your inbox for health tips and have us along on your health and wellness journey</p></Modal.Body>
                                </Modal> */}

                        </div>
                    </div>
                </div>
                <hr className='bg-secondary w-75 mt-5' />
                <div className="text-white font-weight-light footer-copyright text-center pb-3">© {(new Date().getFullYear())}
                    <span>  Second opinion All Rights Reserved.</span> <a className='text-white' href={NONAUTHROUTE.PATIENT.PRIVACYPOLICYPATH}>Privacy Policy</a> | <a className='text-white' href={NONAUTHROUTE.PATIENT.TERMSPATH}>Terms of Use</a>
                </div>
            </footer>) : (
                <footer className='mobile-footer "page-footer font-small blue pt-4 footerSection'>
                    <div className="form-section">
                        <h4 className="text-white">Subscribe to our newsletter <br />& stay updated</h4>

                        <Formik
                            validationSchema={schema}
                            onSubmit={async (values) => {
                                setLoading(true)
                                await email(props, values, setSubmitMsg);
                                setLoading(false)
                            }
                            }
                            initialValues={{
                                email: ''
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (

                                <Form noValidate onSubmit={handleSubmit} >
                                    <fieldset disabled={loading}>

                                        <div className="form-group mb-2 mt-3">
                                            <div className='form-holder'>

                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control style={{ backgroundColor: '#000', color: 'white' }} type="text" placeholder="Email" name="email" required value={values.email} onChange={handleChange} isInvalid={errors.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {/* {errors.email} */}
                                                        {errors.email && <div className="text-danger">{errors.email}</div>}

                                                    </Form.Control.Feedback>
                                                </Form.Group>



                                                <Button variant="primary" className='ab-btn' type="submit" ref={target} onClick={() => setShow(!show)} >
                                                    <IoIosArrowForward />
                                                </Button>

                                            </div>

                                        </div>

                                        {/* <button type="submit" className="btn btn-primary ml-2 mb-2 px-4 d-none d-sm-block"  ref={target} onClick={() => setShow(!show)}>Submit</button>
                                                <button type="submit" className="btn btn-primary mb-2 px-4 d-block d-sm-none" style={{ marginLeft: '-10px' }}  ref={target} onClick={() => setShow(!show)}><FaAngleRight /></button> */}




                                        <Overlay target={target.current} show={submitMsg}

                                            placement="top-end">
                                            {(props) => (
                                                <div className='mt-5 mx-3'
                                                    {...props}
                                                    style={{
                                                        position: 'fixed',
                                                        // backgroundColor: 'white',
                                                        padding: '6px 10px',
                                                        color: 'black',
                                                        borderRadius: 3,
                                                        // ...props.style,
                                                        width: '92%',
                                                        bottom: '5%'
                                                        // inset: 'auto auto -346px auto',
                                                        // transform: 'translate3d(-18.8571px, 3339.14px, 0px)',
                                                        // marginLeft: '-50px',
                                                    }}
                                                >
                                                    <div className='staticPopup'>
                                                        <Row>
                                                            <Col xs={4}>
                                                                <img className='mx-0 mt-3' src='/email.png' alt='email' />
                                                            </Col>

                                                            <Col xs={8}>
                                                                <CloseButton onClick={handleClose} />
                                                                <h4 className='mt-4'>Thanks for subscribing!</h4>
                                                                <p className='mb-4'>Check your inbox for health tips and have us along on your health and wellness journey</p>
                                                            </Col>
                                                        </Row>


                                                    </div>
                                                </div>
                                            )}
                                        </Overlay>

                                    </fieldset>
                                </Form>

                            )
                            }
                        </Formik>
                    </div>
                    <hr className='bg-secondary w-75 my-2 mb-4' />

                    <div className="menu-section">
                        <ul className="list-unstyled">
                            <li className='mb-3'>
                                <a href="/" className='text-white'>Home</a>
                            </li>
                            <li className='mb-3'>
                                <a href={NONAUTHROUTE.GENERAL.PATIENTPATH} className='text-white'>Patient</a>
                            </li>
                            <li className='mb-3'>
                                <a href={NONAUTHROUTE.GENERAL.ABOUTUSPATH} className='text-white about'>About</a>
                            </li>
                            <li className='mb-3'>
                                <a href={NONAUTHROUTE.GENERAL.CONTACTUSPATH} className='text-white'>Contact</a>
                            </li>
                            <li className='mb-3'>
                                <a href={NONAUTHROUTE.GENERAL.DOCTORPATH} className='text-white'>Doctor</a>
                            </li>

                        </ul>
                    </div>
                    <div className="address-section">
                        {/* <img src='/FooterIcon.png' className="my-3 logo" alt="Logo" /> */}
                        <p className="text-uppercase">62389 Dooley Summit Suite 705 <br />
                            New York, NY- 30010</p>
                        <p><FaPhoneAlt size={16} className='mr-3' />+1 336-663-1531</p>
                        <p><AiOutlineMail size={16} className='mr-3' />info@secondopinion.com</p>
                        <div className="social-holder">
                            <AiFillFacebook size={20} className='mr-2' style={{ color: '#4267B2' }} /><RiLinkedinFill size={20} className='mr-2' style={{ color: '#0077B5' }} /><AiOutlineTwitter size={20} className='mr-2' style={{ color: '#03A9F4' }} />

                        </div>
                    </div>
                    <hr className='bg-secondary w-75 mt-2' />
                    <div className="text-white font-weight-light footer-copyright text-center pb-3">© {(new Date().getFullYear())}
                        <span>  Second opinion All Rights Reserved.</span> <a className='text-white' href={NONAUTHROUTE.PATIENT.PRIVACYPOLICYPATH}>Privacy Policy</a> | <a className='text-white' href={NONAUTHROUTE.PATIENT.TERMSPATH}>Terms of Use</a>
                    </div>
                </footer>
            )
        }

        </div >
    )
}

export default Footer
import {profileAction} from './types'
import ProfileService from '../../services/patient/Profile.Service';
import { alertActions } from "../alertAction";

/**
 * Get all profile details
 * @returns 
 */
export const getProfileDetails = () => async (dispatch) => {
  try {
    dispatch(profileDataRequest())
    const userResponse =await ProfileService.getProfile();
    if (userResponse.data) {
      const users = userResponse.data ? userResponse.data.user[0] : {};
      dispatch(profileDataSuccess({ users}))
      return Promise.resolve({ users});
    } else {
      dispatch(profileDataFailure(userResponse.data.message));
      dispatch(alertActions.error(userResponse.data.message))
      return Promise.reject(userResponse.data.message);
    }
  } catch (err) {
    dispatch(profileDataFailure(err.message));
    dispatch(alertActions.error(err.message))
    return Promise.reject(err);
  }
}

/**
 * 
 * @returns Short hand actions
 */

export const profileDataRequest = () => {
  return {
    type: profileAction.PROFILE_DATA_REQUEST
  }
}

export const profileDataSuccess = profile => {
  return {
    type: profileAction.PROFILE_DATA_SUCCESS,
    payload: profile,
  }
}

export const profileDataFailure = error => {
  return {
    type: profileAction.PROFILE_DATA_FAILURE,
    payload: error
  }
}

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { AUTHROUTE, NONAUTHROUTE } from "../../../constants/RouterPath";
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { formikFormalidation } from '../../general/formvalidator/validationFroms'
import { getSingleEnquiryDetails } from '../../../actions/patient/EnquiriesAction'
import { message } from 'antd';
import { Container, Form, Button, Row, Col, Spinner, Card } from 'react-bootstrap';
import { authenticationService } from '../../../component/general/login/login.service';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { errorMessage } from '../../../utils/ServiceHandler';
import { Link } from 'react-router-dom';
import Role from '../../../constants/role'
import { compose } from 'redux';
import { connect } from 'react-redux'
import ProviderScheduleService from '../../../services/provider/Scheduler.Service';
import { getParsedPatientSlots } from '../../provider/scheduler/SchedulerUtils';
import { get_patient_obj, get_provider_obj, get_user_obj, get_provider_user_obj, get_formated_date, getBase64, get_formated_timeslot, refreshPage, handleOnSelectStage } from './EnquiryUtils';
import _ from 'lodash';
import { BiArrowBack } from 'react-icons/bi';
import { FaFile, FaFileAlt, FaFileWord } from 'react-icons/fa';
import { Formik } from 'formik';
import * as yup from 'yup'




import EditOpinionAdmin from './EditOpinionAdmin';


//const { TextArea, Search } = Input;

export class EnquiryDetailsEditor extends Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        const id = this.props && this.props.match && this.props.match.params && this.props.match.params.id
        this.state = {
            enquiry_id: id,
            loading: true,
            selected_enquiry: {},
            prevPath: "",
            all_specialities: [],
            suggested_providers: [],
            fileList: [],
            currentUser: [],
            chosen_provider: {},
            previewVisible: false,
            confirmationModal: false,
            selectedFile: {},
            patient_obj: {},
            patient_user_obj: {},
            confirmLoading: false,
            isAssigneeRequired: false,
            scheduleloading: false,
            available_slots: [],
            searchParams: {},
            schedulestatus: false,
            showModel: false,
            selectedprovider: ""
        }
        this.dispatchProviderSearch = _.debounce(this.dispatchProviderSearch, 1000);
        //this.redirectCheck(props)
    }
    redirectCheck(props) {
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH)
            }
        }
    }
    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };
    hideCancelModal = () => {
        this.setState({ confirmLoading: false });
    };
    handleImgClose = () => {
        this.setState({ showModel: false })
    }

    setProviderModal = async () => {
        if (this.state.available_slots.length > 0) {
            this.setState({ confirmLoading: true })
            var data = {
                "enquiry_id": this.state.selected_enquiry && this.state.selected_enquiry._id,
                "provider_id": this.state.chosen_provider && this.state.chosen_provider.provider_id,
                "countrycode": this.state.patient_user_obj && this.state.patient_user_obj.countrycode,
                "phone": this.state.patient_user_obj && this.state.patient_user_obj.phone,
                "email": (this.state.patient_user_obj && this.state.patient_user_obj.email) || "",
                "patientname": this.state.patient_user_obj && this.state.patient_user_obj.displayname,
            };
            try {
                let response = await EnquiryListService.setEnquiryProvider(data);
                if (response.data.status === "success") {
                    this.setState({ confirmLoading: false });
                    refreshPage()
                } else {
                    this.setState({ confirmLoading: false });
                    message.error(response.data.message);
                }

            } catch (error) {
                errorMessage(error)
                this.setState({ confirmLoading: false });
            }
        } else {
            this.setState({ confirmLoading: false });
        }
    }
    pushImage = async (values) => {


        if (values) {
            values.forEach(event => {
                const oldfiles = this?.state?.fileList;
                if (event[0].type === 'application/pdf') {
                    oldfiles?.push({ filetype: 'application/pdf', originFileObj: event[0], url: URL.createObjectURL(event[0]) });
                }
                else if (event[0].type === 'application/msword') {
                    oldfiles?.push({ filetype: 'application/msword', originFileObj: event[0], url: URL.createObjectURL(event[0]) })
                }
                else if (event[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    oldfiles?.push({ filetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', originFileObj: event[0], url: URL.createObjectURL(event[0]) })
                }
                else
                    oldfiles?.push({ filetype: 'image/jpeg', originFileObj: event[0], url: URL.createObjectURL(event[0]) });

                this?.setState({ fileList: oldfiles });
                return true
            })
        }
    }
    addFiles = async (file) => {

        // console.log(await file,"kl")
        // this.setState({ fileList: this.state.fileList.concat(file&&file) })
        // console.log(this.state.fileList,"lk")
    }
    handlePreview = async file => {
        if (file && file.filetype && !file.filetype.match('image')) {
            var win = window.open(file.url, '_blank');
            win.focus();
        } else {
            if (file.originFileObj) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.setState({
                previewImage: file.preview || file.url,
                previewVisible: true
            });
        }
        //  else {
        //     this.setState({
        //         previewImage: file.url,
        //         previewVisible: true
        //     });
        // }
    };
    handleUpload = ({ fileList }) => {
        this.setState({ fileList });
    };
    onRemove = (file) => {
        this.setState({ selectedFile: file })
        return new Promise((resolve, reject) => {
            this.setState({ confirmationModal: true });
            resolve(false)
        })
    };

    handleOkModalRemove = async () => {
        if (this.state.fileList && this.state.fileList.length === 1) {
            message.error("You cannot delete this as atleast one file is mandatory.");
            return
        }
        else if (this.state.selectedFile && !this.state.selectedFile.originFileObj) {
            var data = {
                "enquiry": this.state.selected_enquiry,
                "file_obj": this.state.selectedFile
            };
            try {
                this.setState({ confirmLoading: true, confirmationModal: false })
                let response = await EnquiryListService.removeFile(data);
                if (response.data.status === "success") {
                    refreshPage()
                } else {
                    this.setState({ confirmationModal: false, confirmLoading: false })
                    message.error(response.data.message);
                }
            } catch (error) {
                this.setState({ confirmationModal: false, confirmLoading: false })
                errorMessage(error)
            }
        } else {
            refreshPage()
        }
    };

    handleCancelModalRemove = () => {
        this.setState({ confirmationModal: false });
    }
    getInitalValues(_enquiry) {
        return {
            speciality: (_enquiry && _enquiry.speciality && _enquiry.speciality.id) || [],
            enquiry_message: _enquiry.enquiry_message || "",
            assignee_id: (_enquiry && _enquiry.assignee && _enquiry.assignee.id) || "",
            stage_id: (_enquiry && _enquiry.stage && _enquiry.stage.id) || null,
        }
    }
    getSuggestedProviders = async (_enquiry) => {
        try {
            this.setState({ provider_loading: true });
            let response = await EnquiryListService.getProviderBySpeciality(_enquiry);
            if (response.data.status === "success") {
                this.setState(state => ({
                    provider_loading: false,
                    suggested_providers: response.data.data
                }));
                //this.setState({ suggested_providers: response.data.data, loading: false })
            } else {
                this.setState({ suggested_providers: [], provider_loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            this.setState({ suggested_providers: [], provider_loading: false })
        }
    }

    getSchedulebyProviders = async (item) => {
        if (item) {
            try {
                this.setState({ scheduleloading: true });
                const provider_id = item && item._id
                if (provider_id) {
                    let response = await ProviderScheduleService.getSchedulebyProvider(provider_id);
                    if (response.data.status === "success") {
                        if (response && response.data && response.data.schedules && response.data.schedules.length > 0) {
                            const responseObj = response.data
                            const _schedules = responseObj.schedules
                            const all_schedule = getParsedPatientSlots(_schedules);
                            setTimeout(() => {
                                this.setState({
                                    available_slots: all_schedule,
                                    scheduleloading: false,
                                    schedulestatus: true
                                })
                            }, 100);
                        }
                    } else {
                        this.setState({ scheduleloading: false, schedulestatus: true, available_slots: [] })
                    }
                } else {
                    this.setState({ scheduleloading: false, schedulestatus: true, available_slots: [] })
                    //message.error(response.data.message);, 
                }
            } catch (error) {
                errorMessage(error)
                this.setState({ scheduleloading: false, schedulestatus: true, available_slots: [] })
            }
        }
    }


    getvalsfromAPI = async () => {
        try {
            this.setState({ loading: true });
            const currentUser = authenticationService.currentUserValue;
            this.setState({ loading: true })
            //const _all_specialities = this.props && this.props.location && this.props.location.state && this.props.location.state.all_specialities
            const _prevPath = this.props && this.props.location && this.props.location.state && this.props.location.state.prevPath
            //const _internalusers = this.props && this.props.location && this.props.location.state && this.props.location.state.internalusers
            //const _stages = this.props && this.props.location && this.props.location.state && this.props.location.state.stages
            const _enquiryProps = this.props && this.props.location && this.props.location.state && this.props.location.state.selected_enquiry
            let patient_id;
            if (currentUser.usertype === Role.localprovider.name) {
                const _patientProps = get_patient_obj(_enquiryProps)
                patient_id = _patientProps && _patientProps._id
            } else if (currentUser.usertype === Role.admin.name || currentUser.usertype === Role.internaluser.name) {
                patient_id = currentUser._id
            } else if (currentUser.usertype === Role.patient.name) {
                patient_id = currentUser.usertype_id
            }
            const data = {
                enquiry_id: this.state && this.state.enquiry_id,
                patient_id: patient_id
            }

            let response = await EnquiryListService.getEnquiryRelatedDetails(data)
            if (response && response.enquiryData) {
                let _stages, _internalusers, _all_specialities;
                if (response && response.stages) {
                    _stages = response.stages
                }
                if (response && response.specialities) {
                    _all_specialities = response.specialities
                }
                if (response && response.internalusers) {
                    _internalusers = response.internalusers
                }
                const _enquiry = response.enquiryData && response.enquiryData.enquiryInfo
                const _selected_enquiry = response.enquiryData && response.enquiryData
                const _provider_obj = get_provider_obj(_selected_enquiry)
                const _provider_user_obj = get_provider_user_obj(_provider_obj)
                this.setState({
                    loading: false,
                    selected_enquiry: _enquiry,
                    stages: _stages,
                    all_specialities: _all_specialities,
                    internalusers: _internalusers,
                    prevPath: _prevPath,
                    patient_obj: get_patient_obj(_selected_enquiry),
                    patient_user_obj: get_user_obj(_selected_enquiry),
                    provider_obj: _provider_obj,
                    provider_user_obj: _provider_user_obj,
                    currentUser: currentUser,
                    fileList: _enquiry.documents || []
                })
                if (currentUser.usertype === Role.admin.name || currentUser.usertype === Role.internaluser.name) {
                    this.getSuggestedProviders(_enquiry)
                }
                if (this.formRef && this.formRef.current) {
                    this.formRef.current.setFieldsValue(this.getInitalValues(_enquiry))
                }
            } else {
                this.setState({ loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }

    }

    componentDidMount() {
        //this.getValsfromProps()
        this.getvalsfromAPI()

    }
    onFinish = async (values) => {
        if (this.state.fileList.length === 0) {
            message.error('Please upload Past Reports')
            return
        }
        let formData = new FormData();
        formData.append("speciality_id", values.speciality || "");
        formData.append("enquiry_message", this.state.selected_enquiry && this.state.selected_enquiry.enquiry_message);
        formData.append("patient_id", this.state.patient_obj && this.state.patient_obj._id);
        formData.append("enquiry_id", this.state.selected_enquiry && this.state.selected_enquiry._id);
        formData.append("file_count", this.state.fileList && this.state.fileList.length);
        //formData.append("stage_id", values.stage)
        for (let i = 0; i < this.state.fileList.length; i++) {
            const file = this.state.fileList[i];
            if (file['originFileObj']) {
                formData.append('file', file['originFileObj']);
            }
        }

        if (this.state.currentUser) {
            if (this.state.currentUser.usertype === Role.admin.name || this.state.currentUser.usertype === Role.internaluser.name) {
                if (values && values.stage_id) {
                    formData.append("stage_id", values.stage_id || "");
                }
                if (values && values.provider_id) {
                    this.setState({ chosen_provider: values });
                }
            }
            if (this.state.currentUser.usertype === Role.admin.name) {
                if (values && values.assignee_id) {
                    formData.append("assignee_id", values.assignee_id || "");
                }
            }
        }

        try {
            this.setState({ loading: true });
            let response = await EnquiryListService.updateEnquiry(formData);
            if (response.data.status === "success") {
                message.success(response.data.message);
                this.setState({ loading: false })
                this.setProviderModal();
            } else {
                this.setState({ loading: false })
                message.error(response.data.message);
            }

        } catch (error) {
            errorMessage(error)
            this.setState({ loading: false })
        }
    }
    onFinishFailed = values => {
    }

    handleOnSelectStage = (value, event) => {
        this.setState({ isAssigneeRequired: handleOnSelectStage(value, event) })
    }
    handleShow = (e) => {
        e.preventDefault()
        this.setState({ showModel: true })

    }
    onSearch = (event) => {
        if (event && event.target && event.target.value) {
            let searchParams = {
                "speciality_id": this.state.selected_enquiry && this.state.selected_enquiry.speciality && this.state.selected_enquiry.speciality.id,
                "searchkey_value": event.target.value
            }
            this.setState({ searchParams: searchParams })
            this.dispatchProviderSearch()
        } else {
            this.getSuggestedProviders(this.state.selected_enquiry)
        }
    }

    dispatchProviderSearch = async () => {
        try {
            let response = await EnquiryListService.searchProviders(this.state.searchParams);
            if (response && response.data && response.data.status === "success") {
                this.setState(({
                    provider_loading: false,
                    suggested_providers: response.data.data
                }));
            } else {
                this.setState({ suggested_providers: [], provider_loading: false })
            }
        } catch (error) {
            errorMessage(error)
        }
    }

    handleOnChangeSpeciality = (speciality_id, speciality_name) => {
        if (speciality_id && speciality_name) {
            let enquiry = this.state.selected_enquiry
            enquiry['speciality'] = {
                id: speciality_id,
                name: speciality_name
            }
            this.getSuggestedProviders(enquiry)
        } else {
            this.getSuggestedProviders(this.state.selected_enquiry)
        }
    }
    // hideModal = () => {
    // };


    handleOnChangeProvider = (provider_id, provider_name) => {
        if (provider_id) {
            let item = {
                '_id': provider_id
            }
            this.getSchedulebyProviders(item);
            this.setState({ selectedprovider: provider_name })
        }
    }

    render() {
        const currentUser = authenticationService.currentUserValue;
        const schema = yup.object().shape({
            speciality: formikFormalidation.speciality,
            provider_id: formikFormalidation.provider_id,
            // stage: formikFormalidation.stage
        });
        let urlChanger = '';
        if (currentUser.usertype === Role.admin.name) {
            urlChanger = AUTHROUTE.ADMIN.ENQUIRIES_PATH;
        }
        if (currentUser.usertype === Role.internaluser.name) {
            urlChanger = AUTHROUTE.INTERNALUSER.ENQUIRIES_PATH;
        }
        return (
            <React.Fragment>
                <AfterLoginLayout>
                    <Container fluid >
                        <Row style={{ "width": "100%" }}>
                            <Col lg={12} md={12} sm={12} className='pb-3'> <h4 className='text-left'><Link to={{ pathname: urlChanger }} > < BiArrowBack style={{ color: "black", textDecoration: "none" }} /></Link>Opinion request</h4>
                                <Col lg={12} md={12} sm={12} className='pb-3'>  {(this.state.selected_enquiry && this.state.selected_enquiry.enquiryCode)} </Col>
                            </Col>
                        </Row>

                        {this.state.loading &&
                            <div className='d-flex justify-content-center flex-nowrap'>
                                <div className='p-4' variant="primary">
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            </div>
                        }
                        {!this.state.loading && <div>

                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Card className='leftbottomsection' >
                                        <Row className='p-4'>
                                            <Col lg={4} md={4} sm={12}>
                                                <div className='breadcrumb-topics'>PATIENT</div>
                                                <div className='data mt-2'>{this.state.selected_enquiry && this.state.patient_obj.firstname}</div>
                                            </Col>
                                            <Col lg={3} md={3} sm={12}>
                                                <div className='breadcrumb-topics'>SUBMITTED DATE</div>
                                                <div className='data mt-2'>{this.state.selected_enquiry && get_formated_date(this.state.selected_enquiry.createddate)}</div>
                                            </Col>
                                            <Col lg={5} md={5} sm={12}>
                                                <div className='breadcrumb-topics'>CONCERN</div>
                                                <div className='data mt-2'>{this.state.selected_enquiry && this.state.selected_enquiry.enquiry_message}</div>
                                            </Col>

                                        </Row>
                                        <Row><Col><hr /></Col></Row>
                                        <Row className='p-4'>
                                            <Col lg={10} md={10} sm={12} pt-4>
                                                <div className='breadcrumb-topics'>DETAILS </div>
                                                <Col className="gutter-row">
                                                    <Row className='mobile_row'>
                                                        {this.state.fileList.map((x, i) => {
                                                            return <Col lg={4} md={4} sm={6} xs={6} className="p-4" >
                                                                {(x?.filetype === 'image/jpeg' || x?.filetype === 'image/png') && <img style={{ border: "1px solid #ccc", cursor: "pointer" }} src={x?.url} alt=" " width={100} height={100} onClick={() => this.documentViewer(x?.url)}></img>}
                                                                {x?.filetype === 'application/octet-stream' && <div> <a href={x?.url}><FaFileWord className='fa fa-6x text-secondary' /></a></div>}
                                                                {x?.filetype === 'text/plain' && <div> <a href={x?.url}><FaFileAlt className='fa fa-6x text-secondary' /></a></div>}

                                                                {(x?.filetype === 'application/pdf' || x?.filetype === 'application/msword' || x?.filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                                                                    <div className='image'>
                                                                        <a href={x?.url}><FaFile className='fa fa-6x' /></a>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        })}
                                                    </Row></Col>
                                            </Col>
                                            <Col lg={2} md={2} sm={12} pt-4>
                                                <button class="btn btn-default btn-file" onClick={this.handleShow} >
                                                    Edit
                                                    {/* <Form.Control type="file" accept=".pdf, .jpg,.png,.jpeg,.doc,.docx"
                                            required label={'Past Reports'} name="prescription"

                                           onChange={e => this.pushImage(e)} style={{ padding: '30px' }} size="60" /> */}
                                                </button>
                                                {/* Link to={{ pathname: '/patient/appointment/' + this.props?.location?.state?.selected_appointment?.consultationInfo?.id }} */}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Card className='p-3'>
                                        <div className="newtwo"> Allot provider</div>
                                        <Row>
                                            <Col lg={12} md={12} sm={12}>
                                                <Formik
                                                    initialValues={{
                                                        speciality: this.state.selected_enquiry && this.state.selected_enquiry && this.state.selected_enquiry.speciality.id,
                                                        provider_id: this.state.selected_enquiry && this.state.selected_enquiry && this.state.selected_enquiry.provider_id,
                                                        // stage: this.state.selected_enquiry && this.state.selected_enquiry && this.state.selected_enquiry.stage.id
                                                    }}
                                                    validationSchema={schema}
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    onSubmit={values => {
                                                        if (values.speciality !== '' && values.provider_id !== '' && this.state.available_slots.length > 0) {
                                                            this.onFinish(values);
                                                        }

                                                    }}
                                                >
                                                    {({ handleSubmit, handleChange, handleBlur, errors, values, touched }) => (
                                                        <Form noValidate onSubmit={handleSubmit}>
                                                            <Row>

                                                                <Col lg={5} md={5} sm={12}>
                                                                    <Form.Group controlId="formBasicSpeciality">
                                                                        <Form.Label>Specilization <span style={{ "color": "red" }}>*</span></Form.Label>
                                                                        <Form.Control as={"select"}
                                                                            required
                                                                            name="speciality"
                                                                            value={values.speciality}
                                                                            className='selectors'
                                                                            isInvalid={errors.speciality && touched.speciality}
                                                                            onChange={e => {
                                                                                handleChange(e)
                                                                                let speciality_id = e.currentTarget.value;
                                                                                let speciality_name = e.currentTarget.selectedOptions[0].label;
                                                                                this.handleOnChangeSpeciality(speciality_id, speciality_name)
                                                                            }}
                                                                            onBlur={handleBlur}  >
                                                                            {
                                                                                this.state.all_specialities?.map((item) => {
                                                                                    const { name, _id } = item;
                                                                                    return <option value={_id} key={_id}>{name}</option>
                                                                                })
                                                                            }
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={5} md={5} sm={12}>
                                                                    <Form.Group controlId="formBasicUserName">
                                                                        <Form.Label>Provider <span style={{ "color": "red" }}>*</span></Form.Label>
                                                                        <Form.Control as={"select"}
                                                                            required
                                                                            name="provider_id"
                                                                            value={values.provider_id}
                                                                            className='selectors'
                                                                            isInvalid={errors.provider_id && touched.provider_id}
                                                                            onChange={e => {
                                                                                handleChange(e)
                                                                                let provider_id = e.currentTarget.value;
                                                                                let provider_name = (e.currentTarget.selectedOptions[0].label).split("-");
                                                                                this.handleOnChangeProvider(provider_id, provider_name && provider_name[0])
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                        >
                                                                            <option value="">Assign Provider</option>
                                                                            {
                                                                                this.state.suggested_providers?.map((item) => {
                                                                                    const { _id, experience } = item;
                                                                                    const { displayname } = item && item.userinfo && item.userinfo[0] ? item.userinfo[0] : '';
                                                                                    return <option value={_id} key={_id}>{displayname + '-' + experience + ' Exp '}</option>
                                                                                })
                                                                            }
                                                                        </Form.Control>
                                                                        <Form.Text>
                                                                            {(() => {
                                                                                if (this.state && this.state.available_slots && this.state.schedulestatus && !this.state.scheduleloading) {
                                                                                    if (this.state.available_slots.length > 0) {
                                                                                        let schedule_obj = this.state.available_slots[0]
                                                                                        let relative_hrs = get_formated_timeslot(schedule_obj.slot)
                                                                                        return <React.Fragment>
                                                                                            {this.state.available_slots.length} slots available for {(this.state.selectedprovider)}.<br />
                                                                                            <div title="Next available" content={relative_hrs} />
                                                                                            <p style={{ fontStyle: "italic", fontSize: "12px" }} >Note : An Email & SMS will be sent to Patient upon confirmation."</p>
                                                                                        </React.Fragment>
                                                                                    } else {
                                                                                        return <React.Fragment>
                                                                                            <div className='newone'><p>No schedules are available for this provider</p></div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                }
                                                                            })()}
                                                                        </Form.Text>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={2} md={2} sm={12}>
                                                                    <Form.Group>
                                                                        <Form.Label style={{ marginTop: '2.8rem' }}></Form.Label>
                                                                        <Button variant="primary" type="submit">Submit </Button>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>







                                                        </Form>
                                                    )}

                                                </Formik>

                                                {this.state.showModel && <EditOpinionAdmin sel_enq={this.state.selected_enquiry} urlId={this.state.enquiry_id} addFiles={this.addFiles} fileList={this.state.fileList} pushImage={this.pushImage} Close={this.handleImgClose} />}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        }
                    </Container>
                </AfterLoginLayout>

            </React.Fragment >
        )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleEnquiryDetails: () => dispatch(getSingleEnquiryDetails())
    }
}
const mapStateToProps = state => {
    return {
        enquiryStore: state.enquiryList,
    }
}


//export default withRouter(EnquiryDetailsEditor)
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EnquiryDetailsEditor);
import React, { Component } from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import './Section6.css'
import doctor1 from "../../../../assets/images/doctor1.png";



export class Section6 extends Component {
    render() {
        return (

            <Container className='pl-5 main'>
                <Row>
                    <Col className=' justify-content-center d-flex' sm={12} md={6} >
                        <Card.Body>
                            <div className=''>
                                <h5 className='sec6_head'>WHO ARE WE?</h5>
                                <h3 className='sec6_head1'>We believe in  delivering quality healthcare </h3>
                                <h6 className='sec6_head2'> Our vision is to ensure better health for all. Here at Second Opinion, we aid you in the process of making informed decisions about health conditions.</h6>
                                

                                <h6 className='sec6_head2'> This platform is crafted for enhancing online consultations and to provide smooth and seamless experience for both patients and doctors</h6>
                            </div>
                        </Card.Body>
                    </Col>
                    <Col className=' justify-content-end d-flex' sm={12} md={6} >


                        <Card.Img

                            src={doctor1}
                            alt='video'
                            className='card_Img'


                        />

                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Section6
import React, { Component } from 'react'
import PatientLogin from './ExistingUser';
import PatientSignUp from './NonExistingUser';

export class OpinionConfirmationPage extends Component {
    render() {
        return (
            <div>
              {this.props.location.state.response.userfound
                    ? <PatientLogin {...this.props} /> : <PatientSignUp {...this.props} />}
            </div>
        )
    }
}

export default OpinionConfirmationPage
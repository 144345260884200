
import moment from 'moment-timezone';
import { get_formated_patient_ist_date, get_formated_patient_ist_time, get_formated_provider_ist_date, get_formated_provider_ist_time } from '../../patient/enquiry/EnquiryUtils'
let _ = require('underscore');

const BOOKING_STATUS = {
  CREATED: 1,
  TAKEN: 2,
  BOOKED: 3
}

export const getStartDate = () => {
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1)
  return tomorrow
}

export const getParsedPatientSlots = (_schedule) => {
  var slots = []
  if (_schedule && _schedule.length > 0) {
    _schedule.forEach(async e => {
      if (e.slots && e.slots.length > 0) {
        await e.slots.map((x) => {
          let date = get_formated_provider_ist_date(x.date, x.slot, e.timezone[0].name);
          let slot = get_formated_provider_ist_time(x.date, x.slot, e.timezone[0].name) + " " + e.timezone[0].name;
          x.date = date;
          x.slot = slot;
          return x;
        })
        let all_slots = e.slots.sort(function (a, b) {
          return new Date(a.slot) - new Date(b.slot);
        });
        all_slots.forEach(slotObj => {
          if ('slot' in slotObj) {
            slotObj.timezone = e.timezone[0].name;
            if (slotObj.status === BOOKING_STATUS.CREATED) {

              slots.push(slotObj)
            }
          }
        })
      }
    })
  }
  return slots.sort((a, b) => new Date(a.date) - new Date(b.date));
}

export const getParsedPatientSlots1 = (_schedule) => {
  var slots = []
  if (_schedule && _schedule.length > 0) {
    _schedule.forEach(async e => {
      if (e.slots && e.slots.length > 0) {
        await e.slots.map((x) => {
          let date = get_formated_patient_ist_date(x.date, x.slot, e.timezone[0].name);
          let slot = get_formated_patient_ist_time(x.date, x.slot, e.timezone[0].name);
          x.date = date;
          x.slot = slot;
          return x;
        })
        let all_slots = e.slots.sort(function (a, b) {
          return new Date(a.slot) - new Date(b.slot);
        });
        all_slots.forEach(slotObj => {
          if ('slot' in slotObj) {
            slotObj.timezone = e.timezone[0].name;
            if (slotObj.status === BOOKING_STATUS.CREATED) {

              slots.push(slotObj)
            }
          }
        })
      }
    })
  }
  return slots.sort((a, b) => new Date(a.date) - new Date(b.date));
}

export const getParsedSlots = (_schedule, timeZones) => {
  var available_slots = []
  var booked_slots = []
  var taken_slots = []
  const check_booked = []
  if (_schedule && _schedule.length > 0) {
    _schedule.forEach(e => {
      if (e.slots && e.slots.length > 0) {
        e.slots.forEach(slotObj => {
          if ('slot' in slotObj) {
            if (slotObj.status === BOOKING_STATUS.CREATED) {
              //  convertZone(slotObj,e.timezone && e.timezone)
              const attDate = moment(slotObj.date + ' ' + slotObj.slot, "YYYY-MM-DD HH:mm A").format()
              available_slots.push(new Date(attDate))
            } else if (slotObj.status === BOOKING_STATUS.BOOKED) {
              check_booked.push(moment(slotObj.date + ' ' + slotObj.slot, "YYYY-MM-DD HH:mm A").format())
              const booked_date = moment(slotObj.date + ' ' + slotObj.slot, "YYYY-MM-DD HH:mm A").format()
              booked_slots.push(new Date(booked_date))
            } else if (slotObj.status === BOOKING_STATUS.TAKEN) {
              const takenSlotz = moment(slotObj.date + ' ' + slotObj.slot, "YYYY-MM-DD HH:mm A").format()
              taken_slots.push(new Date(takenSlotz))
            }
          }
        })
      }
    })

    const ite = []
    const filteredAvailSlots = []
    available_slots.filter(item => {
      if (booked_slots.find(booked => JSON.stringify(booked) === JSON.stringify(item))) {
        ite.push(item)
      } else {
        filteredAvailSlots.push(item)
      }
      return ite
    })

    return {
      available_slots: filteredAvailSlots,
      booked_slots: [...new Set(check_booked)].map(item => new Date(item)),
      taken_slots: taken_slots
    }
  }
}

export const parse_timeslot = (schedule, newZoneID, newSlotz, sched) => {
  const newSlots = newSlotz.map(item => moment(item).format("YYYY-MM-DD")).map(item => moment(item).format())
  const track_timezone = []
  sched && sched.map(item =>
    track_timezone.push({ date_day: item.day, timezoneId: item.timezone[0]._id })
  )
  var occurrenceDay = function (occurrence) {
    return moment(occurrence).startOf('day').format();
  };


  var groupToDay = function (group, day) {
    const dateoonly = moment(day).format("YYYY-MM-DD")
    //const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let time_zone_id
    const getTimeZoneID = () => {
      if (newSlots.includes(day)) {
        time_zone_id = newZoneID
      } else {
        const id = track_timezone.find(zoneId => day === zoneId.date_day)
        time_zone_id = id ? id.timezoneId : newZoneID
      }
    }
    getTimeZoneID()
    return {
      date: dateoonly,
      timezone: time_zone_id,
      day: day,
      slots: group.map(tim => moment(tim).format("hh:mm A"))
    }
  };

  var parsedslots = _.chain(schedule)
    .groupBy(occurrenceDay)
    .map(groupToDay)
    .sortBy('day')
    .value();

  return [...parsedslots]
}


//old-code-reference
export const new_parse_timeslot = (schedule) => {
  var occurrenceDay = function (occurrence) {
    return moment(occurrence).startOf('day').format();
  };

  var groupToDay = function (group, day) {
    const dateoonly = moment(day).format("YYYY-MM-DD")
    //const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return {
      date: dateoonly,
      // timezone:schedule,
      day: day,
      slots: group.map(tim => moment(tim).format("hh:mm A"))
    }
  };

  var parsedslots = _.chain(schedule)
    .groupBy(occurrenceDay)
    .map(groupToDay)
    .sortBy('day')
    .value()

  return parsedslots
}


export const update_myslots_parse_timeslot = (newRes, selctedToUpdate, updatedAvail) => {
  const date_timezone = []
  newRes.forEach(item => {
    let obj = {}
    obj.date = moment(item.day).format("YYYY-MM-DD")
    obj.timezone_id = item.timezone[0]._id
    date_timezone.push(obj)
  })
  const new_unselected = []
  const parsed = new_parse_timeslot(selctedToUpdate)
  date_timezone.forEach(day => {
    parsed.forEach(item => {
      if (item.date === day.date) {
        let add_timezone = item
        add_timezone.timezone = day.timezone_id
        new_unselected.push(add_timezone)
      }
    })
  })
  return selctedToUpdate.length !== 0 ? new_unselected : []
}


export const renderLegend = () => {
  return (
    <div className='mb-2'>

      <span style={{ fontSize: '12px', color: 'grey' }}>* Click on the time blocks to add/change your availability</span>
      <div style={{ width: '160px', padding: '10px', display: 'block ruby' }}>
        <div style={{ backgroundColor: '#F8F8F8', margin: '5px', border: "1px solid #F8F8F8", borderRadius: "4px", padding: '5px', color: '#143C8C', textAlign: 'center', fontWeight: 530 }}>
          Free
        </div>
        <div style={{ backgroundColor: '#143C8C ', margin: '5px ', border: "1px solid #143C8C", borderRadius: "4px", padding: '5px', color: '#ffffff', textAlign: 'center', fontWeight: 530 }}>
          Available
        </div>
        <div style={{ backgroundColor: 'red', margin: '5px', border: "1px solid red", borderRadius: "4px", padding: '5px', color: '#ffffff', textAlign: 'center', fontWeight: 530 }}>
          Booked
        </div>
        {/* <div style={{ backgroundColor: 'white', margin: '5px',border:"1px solid red", borderRadius:"4px", padding: '5px', color: '#000000', textAlign: 'center', borderStyle: 'solid', borderColor: '#f2f2f2' }}>
          Free
        </div> */}
      </div>

    </div>
  )
}

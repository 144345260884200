import { Button, Modal } from 'react-bootstrap';
import success from '../../../assets/images/calender.png'
export const AppointmentModal = (props) => {
    return (
        <Modal show={props.showModal}
            onHide={props.onHideModal} centered
            backdrop="static">
            <Modal.Body className='p-5'>
                {(props.modalData === 'cancel') && <center></center>}
                {(props.modalData === 'reschedule') && <center><img src={success} alt="BigCo Inc. logo" /></center>}
                <center>
                    <p>
                        {(props.modalData === 'cancel') && 'Are you sure want to cancel appointment?'}
                        {(props.modalData === 'reschedule') && 'Are you sure want to Reschedule appointment?'}
                    </p>
                    <Button variant="primary" className='px-4 mx-2' onClick={props.onYesClick}>
                        Yes
                    </Button>
                    <Button variant="secondary" className=' px-4 mx-2' onClick={props.onNoClick}>
                        No
                    </Button>

                </center>

            </Modal.Body>
        </Modal >
    )
}
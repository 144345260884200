import React from 'react';
import "../../provider/profile/providerProfile.style.css"
import LocalProviderView from './ListLocalProviders';

export class LocalProviderAdminView extends React.Component {

  render() {
    return (<React.Fragment>
      <LocalProviderView
        showMultiSectionOption={true}
        showCreate={true}
        {...this.props}
      />

    </React.Fragment>)

  }
}

export default LocalProviderAdminView
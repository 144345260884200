import React, { Component } from "react";
import {
    Form,
    Button,
    Spinner,
    Container,
    Row,
    Col,
    Card,
} from "react-bootstrap";
import { FullLayout } from "../../shared";
import { resetpassword, resetResendOtp } from "./resetpassword.service";
import { formikFormalidation } from "../../general/formvalidator/validationFroms";
import "./resetpassword.style.css";
import { NONAUTHROUTE } from "../../../constants/RouterPath";
import { Formik } from "formik";
import * as yup from "yup";
import TextField from "./textField";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import OtpTimer from "../otp/OtpTimer";


export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                props.history.push(NONAUTHROUTE.GENERAL.LANDINGPATH);
            }
        }
        this.state = {
            loading: false,
            phoneotp: "",
            emailotp: "",
            numInputs: 6,
            separator: "-",
            isDisabled: false,
            hasErrored: false,
            isInputNum: false,
            isInputSecure: false,
            minLength: 0,
            maxLength: 40,
            placeholder: "",
            data: "",
            user_id: "",
            values: "",
            verificationcode: "",
            password: "",
            confirmpassword: "",
            validated: false,
            emailotpsent: true,
            phoneotpsent: true,
            allFormData: { pin1: '', pin2: '', pin3: '', pin4: '', pin5: '', pin6: '', confirmpassword: '', password: '', load: false },
            showPassword: false,
            showConfirmPassword: false,
            counter: 45,
        };

    }

    handleEmailOtpChange = (emailotp) => {
        this.setState({ emailotp });
    };
    handlePhoneOtpChange = (phoneotp) => {
        this.setState({ phoneotp });
    };
    

    componentDidMount() {
        this.setState({
            user_id:
                this.props.location.state &&
                this.props.location.state.response &&
                this.props.location.state.response.user_id,
            password:
                this.props.location.state &&
                this.props.location.state.response &&
                this.props.location.state.response.newpassword,
            confirmpassword:
                this.props.location.state &&
                this.props.location.state.response &&
                this.props.location.state.response.confirm,
            verificationcode:
                this.props.location.state &&
                this.props.location.state.response &&
                this.props.location.state.response.verificationcode,
        });
    }

    hadlePastedData = (val) => {
        this.setState({
            allFormData: {
                ...this.state.allFormData, pin1: val[0], pin2: val[1], pin3: val[2], pin4: val[3],
                pin5: val[4], pin6: val[5], load: true
            }
        })
    }

    handleShowPassword = (passwordProps) => {
        if (passwordProps === 'password') {
            this.setState({ showPassword: !this.state.showPassword })
        }
    }

    handleConfirmShowPassword = (passwordProps) => {
        if (passwordProps === 'password') {
            this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
        }
    }

    handleResendOtp = async () => {
        const id = this.props && this.props.location.state && this.props.location.state.response.user_id
       const status = await resetResendOtp(id)
       if(status === "success"){
        this.setState({counter:45})
       }
      }
    
      handleCountValue = (val) => {
        this.setState({counter:this.state.counter - 1})
      }

    render() {
        const schema = yup.object().shape({
            password: formikFormalidation.password,
            confirmpassword: formikFormalidation.confirmpassword,
            pin1: formikFormalidation.pin1,
            pin2: formikFormalidation.pin2,
            pin3: formikFormalidation.pin3,
            pin4: formikFormalidation.pin4,
            pin5: formikFormalidation.pin5,
            pin6: formikFormalidation.pin6,
        });

        const {
            emailotpsent,
        } = this.state;

        return (
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values) => {
                    const emailOTP = "";
                    const verificationcode = emailOTP.concat(
                        values.pin1,
                        values.pin2,
                        values.pin3,
                        values.pin4,
                        values.pin5,
                        values.pin6
                    );
                    values.verificationcode = verificationcode;
                    values.user_id = this.state.user_id;
                    this.setState({ loading: true });
                    await resetpassword(this.props, values);
                    this.setState({ loading: false });
                }}
                initialValues={this.state.allFormData}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                }) => (
                    <FullLayout className="app-login" isHeader={false} isFooter={false}>
                        <Container>
                            <Row>
                                <Col lg={8} md={6} sm={12}>
                                    &nbsp;
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <Card className="p-5 rightcol">
                                        <Card.Header as="h2" className="px-2">
                                            Reset Password
                                        </Card.Header>
                                        <Card.Body className="p-2">
                                            {this.state.loading && (
                                                <div className="d-flex justify-content-center flex-nowrap">
                                                    <div
                                                        className="position-absolute mx-3 p-4"
                                                        variant="primary"
                                                    >
                                                        <Spinner animation="border" variant="primary" />
                                                    </div>
                                                </div>
                                            )}

                                            <form
                                                noValidate
                                                //  validated={this.state.validated}
                                                onSubmit={handleSubmit}
                                            >
                                                <fieldset disabled={this.state.loading}>
                                                    {(() => {
                                                        if (emailotpsent) {
                                                            return (
                                                                <div>
                                                                    <p className="d-block">
                                                                        Enter email/phone verification code
                                                                    </p>
                                                                    <div>
                                                                        <TextField values={values}
                                                                            onChange={handleChange} hadlePastedData={this.hadlePastedData}
                                                                            errors={errors} />
                                                                    </div>

                                                                    <div>
                                                                        {Object.keys(errors).length !== 0 &&
                                                                            (() => {
                                                                                for (const err in errors) {
                                                                                    if (errors[err].length === 32) {
                                                                                        return (
                                                                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                                                                Enter only small letters and numeric values
                                                                                            </span>
                                                                                        );
                                                                                    } else if (errors[err].length <= 4)
                                                                                        return (
                                                                                            <span style={{ fontSize: "12px", color: "red" }}>
                                                                                                OTP fields can't be empty
                                                                                            </span>
                                                                                        );
                                                                                }
                                                                            })()}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })()}
                                                    <br />
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicNewpassword"
                                                    >
                                                        <Form.Control
                                                            autoComplete="off"
                                                            placeholder="New password"
                                                            name="password"
                                                            type={this.state.showPassword ? "text" : "password"}
                                                            required
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            isInvalid={errors.password}
                                                            size="xs"
                                                        />
                                                        {!this.state.showPassword && <FaEyeSlash onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                        {this.state.showPassword && <FaEye onClick={() => this.handleShowPassword("password")} className="loginpasswordeye" />}
                                                        <Form.Control.Feedback type="invalid">
                                                            <p> {errors.password}</p>
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicConfirmpassword"
                                                    >
                                                        <Form.Control
                                                            autoComplete="off"
                                                            placeholder="Confirm password"
                                                            name="confirmpassword"
                                                            type={this.state.showConfirmPassword ? "text" : "password"}
                                                            dependencies={["password"]}
                                                            required
                                                            value={values.confirmpassword}
                                                            onChange={handleChange}
                                                            isInvalid={errors.confirmpassword}
                                                            size="xs"
                                                        />
                                                        {!this.state.showConfirmPassword && <FaEyeSlash onClick={() => this.handleConfirmShowPassword("password")} className="loginpasswordeye" />}
                                                        {this.state.showConfirmPassword && <FaEye onClick={() => this.handleConfirmShowPassword("password")} className="loginpasswordeye" />}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.confirmpassword}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <p className='font-weight-bold mt-4' style={{ fontSize: "14px" }}>{
                                                        this.props.location.state && this.props.location.state.response && this.props.location.state.response.status === "success" && this.state.counter > 0
                                                            ? <OtpTimer handleCount={this.handleCountValue} count={this.state.counter} /> : <span>Didn't received an OTP?<span onClick={() => this.handleResendOtp()} className='ml-2' style={{ color: "#143C8C",cursor:"pointer" }}>ResendOTP</span></span>
                                                    }</p>
                                                    {(() => {
                                                        if (this.props.location.state.response.status === "success") {
                                                            return (
                                                                <Button
                                                                    type="submit"
                                                                    className="ml-0 btn-block "
                                                                    variant="primary"
                                                                >
                                                                    SUBMIT
                                                                </Button>
                                                            );
                                                        }
                                                    })()}
                                                </fieldset>
                                            </form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </FullLayout>
                )}
            </Formik>
        );
    }
}
export default ResetPassword;

const APIENDPOINT = {
	SIGNIN: "/authenticate/v1",
	SIGNUP: "/user",
	REGISTRATION_OTP: '/user/validateotp',
	FORGOT_PASSWORD: '/forgotpassword',
	RESET_PASSWORD: '/forgotpassword/changepassword',
	PROFILE_DETAILS: '/user/profile',
	SPECIALITY_DETAILS: '/search/speciality',
	FETCH_TWILIO_TOKEN: '/twilio/video/token',
	GET_MASTER_DATA: '/user/profile/masterdata',
	GET_OPINION: '/user/enquiry',
	GET_PATIENT_OPINION: '/patient/enquiry',
	CONTACT_USER:'/user/contact',
	EMAIL_SUBSCRIBE: '/user/email',
	SYMPTOMS_DETAILS: '/search/symptoms',
	RESEND_OTP:"/user/resendotp",
	RESET_PASSWORD_RESENDOTP:"/forgotpassword/resend-verification-code",
	
	INTERNALUSER: {
		VERIFY_NPI: "/search/npi",
		VERIFYACTIVATIONLINK: '/forgotpassword/activatelogin',
		INTERNALRESETPASSWORD: '/forgotpassword/internal/changepassword',
	},
	ADMIN: {
		LISTUSERS: "/users?showActive=true&userType=internal",
		LISTPROVIDERS: "/users?userType=provider",
		GETSTAGES: "/search/stages",
		BULK_PROVIDER_ASSIGNEE: "provider/update",
		BULK_ENQUIRY_ASSIGNEE: "patient/update",
		PROVIDER_ASSIGNEE: "patient/provider-update",
		CREATE_NEW_PROVIDER: "/provider/create",
		CREATE_NEW_LOCAL_PROVIDER: '/provider/create/localprovider',
		LISTLOCALPROVIDERS: "/provider/localprovider",
		BULK_LOCAL_PROVIDER_ASSIGNEE: "provider/update",
		ADD_USER:"/add_user",
		PATIENT: "/users?userType=patient",

	},
	PATIENT: {
		LISTENQUIRIES: "/patient/enquiry",
		UPDATE_ENQUIRY: "/patient/enquiry",
		REMOVE_FILE: "/patient",
		NON_AUTH_GET_ENQUIRY: "/patient/customer-enquiry",
		GENERATE_CONSULTATION_OTP: "/user/consultation/otp",
		VERIFY_CONSULTATION_OTP: "/user/consultation/verify-otp",
		CONFIRM_APPOINTMENT_BOOKING: "/user/consultation/processorder",
		LISTAPPOINTMENTS: "/consultation/patient/",
		RESCHEDULE_CONSULATION: "consultation/reschedule",
		UPDATE_CONSULTATION: "/consultation/updatedocuments",
		APPOINTMENT_DETAIL_BY_ID: "/patient/consultation/",
		ENCRYPT_ID : "patient/encryptdata",
		GENERATE_ORDER_CODE : '/user/consultation/createordercode',
		FEEDBACK_QUESTIONS:"/feedback/metaquestion",
		FEEDBACK_POST_PATIENT:"/feedback/patient"
	},
	PROVIDER: {
		GET_PROVIDER_BY_SPECIALITY: "/provider/speciality/",
		SAVE_PROVIDER_SCHEDULE: "/provider/saveproviderschedule",
		GET_PROVIDER_SCHEDULE: "/provider/getproviderschedule/",
		LISTAPPOINTMENTS: "/consultation/provider/",
		SEARCH_PROVIDERS: "/provider/speciality/",
		GET_TIMEZONES:"/provider/gettimezonelist",
		GET_TIMEZONE_AVAIL_SLOTS:"/provider/gettimezoneslot/",
		FEEDBACK_POST_PROVIDER:"/feedback/provider"
	},
	LOCALPROVIDER: {
		LIST_LOCAL_PATIENTS:"/user/profile",
		CREATE_LOCAL_PATIENT: "/patient/createpatient",
		LISTAPPOINTMENTS: "/consultation/localprovider/",
	}
}


export { APIENDPOINT }

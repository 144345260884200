import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import './Section5.css'

export class Section5 extends Component {
    render() {
        return (
            <div className='bg-white pb-5 section5'>
                <Card className="bg-dark text-white">
                    <Card.Img src="/ward.png" alt="Card image" className='card-Img' />
                    <div className="card-img-overlay figcaption container" style={{ background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3))` }}>
                        <div className="over-the-img">
                            <h6>OUR INTIATIVES</h6>
                            <p>With your help, consultation fees have provided life- saving treatment to over 13.8  million people And our work isn’t done yet.</p>
                        </div>

                    </div>
                </Card>
            </div>
        )
    }
}

export default Section5
import { GET, POST, PUT } from "../../api"
import { APIENDPOINT } from "../../constants/APIEndpoint";
import Role from '../../constants/role'


/**
 * Get Provider List information 
 */
function getProviders() {
  let url = APIENDPOINT.ADMIN.LISTPROVIDERS;
  return GET(url);
}
/**
 * Verify Provider by NPI information 
 */
function getNPIDetails(npi_id) {
  let url = APIENDPOINT.INTERNALUSER.VERIFY_NPI + "/" + npi_id;
  return GET(url);
}

/**
 * Get Stages List information 
 */
function getStages() {
  let url = APIENDPOINT.ADMIN.GETSTAGES;
  return GET(url);
}
function getSpecialites() {
  let url = APIENDPOINT.SPECIALITY_DETAILS;
  return GET(url)
}
function getTimezoneList() {
  let url = APIENDPOINT.PROVIDER.GET_TIMEZONES;
  return GET(url)
}
/**
 * Update Provider Profile Information 
 */
const updateProfile = async (dataObj) => {
  const formData = dataObj.formData
  const currentUserData = dataObj.currentProvider
  if (currentUserData && currentUserData.providerInfo.length > 0) {
    const currentProviderData = currentUserData.providerInfo[0] || []
    var data = {
      "firstname": formData.firstname,
      "lastname": formData.lastname,
      "email": formData.email,
      "phone": currentUserData.phone,
      "countrycode": currentUserData.countrycode,
      "speciality": formData.speciality,
      "taxonomy": formData.taxonomy,
      "usertype": currentUserData.usertype,
      "experience": formData.experience,
      "npi": formData.NPI,
      "user_id": currentUserData._id,
      "usertype_id": currentProviderData._id,
      "stage_id": formData.stage_id || "",
      "assignee_id": formData.assignee_id || "",
      "status": formData.provider_status ? "Active" : "Inactive",
      "timezone": formData.timezone
    };
    return POST(APIENDPOINT.PROFILE_DETAILS, data)
    // await POST(APIENDPOINT.PROFILE_DETAILS, data).then((response) => {
    //   if (response.data.status === "success") {
    //     message.success(response.data.message);
    //   } else {
    //     message.error(response.data.message);
    //   }
    // }).catch((err) => {
    //   message.error(err.message);
    // });
  }
}

/**
 * Bulk Update Assignee for Provider Verification 
 */
const bulkUpdateAssignee = async (dataObj) => {
  //const formData = dataObj.formData
  const select_user_obj = dataObj && dataObj.select_user_obj
  const provider_ids = dataObj.provider_ids
  var data = {
    "provider_ids": provider_ids || [],
    "assignee_id": select_user_obj._id || ""
  };
  return PUT(APIENDPOINT.ADMIN.BULK_PROVIDER_ASSIGNEE, data)
  // await PUT(APIENDPOINT.ADMIN.BULK_PROVIDER_ASSIGNEE, data).then((response) => {
  //   if (response.data.status === "success") {
  //     message.success(response.data.message);
  //   } else {
  //     message.error(response.data.message);
  //   }
  // }).catch((err) => {
  //   message.error(err.message);
  // });
}

const createNewProvider = async (formDetails) => {
  var data = {
    "firstname": formDetails.formData.firstname,
    "lastname": formDetails.formData.lastname,
    "npi": formDetails.formData.NPI,
    "phone": formDetails.nationalNumber,
    "countrycode": formDetails.countryCallingCode,
    "email": formDetails.formData.email,
    "username": btoa(formDetails.formData.username),
    "usertype": Role.provider.name,
    "schedules": formDetails.schedules,
    "timezone": formDetails.timezone,
    "speciality": formDetails.formData.speciality,
  };

  return POST(APIENDPOINT.ADMIN.CREATE_NEW_PROVIDER, data)
}

const providerListService = {
  getProviders: getProviders,
  getStages: getStages,
  update: updateProfile,
  getNPIDetails: getNPIDetails,
  bulkUpdateAssignee: bulkUpdateAssignee,
  createNewProvider: createNewProvider,
  getSpecialites: getSpecialites,
  getTimezoneList: getTimezoneList
}
export default providerListService;
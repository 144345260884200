import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Col, Row, message, Spin, Skeleton, Divider, Button, Upload, Modal } from 'antd';
import { Layout } from '../../shared';
import EnquiryListService from '../../../services/patient/EnquiryList.Service';
import { NONAUTHROUTE } from "../../../constants/RouterPath"
import { errorMessage } from '../../../utils/ServiceHandler';
import { get_patient_obj, get_user_obj, get_provider_obj, get_provider_user_obj, DescriptionItem, get_price_with_curr } from './EnquiryUtils';
import './enquiry.style.css'

export class EnquirySessionCheckout extends Component {
    formRef = React.createRef();
    layout = {
        labelCol: { span: 24 }
    };
    constructor(props) {
        super(props);
        if (props.location) {
            if (typeof props.location.state === "undefined") {
                props.history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
            }
        }
        this.state = {
            loading: false,
            confirmLoading: false,
            selected_enquiry: {},
            patient_obj: {},
            user_obj: {},
            provider_obj: {},
            provider_user_obj: {},
            schedules: [],
            schedule_slot_id: '',
            selected_slot: '',
            selected_date: '',
            salesOrderId: '',
            session_id: '',
            salesOrderCode: '',
            schedule_date: '',
            schedule_id: '',
        }
    }
    confirmBooking = async () => {
        this.setState({ confirmLoading: true });
        var data = {
            "ordercode": this.state && this.state.salesOrderCode,
        };
        try {
            let response = await EnquiryListService.confirmAppointmentBooking(data);
            if (response.data.status === "success") {
                this.setState({ confirmLoading: false });
                this.navigateToAppointmentConfirmation()
            } else {
                this.setState({ confirmLoading: false });
                message.error(response.data.message);
            }
        } catch (error) {
            errorMessage(error)
            this.setState({ confirmLoading: false });
        }
    }

    navigateToAppointmentConfirmation() {
        this.props.history.push({
            pathname: NONAUTHROUTE.PATIENT.APPOINTMENT_CONFIRMATION,
            state: {
                selected_enquiry: this.state.selected_enquiry,
                patient_obj: this.state.patient_obj,
                user_obj: this.state.user_obj,
                provider_obj: this.state.provider_obj,
                provider_user_obj: this.state.provider_user_obj,
                schedule_slot_id: this.state.schedule_slot_id,
                selected_slot: this.state.selected_slot,
                selected_date: this.state.selected_date,
                schedule_id: this.state.schedule_id,
                schedule_date: this.state.schedule_date,
                salesOrderId: this.state.salesOrderId,
                session_id: this.state.session_id,
                salesOrderCode: this.state.salesOrderCode,
            }
        });
    }

    getEnquiryDetails = async () => {
        try {
            this.setState({ loading: true })
            const state_vals = this.props && this.props.location && this.props.location.state
            if (state_vals) {
                const _enquiry_response = state_vals && state_vals.enquiry_response
                const _enquiry = state_vals && state_vals.selected_enquiry
                const _patient_obj = get_patient_obj(_enquiry_response)
                const _user_obj = get_user_obj(_enquiry_response)
                const _provider_obj = get_provider_obj(_enquiry_response)
                const _provider_user_obj = get_provider_user_obj(_provider_obj)
                const _schedules = _enquiry_response.schedules
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({
                        selected_enquiry: _enquiry,
                        patient_obj: _patient_obj,
                        user_obj: _user_obj,
                        provider_obj: _provider_obj,
                        provider_user_obj: _provider_user_obj,
                        schedules: _schedules,
                        schedule_slot_id: state_vals && state_vals.schedule_slot_id,
                        selected_date: state_vals && state_vals.selected_date,
                        selected_slot: state_vals && state_vals.selected_slot,
                        salesOrderId: state_vals && state_vals.salesOrderId,
                        session_id: state_vals && state_vals.session_id,
                        salesOrderCode: state_vals && state_vals.salesOrderCode,
                        schedule_id: state_vals && state_vals.schedule_id,
                        schedule_date: state_vals && state_vals.schedule_date,
                    })
                }, 500)
            } else {
                this.setState({ loading: false })
                message.error("Login to continue")
                this.props.history.push(NONAUTHROUTE.GENERAL.LOGINPATH)
            }

        } catch (error) {
            this.setState({ loading: false })
            errorMessage(error)
        }
    }

    componentDidMount() {
        const { history } = this.props;
        history.listen((newLocation, action) => {
        });
        this.getEnquiryDetails();
    }

    handlePreview = file => {
        if (file && file.filetype && !file.filetype.match('image')) {
            var win = window.open(file.url, '_blank');
            win.focus();
        } else {
            this.setState({
                previewImage: file.url,
                previewVisible: true
            });
        }
    };
    handleImagePreviewCancel = () => {
        this.setState({ previewVisible: false });
    };

    render() {
        const style = { background: '#fff', padding: '10px' };
        const { previewVisible, previewImage } = this.state;
        const showUploadList = ({
            showPreviewIcon: true,
            showRemoveIcon: false,
        });
        return (
            <Layout className="registration" isHeader={true} isFooter={true}>
                <div>
                    <div className="main-wrapper">
                        <section className="section section-search">
                            <div className="container-fluid">
                                <div className="banner-wrapper" style={{ maxWidth: '950px' }}>
                                    <Skeleton loading={this.state.loading} active avatar>
                                        <Spin spinning={this.state.loading}>
                                            <Row gutter={[16, 24]}>
                                                <Col className="gutter-row" span={16} xs={24} sm={24} md={12} lg={16} xl={16}>
                                                    <div style={style}>
                                                        <p className="site-description-item-profile-p">Enquiry Details</p>
                                                        <DescriptionItem title="Speciality" content={this.state.selected_enquiry && this.state.selected_enquiry.speciality && this.state.selected_enquiry.speciality.name} />
                                                        <DescriptionItem title="Description" content={(this.state.selected_enquiry && this.state.selected_enquiry.enquiry_message) || ""} />
                                                        <DescriptionItem title="Past Reports" />
                                                        <Upload
                                                            accept=".pdf, .jpg,.png,.jpeg,.doc,.docx"
                                                            listType="picture-card"
                                                            fileList={this.state.selected_enquiry && this.state.selected_enquiry.documents}
                                                            showUploadList={showUploadList}
                                                            onPreview={this.handlePreview} />
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" span={8} xs={24} sm={24} md={12} lg={8} xl={8}>
                                                    <div style={style}>
                                                        <p className="site-description-item-profile-p">Booking Details</p>
                                                        <DescriptionItem title="Doctor Name" content={this.state.provider_obj && "Dr. " + this.state.provider_obj.displayname} />
                                                        <DescriptionItem title="Date" content={this.state && this.state.selected_date} />
                                                        <DescriptionItem title="Time" content={this.state && this.state.selected_slot} />
                                                        <DescriptionItem title="Consulting Fee" content={this.state.provider_obj && (get_price_with_curr(this.state.provider_obj.price) || "-")} />
                                                        <Divider />
                                                        <Button type="primary" htmlType="submit"
                                                            loading={this.state.confirmLoading}
                                                            onClick={this.confirmBooking} style={{ width: '100%' }}>
                                                            Confirm Booking
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Spin>
                                    </Skeleton>
                                    <Modal
                                        visible={previewVisible}
                                        footer={null}
                                        onCancel={this.handleImagePreviewCancel}
                                    >
                                        <img alt="example" style={{ width: "100%" }} src={previewImage} />
                                    </Modal>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default withRouter(EnquirySessionCheckout)
import React from 'react';
import { message } from 'antd';
import { authenticationService } from '../../general/login/login.service';
import { errorMessage } from '../../../utils/ServiceHandler';
import AppointmentService from '../../../services/provider/Appointment.Service';
import { AUTHROUTE } from "../../../constants/RouterPath";
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { parseAppointments, get_schedule_details_obj, get_room_details_obj } from '../../patient/appointment/Utils';
import { get_formated_date_only_provider, get_formated_time_only_provider, formateName } from '../../patient/enquiry/EnquiryUtils';
import BootstrapTable from 'react-bootstrap-table-next';
import { Dropdown, Button, Badge, Spinner } from 'react-bootstrap';
import { FaVideo } from 'react-icons/fa';
import '../../patient/appointment/Appointment.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom'
import { get_provider_user_obj } from '../../patient/appointment/Utils';
import { get_provider_obj, get_patient_obj, get_patient_user_obj, get_speciality_obj } from '../../patient/enquiry/EnquiryUtils';
import moment from 'moment';
import { RiCalendar2Line } from "react-icons/ri";


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button variant='link'
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </Button>
));
export class AppointmentProviderView extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      consultations: [],
      past: [],
      upcoming: [],
      previewVisible: false,
      previewImage: "",
      fileList: [],
      currentUser: {},
      viewer_visible: false,
      reschedule_visible: false,
      filterItems: ['upcoming'],
      showList: [],
      selected_appointment: {},
      showFilterList: [],
      // viewMore: false
    }
  }


  getVals() {
    const currentItem = this.state.selected_appointment
    const currentUser = authenticationService.currentUserValue;
    //const all_specialities = this.props && this.props.all_specialities
    const patient_obj = get_patient_obj(currentItem)
    const user_obj = get_patient_user_obj(currentItem)
    const provider_obj = get_provider_obj(currentItem)
    const provider_user_obj = get_provider_user_obj(currentItem)
    const speciality_obj = get_speciality_obj(this.state.selected_appointment.consultationInfo)

    this.setState({
      currentItem: currentItem,
      consultationInfo: this.state.selected_appointment.consultationInfo,
      user_obj: user_obj,
      patient_obj: patient_obj,
      provider_obj: provider_obj,
      provider_user_obj: provider_user_obj,
      currentUser: currentUser,
      speciality_obj: speciality_obj
      //all_specialities: all_specialities
    })
  }


  navigatetoVideoCall = () => {
    const room_details_obj = get_room_details_obj(this.state && this.state.selected_appointment.consultationInfo)
    if (room_details_obj) {
      this.props && this.props.history && this.props.history.push({
        pathname: AUTHROUTE.GENERAL.TWILIO_VIDEO_PREJOIN_SCREEN,
        state: {
          currentItem: this.state.currentItem,
          consultationInfo: this.state.selected_appointment.consultationInfo,
          user_obj: this.state.user_obj,
          patient_obj: this.state.patient_obj,
          provider_obj: this.state.provider_obj,
          provider_user_obj: this.state.provider_user_obj,
          currentUser: this.state.currentUser,
        }
      });
    } else {
      message.error("Issue while initiating the Video call. Room details not available")
    }
  };

  handleTableFilter = async (data, filterType) => {
    await this.setState({ showFilterList: [] });
    await this.setState({
      filterItems: await Array.from(new Set([...this.state.filterItems, data]))
    })
    if (this.state.filterItems.includes('upcoming')) {
      await this.setState({ showFilterList: await this.state.upcoming });
    }
    if (this.state.filterItems.includes('past')) {
      await this.setState({ showFilterList: await [...this.state.showFilterList, ...this.state.past] })
    }
    this.renderFilteredList(data, filterType);
  }



  handleRemoveFilter = async (index) => {
    await this.state.filterItems.splice(index, 1);
    await this.setState({ showFilterList: [] });
    if (this.state.filterItems.includes('upcoming')) {
      await this.setState({ showFilterList: await this.state.upcoming });
    }
    if (this.state.filterItems.includes('past')) {
      await this.setState({ showFilterList: await [...this.state.past] })
    }
    if (this.state.filterItems.length === 0) {
      await this.setState({ showFilterList: await [...this.state.upcoming, ...this.state.past] })
    }
    this.renderFilteredList();
  }

  renderFilteredList(data) {
    this.setState({
      showList: data
    })
  }





  view_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record, viewer_visible: true });
      }
    }, 1);
  }

  reschedule_record = (record) => {
    setTimeout(() => {
      if (record) {
        this.setState({ selected_appointment: record, reschedule_visible: true });
      }
    }, 1);
  }

  onClose = () => {
    this.setState({
      viewer_visible: false,
      reschedule_visible: false
    });
  };

  handleResponse = async (response) => {
    if (response.data.status === "success") {
      if (response && response.data && response.data.data) {
        if (response.data.data.length > 0) {
          const _consultation = parseAppointments(response.data.data)
          this.setState({
            upcoming: await _consultation && _consultation.upcoming.map(x => {
              x.shown_status = 'upcoming';
              return x;
            }),
            past: await _consultation && _consultation.past.map(x => {
              x.shown_status = 'closed';
              return x;
            }),
            loading: false
          });
          this.state.upcoming && this.state.upcoming.sort((a, b) => {
            const date1 = new Date(`${a?.consultationInfo?.scheduleDetailsInfo?.date}${" "}${a?.consultationInfo?.scheduleDetailsInfo?.slot}`).getTime()
            const date2 = new Date(`${b?.consultationInfo?.scheduleDetailsInfo?.date}${" "}${b?.consultationInfo?.scheduleDetailsInfo?.slot}`).getTime()
            return date1 - date2;

          })
          this.setState({
            showFilterList: this.state.upcoming
          })

        }
      }
    } else {
      this.setState({ loading: false })
      message.error(response.data.message);
    }
  }

  getAppointments = async () => {
    try {
      this.setState({ loading: true });
      const currentUser = authenticationService.currentUserValue;
      this.setState({ loading: true })

      let response = await AppointmentService.getProviderAppointments(currentUser.usertype_id);
      this.handleResponse(response)

    } catch (error) {
      errorMessage(error)
      this.setState({ loading: false })
    }
  }



  showModal = () => {
    this.setState({
      modalVisible: true,
      fileList: []
    });
  };

  componentDidMount() {
    this.getAppointments()
    this.getVals()
  }


  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="loadmore" role="group">
        {
          options.map(option => (
            <Button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary invisible' : 'btn-warning invisible'}`}
            >
              {'load more'}
            </Button>
          ))
        }
        <Button variant="link" onClick={() => onSizePerPageChange(Number(currSizePerPage) + 10)} className="load-more">Load more</Button>
      </div>
    );

    const options = {
      sizePerPage: 10,
      disablePageTitle: false,
      sizePerPageRenderer,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '20', value: 20
      }]
    };
    const columns = [
      {
        text: 'PATIENT',
        dataField: 'patientUserInfo.firstname',
        formatter: (text, record) => (
          <>
            {(() => {
              return formateName(record.patientUserInfo ? record.patientUserInfo.firstname : '')
            })
              ()}
          </>
        )
        // key: ['patientInfo', '_id'],
      },
      {
        text: 'TIME',
        dataField: 'scheduleDate',
        formatter: (text, record) => (
          <>
            {(() => {
              let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)
              const verifyDateFormat = moment(schedule_obj.slot)
              let attDateAndTime = ""
              if (verifyDateFormat.isValid()) {
                attDateAndTime = schedule_obj.slot
              } else {
                attDateAndTime = moment(schedule_obj.date + ' ' + schedule_obj.slot, 'YYYY-MM-DD HH:mm A').format()
              }
              let time_format = get_formated_time_only_provider(attDateAndTime)
              // let relative_hrs = get_relative_time(schedule_obj.slot)
              return (<React.Fragment>
                <span>{time_format} {record.consultationInfo.timename}</span>
              </React.Fragment>
              )
            })()}
          </>
        ),
      },
      {
        text: 'DATE',
        dataField: 'scheduleDate',
        formatter: (text, record) => (
          <>
            {(() => {
              let schedule_obj = get_schedule_details_obj(record && record.consultationInfo)
              const verifyDateFormat = moment(schedule_obj.slot)
              let attDateAndTime = ""
              if (verifyDateFormat.isValid()) {
                attDateAndTime = schedule_obj.slot
              } else {
                attDateAndTime = moment(schedule_obj.date + ' ' + schedule_obj.slot, 'YYYY-MM-DD HH:mm A').format()
              }
              let time_format = get_formated_date_only_provider(attDateAndTime)
              return (<React.Fragment>
                <span>{time_format}</span>
              </React.Fragment>
              )
            })()}
          </>
        ),
      },


      {
        text: 'STATUS',
        dataField: 'shown_status',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.shown_status === 'upcoming')
                return (<React.Fragment>
                  <Badge style={{ backgroundColor: '#E3F7DE', color: '#52883A', fontSize: '14px' }}>Upcoming </Badge>
                </React.Fragment>
                )
              if (record.shown_status === 'closed')
                return (<React.Fragment>
                  <Badge style={{ backgroundColor: '#C7C7C7', color: '#858585', fontSize: '14px' }}>Closed </Badge>
                </React.Fragment>
                )
            })()}
          </>
        ),

      },
      {
        text: ' ',
        dataField: '',
        formatter: (text, record) => (
          <>
            {(() => {
              if (record.shown_status === 'upcoming')
                return (<React.Fragment>
                  <div className='actionHolder' onMouseOver={() => this.view_record(record)}>
                    <div className="joinBtn">
                      < FaVideo className='text-info mx-2' />
                      <Button style={{ alignItems: 'center' }} variant='link' onClick={this.navigatetoVideoCall.bind(this, record)}> Join </Button>
                    </div>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              <Link to={{ pathname: AUTHROUTE.PROVIDER.APPOINTMENT_PROVIDER, state: { selected_appointment: record } }}>View </Link>
                            </Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>

                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              else {
                return (<React.Fragment>

                  <div className='actionHolder'><p style={{ width: "70px" }}>      _     </p>
                    <div className="joinHolder">
                      <div className="moreBtn">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />
                          <Dropdown.Menu title="">
                            <Dropdown.Item>
                              <Link to={{ pathname: AUTHROUTE.PROVIDER.APPOINTMENT_PROVIDER, state: { selected_appointment: record } }}>View </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              }
            })()}
          </>
        ),
        // key: ['patientInfo', '_id'],
      },
      // {
      //   text: ' ',
      //   dataField: '',
      //   formatter: (text, record) => (
      //     <>
      //       {(() => {
      //         if (record.shown_status === 'upcoming')
      //           return (<React.Fragment>
      //             <div className="joinHolder">
      //               <div className="moreBtn">
      //                 <Dropdown>
      //                   <Dropdown.Toggle as={CustomToggle} />
      //                   <Dropdown.Menu title="">
      //                     <Dropdown.Item>
      //                       <Link to={{ pathname: AUTHROUTE.PROVIDER.APPOINTMENT_PROVIDER, query: { selected_appointment: record } }}>View
      //                       </Link>
      //                     </Dropdown.Item>
      //                   </Dropdown.Menu>
      //                 </Dropdown>
      //               </div>
      //             </div>
      //           </React.Fragment>
      //           )
      //       })()}
      //     </>
      //   ),
      //   // key: ['patientInfo', '_id'],
      // },

    ]

    return (

      <React.Fragment>
        <AfterLoginLayout>
          <div className="container-fluid">
            <div className="banner-header">
              <h2>Your Appointments</h2>
            </div>
            <Dropdown className="float-right">
              <Dropdown.Toggle variant="primary" id="filter-dropdown" style={{ marginright: '40%' }}>
                Filter
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleTableFilter('upcoming', 'addFilter')}>Upcoming</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleTableFilter('past', 'addFilter')}>Closed</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div>
              {this.state.filterItems?.map((data, index) => (
                <Button key={index} style={{ backgroundColor: 'white', color: '#252930', borderRadius: '30px' }} variant="outline-info" onClick={() => this.handleRemoveFilter(index)}>{data === 'past' ? 'closed' : data} x</Button>
              ))}
            </div>
            {this.state.loading &&
              <div className='d-flex justify-content-center flex-nowrap'>
                <div className='position-absolute mx-3 p-4' variant="primary">
                  <Spinner animation="border" variant="primary" className='spin' />
                </div>
              </div>
            }


            {this.state.showFilterList.length > 0 ?
              <BootstrapTable keyField='_id'
                data={this.state.showFilterList}
                hover={false}
                columns={columns}
                bordered={false}
                pagination={paginationFactory(options)}
              /> : <div>
                {this.state.loading ? "" :
                  <div>
                    <div style={{ marginTop: "15px" }} className="card">
                      <div className="card-body text-center">
                        <RiCalendar2Line size={60} className='text-secondary' />
                        <h6>No appointments</h6>
                        <small><p className='text-secondary'>We are finding the right doctor to provide you proper care. You will be notified within 24 hours.
                        </p></small>
                      </div>
                      {/* {this.state.openModal && <OpinionRequest displayname={this.state.currentUser && this.state.currentUser.displayname} patient_id={this.state.currentUser && this.state.currentUser.usertype_id} openModal={this.state.openModal} setOpenModal={this.handleClose} />} */}

                    </div>


                  </div>}
              </div>}
          </div>
        </AfterLoginLayout >
      </React.Fragment >
    )
  }
}

export default AppointmentProviderView
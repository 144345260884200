import React, { Component } from 'react'
import { Card, Row, Col, Container, Modal, Button } from 'react-bootstrap'
import { NONAUTHROUTE } from '../../../../constants/RouterPath';
import GetOpinion from '../GetOpinion/GetOpinion';
import './Section6.css'

export class Section6 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            newUserVal: false
        }
    }

    render() {
        const handleClose = (e) => {
            this.setState({ showModal: false })
        }
        return (
            <div className='bg-white py-4 section6'>
                <Container>
                    <Card className='card'>
                        <Row className='no-glutters'>
                            <Col className='rel-col'>
                                <Card.Body>
                                    <div className="content-holder">
                                        <h3 className='mt-5'>  Your appointment fee <br /> contributes to the Global Fund’s <br /> support programmes.</h3>
                                        <div className='my-5'>
                                            <Button className='btn btn-primary btn-lg my-4 px-4' style={{ fontSize: '16px' }} onClick={() => this.setState({ showModal: true, newUserVal: true })}>Get an opinion</Button>
                                            <a href={NONAUTHROUTE.PROVIDER.REGISTRATIONPATH} className='btn button2  px-4'>Register as a doctor</a>
                                        </div>
                                    </div>



                                </Card.Body>
                            </Col>
                            <Col md={5} lg={5} className="ab-col"  >
                                <div className="ab-img-holder">
                                    <Card.Img src="/registerDoctor.png" alt='doctor' className='doctorImg' />

                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <Modal show={this.state.showModal} onHide={handleClose}
                    backdrop="static"
                    keyboard={true}
                    centered={false}
                    size="xl"
                    className='modalData'
                >
                    <Modal.Header style={{ border: "0" }} closeButton>
                    </Modal.Header>
                    <Modal.Body><GetOpinion {...this.props} handleClose={handleClose} /></Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default Section6
import React from 'react';
import { AfterLoginLayout } from '../../shared/AfterLoginLayout';
import { connect } from 'react-redux'
import { getProfileDetails } from '../../../actions/provider/ProfileAction'
import { errorMessage } from '../../../utils/ServiceHandler';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import "./providerProfile.style.css"
import ProfileService from '../../../services/provider/Profile.Service';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap'
import { formikFormalidation } from '../../general/formvalidator/validationFroms'
import * as yup from 'yup'
import { FaUserAlt } from "react-icons/fa";

export class ProviderProfileComponent extends React.Component {
  formRef = React.createRef();
  layout = {
    labelCol: { span: 24 }
  };
  constructor(props) {
    super(props);
    this.state = {
      all_specialities: [],
      user_taxonomies: [],
      timezonelist: [],
      user: {},
      providerData: {
        firstname: "", speciality: "", taxonomy: "", timezone: "", npi: "", experience: "",
        phone: "", countrycode: "", middlename: "", lastname: "", load: false, profilestatus: ""
      },
      taxonomy: "",
    }

  }

  getTimezoneList = async () => {
    const responses = await ProfileService.getTimezoneList();
    if (responses.data.status === "success") {
      this.setState({ timezonelist: responses.data.data });
    }
  }
  componentDidMount() {
    this.getTimezoneList();
    this.props.getProfileDetails().then(async (profileData) => {

      const { firstname, countrycode, phone, email, providerInfo, middlename, lastname, timezone } = await profileData.users;
      const { experience, npi } = await providerInfo[0];
      const specialities = await profileData.specialities;
      const speciality = await providerInfo && providerInfo[0] && providerInfo[0].speciality && providerInfo[0].speciality.length === 1 ? providerInfo[0].speciality[0] : providerInfo[0].speciality;
      const taxonomy = await providerInfo && providerInfo[0] && providerInfo[0].taxonomy && providerInfo[0].taxonomy[0] ? providerInfo[0].taxonomy[0] : "";
      const special = await speciality && specialities.filter(item => item._id === speciality)
      const profilestatus = await providerInfo[0].status;

      await specialities.map((data) => {
        if (data._id === speciality) {
          this.setState({ user_taxonomies: data.taxonomies })

        }
        else if (speciality === "") {
          this.setState({ user_taxonomies: specialities[0].taxonomies })
        }

        return data;
      })
      if (this.state.user_taxonomies.length === 0)
        this.setState({ user_taxonomies: specialities[0].taxonomies })

      this.setState({
        providerData: {
          ...this.state.providerData, firstname: firstname ? firstname : "", lastname: lastname ? lastname : "",
          middlename: middlename ? middlename : "", load: true,
          email : email? email : "",
          npi: npi ? npi : "", countrycode: countrycode ? countrycode : "",
          phone: phone ? `${countrycode}${phone}` : "+91", speciality: special ? speciality : "",
          taxonomy: taxonomy ? taxonomy : "", experience: experience ? experience : "",
          timezone: timezone ? timezone : "",
          profilestatus: profilestatus ? profilestatus : "Inactive",
        }, all_specialities: specialities, special: special ? special['0'] : "",
      })

    }).catch(err => {
      errorMessage(err)

    })
  }
  handleSelect(value) {
    this.state.all_specialities.map((data) => {
      if (data._id === value) {
        this.setState({ user_taxonomies: data.taxonomies })
      }
      return data;
    })
    this.setState({
      providerData: {
        ...this.state.providerData,
        taxonomy: "",
        speciality: value
      }
    });
  }

  handleSelectTaxonomy(value) {
    if (value) {
      this.setState({
        providerData: {
          ...this.state.providerData,
          taxonomy: value
        }
      });
    }
  }
  render() {

    const schema = yup.object().shape({
      firstname: formikFormalidation.firstname,
      lastname: formikFormalidation.lastnamedr,
      middlename: formikFormalidation.middlename,
      phone: formikFormalidation.phone,
      emergencycontact: formikFormalidation.emergencycontact,
      speciality: formikFormalidation.speciality,
      taxonomy: formikFormalidation.taxonomy,
      npi: formikFormalidation.npi,
      experience: formikFormalidation.experience,
      timezone: formikFormalidation.timezone,


    })
    return (<React.Fragment>
      <AfterLoginLayout>
        {this.state.providerData.load ?

          <Formik
            validationSchema={schema}
            initialValues={this.state.providerData}
            validate={values => {
              const errors = {};      
                if (!this.state.providerData.taxonomy) {
                  errors.taxonomy = 'Taxonomy cannot be empty!';
                }
              if (!values.phone) {
                errors.phone = "Mobile number is required!";
              } else {
                const countryCallingCode = parsePhoneNumber(values.phone)?.countryCallingCode;
                if (!countryCallingCode || !isValidPhoneNumber(values.phone)) {
                  errors.phone = 'Invalid Mobile number format!';
                }
              }
              if (values.emergencycontact) {
                const emergencyCountryCode = parsePhoneNumber(values.emergencycontact) && parsePhoneNumber(values.emergencycontact).countryCallingCode;

                if (emergencyCountryCode !== undefined && emergencyCountryCode === "91" && values.emergencycontact.length !== 13) {
                  errors.emergencycontact = 'Invalid Emergency Mobile number format!';
                }
                if (emergencyCountryCode !== undefined && emergencyCountryCode === "91" && values.emergencycontact.length !== 13) {
                  errors.emergencycontact = 'Invalid Emergency Mobile number format!';
                }
              }
              return errors;
            }}

            onSubmit={async (values) => {
              const phoneCountryCallingCode = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).countryCallingCode
              const phoneNationalNumber = parsePhoneNumber(values.phone) && parsePhoneNumber(values.phone).nationalNumber
              let emergencyCountryCallingCode;
              let emergencyNationalNumber;
              if (values.emergencycontact) {
                emergencyCountryCallingCode = parsePhoneNumber(values.emergencycontact) && parsePhoneNumber(values.emergencycontact).countryCallingCode
                emergencyNationalNumber = parsePhoneNumber(values.emergencycontact) && parsePhoneNumber(values.emergencycontact).nationalNumber
              }
              const { npi, dob, experience, speciality, timezone, taxonomy, user_taxonomies, height, lastname, firstname, middlename, weight, location, profilestatus } = values


              const { gender } = this.state

              const allFormData = {
                countrycode: "+" + phoneCountryCallingCode,
                phone: phoneNationalNumber,
                emergencycontactcountrycode: emergencyCountryCallingCode === undefined ? "" : "+" + emergencyCountryCallingCode,
                emergencycontact: emergencyNationalNumber === undefined ? "" : emergencyNationalNumber,
                speciality: [speciality],
                timezone: timezone,
                taxonomy: taxonomy === "" ? "" : [taxonomy],
                user_taxonomies: user_taxonomies,
                dateOfBirth: dob,
                gender: gender,
                lastname: lastname,
                firstname: firstname,
                middlename: middlename,
                weight: weight,
                email: this.state.providerData.email,
                height: height,
                location: location,
                experience: experience,
                NPI: npi,
                status: profilestatus
              }
              console.log(allFormData,"allFormData")
              await ProfileService.update(allFormData)
              localStorage.setItem("timeZone", timezone)
            }}

          >

            {(({ values, errors, handleChange, touched, handleSubmit, handleBlur }) => (

              <div className="container-fluid">
                <div>
                  <div style={{ 'width': "100%" }} class="row">
                    <div class="pb-3 col-lg-12 col-md-12 col-sm-12">
                      <h3 class="text-left">My Profile</h3>
                    </div>
                  </div>

                </div>
                <div className='p-5 profilescreen'>

                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="media mt-3">
                      <span className="media-left">
                        <FaUserAlt className='mt-1 mr-3  rounded-circle p-1' style={{ background: '#488FB1', color: '#143C8C' }} size={30} />
                      </span>
                      <div className="media-body">
                        <p className='font-weight-light'><small><h4>{values.firstname ? values.firstname : ""}</h4></small>

                          <small><h6 className='ml-0 font-weight-normal'>{this.state?.special ? this.state?.special?.name : ""}</h6></small>
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col form-group mr-2'>
                        <label className="mb-1 font-weight-bold text-dark">
                          First Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <Form.Control isInvalid={errors.firstname || !values.firstname} autoComplete='off' class className='shadow-none'
                          name="firstname" onChange={handleChange} value={values.firstname}
                        />
                        {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                      </div>
                      <div className='col form-group mr-2'>
                        <label className="mb-1 font-weight-bold">Middle Name</label>
                        <Form.Control isInvalid={errors.middlename} autoComplete='off' className='shadow-none'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='middlename' value={values.middlename} />
                        {errors.middlename && <div className="text-danger">{errors.middlename}</div>}
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col form-group'>
                        <label className="mb-1 font-weight-bold">Last Name<span style={{ color: "red" }}>*</span></label>
                        <Form.Control autoComplete='off' isInvalid={errors.lastname}
                          className='shadow-none'
                          onChange={handleChange} name="lastname"
                          onBlur={handleBlur}
                          value={values.lastname} />
                        {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                      </div>
                      <div className='col'>
                        <label className='font-weight-bold mb-1'>Mobile Number<span style={{ color: "red" }}>*</span></label>
                        <Form.Control as={PhoneInput} className="w-100"
                          label="Mobile"
                          placeholder="Enter Mobile number"
                          style={values.phone && isValidPhoneNumber(values.phone) ? { border: '1px solid #d9d9d9' } : { border: '1px solid red' }}
                          international
                          defaultCountry="US"
                          countries={['US', 'IN']}
                          countryCallingCodeEditable={false}
                          limitMaxLength={true}
                          value={values.phone}
                          onChange={e => values.phone = e}
                          isInvalid={errors.phone && touched.phone}
                        />
                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                      </div>

                    </div>


                    {/* phone inp1 */}
                    <div className='row'>
                      <div className='col form-group mr-2'>
                        <label className="mb-1 font-weight-bold">NPI<span style={{ color: "red" }}>*</span></label>
                        <Form.Control isInvalid={errors.npi} autoComplete='off' className='shadow-none'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='npi' value={values.npi} />
                        {errors.npi && <div className="text-danger">{errors.npi}</div>}
                      </div>
                      <div className='col mr-2'>
                        <label className="mb-1 font-weight-bold">Specialization<span style={{ color: "red" }}>*</span></label>

                        <Form.Control as={"select"}
                          name="speciality"
                          className='selectors'
                          isInvalid={errors.speciality && touched.speciality}
                          onChange={event => { handleChange(event); this.handleSelect(event.target.value) }}
                          onBlur={handleBlur}
                          value={values.speciality ? values.speciality : ""}
                          id={values.speciality ? values.speciality : ""}
                        >
                          <option value="">Select</option>
                          {
                            this.state.all_specialities?.map((item) => {
                              const { name, _id } = item;
                              return <option value={_id} key={_id}>{name}</option>
                            })
                          }
                        </Form.Control>
                        {
                          errors.speciality && touched.speciality && (
                            <div className="invalid-feedback">{errors.speciality}</div>
                          )
                        }
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col form-group'>
                        <label className="mb-1 font-weight-bold">Taxonomy <span style={{ color: "red" }}>*</span></label>
                        <Form.Control
                          as={"select"}
                          className='selectors'
                          isInvalid={errors.taxonomy && touched.taxonomy}
                          onChange={(value) => { handleChange(value); this.handleSelectTaxonomy(value.target.value) }}
                          onBlur={handleBlur}
                          name="taxonomy"
                          value={values.taxonomy ? values.taxonomy : ""}
                          id={values.taxonomy ? values.taxonomy : ""}
                        >
                          <option value="">Select</option>
                          {
                            this.state.user_taxonomies && this.state.user_taxonomies?.map((item) => {
                              return <option value={item._id} key={item._id}>{item.code}</option>
                            })
                          }
                        </Form.Control>
                        {
                          errors.taxonomy && touched.speciality && (
                            <div className="invalid-feedback">{errors.taxonomy}</div>
                          )
                        }
                      </div>
                      <div className='col form-group '>
                        <label className="mb-1 font-weight-bold text-dark">
                          Experience(No. of years)<span style={{ color: "red" }}>*</span>
                        </label>
                        <Form.Control
                          isInvalid={errors.experience && touched.experience}
                          autoComplete='off'
                          className='shadow-none'
                          name="experience"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.experience}
                        />
                        {
                          errors.experience && touched.experience && (
                            <div className="invalid-feedback">{errors.experience}</div>
                          )
                        }

                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-6 form-group'>
                        <label className="mb-1 font-weight-bold">Time Zone <span style={{ color: "red" }}>*</span></label>
                        <Form.Control as={"select"}
                          name="timezone"
                          className='form-control shadow-none'
                          isInvalid={errors.timezone && touched.timezone}
                          onChange={handleChange}
                          value={values.timezone ? values.timezone : ""}
                          id={values.timezone ? values.timezone : ""}
                        >
                          <option value="">Select</option>
                          {
                            this.state.timezonelist?.map((item) => {
                              const { name, _id } = item;
                              return <option value={_id} key={_id}>{name}</option>
                            })
                          }
                        </Form.Control>
                        {
                          errors.timezone && <div className="invalid-feedback">{errors.timezone}</div>
                        }
                      </div>
                    </div>

                    <div>
                      <button className='btn btn-primary my-2' type="submit">
                        Save
                      </button>

                    </div>
                  </Form>
                </div>

              </div>


            ))}
          </Formik> : <div className='container d-flex justify-content-center align-items-center' style={{}}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
      </AfterLoginLayout>
    </React.Fragment>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProfileDetails: () => dispatch(getProfileDetails())
  }
}
const mapStateToProps = state => {
  return {
    profileStore: state.providerProfileData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderProfileComponent)